import React, { Component, Fragment } from 'react';
import { CheckIcon, SelectorIcon, LockClosedIcon, SearchIcon } from '@heroicons/react/outline'
import { Listbox, Transition } from '@headlessui/react';
const fuzzysort = require('fuzzysort');

class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locked: false,
            search: "",
            label: "",
            options: [],
            selected: {},
            default: {}
        }
    };

    componentDidMount() {
        this.setState({
            label: this.props.label,
            selected: this.props.selected,
            options: this.props.options,
            default: this.props.default,
            loader: this.props.loader,
            disabled: this.props.disabled,
            locked: this.props.locked,
            channel: this.props.channel,
            loadingSearch: this.props.loadingSearch,
            rightLabelText: this.props.rightLabelText,
            selectedTextColor: this.props.selectedTextColor
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            label: nextProps.label,
            selected: nextProps.selected,
            options: nextProps.options,
            loader: nextProps.loader,
            disabled: nextProps.disabled,
            locked: nextProps.locked,
            channel: nextProps.channel,
            loadingSearch: nextProps.loadingSearch,
            rightLabelText: nextProps.rightLabelText,
            selectedTextColor: nextProps.selectedTextColor
        })
    }

    functions = {
        resetSearch: () => {
            this.setState({
                search: ""
            })
        }
    };

    filter = () => {
        let options = this.state.options;
        if (this.props.searchInput && this.state.search !== "") {
            let result = fuzzysort.go(this.state.search, this.state.options.map((item) => { return item.name }));
            options = [];
            let old_options = JSON.parse(JSON.stringify(this.state.options));
            result.filter((item, index) => {
                return index < 20
            }).map((item) => {
                for (let i = 0; i < old_options.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_options[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_options[i].html = html_string;
                        options.push(old_options[i]);
                        old_options.splice(i, 1);
                        break;
                    }
                }
            });
        }
        return options;
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <Listbox disabled={this.state.disabled} value={this.state.selected} onChange={(value) => {
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            }}>
                {({ open }) => (
                    <>
                        <div className="flex flex-row">
                            {
                                this.state.label && this.state.label !== "" &&
                                <Listbox.Label className={(this.props.proptree ? "text-xs" : "text-xs text-sm") + " font-medium text-gray-900 block"}>
                                    {this.state.label}
                                </Listbox.Label>
                            }
                            {
                                this.props.rightLabel &&
                                <div onClick={() => {
                                    if (this.props.onRightLabelClick) {
                                        this.props.onRightLabelClick();
                                    }
                                }} className="flex flex-1 hover:text-purple-500 justify-end text-xs cursor-pointer font-medium text-gray-700">
                                    {this.state.rightLabelText}
                                </div>
                            }
                        </div>
                        <div onClick={() => {
                            if (this.props.searchInput) {
                                setTimeout(() => {
                                    try {
                                        document.getElementById("test").focus();
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }, 100);
                            }
                        }} className={(this.state.label && this.state.label !== "" ? "mt-1" : "") + " relative"}>

                            {
                                this.state.loader &&
                                <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">

                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    <div className="ml-2 text-xs">
                                        Loading ...
                                    </div>
                                </div>
                            }

                            <Listbox.Button className={(this.props.proptreeLogin ? "bg-white border h-12" : (this.props.proptree ? "bg-white border text-sm h-10" : "shadow-sm border-gray-300 text-sm bg-custom-input h-12")) + " relative w-full rounded-md pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none"}>
                                <span className="flex items-center">
                                    {
                                        this.state.selected.id != 0 && this.state.selected.image &&
                                        <div className="h-5 w-5 mr-3 rounded-full flex-shrink-0 overflow-hidden justify-center flex items-center border bg-white">
                                            <img src={this.state.selected.image} alt="" className="w-auto" />
                                        </div>
                                    }
                                    {
                                        this.state.selected.id != 0 && this.state.selected.color &&
                                        <div className={this.state.selected.color + " h-5 w-5 mr-3 rounded-full overflow-hidden justify-center flex items-center"}>

                                        </div>
                                    }
                                    <span className={(this.props.proptree ? "" : "") + (this.state.disabled ? " opacity-25" : "") + " block truncate"}>{this.state.selected.name}</span>
                                </span>
                                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    {
                                        !this.state.locked &&
                                        <SelectorIcon className={(this.props.proptree ? "" : "text-gray-400") + " h-5 w-5"} aria-hidden="true" />
                                    }
                                    {
                                        this.state.locked &&
                                        <LockClosedIcon className={(this.props.proptree ? "" : "text-gray-400") + " h-5 w-5"} aria-hidden="true" />
                                    }
                                </span>
                            </Listbox.Button>

                            {
                                !this.state.locked &&
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className={(this.props.proptree ? "text-sm font-medium" : "text-md") + " absolute border-1.5 border-gray-300 z-10 mt-1 w-full bg-white border shadow-lg max-h-40 rounded-md p-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"}>
                                        {
                                            this.props.searchInput &&
                                            <div className={(this.filter().length > 0 ? "mb-1" : "") + " bg-gray-100 rounded-md justify-center items-center overflow-hidden flex flex-row"}>
                                                <div className="px-3 text-gray-500 text-opacity-75">
                                                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                                </div>
                                                <input
                                                    type={this.props.type}
                                                    disabled={this.state.disabled}
                                                    className={"bg-gray-100 block font-normal w-full border-none pr-4 py-2 h-12 focus:outline-none text-md"}
                                                    placeholder={this.props.placeholder}
                                                    value={this.state.search}
                                                    onChange={(event) => {
                                                        if (this.props.onSearch) {
                                                            this.props.onSearch(event.target.value);
                                                        }
                                                        this.setState({
                                                            search: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                        <div className="relative">
                                            {
                                                this.props.searchInput &&
                                                this.filter().length > 0 &&
                                                this.state.loadingSearch &&
                                                <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            {
                                                this.filter().map((item) => {
                                                    return (
                                                        <Listbox.Option
                                                            key={item.id}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'text-gray-900 bg-gray-100' : 'text-gray-900',
                                                                    item.id === this.state.selected.id ? this.props.selectedTextColor ? "" : "text-purple-500" : "",
                                                                    'cursor-pointer select-none relative py-2 pl-3 pr-3 rounded-md focus:outline-none h-12 items-center flex'
                                                                )}
                                                            style={item.id === this.state.selected.id && this.props.selectedTextColor ? { color: this.props.selectedTextColor } : {}}
                                                            value={item}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <div className="block truncate flex flex-1 items-center">
                                                                        {
                                                                            item.image &&
                                                                            <div className="h-5 w-5 mr-3 rounded-full overflow-hidden justify-center flex items-center border bg-white">
                                                                                <img src={item.image} alt="" className="w-auto" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.color &&
                                                                            <div className={item.color + " h-5 w-5 mr-3 rounded-full overflow-hidden justify-center flex items-center"}>

                                                                            </div>
                                                                        }
                                                                        <span
                                                                            dangerouslySetInnerHTML={{ __html: item.html ? item.html : item.name }}
                                                                            className={classNames(
                                                                                selected ? (this.props.selectedTextColor ? 'text-current' : 'text-purple-500') : 'font-normal',
                                                                                'block truncate text-sm'
                                                                            )}
                                                                            style={selected && this.props.selectedTextColor ? { color: this.props.selectedTextColor } : {}}
                                                                        >

                                                                        </span>
                                                                        <div className="flex flex-1 justify-end text-sm items-center">
                                                                            {item.count}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Listbox.Options>
                                </Transition>
                            }
                        </div>
                    </>
                )
                }
            </Listbox>
        )
    }
}

export default Dropdown;
