import React, { Component, Fragment } from 'react';
import { PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon, UploadIcon, VideoCameraIcon, DocumentIcon, PhotographIcon, LockOpenIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import { versionRegister } from '../../services/versionRegister';
import SingleSelection from '../../components/singleSelection';
import Dropdown from '../../components/dropdown';
import Input from '../../components/input';
import SuccessModal from '../../components/successModal';
var moment = require('moment');

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            tab: { id: 0, name: 'Security', icon: LockOpenIcon, },
            success: false,
            new_password: "",
            repeat_password: ""
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    componentWillUnmount() {

    }

    functions = {
        getUser: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get() ? userRegister.get() : {},
                    loading: false
                })
            }
        },
        updatePassword: async () => {
            await this.promisedSetState({
                password_error: false,
                loading_password: true
            });
            try {
                await this.calls.updatePassword({ new_password: this.state.new_password, repeat_password: this.state.repeat_password });
                this.setState({
                    new_password: "",
                    repeat_password: "",
                    success: true,
                    loading_password: false
                });
            } catch (error) {
                await this.promisedSetState({
                    password_error: error && error.body ? error.body.message : "Something went wrong",
                    loading_password: false
                });
            }
        },
    };

    calls = {
        updatePassword: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/user/updatePassword";
            return apiRegister.call(options, url);
        }
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 h-full min-h-full bg-gray-50">


                <SuccessModal
                    open={this.state.success}
                    title={"Success"}
                    text={"User is updated"}
                    button={"close"}
                    onClose={() => {
                        this.setState({
                            success: false
                        })
                    }}
                />

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading settings ...</div>
                        </div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="col-span-12 w-full flex items-center justify-center">
                        <div style={{ maxWidth: "900px" }} className="bg-white border shadow-lg rounded-lg w-full">
                            <div className="flex p-4 px-6 border-b border-gray-100">
                                <div className="font-sm flex-1 flex items-center">
                                    <div className="font-semibold">
                                        Update password
                                    </div>
                                </div>
                            </div>
                            <div className="p-4 px-6 grid grid-cols-12 gap-4">
                                <div className="col-span-12 sm:col-span-6">
                                    <Input
                                        label="New password"
                                        value={this.state.new_password}
                                        onChange={(value) => {
                                            this.setState({
                                                new_password: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-12 sm:col-span-6">
                                    <Input
                                        label="New password (repeat)"
                                        value={this.state.repeat_password}
                                        onChange={(value) => {
                                            this.setState({
                                                repeat_password: value
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.state.password_error &&
                                    <div className="col-span-12">
                                        <div className="rounded-md p-4 bg-red-100 text-red-500 font-medium text-sm">
                                            {this.state.password_error}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="p-4 px-6 border-t flex justify-end items-center gap-4">
                                <button
                                    onClick={() => {
                                        if (!this.state.loading_password) {
                                            this.functions.updatePassword();
                                        }
                                    }}
                                    type="submit"
                                    className="bg-indigo-600 border border-transparent relative rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    {
                                        this.state.loading_password &&
                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                }

            </div >
        )
    }
}

export default Settings;
