import { EyeIcon, CursorClickIcon } from '@heroicons/react/outline';
import React, { Component, Fragment } from 'react';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';

class CategoryPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_listings: true,
            sort: 'qualifiedStatus',
            order: 'descending',
            page: 1,
            page_count: 10,
            total: 0
        }
    };

    componentDidMount() {
        //this.functions.getCategory();
        //this.functions.getCategoryListings();
    }

    functions = {
        getCategory: async () => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.getCategory();
            } catch (error) { }
            await this.promisedSetState({ loading: false });
        },
        getCategoryListings: async () => {
            await this.promisedSetState({ loading_listings: true });
            try {
                let response = await this.calls.getCategoryListings();
            } catch (error) { }
            await this.promisedSetState({ loading_listings: false });
        }
    };

    calls = {
        getCategory: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/category?agent=627eca15f4f5e16a4fafc9d1";
            return apiRegister.call(options, url);
        },
        getCategoryListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/category?agent=627eca15f4f5e16a4fafc9d1" + "&page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&sortBy=" + this.state.sort;
            return apiRegister.call(options, url);
        }
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="h-full min-h-full">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/*HEADER*/}
                {
                    !this.state.loading &&
                    <div style={{ backgroundColor: '#f3f3f4' }} className="flex relative pb-20 flex-col justify-center items-center  py-10">
                        <div className="w-48 h-48 rounded-full bg-purple-400">

                        </div>
                        <div style={{ bottom: '-30px' }} className="flex justify-center items-center absolute">
                            <div className="shadow-sm w-100 h-16 rounded-md bg-white"></div>
                        </div>
                    </div>
                }

                {/*INFORMATION*/}
                {
                    !this.state.loading &&
                    <div className="text-center pt-16">
                        <div className="font-bold text-4xl">
                            Real estate
                        </div>
                        <div className="font-medium text-gray-500 text-lg">
                            Discover 10,000+ real estate designs, illustrations, and graphic elementsv
                        </div>
                    </div>
                }

                {/*LISTINGS*/}
                {
                    !this.state.loading &&
                    <div className="grid grid-cols-12 pt-12 gap-6 sm:gap-12 mx-auto max-w-8xl px-6 lg:px-8">
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                                return (
                                    <div className="col-span-12 sm:col-span-4 md:col-span-3">
                                        <div style={{ paddingTop: "90%", backgroundImage: 'url("https://64.media.tumblr.com/8a5ba38e9bfc2479084bba7e92ed2baf/9f8ac445faa499ba-de/s640x960/f4c1e8af3abaf4bccc044ad631c4ed4319b3ed3b.jpg")' }} className="w-full rounded-lg overflow-hidden relative bg-cover bg-center">
                                            <div className="top-0 bottom-0 absolute left-0 right-0 overflow-hidden">
                                                <div className="opacity-0 cursor-pointer hover:opacity-100 h-full w-full transition-all ease-in-out duration-300">
                                                    <div style={{ background: 'linear-gradient(180deg, rgba(0,0,0,0.20) 25%, rgba(0,0,0,0.7) 100%)' }} className="h-full w-full flex flex-col">
                                                        <div className="flex flex-1"></div>
                                                        <div className="text-md text-white font-semibold px-4 py-4">
                                                            Property name
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-row flex-1 pt-2 items-center">
                                            <div className="flex flex-row flex-1 items-center">
                                                <div className="h-5 w-5 rounded-full bg-gray-700 mr-2"></div>
                                                <div className="text-sm font-semibold text-gray-800">
                                                    Agent name
                                                </div>
                                            </div>
                                            <EyeIcon className="w-4 h-4 mr-1" />
                                            <div className="text-sm font-semibold text-gray-800 mr-2">
                                                120
                                            </div>
                                            <CursorClickIcon className="w-4 h-4 mr-1" />
                                            <div className="text-sm font-semibold text-gray-800">
                                                40
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {/*LISTINGS*/}
                {
                    !this.state.loading &&
                    <div className="flex flex-1 justify-center items-center mt-10">
                        <div className="rounded-full px-12 py-2 cursor-pointer text-sm font-medium text-white bg-purple-500">
                            Load more
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default CategoryPage;
