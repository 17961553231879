import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { userRegister } from '../../services/userRegister';
import { tokenRegister } from '../../services/tokenRegister';
import ListAgents from './listAgents';
import ListLeads from './listLeads';
import ListAudience from './listAudience';
import ListListings from './listListings';
import ListClients from './listClients';
import ListProptrees from './listProptrees';
import Report from './report';
import Client from './client';
import Dashboard from './dashboard';
import ListCustomCampaigns from './listCustomCampaigns';
import CategoryPage from './categoryPage';
import { apiRegister } from '../../services/apiRegister';
import Opportunities from './opportunities';
import ListLeadsd from './listLeads';
import ListWhatsappMessages from './listWhatsappMessages';
import ListFeeds from './listFeeds';
import ListReports from './listReports';
import ListLogs from './listLogs';
import ListForms from './listForms';
import ListMedia from './listMedia';
import Settings from './settings';

class LoggedInAgentManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            sidebarOpen: false,
            version: {},
            navigation: [
                { name: 'Dashboard', link: '/manager' },
                { name: 'Listings', link: '/manager/listings' },
                { name: 'Agents', link: '/manager/agents' },
                { name: 'Feeds', link: '/manager/feeds' },
                { name: 'Forms', link: '/manager/forms' },
                { name: 'Proptree', link: '/manager/affiliate' },
                { name: 'Leads', link: '/manager/leads' },
                { name: 'Clients', link: '/manager/clients' },
                { name: 'Reports', link: '/manager/reports' },
                { name: 'Audience', link: '/manager/audience' },
                { name: 'Report', link: '/manager/report' },
                { name: 'Opportunities', link: '/manager/opportunities' },
                { name: 'Media', link: '/manager/media' },
                { name: 'Logs', link: '/manager/logs' }
            ]
        }
    };

    componentWillMount() {
        this.functions.getUser();
    }

    componentDidMount() { }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({
                user: user
            });
            if (this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled') {
                this.setState({
                    navigation: [
                        { name: 'Dashboard', link: '/manager' },
                        { name: 'Listings', link: '/manager/listings' },
                        { name: 'Agents', link: '/manager/agents' },
                        { name: 'Feeds', link: '/manager/feeds' },
                        { name: 'Forms', link: '/manager/forms' },
                        { name: 'Proptree', link: '/manager/affiliate' },
                        { name: 'Inquiries', link: '/manager/leads' },
                        { name: 'Contacts', link: '/manager/clients' },
                        { name: 'Reports', link: '/manager/reports' },
                        { name: 'Audience', link: '/manager/audience' },
                        { name: 'Report', link: '/manager/report' },
                        { name: 'Opportunities', link: '/manager/opportunities' },
                        { name: 'Media', link: '/manager/media' },
                        { name: 'Logs', link: '/manager/logs' }
                    ]
                })
            }
        },
        logout: async () => {
            await this.calls.logoutUser();
            userRegister.remove();
            tokenRegister.remove();
            this.props.history.push('/login');
        }
    };

    renders = {
        lightUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value == 'enabled';
        },
        basicUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled';
        },
        creativeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.creativeSwitch && this.state.user.enterprise.creativeSwitch.value == 'enabled';
        },
        proptreeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.proptreeSwitch && this.state.user.enterprise.proptreeSwitch.value == 'enabled';
        }
    };

    calls = {
        logoutUser: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/user/logout";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="min-h-full h-full">
                <Disclosure as="nav" className="border-b border-gray-200 bg-white z-50 fixed left-0 right-0">
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 justify-between">
                                    <div className="flex">
                                        <div className="flex flex-shrink-0 items-center">
                                            {
                                                this.state.user.enterprise &&
                                                <img
                                                    className="block h-12 w-auto"
                                                    src={this.state.user.enterprise.logo}
                                                    alt="Your Company"
                                                />
                                            }
                                        </div>
                                        <div className="hidden sm:-my-px sm:ml-6 md:flex sm:space-x-8">
                                            {
                                                this.state.navigation.filter((item) => {

                                                    //LIGHT
                                                    if (this.renders.lightUI()) {

                                                        //BASIC
                                                        if (this.renders.basicUI()) {
                                                            if (item.name === 'Dashboard' || item.name === 'Agents' || item.name === 'Listings') {
                                                                return true;
                                                            } else if (item.name === 'Audience' || item.name === 'Logs' || item.name === 'Media') {
                                                                if (item.name === 'Audience' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIaudience && this.state.user.enterprise.managerUIaudience.value === 'disabled')) {
                                                                    return true;
                                                                } else if (item.name === 'Logs' && (this.state.user.enterprise && this.state.user.enterprise.managerUIlogs && this.state.user.enterprise.managerUIlogs.value === 'enabled')) {
                                                                    return true;
                                                                } else if (item.name === 'Media' && (this.state.user.enterprise && ((this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("csv_unit") !== -1) || (this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("csv_project") !== -1)))) {
                                                                    return true;
                                                                } else {
                                                                    return false;
                                                                }
                                                            } else if (item.name === 'Proptree' || item.name === 'Inquiries' || item.name === 'Contacts' || item.name === 'Forms') {
                                                                return this.renders.proptreeUI(); //PROPTREE
                                                            } else if (item.name === 'Feeds') {
                                                                return this.renders.creativeUI(); //CREATIVE
                                                            } else {
                                                                return false;
                                                            }
                                                        }

                                                        //LIGHT ONLY
                                                        if (!this.renders.basicUI()) {
                                                            if (item.name === 'Proptree') {
                                                                return this.renders.proptreeUI(); //PROPTREE
                                                            } else {
                                                                return true; //LIGHT WITHOUT PROPTREE
                                                            }
                                                        }

                                                    }

                                                    //LEGACY
                                                    if (!this.renders.lightUI()) {
                                                        return true;
                                                    }

                                                }).filter((item) => {

                                                    if (this.renders.lightUI() && this.renders.basicUI()) {
                                                        return true;
                                                    } else {
                                                        if (item.name === 'Dashboard' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIdashboard && this.state.user.enterprise.managerUIdashboard.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Listings' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIlistings && this.state.user.enterprise.managerUIlistings.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Agents' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIagents && this.state.user.enterprise.managerUIagents.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Feeds' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIfeeds && this.state.user.enterprise.managerUIfeeds.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Forms' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIforms && this.state.user.enterprise.managerUIforms.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Proptree' && (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled')) {
                                                            return true;
                                                        } else if (item.name === 'Audience' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIaudience && this.state.user.enterprise.managerUIaudience.value === 'disabled')) {
                                                            return true;
                                                        } else if ((item.name === 'Contacts' || item.name === 'Clients') && !(this.state.user.enterprise && this.state.user.enterprise.managerUIclients && this.state.user.enterprise.managerUIclients.value === 'disabled')) {
                                                            return true;
                                                        } else if ((item.name === 'Inquiries' || item.name === 'Leads') && !(this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Report' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIreport && this.state.user.enterprise.managerUIreport.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Opportunities' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIopportunities && this.state.user.enterprise.managerUIopportunities.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Logs' && (this.state.user.enterprise && this.state.user.enterprise.managerUIlogs && this.state.user.enterprise.managerUIlogs.value === 'enabled')) {
                                                            return true;
                                                        } else if (item.name === 'Media' && (this.state.user.enterprise && ((this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("csv_unit") !== -1) || (this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("csv_project") !== -1)))) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    }

                                                }).map((item) => (
                                                    <div
                                                        onClick={() => {
                                                            this.props.history.push(item.link);
                                                        }}
                                                        key={item.name}
                                                        className={classNames(
                                                            window.location.pathname === item.link
                                                                ? 'border-purple-500 text-gray-900'
                                                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer'
                                                        )}
                                                        aria-current={window.location.pathname === item.link ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="hidden sm:ml-6 md:flex sm:items-center">
                                        {/* Profile dropdown */}
                                        <div className="flex flex-row items-center">
                                            <div className="flex items-center">
                                                <Menu as="div" className="ml-3 relative">
                                                    <div>
                                                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-2 rounded-md hover:bg-gray-100 hover:bg-opacity-50">
                                                            {
                                                                !this.state.user.image &&
                                                                <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                </span>
                                                            }
                                                            {
                                                                this.state.user.image &&
                                                                <img
                                                                    className="h-8 w-8 rounded-full"
                                                                    src={this.state.user.image}
                                                                    alt=""
                                                                />
                                                            }
                                                            <span className="ml-3 text-gray-700 text-left text-sm font-semibold block">
                                                                <span className="sr-only">Open user menu for </span>{this.state.user.name}
                                                            </span>
                                                            <ChevronDownIcon
                                                                className="flex-shrink-0 ml-2 h-5 w-5 text-gray-400 block"
                                                                aria-hidden="true"
                                                            />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="origin-top-right absolute z-50 border right-0 mt-4 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                                            <div className="px-4 py-3">
                                                                <p className="text-sm">Signed in{this.state.user.from_admin && <span className="ml-1 text-pink-500">from Admin</span>}</p>
                                                                <p className="text-sm font-medium text-gray-900 truncate">{this.state.user.email}</p>
                                                            </div>
                                                            <div className="py-1">
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <button
                                                                            onClick={() => {
                                                                                this.props.history.push("/manager/settings");
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                'block w-full text-left px-4 py-2 text-sm cursor-pointer'
                                                                            )}
                                                                        >
                                                                            Settings
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <button
                                                                            onClick={() => {
                                                                                this.functions.logout();
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                'block w-full text-left px-4 py-2 text-sm cursor-pointer'
                                                                            )}
                                                                        >
                                                                            Sign out
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex items-center md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 pt-2 pb-3">
                                    {
                                        this.state.navigation.filter((item) => {

                                            //LIGHT
                                            if (this.renders.lightUI()) {

                                                //BASIC
                                                if (this.renders.basicUI()) {
                                                    if (item.name === 'Dashboard' || item.name === 'Agents' || item.name === 'Listings') {
                                                        return true;
                                                    } else if (item.name === 'Audience' || item.name === 'Logs' || item.name === 'Media') {
                                                        if (item.name === 'Audience' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIaudience && this.state.user.enterprise.managerUIaudience.value === 'disabled')) {
                                                            return true;
                                                        } else if (item.name === 'Logs' && (this.state.user.enterprise && this.state.user.enterprise.managerUIlogs && this.state.user.enterprise.managerUIlogs.value === 'enabled')) {
                                                            return true;
                                                        } else if (item.name === 'Media' && (this.state.user.enterprise && ((this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("csv_unit") !== -1) || (this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("csv_project") !== -1)))) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    } else if (item.name === 'Proptree' || item.name === 'Inquiries' || item.name === 'Contacts' || item.name === 'Forms') {
                                                        return this.renders.proptreeUI(); //PROPTREE
                                                    } else if (item.name === 'Feeds') {
                                                        return this.renders.creativeUI(); //CREATIVE
                                                    } else {
                                                        return false;
                                                    }
                                                }

                                                //LIGHT ONLY
                                                if (!this.renders.basicUI()) {
                                                    if (item.name === 'Proptree') {
                                                        return this.renders.proptreeUI(); //PROPTREE
                                                    } else {
                                                        return true; //LIGHT WITHOUT PROPTREE
                                                    }
                                                }

                                            }

                                            //LEGACY
                                            if (!this.renders.lightUI()) {
                                                return true;
                                            }

                                        }).filter((item) => {

                                            if (this.renders.lightUI() && this.renders.basicUI()) {
                                                return true;
                                            } else {
                                                if (item.name === 'Dashboard' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIdashboard && this.state.user.enterprise.managerUIdashboard.value === 'disabled')) {
                                                    return true;
                                                } else if (item.name === 'Listings' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIlistings && this.state.user.enterprise.managerUIlistings.value === 'disabled')) {
                                                    return true;
                                                } else if (item.name === 'Agents' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIagents && this.state.user.enterprise.managerUIagents.value === 'disabled')) {
                                                    return true;
                                                } else if (item.name === 'Feeds' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIfeeds && this.state.user.enterprise.managerUIfeeds.value === 'disabled')) {
                                                    return true;
                                                } else if (item.name === 'Forms' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIforms && this.state.user.enterprise.managerUIforms.value === 'disabled')) {
                                                    return true;
                                                } else if (item.name === 'Proptree' && (this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled')) {
                                                    return true;
                                                } else if (item.name === 'Audience' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIaudience && this.state.user.enterprise.managerUIaudience.value === 'disabled')) {
                                                    return true;
                                                } else if ((item.name === 'Contacts' || item.name === 'Clients') && !(this.state.user.enterprise && this.state.user.enterprise.managerUIclients && this.state.user.enterprise.managerUIclients.value === 'disabled')) {
                                                    return true;
                                                } else if ((item.name === 'Inquiries' || item.name === 'Leads') && !(this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value === 'disabled')) {
                                                    return true;
                                                } else if (item.name === 'Report' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIreport && this.state.user.enterprise.managerUIreport.value === 'disabled')) {
                                                    return true;
                                                } else if (item.name === 'Opportunities' && !(this.state.user.enterprise && this.state.user.enterprise.managerUIopportunities && this.state.user.enterprise.managerUIopportunities.value === 'disabled')) {
                                                    return true;
                                                } else if (item.name === 'Logs' && (this.state.user.enterprise && this.state.user.enterprise.managerUIlogs && this.state.user.enterprise.managerUIlogs.value === 'enabled')) {
                                                    return true;
                                                } else if (item.name === 'Media' && (this.state.user.enterprise && ((this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("csv_unit") !== -1) || (this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("csv_project") !== -1)))) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }

                                        }).map((item) => (
                                            <Disclosure.Button
                                                onMouseDown={() => {
                                                    this.props.history.push(item.link);
                                                }}
                                                key={item.name}
                                                as="div"
                                                className={classNames(
                                                    window.location.pathname === item.link
                                                        ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                                                        : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                                    'block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer'
                                                )}
                                                aria-current={window.location.pathname === item.link ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))
                                    }
                                </div>
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    <div className="flex items-center px-4">
                                        <div className="flex-shrink-0">
                                            {
                                                !this.state.user.image &&
                                                <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                    </svg>
                                                </span>
                                            }
                                            {
                                                this.state.user.image &&
                                                <img className="h-10 w-10 rounded-full" src={this.state.user.image} alt="" />
                                            }
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{this.state.user.name}</div>
                                            <div className="text-sm font-medium text-gray-500">{this.state.user.email}</div>
                                        </div>
                                    </div>
                                    <div className="mt-3 space-y-1">
                                        <Disclosure.Button
                                            onMouseDown={() => {
                                                this.props.history.push("/manager/settings");
                                            }}
                                            key={'settings'}
                                            as="div"
                                            className="block px-4 cursor-pointer py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                        >
                                            Settings
                                        </Disclosure.Button>
                                        <Disclosure.Button
                                            onMouseDown={() => {
                                                this.functions.logout();
                                            }}
                                            key={'signout'}
                                            as="div"
                                            className="block px-4 cursor-pointer py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                        >
                                            Sign out
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <div className="pt-16 min-h-full h-full">
                    <main>
                        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 h-full min-h-full pt-8">
                            <div className="h-full min-h-full">
                                <Route exact path={"/manager"}>
                                    <Dashboard user={this.state.user} ref="dashboard" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/opportunities"}>
                                    <Opportunities user={this.state.user} ref="opportunities" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/agents"}>
                                    <ListAgents user={this.state.user} ref="ListAgents" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/listings"}>
                                    <ListListings user={this.state.user} ref="ListListings" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/clients"}>
                                    <ListClients user={this.state.user} ref="ListClients" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/clients/:id"}>
                                    <Client user={this.state.user} ref="Client" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/leads"}>
                                    <ListLeads user={this.state.user} ref="ListLeads" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/feeds"}>
                                    <ListFeeds user={this.state.user} ref="ListFeeds" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/forms"}>
                                    <ListForms user={this.state.user} ref="ListForms" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/affiliate"}>
                                    <ListProptrees user={this.state.user} ref="ListProptrees" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/reports"}>
                                    <ListReports user={this.state.user} ref="ListReports" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/custom-campaigns"}>
                                    <ListCustomCampaigns user={this.state.user} ref="ListCustomCampaigns" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/audience"}>
                                    <ListAudience user={this.state.user} ref="ListAudience" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/report"}>
                                    <Report user={this.state.user} ref="Report" history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/logs"}>
                                    <ListLogs user={this.state.user} history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/media"}>
                                    <ListMedia user={this.state.user} history={this.props.history} />
                                </Route>
                                <Route exact path={"/manager/settings"}>
                                    <Settings user={this.state.user} history={this.props.history} />
                                </Route>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}

export default LoggedInAgentManager;
