class tokenRegister {

    static token = null;

    static set(token){
        tokenRegister.token = token;
        try {
            sessionStorage.setItem("token", token);
        }catch (e) {}
    }

    static get(){
        if(tokenRegister.token){
            return tokenRegister.token
        }else{
            try {
                if(sessionStorage.getItem("token")){
                    return sessionStorage.getItem("token");
                }else{
                    return null;
                }
            }catch (e) {
                return null;
            }
        }
    }

    static remove(){
        tokenRegister.token = null;
        try {
            sessionStorage.removeItem("token");
        }catch (e) {}
    }

}

export {tokenRegister}