import React, { Component } from 'react';
import { PlusIcon } from '@heroicons/react/solid'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import CreateUser from '../components/createUser';
import WarningModal from '../components/warningModal';
import CreateEnterprise from '../components/createEnterprise';
import SingleSelection from "../components/singleSelection";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            settings: {},
            user: {}
        }
    };

    componentWillMount() {
        this.functions.getUser();
    }

    componentDidMount() { }

    functions = {
        getUser: () => {
            let user = userRegister.get();
            this.setState({
                user: user
            })
        }
    };

    calls = {
        updateSettings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/settings";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <>
                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.image &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.image}
                                                    alt=""
                                                />
                                            }
                                            {
                                                !this.state.user.image &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Settings</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*AGENT HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 2 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.enterprise && this.state.user.enterprise.logo &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.enterprise.logo}
                                                    alt=""
                                                />
                                            }
                                            {
                                                (!this.state.user.enterprise || (this.state.user.enterprise && !this.state.user.enterprise.logo)) &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Settings</p>
                                        <p className="text-sm font-medium text-gray-600">{this.state.user.enterprise.companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8 px-4">
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">

                        {/*ADMIN*/}
                        {
                            this.state.user && this.state.user.level.id == 1 &&
                            <div>
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Integrations</h3>
                                            <p className="mt-1 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                        </div>
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-2">
                                                <label htmlFor="facebook-api-key" className="block text-sm font-medium text-gray-700">
                                                    Facebook API key
                                                </label>
                                                <input
                                                    type="text"
                                                    name="facebook-api-key"
                                                    id="facebook-api-key"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-2">
                                                <label htmlFor="google-api-key" className="block text-sm font-medium text-gray-700">
                                                    Google API key
                                                </label>
                                                <input
                                                    type="text"
                                                    name="google-api-key"
                                                    id="google-api-key"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-2">
                                                <label htmlFor="stripe-api-key" className="block text-sm font-medium text-gray-700">
                                                    Stripe API key
                                                </label>
                                                <input
                                                    type="text"
                                                    name="stripe-api-key"
                                                    id="stripe-api-key"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-white text-right sm:px-6">
                                        <button
                                            type="submit"
                                            className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default Settings;
