import React, { Component, Fragment } from 'react';
import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import CreateUser from '../components/createUser';
import WarningModal from '../components/warningModal';
import CreateEnterprise from '../components/createEnterprise';
import { RefreshIcon, PlusCircleIcon, TrashIcon, PencilIcon, ArchiveIcon, ChevronDownIcon, CheckIcon, XIcon, LocationMarkerIcon, LibraryIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline'
import { versionRegister } from '../services/versionRegister';
import moment from 'moment';
import Pusher from 'pusher-js';
import SingleSelection from '../components/singleSelection';
import Input from '../components/input';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListEnterprise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_enterprise: false,
            open: false,
            enterprise: [],
            user: {},
            tab: 1,
            adbooster_type: { id: 1, name: "Property Booster" },
            enterprise_type: { id: 1, name: "Both", value: "both" },
            version: {},
            sort: 'name',
            order: 'ascending',
            page: 1,
            page_count: 100,
            total: 0,
            search: ""
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    componentWillUnmount() { }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });

            let version = versionRegister.get();
            await this.promisedSetState({ version: version });

            this.functions.getEnterprise(true,);
        },
        getEnterprise: async (init, search_value) => {
            try {
                await this.promisedSetState({
                    loading_enterprise: !init,
                });
                let response = await this.calls.getEnterprise();
                if (!search_value || (search_value && search_value == this.state.search)) {
                    this.setState({
                        enterprise: response.data,
                        total: response.meta ? response.meta.total : 0
                    });
                }
            } catch (error) { }
            this.setState({
                loading: false,
                loading_enterprise: false
            })
        },
        removeEnterprise: async () => {
            let selected_enterprise = JSON.parse(JSON.stringify(this.state.remove));
            this.setState({
                remove: null,
                enterprise: this.state.enterprise.map((item) => {
                    if (item.id == selected_enterprise.id) {
                        item.loading = true;
                    }
                    return item;
                })
            }, async () => {
                try {
                    let response = await this.calls.removeEnterprise(selected_enterprise.id);
                    this.setState({
                        enterprise: this.state.enterprise.filter((item) => { return response.data.id != item.id }),
                        remove: null
                    })
                } catch (error) {
                    this.setState({
                        remove: null,
                        enterprise: this.state.enterprise.map((item) => {
                            if (item.id == selected_enterprise.id) {
                                item.loading = false;
                            }
                            return item;
                        })
                    })
                }
            })
        },
        updateCurrency: async (enterprise) => {
            await this.promisedSetState({
                enterprise: this.state.enterprise.map((item) => {
                    if (item.id == enterprise.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            let updated_date = null;
            try {
                let response = await this.calls.updateCurrency(enterprise.id);
                if (response.data.currenciesUpdated) {
                    updated_date = response.data.currenciesUpdated;
                }
            } catch (error) { }
            this.promisedSetState({
                enterprise: this.state.enterprise.map((item) => {
                    if (item.id == enterprise.id) {
                        item.loading = false;
                        if (updated_date) {
                            item.currenciesUpdated = updated_date;
                        }
                    }
                    return item;
                })
            })
        }
    };

    calls = {
        getEnterprise: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/enterprise/list?version=" + this.state.version.id + "&type=" + this.state.adbooster_type.id + "&page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&search=" + this.state.search + "&enterprise=" + this.state.enterprise_type.value;
            return apiRegister.call(options, url);
        },
        updateCurrency: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = apiRegister.url.api + "/enterprise/update-currencies/" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        enterpriseType: (enterprise) => {
            let string = '';
            try {
                if (enterprise.version) {
                    string += '<span class="text-sm text-indigo-500 uppercase">' + enterprise.version.name + ' • </span>';
                }
                if (enterprise.legacy && enterprise.legacy.value == "enabled") {
                    string += '<span class="text-sm text-pink-500 uppercase">SCALE</span>';
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") !== -1) {
                        string += '<span class="text-sm text-pink-500 uppercase"> PROJECTS</span>';
                    }
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") === -1) {
                        string += '<span class="text-sm text-pink-500 uppercase"> UNITS</span>';
                    }
                    if (enterprise.basicSwitch && enterprise.basicSwitch.value == "enabled") {
                        string += '<span class="text-sm text-orange-500 uppercase"> • BASIC</span>';
                    }
                    if (enterprise.creativeSwitch && enterprise.creativeSwitch.value == "enabled") {
                        string += '<span class="text-sm text-yellow-600 uppercase"> • CREATIVE</span>';
                    }
                    if (enterprise.proptreeSwitch && enterprise.proptreeSwitch.value == "enabled") {
                        string += '<span class="text-sm text-purple-500 uppercase"> • PROPTREE</span>';
                    }
                } else {
                    string += '<span class="text-sm text-indigo-500 uppercase">LEGACY</span>';
                }
            } catch (error) { }
            return string;
        },
        timeDifference: (item) => {
            let minutes = null;
            try {
                if (!item.syncing && item.stopSync) {
                    var startTime = new Date(item.startSync);
                    var endTime = new Date(item.stopSync);
                    var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
                    minutes = Math.round(difference / 60000);
                }
            } catch (error) {
                console.log(error);
            }
            return minutes;
        },
        getTime: (item) => {
            let start = "";
            try {
                start = item.startSync.split("T")[1];
                start = start.split(".")[0];
            } catch (error) {
                console.log(error);
            }
            return start;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>
                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete Enterprise"}
                    description={"Are you sure you want to delete this enterprise? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    onClose={() => {
                        this.setState({
                            remove: null
                        })
                    }}
                    onSubmit={() => {
                        this.functions.removeEnterprise();
                    }}
                />

                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center bg-purple-100 border-purple-500">
                                        <div>
                                            <LibraryIcon className="h-8 w-8 text-purple-500" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Enterprises</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*LIST ENTERPRISE*/}
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        {
                            !this.state.loading &&
                            <section>
                                <div className="bg-white shadow sm:rounded-md">

                                    <div className="flex items-center p-6 border-b">
                                        <div className="flex flex-1 flex-col">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Manage enterprise</h3>
                                            <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                        </div>
                                        <button
                                            onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    selected: null
                                                })
                                            }}
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Create new
                                        </button>
                                    </div>

                                    <div className="p-6 grid sm:grid-cols-9 gap-4">
                                        <div className={"col-span-3"}>
                                            <Input
                                                label={"Search name"}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        loading_search: true,
                                                        search: value
                                                    });
                                                    setTimeout(async () => {
                                                        if (value === this.state.search) {
                                                            await this.promisedSetState({
                                                                page: 1
                                                            });
                                                            this.functions.getEnterprise(false, value);
                                                        }
                                                    }, 400);
                                                }}
                                                value={this.state.search}
                                                type={"text"}
                                            />
                                        </div>
                                        <div className={"col-span-3"}>
                                            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                Enterprise type
                                            </label>
                                            <div className="mt-1">
                                                <SingleSelection
                                                    select={(option) => {
                                                        this.setState({
                                                            enterprise_type: option,
                                                            sort: "name",
                                                            page: 1,
                                                            order: "ascending"
                                                        }, () => {
                                                            this.functions.getEnterprise();
                                                        });
                                                    }}
                                                    selected={this.state.enterprise_type}
                                                    options={[
                                                        { id: 1, name: "Both", value: "both" },
                                                        { id: 2, name: "Scale", value: "scale" },
                                                        { id: 3, name: "Legacy", value: "legacy" }]
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-span-3"}>
                                            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                adBooster type
                                            </label>
                                            <div className="mt-1">
                                                <SingleSelection
                                                    select={(option) => {
                                                        this.setState({
                                                            adbooster_type: option,
                                                            sort: "name",
                                                            page: 1,
                                                            order: "ascending"
                                                        }, () => {
                                                            this.functions.getEnterprise();
                                                        });
                                                    }}
                                                    selected={this.state.adbooster_type}
                                                    options={[{ id: 1, name: "Property Booster" }, { id: 2, name: "Yieldlock" }, { id: 3, name: "Boosta Bostad" }]}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        !this.state.loading_enterprise &&
                                        this.state.enterprise.length > 0 &&
                                        <div className="flex flex-col">
                                            <div className="-my-2 overflow-x-auto sm:-mx-0 lg:-mx-0">
                                                <div className="py-2 align-middle inline-block min-w-full">
                                                    <div className="overflow-hidden border-t border-gray-200">
                                                        <table className="min-w-full divide-y relative divide-gray-200">
                                                            <thead className="bg-gray-100">
                                                                <tr className="">
                                                                    <th scope="col" className="px-6 border-r py-3">
                                                                        <span className="sr-only">Actions</span>
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "300px" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                sort: "name",
                                                                                page: 1,
                                                                                order: this.state.order == 'ascending' ? 'descending' : 'ascending'
                                                                            }, () => {
                                                                                this.functions.getEnterprise();
                                                                            })
                                                                        }}
                                                                        scope="col"
                                                                        className="px-6 py-2 border-r cursor-pointer hover:text-gray-600 text-left text-xs font-medium whitespace-no-wrap text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        <div className={(this.state.sort == "name" ? "text-gray-900" : "") + " flex flex-row"}>
                                                                            <div className="mr-4"> Name</div>
                                                                            {

                                                                                this.state.order == 'ascending' &&
                                                                                <SortAscendingIcon className={(this.state.sort == "name" ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                            }
                                                                            {

                                                                                this.state.order == 'descending' &&
                                                                                <SortDescendingIcon className={(this.state.sort == "name" ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                            }
                                                                        </div>
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "170px" }}
                                                                        scope="col"
                                                                        className="px-6 py-3 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Cron
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "170px" }}
                                                                        scope="col"
                                                                        className="px-6 py-3 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Live Sync
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "170px" }}
                                                                        scope="col"
                                                                        className="px-6 py-3 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Last Cron Sync
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "210px" }}
                                                                        scope="col"
                                                                        className="px-6 py-3 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Last Admin Sync
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "210px" }}
                                                                        scope="col"
                                                                        className="px-6 py-3 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Last Agency Sync
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "230px" }}
                                                                        scope="col"
                                                                        className="px-6 py-3 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        adBooster
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Status
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "130px" }}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Currency
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {this.state.enterprise.map((item) => (
                                                                    <tr key={item.email}>
                                                                        <td style={{ width: "150px" }} className="px-6 py-4 border-r whitespace-no-wrap text-left text-sm font-medium">
                                                                            <div className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                                                                {
                                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                                        <div>
                                                                                            <Menu.Button className="inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                                                                Options
                                                                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                                {
                                                                                                    item.loading &&
                                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </Menu.Button>
                                                                                        </div>
                                                                                        <Transition
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-100"
                                                                                            enterFrom="transform opacity-0 scale-95"
                                                                                            enterTo="transform opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-75"
                                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                                            leaveTo="transform opacity-0 scale-95"
                                                                                        >
                                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                                <div className="py-1">
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    this.props.history.push("/dashboard/enterprise/" + item.id);
                                                                                                                }}
                                                                                                                className={classNames(
                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                    'block px-4 py-2 text-sm relative'
                                                                                                                )}
                                                                                                            >
                                                                                                                Edit
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    this.functions.updateCurrency(item);
                                                                                                                }}
                                                                                                                className={classNames(
                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                    'block px-4 py-2 text-sm relative'
                                                                                                                )}
                                                                                                            >
                                                                                                                Update currencies
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                </div>
                                                                                            </Menu.Items>
                                                                                        </Transition>
                                                                                    </Menu>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 border-r whitespace-no-wrap">
                                                                            <div className="flex items-center">
                                                                                {
                                                                                    !item.logo &&
                                                                                    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    item.logo &&
                                                                                    <div className="h-10 min-w-10 rounded-full overflow-hidden flex items-center justify-center border">
                                                                                        <img className="w-10" src={item.logo} alt="" />
                                                                                    </div>
                                                                                }
                                                                                <div className="ml-4">
                                                                                    <div className="text-sm font-medium text-gray-900 capitalize">{item.name}</div>
                                                                                    <div className="text-sm text-gray-500">{item.companyName}</div><div dangerouslySetInnerHTML={{ __html: this.renders.enterpriseType(item) }}></div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 border-r whitespace-no-wrap">
                                                                            <div className="flex items-center">
                                                                                <div className="">
                                                                                    <div
                                                                                        className={(item.enableCronSync && item.enableCronSync.value ? "text-purple-500" : "text-gray-500") + " text-sm font-medium"}>
                                                                                        {item.enableCronSync && item.enableCronSync.value ? "ON" : "OFF"}
                                                                                    </div>
                                                                                    {
                                                                                        <div className="text-sm text-gray-500">
                                                                                            {item.enableCronSync && item.enableCronSync.value
                                                                                                ? (
                                                                                                    (item.cronSyncRecurrence && item.cronSyncRecurrence.value === "weekly")
                                                                                                        ? ("weekly" + " (" + (item.runCronSyncWeekday && item.runCronSyncWeekday.value ? item.runCronSyncWeekday.value : "-") + ", " + (item.runCronSyncHour && item.runCronSyncHour.value ? item.runCronSyncHour.value : "-") + ")")
                                                                                                        : ("daily" + " (" + (item.runCronSyncHour && item.runCronSyncHour.value ? item.runCronSyncHour.value : "-") + ")")
                                                                                                )
                                                                                                : "-"}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 border-r whitespace-no-wrap">
                                                                            <div className="flex items-center">
                                                                                <div className="">
                                                                                    <div className="text-sm font-medium text-purple-500">{item.syncing ? "RUNNING" : '-'}</div>
                                                                                    {
                                                                                        false &&
                                                                                        item.syncing &&
                                                                                        <div className="text-sm text-gray-500">{item.lastSyncString}</div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 border-r whitespace-no-wrap">
                                                                            <div className="flex items-center">
                                                                                <div className="">
                                                                                    <div className="text-sm font-medium text-gray-900">{item.lastCronSyncString ? item.lastCronSyncString : '-'}</div>
                                                                                    {
                                                                                        false &&
                                                                                        item.lastCronSyncString &&
                                                                                        <div className="text-sm text-gray-500">{Array.isArray(item.syncCronErrors) ? item.syncCronErrors.length : "0"} Errors</div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 border-r whitespace-no-wrap">
                                                                            <div className="flex items-center">
                                                                                <div className="">
                                                                                    <div className="text-sm font-medium text-gray-900">{item.lastSyncString ? item.lastSyncString : '-'}</div>
                                                                                    {
                                                                                        false &&
                                                                                        item.lastSyncString &&
                                                                                        <div className="text-sm text-gray-500">{item.enterpriseSyncData && item.enterpriseSyncData.third && item.enterpriseSyncData.third.data && item.enterpriseSyncData.third.data.errors ? item.enterpriseSyncData.third.data.errors : "0"} Errors</div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 border-r whitespace-no-wrap">
                                                                            <div className="flex items-center">
                                                                                <div className="">
                                                                                    <div className="text-sm font-medium text-gray-900">{item.lastAgentSyncString ? item.lastAgentSyncString : '-'}</div>
                                                                                    {
                                                                                        false &&
                                                                                        this.renders.enterpriseType(item).indexOf("LEGACY") !== -1 &&
                                                                                        <div className="text-sm font-medium whitespace-no-wrap text-gray-900">{'-'}</div>
                                                                                    }
                                                                                    {
                                                                                        false &&
                                                                                        !(this.renders.enterpriseType(item).indexOf("LEGACY") !== -1) &&
                                                                                        <div className="text-sm font-medium whitespace-no-wrap text-gray-900">{item.lastSyncString && !item.syncing ? item.lastSyncString : '-'}{this.renders.timeDifference(item) && !item.syncing ? " (" + this.renders.timeDifference(item) + " min)" : ""}</div>
                                                                                    }
                                                                                    {
                                                                                        false &&
                                                                                        !item.syncing && this.renders.enterpriseType(item).indexOf("LEGACY") !== -1 &&
                                                                                        <div className="text-sm text-gray-500">{"-"}</div>
                                                                                    }
                                                                                    {
                                                                                        false &&
                                                                                        !item.syncing && !(this.renders.enterpriseType(item).indexOf("LEGACY") !== -1) &&
                                                                                        <div className="text-sm text-gray-500">{item.syncType ? (item.syncType + "&#x2022;") : ""} {Array.isArray(item.syncErrors) ? item.syncErrors.length : "0"} Errors</div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 border-r whitespace-no-wrap">
                                                                            {
                                                                                item.adboosterType &&
                                                                                <div className="flex items-center">
                                                                                    <div className="h-10 min-w-10 rounded-full overflow-hidden flex items-center justify-center border">
                                                                                        <img className="w-10" src={item.adboosterType.image} alt="" />
                                                                                    </div>
                                                                                    <div className="ml-4">
                                                                                        <div className="text-sm font-medium text-gray-900 capitalize">{item.adboosterType.name}</div>
                                                                                        <div className="text-sm text-gray-500">#{item.adboosterId}</div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                        <td className="px-6 py-4 border-r whitespace-no-wrap">
                                                                            {
                                                                                item.status && item.status.id == 1 &&
                                                                                <span className="inline-flex items-center border border-green-500 px-4 py-1 rounded-md text-sm font-medium bg-green-100 text-green-800">
                                                                                    {item.status.name}
                                                                                </span>
                                                                            }
                                                                            {
                                                                                item.status && item.status.id == 2 &&
                                                                                <span className="inline-flex items-center border border-red-500 px-4 py-1 rounded-md text-sm font-medium bg-red-100 text-red-800">
                                                                                    {item.status.name}
                                                                                </span>
                                                                            }
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-no-wrap">
                                                                            <div className="flex items-center">
                                                                                <div className="">
                                                                                    <div className="text-sm font-medium text-gray-900 capitalize">{item.currency ? item.currency.name : '-'}</div>
                                                                                    <div className="text-sm text-gray-500">{item.currenciesUpdated ? moment(item.currenciesUpdated).format("YYYY-MM-DD") : '-'}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.loading_enterprise &&
                                        <div className="w-full h-64 relative bg-white flex justify-center items-start border-t rounded-b-md">
                                            <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.enterprise.length < 1 &&
                                        !this.state.loading_enterprise &&
                                        <div className="bg-white h-48 flex border-t justify-center items-center rounded-b">
                                            <div className="text-center flex justity-center items-center flex-col">
                                                <ExclamationCircleIcon className="h-10 w-10 text-gray-300" />
                                                <h3 className="mt-2 text-sm font-medium text-gray-900">No <span className="text-purple-500">{this.state.adbooster_type.name}</span> enterprise available</h3>
                                                <p className="mt-1 text-sm text-gray-500">Add one by clicking "Create new"</p>
                                            </div>
                                        </div>
                                    }

                                </div>

                                {
                                    !this.state.loading_enterprise &&
                                    this.state.enterprise.length > 0 &&
                                    <div className="rounded-b-md bg-gray-50">
                                        <nav
                                            className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                            aria-label="Pagination"
                                        >
                                            <div className="hidden sm:block">
                                                <p className="text-sm text-gray-700">
                                                    Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.enterprise.length ? this.state.enterprise.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                                    <span className="font-medium">{this.state.total}</span> results
                                                </p>
                                            </div>
                                            <div className="flex-1 flex justify-between sm:justify-end">
                                                <Menu as="div" className="relative inline-block text-left mr-2">
                                                    <div>
                                                        <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                            Page limit: {this.state.page_count}
                                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                                {
                                                                    [1, 10, 20, 30, 50, 100].map((item) => {
                                                                        return (
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                page_count: item,
                                                                                                page: 1
                                                                                            }, () => {
                                                                                                this.functions.getEnterprise();
                                                                                            })
                                                                                        }}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                            'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                            this.state.page_count == item ? "text-purple-500" : ""
                                                                                        )}
                                                                                    >
                                                                                        {item}
                                                                                    </div>
                                                                                )}
                                                                            </Menu.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                                <div
                                                    onClick={() => {
                                                        this.setState({
                                                            select_all: false,
                                                            page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                        }, () => {
                                                            this.functions.getEnterprise();
                                                        });
                                                    }}
                                                    className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                >
                                                    Previous
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page + 1
                                                            }, () => {
                                                                this.functions.getEnterprise();
                                                            });
                                                        }
                                                    }}
                                                    className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                >
                                                    Next
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                }
                            </section>
                        }

                        {/*CREATE USER*/}
                        <CreateEnterprise
                            version={this.state.version}
                            open={this.state.open}
                            toggle={() => {
                                this.setState({
                                    open: !this.state.open
                                })
                            }}
                            onEnterprise={(enterprise) => {
                                this.state.enterprise.push(enterprise);
                                this.setState({
                                    enterprise: this.state.enterprise
                                })
                            }}
                        />

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default ListEnterprise;
