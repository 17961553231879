import React, { Component, Fragment } from 'react';
import { PlusIcon, SortAscendingIcon, SortDescendingIcon, UserGroupIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { CursorClickIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import CreateUser from '../components/createUser';
import WarningModal from '../components/warningModal';
import { RefreshIcon, PlusCircleIcon, TrashIcon, PhoneIcon, PencilIcon, ArchiveIcon, ChevronDownIcon, CheckIcon, XIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { versionRegister } from '../services/versionRegister';
import SingleSelection from '../components/singleSelection';
import CreateClient from '../components/createClient';
import moment from 'moment';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_enterprise: true,
            open: false,
            tab: 2,
            actions: [],
            user: {},
            version: {},
            enterprises: [],
            enterprise: { id: 0, name: "Select" },
            status: { id: 0, name: "Show all", value: "all" },
            sort: 'createdAt',
            order: 'descending',
            page: 1,
            page_count: 100,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
            this.functions.getEnterprise();
        },
        query: async () => {
            let queries = window.location.search.replace("?", "").split("&");
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("enterprise=") !== -1) {
                    let value = queries[i].replace("enterprise=", "");
                    let enterprise = null;
                    this.state.enterprises.map((item) => {
                        if (item.id == value) {
                            enterprise = item;
                        }
                    });
                    await this.promisedSetState({
                        enterprise: enterprise ? enterprise : this.state.enterprise
                    });
                    if (this.state.enterprise.id !== 0) {
                        this.functions.getActions();
                    }
                }
            }
        },
        getEnterprise: async () => {
            try {
                let response = await this.calls.getEnterprise();
                await this.promisedSetState({
                    enterprises: response.data,
                    loading: false
                });
                this.functions.query();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false
                })
            }
        },
        getActions: async () => {
            try {
                await this.promisedSetState({ loading_actions: true });
                let response = await this.calls.getActions();
                this.setState({
                    actions: response.data,
                    total: response.meta.total ? response.meta.total : 0,
                    loading_actions: false
                })
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_actions: false
                })
            }
        },
        removeAction: async () => {
            let selected_user = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                actions: this.state.actions.map((item) => {
                    if (item.id == selected_user.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeAction(selected_user.id);
                await this.promisedSetState({
                    actions: this.state.actions.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                })
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    actions: this.state.actions.map((item) => {
                        if (item.id == selected_user.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        getEnterpriseType: (listing) => {
            try {
                if (this.state.enterprise.adboosterType && this.state.enterprise.adboosterType.value === 'boostabostad') {
                    if (this.state.enterprise.version && this.state.enterprise.version.id === 1) {
                        return "https://lp.boostabostad.se/" + listing.adboosterId;
                    } else {
                        return "https://lp.devboostabostad.se/" + listing.adboosterId;
                    }
                } else {
                    if (this.state.enterprise.version && this.state.enterprise.version.id === 1) {
                        return "https://lp.propertybooster.io/" + listing.adboosterId;
                    } else {
                        return "https://lp.devpropertybooster.com/" + listing.adboosterId;
                    }
                }
            } catch (error) {}
        }
    };

    calls = {
        getEnterprise: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/enterprise/list?version=" + this.state.version.id;
            return apiRegister.call(options, url);
        },
        getActions: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/action/listActions?enterprise=" + this.state.enterprise.id + "&page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&sortBy=" + this.state.sort + "&status=" + this.state.status.id;
            return apiRegister.call(options, url);
        },
        removeAction: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/action/removeAction?id=" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                {/*ERROR WARNING*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={"Error"}
                    description={typeof this.state.message == "string" ? this.state.message : "Something went wrong"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete User"}
                    description={"Are you sure you want to delete this user? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name" to remove'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeAction();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*ADMIN HEADER*/}
                {
                    false &&
                    this.state.user && this.state.user.level && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center bg-pink-100 border-pink-500">
                                        <div>
                                            <CursorClickIcon className="h-8 w-8 text-pink-500" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Actions</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">

                        {/*LIST USERS*/}
                        {
                            !this.state.loading &&
                            <section>
                                <div className="bg-white shadow sm:rounded-md">

                                    <div className="flex items-center p-6 border-b flex-row">
                                        <div className="flex flex-row">
                                            <div className="rounded-full mr-3 h-12 w-12 border overflow-hidden justify-center flex items-center bg-purple-100 border-purple-500">
                                                <div>
                                                    <CursorClickIcon className="h-6 w-6 text-purple-500" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">Manage actions</h3>
                                                <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-1"></div>
                                        {
                                            false &&
                                            <button
                                                onClick={() => {

                                                }}
                                                type="button"
                                                className={(this.state.enterprise.id == 0 ? "opacity-50 cursor-not-allowed" : "hover:bg-indigo-700") + " inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                Create new
                                            </button>
                                        }
                                    </div>

                                    {/*FILTER USERS*/}
                                    <div className="grid sm:grid-cols-6 pb-6 gap-6 px-4 sm:px-6 pt-4">
                                        <div className={"sm:col-span-6 col-gap-6"}>
                                            <label htmlFor="enterprise" className="block text-sm font-medium text-gray-700">
                                                Enterprise
                                            </label>
                                            <div className="mt-1">
                                                <SingleSelection
                                                    loader={this.state.loading_enterprise}
                                                    select={(option) => {
                                                        this.setState({
                                                            enterprise: option
                                                        }, () => {
                                                            this.functions.getActions();
                                                        });
                                                    }}
                                                    disabled={this.state.loading_actions}
                                                    selected={this.state.enterprise}
                                                    options={this.state.enterprises}
                                                    id="enterprise"
                                                    name="enterprise"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !this.state.loading_actions &&
                                        this.state.actions.length > 0 &&
                                        <div className="flex flex-col">
                                            <div className="overflow-x-auto sm:-mx-0 lg:-mx-0">
                                                <div className="align-middle inline-block min-w-full sm:px-0 lg:px-0">
                                                    <div className="border-t border-gray-200">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-100">
                                                                <tr>
                                                                    <th scope="col" className="relative border-r px-6 py-3">
                                                                        <span className="sr-only">Actions</span>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Created
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Listing
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Client
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Information
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Extra information
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Other information
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {this.state.actions.map((person) => (
                                                                    <tr key={person.email}>
                                                                        <td style={{ width: "100px" }} className="px-6 border-r py-4 whitespace-nowrap text-left text-sm font-medium">
                                                                            <div className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                                                                {
                                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                                        <div>
                                                                                            <Menu.Button className="inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                                                                Options
                                                                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                                {
                                                                                                    person.loading &&
                                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </Menu.Button>
                                                                                        </div>
                                                                                        <Transition
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-100"
                                                                                            enterFrom="transform opacity-0 scale-95"
                                                                                            enterTo="transform opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-75"
                                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                                            leaveTo="transform opacity-0 scale-95"
                                                                                        >
                                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                                <div className="py-1">
                                                                                                    {
                                                                                                        false &&
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        this.setState({
                                                                                                                            selected: person
                                                                                                                        }, () => {
                                                                                                                            this.setState({
                                                                                                                                open: true
                                                                                                                            })
                                                                                                                        })
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Edit
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        remove: person
                                                                                                                    })
                                                                                                                }}
                                                                                                                className={classNames(
                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                    'block px-4 py-2 text-sm relative'
                                                                                                                )}
                                                                                                            >
                                                                                                                Delete
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                </div>
                                                                                            </Menu.Items>
                                                                                        </Transition>
                                                                                    </Menu>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div style={{ whiteSpace: 'nowrap' }} className="flex items-center text-sm font-medium whitespace-nowrap">
                                                                                {person.createdAt ? moment(person.createdAt).format('YYYY-MM-DD') : "-"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="border-r px-6 py-4 whitespace-nowrap">
                                                                            <div className="flex items-center ">
                                                                                {
                                                                                    person.listing &&
                                                                                    person.listing.image &&
                                                                                    <div className="flex-shrink-0 h-10 w-10">
                                                                                        <img className="h-10 w-10 rounded-full" src={person.listing.image} alt="" />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    !(this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") &&
                                                                                    <div className="ml-4 whitespace-no-wrap">
                                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{person.listing ? person.listing.name : "-"}</div>
                                                                                        <a href={this.functions.getEnterpriseType(person.listing)} target="_blank" className="cursor-pointer text-sm text-gray-500 whitespace-no-wrap">
                                                                                            #{person.listing ? person.listing.reference : "-"}
                                                                                        </a>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" &&
                                                                                    <div className="ml-4 whitespace-no-wrap">
                                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{person.listing ? person.listing.name : "-"}</div>
                                                                                        <a className="text-sm text-gray-500 whitespace-no-wrap">
                                                                                            #{person.listing ? person.listing.reference : "-"}
                                                                                        </a>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div className="flex items-center">
                                                                                <div className="whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{person.client ? person.client.phone : "-"}</div>
                                                                                    <div className="text-sm text-gray-500 whitespace-no-wrap">#{person.client ? person.client.email : "-"}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div style={{ whiteSpace: 'nowrap' }} className="flex items-center text-sm font-medium">
                                                                                {person.information}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div className="flex items-center text-sm font-medium">
                                                                                {person.extraInformation}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            <div className="flex items-center text-sm font-medium">
                                                                                {person.otherInformation}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.loading_actions &&
                                        <div className="w-full h-64 relative bg-white flex justify-center items-start border-t rounded-b-md">
                                            <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </section>
                        }

                        {
                            !this.state.loading_actions &&
                            this.state.actions.length > 0 &&
                            <div className="rounded-b-md bg-gray-50">
                                <nav
                                    className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block">
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.actions.length ? this.state.actions.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                            <span className="font-medium">{this.state.total}</span> results
                                        </p>
                                    </div>
                                    <div className="flex-1 flex justify-between sm:justify-end">
                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                            <div>
                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                    Page limit: {this.state.page_count}
                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        {
                                                            [1, 10, 20, 30, 50, 100].map((item) => {
                                                                return (
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <div
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        page_count: item,
                                                                                        page: 1
                                                                                    }, () => {
                                                                                        this.functions.getActions();
                                                                                    })
                                                                                }}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                )}
                                                                            >
                                                                                {item}
                                                                            </div>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                        <div
                                            onClick={() => {
                                                this.setState({
                                                    select_all: false,
                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                }, () => {
                                                    this.functions.getActions();
                                                });
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                        >
                                            Previous
                                        </div>
                                        <div
                                            onClick={() => {
                                                if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                    this.setState({
                                                        select_all: false,
                                                        page: this.state.page + 1
                                                    }, () => {
                                                        this.functions.getActions();
                                                    });
                                                }
                                            }}
                                            className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                        >
                                            Next
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        }

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default ListActions;
