import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { CashIcon, CheckIcon, CogIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import queryString from "query-string";
var moment = require('moment');

class Billing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            billing: {},
            account: {},
            alias: null
        }
    };

    componentDidMount() {
        this.functions.getUser();
        this.functions.getAlias();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({
                user: user ? user : {}
            });
            this.functions.getBilling();
        },
        getAlias: () => {
            let query = {};
            try {
                query = queryString.parse(window.location.search);
            } catch (error) {
                query = {};
            }
            if (query.alias) {
                this.setState({
                    alias: query.alias
                });
            }
        },
        getBilling: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.getBilling();
                await this.promisedSetState({
                    billing: response.data,
                    account: response.data.account ? response.data.account : {}
                });
            } catch (error) { }
            await this.promisedSetState({
                loading: false
            });
        },
        signup: async () => {
            await this.promisedSetState({ loading_signup: true, signup_error: false });
            try {
                await this.calls.subscripe({ alias: this.state.alias });
                this.functions.getBilling();
            } catch (error) {
                let message = error && error.body ? error.body.message : '';
                await this.promisedSetState({ signup_error: message });
            }
            await this.promisedSetState({ loading_signup: false });
        }
    };

    calls = {
        getBilling: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.proptree + "/getProptreeBilling";
            return apiRegister.call(options, url);
        },
        subscripe: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.proptree + "/updateProptreeSubscription";
            return apiRegister.call(options, url);
        }
    };

    renders = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        const includedFeatures = [
            'Verified badge',
            'Collection links (soon)',
            'Impersonation protection',
            'Discovery feature (soon)',
            'Custom shortname',
            'Fancy themes (soon)',
            'Custom buttons',
            'More insights (soon)',
        ];

        return (
            <div className="grid grid-cols-12 sm:row-gap-10 row-gap-6 sm:col-gap-10 h-full min-h-full sm:p-0 p-6 pt-0">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/* BILLING */}
                {
                    !this.state.loading &&
                    <div className="col-span-12 flex flex-col items-center justify-center">
                        <div className="max-w-140 w-full">
                            <div className="bg-white overflow-hidden shadow rounded-lg border">

                                {
                                    this.state.account.subscription &&
                                    <div className="px-4 py-5 sm:p-6">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900">Proptree Plus+</h3>
                                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                                            <p>
                                                You are subscribed ⚡️
                                            </p>
                                        </div>
                                    </div>
                                }

                                {
                                    !this.state.account.subscription &&
                                    <div className="bg-white">
                                        <div className="">
                                            <div className="mx-auto pt-10 max-w-2xl text-center">
                                                <h2 className="text-3xl font-bold tracking-tight text-transparent sm:text-4xl bg-gradient-to-r from-black via-indigo-600 to-indigo-600 bg-clip-text">
                                                    Proptree Plus+
                                                </h2>
                                                <p className="px-6 sm:px-0 mt-6 text-lg leading-8 text-gray-600">
                                                    Plus+ will elevate your brand with new features, customizations, and more insights. Sign up now and build trust with your networks !
                                                </p>
                                            </div>
                                            <div className="mt-10">
                                                <div className="flex items-center flex-col justify-center">
                                                    {
                                                        this.state.alias &&
                                                        <div className="mb-4 mr-4 ml-4">
                                                            <label htmlFor="proptree-shortname" className="block text-sm font-medium text-gray-900">
                                                                Claim your shortname
                                                            </label>
                                                            <div className="mt-1 flex relative">
                                                                <div className="bg-gray-50 border text-sm border-r-none rounded-l-md shadow-sm items-center inline-flex sm:h-10 px-4">
                                                                    https://proptr.ee/
                                                                </div>
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.setState({
                                                                            alias: event.target.value
                                                                        })
                                                                    }}
                                                                    value={this.state.alias}
                                                                    type="text"
                                                                    name="proptree-shortname"
                                                                    id="proptree-shortname"
                                                                    className={" border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 appearance-none block rounded-l-none w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none text-sm"}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="rounded-lg border bg-gray-50 mr-4 ml-4 py-6">
                                                        <div className="mx-auto max-w-xs px-8 text-center">
                                                            <p className="flex items-baseline justify-center gap-x-2">
                                                                <span className="text-5xl font-bold tracking-tight text-gray-900">79</span>
                                                                <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">AED / Month</span>
                                                            </p>
                                                            <button
                                                                onClick={async () => {
                                                                    if (!this.state.loading_signup) {
                                                                        this.functions.signup();
                                                                    }
                                                                }}
                                                                type="button"
                                                                className="inline-flex mt-4 relative w-full justify-center items-center overflow-hidden rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                            >
                                                                Get 6 months free*
                                                                {
                                                                    this.state.loading_signup &&
                                                                    <div className="absolute left-0 right-0 z-60 bg-indigo-600 bottom-0 top-0">
                                                                        <div className="w-full h-full flex justify-center items-center">
                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </button>
                                                            {
                                                                this.state.signup_error &&
                                                                <div className="mx-auto max-w-xs">
                                                                    <div className="bg-red-100 text-red-500 border border-red-500 rounded-md text-sm p-3 px-4 font-medium mt-4">
                                                                        {this.state.signup_error}
                                                                    </div>
                                                                </div>
                                                            }
                                                            <p className="mt-6 text-xs leading-5 text-gray-600">
                                                                *no card needed. Once your trial ends you can access a discounted 12-month plan. Cancel any time
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-10">
                                                <div className="flex items-center gap-x-4">
                                                    <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
                                                    <div className="h-px flex-auto bg-gray-100" />
                                                </div>
                                                <ul
                                                    role="list"
                                                    className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                                                >
                                                    {includedFeatures.map((feature) => (
                                                        <li key={feature} className="flex gap-x-3">
                                                            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <ul
                                                    role="list"
                                                    className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                                                >
                                                    And this is just the beginning! We're actively exploring ways for you to leverage your networks.
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div >
                }

                {
                    !this.state.loading &&
                    <div className="h-12 w-full"></div>
                }

            </div >
        )
    }
}

export default Billing;
