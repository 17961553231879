import React, { Component, Fragment } from 'react';
import { PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, ChevronUpIcon, CalendarIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import moment from 'moment';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import { versionRegister } from '../../services/versionRegister';
import SingleSelection from '../../components/singleSelection';
import Dropdown from '../../components/dropdown';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_listings: true,
            open: false,
            dates: {},
            tab: { id: 1, name: "This month", value: 'current_month' },
            start_date: { id: 1, name: "This month", value: 'current_month' },
            end_date: { id: 1, name: "This month", value: 'current_month' },
            sektions: {
                google: [
                    { name: "Impressions", value: "impressions", suffix: "" },
                    { name: "Clicks", value: "click", suffix: "" },
                    { name: "CTR", value: "ctr", suffix: "%" },
                    { name: "Engagements", value: "engagements", suffix: "" }
                ],
                //legacy
                facebook: [
                    { name: "Impressions", value: "impressions", suffix: "" },
                    { name: "Clicks", value: "click", suffix: "" },
                    { name: "CTR", value: "ctr", suffix: "%" },
                    { name: "Reactions", value: "reactions", suffix: "" }
                ],
                meta: [
                    { name: "Impressions", value: "impressions", suffix: "" },
                    { name: "Clicks", value: "click", suffix: "" },
                    { name: "CTR", value: "ctr", suffix: "%" },
                    { name: "Leads", value: "leads", suffix: "" },
                    { name: "CVR", value: "cvr", suffix: "%" },
                    { name: "Reactions", value: "reactions", suffix: "" }
                ],
                snapchat: [
                    { name: "Impressions", value: "impressions", suffix: "" },
                    { name: "Slide up", value: "slide_up", suffix: "" },
                    { name: "CTR", value: "ctr", suffix: "%" },
                    { name: "Likes", value: "likes", suffix: "" }
                ],
                tiktok: [
                    { name: "Impressions", value: "impressions", suffix: "" },
                    { name: "Slide up", value: "slide_up", suffix: "" },
                    { name: "CTR", value: "ctr", suffix: "%" },
                    { name: "Likes", value: "likes", suffix: "" }
                ],
                all_channels: [
                    { name: "Impressions", value: "impressions", suffix: "" },
                    { name: "Clicks", value: "click", suffix: "" },
                    { name: "CTR", value: "ctr", suffix: "%" },
                    { name: "Actions", value: "actions", suffix: "" },
                    { name: "CVR", value: "conversion_rate", suffix: "%" },
                    { name: "Engagements", value: "engagements", suffix: "" }
                ]
            },
            metric: { name: "Impressions", value: "impressions" },
            breakdown: { name: "Daily breakdown", value: "daily", property: 'date' },
            channel: {},
            channels: [
                { id: 3, name: "All channels", value: 'all_channels' },
                { id: 1, name: "Facebook", value: 'facebook' },
                { id: 2, name: "Google", value: 'google' },
                //{ id: 3, name: "Snapchat", value: 'snapchat' },
                //{ id: 4, name: "TikTok", value: 'tiktok' }
            ],
            listingType: "Active listings",
            listings: [],
            feed: null,
            enterprise_light: null,
            enterprise_project: false,
            feed_items: [],
            sort: 'click',
            order: 'descending',
            feed_page: 1,
            feed_count: 10,
            page: 1,
            page_count: 10,
            total: 0,
            report: {
                total: {}
            },
            proptree: {},
            chart_options: {
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                },
                legend: {
                    display: false
                },
                scales: {
                    x: {
                        stacked: false,
                    },
                    y: {
                        stacked: false,
                    }
                },
            },
            chart_options_proptree: {
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                },
                legend: {
                    display: false
                },
                scales: {
                    x: {
                        stacked: false,
                    },
                    y: {
                        stacked: false,
                    }
                },
            },
            lead_page: 1,
            lead_page_count: 100
        }
    };

    componentDidMount() {
        this.functions.getReport(true);
    }

    functions = {
        getReport: async (init) => {
            try {
                let user = userRegister.get();
                await this.promisedSetState({
                    user: user,
                    enterprise_light: user.enterprise.legacy.value == "enabled",
                    enterprise_project: user.enterprise.parserType && user.enterprise.parserType.value.indexOf("project") !== -1
                });
            } catch (error) { }
            try {
                if (this.state.enterprise_light) {
                    this.setState((prevState) => ({
                        sektions: {
                            ...prevState.sektions,
                            facebook: [...prevState.sektions.meta], // Replace facebook values with meta
                        }
                    }));
                }
            } catch (error) { }
            try {
                let feed = null;
                if (window.location.search.indexOf("?feed=") !== -1) {
                    feed = window.location.search.replace("?feed=", "");
                }
                await this.promisedSetState({
                    feed: feed
                });
            } catch (error) { }
            try {
                if (!init) {
                    await this.promisedSetState({ loading: true });
                }
                let response = await this.calls.report();
                await this.promisedSetState({
                    report: response.data.report ? response.data.report : {},
                    dates: response.data.date_spans,
                    breakdown: this.state.start_date.value == this.state.end_date.value ? { name: "Daily breakdown", value: "daily", property: 'date' } : { name: "Monthly breakdown", value: "monthly", property: 'month' }
                });
            } catch (error) { }
            if (init) {
                await this.promisedSetState({
                    channel: (this.state.enterprise_light || this.state.feed) ? { id: 1, name: "Meta", value: 'facebook' } : { id: 3, name: "All channels", value: 'all_channels' },
                    start_date: { id: 1, year_month: this.renders.yearAndMonth('current_month'), name: this.renders.dateSpan('current_month'), value: 'current_month' },
                    end_date: { id: 1, year_month: this.renders.yearAndMonth('current_month'), name: this.renders.dateSpan('current_month'), value: 'current_month' }
                });
                if (this.state.feed) {
                    this.functions.getFeed(this.state.feed);
                    this.functions.getLeads(this.state.feed);
                } else if (this.state.channel.value === "facebook" && this.state.start_date.value == this.state.end_date.value) {
                    this.functions.getListings();
                }
            } else {
                if (this.state.feed) {
                    this.functions.getFeed(this.state.feed);
                    this.functions.getLeads(this.state.feed);
                } else if (this.state.channel.value === "facebook" && this.state.start_date.value == this.state.end_date.value) {
                    this.functions.getListings();
                }
            }
            await this.promisedSetState({
                loading: false
            });
        },
        getProptree: async () => {
            let this_year = this.state.start_date.year_month ? this.state.start_date.year_month.year : moment().format("YYYY");
            let this_month = this.state.start_date.year_month ? this.state.start_date.year_month.month : moment().format("MM");
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.proptreeStats(this_year, this_month);
                this.state.proptree = {
                    this_month_daily: response.data.this_month_daily,
                    page_loads: response.data.page_loads,
                    clicks: response.data.clicks,
                    ctr: response.data.ctr,
                    cvr: response.data.cvr,
                    inquiries: response.data.inquiries,
                    contacts: response.data.contacts,
                    top_10_accounts: response.data.top_10_accounts ? response.data.top_10_accounts : [],
                    top_10_listings: response.data.top_10_listings ? response.data.top_10_listings : []
                };
                await this.promisedSetState({
                    proptree: this.state.proptree
                });
            } catch (error) { }
            await this.promisedSetState({
                loading: false
            });
        },
        getListings: async () => {
            try {
                await this.promisedSetState({ loading_listings: true });
                let response = await this.calls.getListings();
                this.setState({
                    listings: response.data,
                    total: this.state.listingType === 'Active listings' ? (response.meta ? response.meta.total : 0) : (response.meta ? response.meta.archived : 0),
                    loading_listings: false
                })
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_listings: false
                })
            }
        },
        getFeed: async (feed) => {

            let month_days = {
                '01': "31",
                '03': "31",
                '05': "31",
                '08': "31",
                '10': "31",
                '12': "31"
            };

            let start = this.state.start_date.year_month ? (this.state.start_date.year_month.year + '-' + this.state.start_date.year_month.month + '-01') : null;
            let end = this.state.end_date.year_month ? (this.state.end_date.year_month.year + '-' + this.state.end_date.year_month.month + "-" + (month_days[this.state.end_date.year_month.month] ? month_days[this.state.end_date.year_month.month] : '30')) : null;

            if (!end) {
                end = this.state.start_date.year_month ? (this.state.start_date.year_month.year + '-' + this.state.start_date.year_month.month + (month_days[this.state.start_date.year_month.month] ? month_days[this.state.start_date.year_month.month] : '30')) : null;
            }

            try {
                await this.promisedSetState({ loading_feed: true });
                let response = await this.calls.getFeed(feed, start, end);

                //SET METRICS
                let feed_items = [];
                try {
                    if (response.data && Array.isArray(response.data.listings)) {
                        feed_items = response.data.listings.map((item, index) => {
                            item.metrics = {};
                            for (let date in this.state.report["facebook"]) {
                                if (Array.isArray(this.state.report["facebook"][date]["feed"])) {
                                    this.state.report["facebook"][date]["feed"].map((listing) => {
                                        let parsed_id = typeof listing.product_id == 'string' ? listing.product_id.split(',') : null;
                                        if (parsed_id && Array.isArray(parsed_id) && parsed_id[0] == item.externalId) {
                                            item.metrics = listing ? listing : {};
                                            item.metrics.leads = item.leads;

                                            //CTR
                                            try {
                                                item.metrics.ctr = (item.metrics.click / item.metrics.impressions) * 100;
                                                item.metrics.ctr = item.metrics.ctr.toFixed(2);
                                            } catch (error) {
                                                item.metrics.ctr = 0;
                                            }

                                            //CVR
                                            try {
                                                item.metrics.cvr = (item.metrics.leads / item.metrics.click) * 100;
                                                item.metrics.cvr = item.metrics.cvr.toFixed(2);
                                            } catch (error) {
                                                item.metrics.cvr = 0;
                                            }

                                        }
                                    });
                                }
                            }
                            return item;
                        });
                    }
                } catch (error) {
                    console.log(error);
                }

                this.setState({
                    feed_items: feed_items,
                    loading_feed: false
                });

            } catch (error) {
                console.log(error);
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_feed: false
                })
            }
        },
        getEnterpriseType: (listing) => {
            if (listing && listing.enterprise) {
                if (listing.enterprise.adboosterType && listing.enterprise.adboosterType.value === 'boostabostad') {
                    if (listing.enterprise.version && listing.enterprise.version.id === 1) {
                        return "https://lp.boostabostad.se/" + listing.adboosterId;
                    } else {
                        return "https://lp.devboostabostad.se/" + listing.adboosterId;
                    }
                } else {
                    if (listing.enterprise.version && listing.enterprise.version.id === 1) {
                        return "https://lp.propertybooster.io/" + listing.adboosterId;
                    } else {
                        return "https://lp.devpropertybooster.com/" + listing.adboosterId;
                    }
                }
            } else {
                return '-'
            }
        },
        barChart: () => {
            try {
                let metrics = ['impressions', 'click', 'reactions', 'engagements', 'ctr_parsed', 'conversion_rate', 'actions', 'leads'];
                let values = [];
                let labels = [];
                let label_values = {};
                let graph_data = {
                    labels: [],
                    datasets: []
                };
                if (this.state.start_date.value == this.state.end_date.value) {
                    this.state.report[this.state.channel.value][this.state.dates[this.state.start_date.value]][this.state.breakdown.value].map((item) => {
                        let value = item[this.state.breakdown.property];
                        if (this.state.breakdown.value === "daily") {
                            value = moment(value).format("DD")
                        }
                        if (!(value in label_values)) {
                            label_values[value] = {};
                            metrics.map((metric) => {
                                label_values[value][metric] = 0;
                            });
                            values[value] = true;
                            labels.push(value);
                        }
                        metrics.map((metric) => {
                            if (metric in item) {
                                label_values[value][metric] += +item[metric];
                            }
                        })
                    });
                    graph_data = {
                        labels,
                        datasets: [
                            {
                                label: 'Impressions',
                                data: labels.map((item) => label_values[item].impressions),
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            },
                            {
                                label: 'Clicks',
                                data: labels.map((item) => label_values[item].click),
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            },
                            {
                                label: 'Reactions',
                                data: labels.map((item) => label_values[item].reactions),
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            },
                            {
                                label: 'Engagements',
                                data: labels.map((item) => label_values[item].engagements),
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            },
                            {
                                label: 'CTR',
                                data: labels.map((item) => label_values[item].ctr_parsed),
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            },
                            {
                                label: 'Actions',
                                data: labels.map((item) => label_values[item].actions),
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            },
                            {
                                label: 'CVR',
                                data: labels.map((item) => label_values[item].conversion_rate),
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            },
                            {
                                label: 'Leads',
                                data: labels.map((item) => label_values[item].leads),
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            }
                        ].filter((item) => {
                            return item.label === this.state.metric.name;
                        })
                    };
                } else {
                    let breakdown = null;
                    if (this.state.breakdown.value === 'gender') {
                        graph_data.labels = ['male', 'female', 'unknown'];
                        breakdown = 'gender';
                    } else if (this.state.breakdown.value === 'age') {
                        graph_data.labels = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'];
                        breakdown = 'age';
                    } else if (this.state.breakdown.value === 'monthly') {
                        graph_data.labels = [];
                        for (let date in this.state.report[this.state.channel.value]) {
                            let dates = date.split(" ");
                            //let label = moment(dates[1]).format("MMMM") + " (" + moment(dates[1]).format("YY") + ")";
                            let label = moment(dates[1]);
                            graph_data.labels.push(label);
                        }
                        graph_data.labels.sort(function (a, b) {
                            return new Date(a) - new Date(b);
                        });
                        graph_data.labels = graph_data.labels.map((item) => {
                            return moment(item).format("MMMM") + " (" + moment(item).format("YY") + ")";
                        });
                        breakdown = 'total';
                    }
                    if (breakdown === 'total') {
                        let dataset = {
                            label: this.state.metric.name,
                            data: [],
                            borderColor: 'rgb(69, 63, 241)',
                            backgroundColor: 'rgba(69, 63, 241, 0.7)'
                        };
                        graph_data.labels.map((label) => {
                            for (let date in this.state.report[this.state.channel.value]) {
                                let inner_label = "";
                                try {
                                    let dates = date.split(" ");
                                    inner_label = moment(dates[1]).format("MMMM") + " (" + moment(dates[1]).format("YY") + ")";
                                } catch (error) { }
                                if (label === inner_label) {
                                    dataset.data.push(this.state.report[this.state.channel.value][date].total[this.state.metric.value]);
                                }
                            }
                        });
                        graph_data.datasets.push(dataset);
                    } else {
                        let months = [];
                        for (let date in this.state.report[this.state.channel.value]) {
                            try {
                                let dates = date.split(" ");
                                months.push({ date: date, real_date: moment(dates[1]), label: moment(dates[1]).format("MMMM") + " (" + moment(dates[1]).format("YY") + ")" });
                            } catch (error) { }
                        }
                        months.sort(function (a, b) {
                            return new Date(a.real_date) - new Date(b.real_date);
                        });
                        months.map((month) => {
                            let dataset = {
                                label: month.label,
                                data: [],
                                borderColor: 'rgb(69, 63, 241)',
                                backgroundColor: 'rgba(69, 63, 241, 0.7)'
                            };
                            if (Array.isArray(this.state.report[this.state.channel.value][month.date][breakdown])) {
                                this.state.report[this.state.channel.value][month.date][breakdown].map((item) => {
                                    graph_data.labels.map((label) => {
                                        if (label === item[breakdown]) {
                                            if (this.state.metric.value === 'ctr') {
                                                dataset.data.push(item['ctr_parsed']);
                                            } else {
                                                dataset.data.push(item[this.state.metric.value]);
                                            }
                                        }
                                    })
                                });
                            }
                            graph_data.datasets.push(dataset);
                        })
                    }
                }
                return graph_data;
            } catch (error) {
                console.log(error);
                return {
                    labels: [],
                    datasets: []
                }
            }
        },
        getLeads: async (feed) => {
            let month_days = {
                '01': "31",
                '03': "31",
                '05': "31",
                '08': "31",
                '10': "31",
                '12': "31"
            };
            let start = this.state.start_date.year_month ? (this.state.start_date.year_month.year + '-' + this.state.start_date.year_month.month + '-01') : null;
            let end = this.state.end_date.year_month ? (this.state.end_date.year_month.year + '-' + this.state.end_date.year_month.month + "-" + (month_days[this.state.end_date.year_month.month] ? month_days[this.state.end_date.year_month.month] : '30')) : null;
            if (!end) {
                end = this.state.start_date.year_month ? (this.state.start_date.year_month.year + '-' + this.state.start_date.year_month.month + (month_days[this.state.start_date.year_month.month] ? month_days[this.state.start_date.year_month.month] : '30')) : null;
            }
            await this.promisedSetState({ loading_leads: true });
            try {
                let response = await this.calls.getLeads(feed, start, end);
            } catch (error) { }
            await this.promisedSetState({ loading_leads: false });
        }
    };

    calls = {
        report: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/enterprise/report?dates=" + this.state.tab.value + "&start=" + this.state.start_date.value + "&end=" + this.state.end_date.value + (this.state.feed ? "&feed=" + this.state.feed : "");
            return apiRegister.call(options, url);
        },
        getListings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/listings?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&sortBy=" + this.state.metric.value + "&channel=" + this.state.channel.value + "&dateSpan=" + this.state.dates[this.state.tab.value] + (this.state.listingType === "Archived listings" ? "&status=archived" : "");
            return apiRegister.call(options, url);
        },
        getFeed: (feed, start, end) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed-light/" + feed + "?report=true" + "&start=" + start + "&end=" + end;
            return apiRegister.call(options, url);
        },
        proptreeStats: (year, month) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/proptree/getProptreeStats?year=" + year + "&month=" + month;
            return apiRegister.call(options, url);
        },
        getLeads: (feed, start, end) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/lead/listLeadsLight?page=" + this.state.lead_page + "&limit=" + this.state.lead_page_count + "&start=" + start + "&end=" + end + (feed ? "&feed=" + feed : "")
            return apiRegister.call(options, url);
        }
    };

    renders = {
        total: (sektion) => {
            let total = 0;
            let click = 0;
            let impressions = 0;
            let engagements = 0;
            let actions = 0;
            let reactions = 0;
            let leads = 0;
            try {
                for (let date in this.state.report[this.state.channel.value]) {
                    click = click + +this.state.report[this.state.channel.value][date].total.click;
                    impressions = impressions + +this.state.report[this.state.channel.value][date].total.impressions;
                    engagements = engagements + +this.state.report[this.state.channel.value][date].total.engagements;
                    actions = actions + +this.state.report[this.state.channel.value][date].total.actions;
                    reactions = reactions + +this.state.report[this.state.channel.value][date].total.reactions;
                    leads = leads + +this.state.report[this.state.channel.value][date].total.leads;
                }
                if (sektion.value === 'click') {
                    total = click;
                }
                if (sektion.value === 'impressions') {
                    total = impressions;
                }
                if (sektion.value === 'engagements') {
                    total = engagements;
                }
                if (sektion.value === 'reactions') {
                    total = reactions;
                }
                if (sektion.value === 'conversion_rate' && actions > 0 && click > 0) {
                    total = (actions / click) * 100;
                    total = total.toFixed(2);
                }
                if (sektion.value === 'cvr' && leads > 0 && click > 0) {
                    total = (leads / click) * 100;
                    total = total.toFixed(2);
                }
                if (sektion.value === 'actions' && actions > 0) {
                    total = actions;
                }
                if (sektion.value === 'ctr' && impressions > 0 && click > 0) {
                    total = (click / impressions) * 100;
                    total = total.toFixed(2);
                }
                if (sektion.value === 'leads' && leads > 0) {
                    total = leads;
                }
            } catch (error) {
                total = 0
            }
            return total;
        },
        dateSpan: (item) => {
            let date_span = "";
            try {
                let dates = this.state.dates[item].split(" ");
                date_span = moment(dates[1]).format("MMMM") + " (" + moment(dates[1]).format("YY") + ")";
            } catch (error) { }
            return date_span;
        },
        yearAndMonth: (item) => {
            let year_month = { year: '', month: '' };
            try {
                let dates = this.state.dates[item].split(" ");
                year_month.year = moment(dates[1]).format("YYYY");
                year_month.month = moment(dates[1]).format("MM");
            } catch (error) { }
            return year_month;
        },
        basicUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled';
        },
        creativeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.creativeSwitch && this.state.user.enterprise.creativeSwitch.value == 'enabled';
        },
        proptreeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.proptreeSwitch && this.state.user.enterprise.proptreeSwitch.value == 'enabled';
        },
        channels: () => {
            let channels = [];
            channels = this.state.channels.filter((item) => {
                return this.state.report && this.state.report[item.value]
            });
            if (!this.state.report.facebook && this.state.enterprise_light) {
                channels.push({ id: 1, name: "Meta", value: 'facebook' });
            }
            if (this.renders.proptreeUI()) {
                channels.push({ id: 5, name: "Proptree", value: 'proptree' });
            }
            return channels;
        },
        chartColor: (option) => {
            let options = {
                "page_loads": "26, 213, 189",
                "clicks": "69, 63, 241",
                "leads": "234, 112, 30",
                "ctr": "255, 196, 7"
            };
            return options[option];
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 gap-4">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading report...</div>
                        </div>
                    </div>
                }

                {/*CHANNEL*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12 md:col-span-2 mb-4 md:mb-6">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.enterprise_light && !this.renders.proptreeUI()}
                                selected={this.state.channel}
                                options={this.renders.channels()}
                                searchInput={false}
                                onChange={async (item) => {
                                    await this.promisedSetState({
                                        channel: item,
                                        breakdown: this.state.start_date.value == this.state.end_date.value ? { name: "Daily breakdown", value: "daily", property: 'date' } : { name: "Monthly breakdown", value: "monthly", property: 'month' },
                                        metric: { name: "Impressions", value: "impressions" }
                                    });
                                    if (item.value === "facebook" && this.state.start_date.value == this.state.end_date.value) {
                                        this.functions.getListings();
                                    }
                                    if (this.state.channel.value == 'proptree') {
                                        await this.promisedSetState({
                                            metric: { name: "Views", value: "page_loads", suffix: "" }
                                        });
                                        this.functions.getProptree();
                                    }
                                }}
                            />
                        </div>
                    </div>
                }

                <div className={(this.state.channel.value !== 'proptree' && this.state.start_date.id !== 1 ? "md:col-span-6" : "md:col-span-8") + " col-span-0 hidden md:block"}></div>

                {/*START DATE*/}
                {
                    !this.state.loading &&
                    <div className={(this.state.channel.value !== 'proptree' && this.state.start_date.id !== 1 ? "col-span-6" : "col-span-12") + " md:col-span-2 mb-4 md:mb-6"}>
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.start_date}
                                options={[
                                    { id: 1, year_month: this.renders.yearAndMonth('current_month'), name: this.renders.dateSpan('current_month'), value: 'current_month' },
                                    { id: 2, year_month: this.renders.yearAndMonth('last_month'), name: this.renders.dateSpan('last_month'), value: 'last_month' },
                                    { id: 3, year_month: this.renders.yearAndMonth('third_month'), name: this.renders.dateSpan('third_month'), value: 'third_month' },
                                    { id: 4, year_month: this.renders.yearAndMonth('fourth_month'), name: this.renders.dateSpan('fourth_month'), value: 'fourth_month' },
                                    { id: 5, year_month: this.renders.yearAndMonth('fifth_month'), name: this.renders.dateSpan('fifth_month'), value: 'fifth_month' },
                                    { id: 6, year_month: this.renders.yearAndMonth('sixth_month'), name: this.renders.dateSpan('sixth_month'), value: 'sixth_month' },
                                    { id: 7, year_month: this.renders.yearAndMonth('seventh_month'), name: this.renders.dateSpan('seventh_month'), value: 'seventh_month' },
                                    { id: 8, year_month: this.renders.yearAndMonth('eighth_month'), name: this.renders.dateSpan('eighth_month'), value: 'eighth_month' },
                                    { id: 9, year_month: this.renders.yearAndMonth('ninth_month'), name: this.renders.dateSpan('ninth_month'), value: 'ninth_month' },
                                    { id: 10, year_month: this.renders.yearAndMonth('tenth_month'), name: this.renders.dateSpan('tenth_month'), value: 'tenth_month' },
                                    { id: 11, year_month: this.renders.yearAndMonth('eleventh_month'), name: this.renders.dateSpan('eleventh_month'), value: 'eleventh_month' },
                                    { id: 12, year_month: this.renders.yearAndMonth('twelfth_month'), name: this.renders.dateSpan('twelfth_month'), value: 'twelfth_month' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    console.log(option);
                                    await this.promisedSetState({
                                        tab: option,
                                        start_date: option,
                                        page: 1
                                    });
                                    if (this.state.channel.value == 'proptree') {
                                        this.functions.getProptree();
                                    } else {
                                        this.functions.getReport();
                                    }
                                }}
                            />
                        </div>
                    </div>
                }

                {/*END DATE*/}
                {
                    this.state.start_date.id !== 1 &&
                    this.state.channel.value !== "proptree" &&
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.end_date}
                                options={[
                                    { id: 1, name: this.renders.dateSpan('current_month'), value: 'current_month' },
                                    { id: 2, name: this.renders.dateSpan('last_month'), value: 'last_month' },
                                    { id: 3, name: this.renders.dateSpan('third_month'), value: 'third_month' },
                                    { id: 4, name: this.renders.dateSpan('fourth_month'), value: 'fourth_month' },
                                    { id: 5, name: this.renders.dateSpan('fifth_month'), value: 'fifth_month' },
                                    { id: 6, name: this.renders.dateSpan('sixth_month'), value: 'sixth_month' },
                                    { id: 7, name: this.renders.dateSpan('seventh_month'), value: 'seventh_month' },
                                    { id: 8, name: this.renders.dateSpan('eighth_month'), value: 'eighth_month' },
                                    { id: 9, name: this.renders.dateSpan('ninth_month'), value: 'ninth_month' },
                                    { id: 10, name: this.renders.dateSpan('tenth_month'), value: 'tenth_month' },
                                    { id: 11, name: this.renders.dateSpan('eleventh_month'), value: 'eleventh_month' },
                                    { id: 12, name: this.renders.dateSpan('twelfth_month'), value: 'twelfth_month' }
                                ].filter((item) => {
                                    return item.id <= this.state.start_date.id
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        end_date: option,
                                        page: 1
                                    });
                                    this.functions.getReport();
                                }}
                            />
                        </div>
                    </div>
                }

                {/* PROPTREE */}
                {
                    !this.state.loading &&
                    this.state.channel.value == "proptree" &&
                    <div className="col-span-12 grid sm:grid-cols-3 lg:grid-cols-5 gap-4">
                        {
                            [
                                { name: "Views", value: "page_loads", suffix: "" },
                                { name: "Clicks", value: "clicks", suffix: "" },
                                { name: "CTR", value: "ctr", suffix: "%" },
                                { name: "Leads", value: "inquiries", suffix: "" },
                                { name: "CVR", value: "cvr", suffix: "%" },
                                //{ name: "Shares", value: "shares", suffix: "" }
                            ].map((sektion) => {
                                return (
                                    <div onClick={async () => {
                                        if (sektion.value !== 'cvr') {
                                            await this.promisedSetState({
                                                metric: sektion
                                            });
                                        }
                                    }} className={(sektion.value === this.state.metric.value ? "border-purple-500" : "") + " cursor-pointer sm:col-span-1 col-span-12 border rounded-lg overflow-hidden bg-white shadow"}>
                                        <div className="flex p-4 px-6 border-b border-gray-100">
                                            <div className="font-sm font-semibold flex-1 flex">
                                                {sektion.name}
                                            </div>
                                        </div>
                                        <div className="p-6 py-6">
                                            <dd className="text-4xl font-bold tracking-tight text-gray-900">
                                                {this.state.proptree && this.state.proptree[sektion.value] ? this.state.proptree[sektion.value]['month'] : '-'}{sektion.suffix}
                                            </dd>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {/* PROPTREE */}
                {
                    !this.state.loading &&
                    this.state.channel.value == "proptree" &&
                    this.state['proptree'] &&
                    Array.isArray(this.state['proptree']['this_month_daily']) &&
                    <div className="bg-white border shadow overflow-hidden rounded-lg col-span-12">
                        <div className="flex p-4 flex-col px-6 border-b border-gray-100">
                            <div className="font-sm font-semibold flex-1 flex">
                                Daily breakdown on <span className="text-purple-500 ml-1">{this.state.metric.name}</span>
                            </div>
                        </div>
                        <div className="h-120 pt-4 pb-4 px-2">
                            <Bar
                                options={this.state.chart_options_proptree}
                                data={{
                                    labels: this.state['proptree']['this_month_daily'].map(date => date.split('-')[2]),
                                    datasets: [
                                        {
                                            //borderWidth: 2,
                                            label: 'Leads',
                                            borderColor: 'rgb(69, 63, 241)',
                                            backgroundColor: 'rgba(69, 63, 241, 0.7)',
                                            data: this.state['proptree']["inquiries"]["this_month_daily"].map((item) => { return item.value })
                                        },
                                        {
                                            //borderWidth: 2,
                                            label: 'Clicks',
                                            borderColor: 'rgb(69, 63, 241)',
                                            backgroundColor: 'rgba(69, 63, 241, 0.7)',
                                            data: this.state['proptree']["clicks"]["this_month_daily"].map((item) => { return item.value })
                                        },
                                        {
                                            //borderWidth: 2,
                                            label: 'Views',
                                            borderColor: 'rgb(69, 63, 241)',
                                            backgroundColor: 'rgba(69, 63, 241, 0.7)',
                                            data: this.state['proptree']["page_loads"]["this_month_daily"].map((item) => { return item.value })
                                        },
                                        {
                                            //borderWidth: 2,
                                            label: 'CTR',
                                            borderColor: 'rgb(69, 63, 241)',
                                            backgroundColor: 'rgba(69, 63, 241, 0.7)',
                                            data: this.state['proptree']["ctr"]["this_month_daily"].map((item) => { return item.value })
                                        }
                                    ].filter((item) => {
                                        return item.label == this.state.metric.name;
                                    })
                                }}
                            />
                        </div>
                    </div>
                }

                {/* PROPTREE */}
                {
                    !this.state.loading &&
                    this.state.channel.value == "proptree" &&
                    this.state['proptree'] &&
                    this.state['proptree']['top_10_listings'] &&
                    <div className="col-span-12">
                        <section className="bg-white border shadow rounded-lg">
                            <div className="flex p-4 px-6 border-b border-gray-100">
                                <div className="font-sm font-semibold flex-1 flex">
                                    Top 10 Listings by <span className="text-purple-500 ml-1">Clicks</span>
                                </div>
                            </div>
                            <div className="relative overflow-x-scroll">
                                <table className="min-w-full">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="">
                                                <span className="sr-only">Listing</span>
                                            </th>
                                            <th scope="col" className="">
                                                <span className="sr-only">Clicks</span>
                                            </th>
                                            <th scope="col" className="">
                                                <span className="sr-only">Leads</span>
                                            </th>
                                            <th scope="col" className="">
                                                <span className="sr-only">CVR</span>
                                            </th>
                                            {
                                                false &&
                                                <th scope="col" className="">
                                                    <span className="sr-only">Shares</span>
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    {
                                        this.state['proptree'].top_10_listings.map((item, index) => {
                                            return (
                                                <tbody>
                                                    <tr class="border-b border-gray-100">
                                                        <td className="flex items-center px-6 py-4">
                                                            {
                                                                item.images.length > 0 &&
                                                                <div style={{ backgroundImage: "url(" + item.images[0] + ")" }} className="min-h-10 min-w-10 bg-cover rounded-full border"></div>
                                                            }
                                                            <div className="pl-3">
                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">
                                                                    {this.state.enterprise_project ? (item.projectName ? item.projectName : '-') : (item.adboosterIndustry ? item.adboosterIndustry.name : '-')}
                                                                </div>
                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">
                                                                    #{item.id}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{item.clicks ? item.clicks : "-"}</div>
                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">Clicks</div>
                                                        </td>
                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{item.leads ? item.leads : "-"}</div>
                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">Leads</div>
                                                        </td>
                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{+item.leads > 0 && +item.clicks > 0 ? ((item.leads / item.clicks) * 100).toFixed(2) + "%" : "-%"}</div>
                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">CVR</div>
                                                        </td>
                                                        {
                                                            false &&
                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{item.shares ? item.shares : "-"}</div>
                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Shares</div>
                                                            </td>
                                                        }
                                                        <td className="text-center px-6 py-4"></td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </section>
                    </div>
                }

                {/* PROPTREE */}
                {
                    !this.state.loading &&
                    this.state.channel.value == "proptree" &&
                    this.state['proptree'] &&
                    this.state['proptree']['top_10_accounts'] &&
                    <div className="col-span-12">
                        <section className="bg-white border shadow rounded-lg">
                            <div className="flex p-4 px-6 border-b border-gray-100">
                                <div className="font-sm font-semibold flex-1 flex">
                                    Top 10 Proptree by <span className="text-purple-500 ml-1">Clicks</span>
                                </div>
                            </div>
                            <div className="relative overflow-x-scroll">
                                <table className="min-w-full">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="">
                                                <span className="sr-only">Proptree</span>
                                            </th>
                                            <th scope="col" className="">
                                                <span className="sr-only">Clicks</span>
                                            </th>
                                            <th scope="col" className="">
                                                <span className="sr-only">Leads</span>
                                            </th>
                                            <th scope="col" className="">
                                                <span className="sr-only">CVR</span>
                                            </th>
                                            {
                                                false &&
                                                <th scope="col" className="">
                                                    <span className="sr-only">Shares</span>
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    {
                                        this.state['proptree'].top_10_accounts.map((item, index) => {
                                            return (
                                                <tbody>
                                                    <tr class="border-b border-gray-100">
                                                        <td className="flex items-center px-6 py-4">
                                                            {
                                                                item.image &&
                                                                <div style={{ backgroundImage: "url(" + item.image + ")" }} className="min-h-10 min-w-10 bg-cover rounded-full border"></div>
                                                            }
                                                            <div className="pl-3">
                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">
                                                                    {item.alias ? item.alias : item.id}
                                                                </div>
                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">
                                                                    {item.name ? item.name : '-'}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{item.clicks ? item.clicks : "-"}</div>
                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">Clicks</div>
                                                        </td>
                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{item.leads ? item.leads : "-"}</div>
                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">Leads</div>
                                                        </td>
                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{+item.leads > 0 && +item.clicks > 0 ? ((item.leads / item.clicks) * 100).toFixed(2) + "%" : "-%"}</div>
                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">CVR</div>
                                                        </td>
                                                        {
                                                            false &&
                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{item.shares ? item.shares : "-"}</div>
                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Shares</div>
                                                            </td>
                                                        }
                                                        <td className="text-center px-6 py-4"></td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </section>
                    </div>
                }

                {
                    !this.state.loading &&
                    this.state.channel.value !== "proptree" &&
                    <div className="col-span-12 grid grid-cols-12 gap-4">

                        {/*SECTIONS*/}
                        {
                            Array.isArray(this.state.sektions[this.state.channel.value]) &&
                            this.state.sektions[this.state.channel.value].map((sektion) => {
                                return (
                                    <div onClick={async () => {
                                        if (sektion.value !== 'cvr') {
                                            await this.promisedSetState({
                                                metric: sektion,
                                                page: 1
                                            });
                                            if (sektion.value == 'leads') {
                                                if (this.state.start_date.value !== this.state.end_date.value) {
                                                    await this.promisedSetState({
                                                        breakdown: { name: "Monthly breakdown", value: "monthly", property: 'month' }
                                                    });
                                                } else {
                                                    await this.promisedSetState({
                                                        breakdown: { name: "Daily breakdown", value: "daily", property: 'date' }
                                                    });
                                                }
                                            }
                                            if (this.state.feed) {
                                                //this.functions.getFeed(this.state.feed);
                                            } else if (this.state.start_date.value === this.state.end_date.value) {
                                                this.functions.getListings();
                                            }
                                        }
                                    }} className={(sektion.value === this.state.metric.value ? "border-purple-500" : "") + ((this.state.enterprise_light || this.state.channel.value === "all_channels") ? " sm:col-span-4 lg:col-span-2" : " sm:col-span-3") + " col-span-12 border rounded-lg cursor-pointer overflow-hidden bg-white shadow"}>
                                        <div className="flex p-4 px-6 border-b border-gray-100">
                                            <div className="font-sm font-semibold flex-1 flex">
                                                {sektion.name}
                                            </div>
                                        </div>
                                        <div className="p-6 py-6">
                                            <dd className="text-4xl font-bold tracking-tight text-gray-900">{this.renders.total(sektion)}{sektion.suffix}</dd>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            !this.state.loading &&
                            <div className="col-span-12 row-gap-4 grid grid-cols-12">
                                <div className="bg-white border shadow overflow-hidden rounded-lg col-span-12">
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.breakdown.name} on <span className="text-purple-500 ml-1">{this.state.metric.name}</span>
                                        </div>
                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                            <div>
                                                <Menu.Button className={"cursor-pointer capitalize inline-flex relative justify-center items-center w-full rounded-md px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                    {this.state.breakdown.value}
                                                    {
                                                        this.state.metric.value !== 'leads' &&
                                                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                    }
                                                    {
                                                        this.state.metric.value == 'leads' &&
                                                        <ChevronUpIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                    }
                                                </Menu.Button>
                                            </div>
                                            {
                                                this.state.metric.value !== 'leads' &&
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="border absolute right-0 capitalize z-50 mt-2 w-56 max-h-40 auto-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        {
                                                            [
                                                                { name: "Daily breakdown", value: "daily", property: 'date' },
                                                                { name: "Monthly breakdown", value: "monthly", property: 'month' },
                                                                { name: "Age breakdown", value: "age", property: 'age' },
                                                                { name: "Gender breakdown", value: "gender", property: 'gender' }
                                                            ].filter((item) => {
                                                                if (this.state.metric.value == 'leads') {
                                                                    return item.value == "date" || item.value == "month";
                                                                } else {
                                                                    return true;
                                                                }
                                                            }).filter((item) => {
                                                                if (this.state.start_date.value !== this.state.end_date.value) {
                                                                    if (this.state.channel.value === 'all_channels') {
                                                                        return item.value === 'monthly';
                                                                    } else {
                                                                        return item.value !== 'daily';
                                                                    }
                                                                } else {
                                                                    if (this.state.channel.value === 'all_channels') {
                                                                        return item.value === 'daily';
                                                                    } else {
                                                                        return item.value !== 'monthly';
                                                                    }
                                                                }
                                                            }).map((item) => {
                                                                return (
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <div
                                                                                onClick={async () => {
                                                                                    await this.promisedSetState({
                                                                                        breakdown: item
                                                                                    });
                                                                                }}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer capitalize',
                                                                                    this.state.breakdown.value == item.value ? "text-purple-500" : ""
                                                                                )}
                                                                            >
                                                                                {item.value}
                                                                            </div>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                            }
                                        </Menu>
                                    </div>
                                    <div className="h-120 pt-4 pb-4 px-2">
                                        <Bar
                                            options={this.state.chart_options}
                                            data={this.functions.barChart()}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {/*FEED*/}
                        {
                            this.state.feed &&
                            <div className="col-span-12">
                                <div className="">
                                    {
                                        <section className="bg-white border shadow rounded-lg">
                                            <div className="flex p-4 px-6 border-b border-gray-100">
                                                <div className="font-sm font-semibold flex-1 flex">
                                                    Feed items sorted by <span className="text-purple-500 ml-1">{this.state.metric.name}</span>
                                                </div>
                                            </div>
                                            {
                                                this.state.feed_items.length < 1 &&
                                                <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                                    {
                                                        !this.state.loading_feed &&
                                                        <div className="font-medium text-sm">No <span className="text-purple-500">Feed items</span> available</div>
                                                    }
                                                    {
                                                        this.state.loading_feed &&
                                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                this.state.feed_items.length > 0 &&
                                                <div className="relative px-6 py-4 flex flex-row overflow-x-scroll">
                                                    {
                                                        this.state.feed_items.sort((a, b) => {
                                                            return b.metrics[this.state.metric.value] - a.metrics[this.state.metric.value];
                                                        }).filter((item, index) => {
                                                            return index < (this.state.feed_page * this.state.feed_count);
                                                        }).map((item, index) => {
                                                            return (
                                                                <div className="text-center mr-6">
                                                                    <div className="text-xs mb-2 text-gray-500">
                                                                        {"#" + item.externalId}
                                                                    </div>
                                                                    <div style={{ width: '250px', height: "250px" }} className=" bg-white relative">
                                                                        <div className="absolute w-full h-full z-10">
                                                                            <div className="w-full h-full relative">
                                                                                <iframe src={item.url + "&legacy=false"} width="250" height="250" frameborder="0" scrolling="no" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                                                            </div>
                                                                        </div>
                                                                        <div className="absolute top-0 bg-gray-50 bottom-0 left-0 z-5 right-0 flex justify-center items-center ">
                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                class="w-8 h-8 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-sm mt-4 font-medium">
                                                                        {item.metrics && item.metrics[this.state.metric.value] ? item.metrics[this.state.metric.value] : "-"}
                                                                        {this.state.metric.value == 'ctr' ? "%" : ""}
                                                                    </div>
                                                                    <div className="text-sm text-gray-500">
                                                                        {this.state.metric.name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {
                                                this.state.feed_items.length > 0 &&
                                                <div className="rounded-b-lg">
                                                    <nav
                                                        className="py-4 flex items-center justify-between px-6"
                                                        aria-label="Pagination"
                                                    >
                                                        {
                                                            this.state.listings.length > 0 &&
                                                            <div className="hidden sm:block">
                                                                <p className="text-sm text-gray-700">
                                                                    Showing <span className="font-medium">{((this.state.feed_page * this.state.feed_count) - this.state.feed_count) + 1}</span> to <span className="font-medium">{(this.state.feed_page * this.state.feed_count) > this.state.feed_items.length ? this.state.feed_items.length : (this.state.feed_page * this.state.feed_count)}</span> of{' '}
                                                                    <span className="font-medium">{this.state.total}</span> results
                                                                </p>
                                                            </div>
                                                        }
                                                        <div className="flex-1 flex flex-row">
                                                            <div className="flex-1 sm:justify-end flex">
                                                                <Menu as="div" className="relative inline-block text-left mr-2">
                                                                    <div>
                                                                        <Menu.Button className={(this.state.loading_feed ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                            Page limit: {this.state.feed_count}
                                                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                        </Menu.Button>
                                                                    </div>
                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                            <div className="py-1">
                                                                                {
                                                                                    [1, 10, 20, 30, 50, 100].map((item) => {
                                                                                        return (
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <div
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                feed_count: item,
                                                                                                                //feed_page: 1
                                                                                                            })
                                                                                                        }}
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                            this.state.feed_page == item ? "text-purple-500" : ""
                                                                                                        )}
                                                                                                    >
                                                                                                        {item}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </Menu>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    this.setState({
                                                                        feed_page: this.state.feed_page + 1
                                                                    });
                                                                }}
                                                                className={(((this.state.feed_page) * this.state.feed_count) > this.state.feed_items.length ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                            >
                                                                Show more
                                                            </div>
                                                        </div>
                                                    </nav>
                                                </div>
                                            }
                                        </section>
                                    }
                                </div>
                            </div>
                        }

                        {/*LISTINGS*/}
                        {
                            this.state.start_date.value === this.state.end_date.value &&
                            this.state.channel.value === "facebook" &&
                            !this.state.feed &&
                            !this.state.loading &&
                            <div className="col-span-12">
                                <div className="">
                                    {
                                        <section className="bg-white border shadow rounded-lg">
                                            <div className="flex p-4 px-6 border-b border-gray-100">
                                                <div className="font-sm font-semibold flex-1 flex">
                                                    Listings sorted by <span className="text-purple-500 ml-1">{this.state.metric.name}</span>
                                                </div>
                                                {
                                                    <Menu as="div" className="relative inline-block text-left mr-2">
                                                        <div>
                                                            <Menu.Button className={"cursor-pointer capitalize inline-flex relative justify-center items-center w-full rounded-md px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                {this.state.listingType}
                                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="border absolute right-0 capitalize z-50 mt-2 w-56 max-h-40 auto-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {
                                                                        ["Active listings", "Archived listings"].map((item) => {
                                                                            return (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <div
                                                                                            onClick={async () => {
                                                                                                await this.promisedSetState({
                                                                                                    listingType: item,
                                                                                                    page: 1
                                                                                                });
                                                                                                this.functions.getListings();
                                                                                            }}
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer capitalize',
                                                                                                this.state.listingType == item ? "text-purple-500" : ""
                                                                                            )}
                                                                                        >
                                                                                            {item}
                                                                                        </div>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                }
                                            </div>
                                            {
                                                this.state.listings.length < 1 &&
                                                <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                                    {
                                                        !this.state.loading_listings &&
                                                        <div className="font-medium text-sm">No <span className="text-purple-500">Listings</span> available</div>
                                                    }
                                                    {
                                                        this.state.loading_listings &&
                                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                this.state.listings.length > 0 &&
                                                <div className="relative overflow-x-scroll">
                                                    <table className="min-w-full">
                                                        <thead className="">
                                                            <tr>
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">Listing</span>
                                                                </th>
                                                                {
                                                                    false &&
                                                                    <th scope="col" className="">
                                                                        <span className="sr-only">Permit</span>
                                                                    </th>
                                                                }
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">Impressions</span>
                                                                </th>
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">Clicks</span>
                                                                </th>
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">CTR</span>
                                                                </th>
                                                                {
                                                                    this.state.enterprise_light &&
                                                                    <th scope="col" className="">
                                                                        <span className="sr-only">Leads</span>
                                                                    </th>
                                                                }
                                                                {
                                                                    this.state.enterprise_light &&
                                                                    <th scope="col" className="">
                                                                        <span className="sr-only">CVR</span>
                                                                    </th>
                                                                }
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">Reactions</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="">
                                                            {
                                                                this.state.listings.map((agent, index) => {
                                                                    return (
                                                                        <tr key={agent.email} className="border-b border-gray-100">
                                                                            <td className="flex items-center px-6 py-4">
                                                                                {
                                                                                    !agent.image &&
                                                                                    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                        <svg className="h-full w-full text-gray-300" width="24" height="20" viewBox="0 0 24 20" fill="none">
                                                                                            <path d="M19.0316 0L19.9614 5.43362H24V20.2812H4.67806V18.9156L2.78923 19.2351L0 3.34155L19.0316 0ZM4.06803 17.4332L4.67817 17.3172V5.43339H18.3927L17.7533 1.80113L1.8017 4.61979L4.06803 17.4332ZM6.247 18.7119H22.4312V7.00255H6.247V18.7119ZM7.29291 16.7359V8.04847H21.3849V14.3535L18.1889 10.6632L17.5494 10.4018L12.6101 15.8352L10.5763 14.557L7.29291 16.7359ZM9.03633 10.4018C9.03633 11.1574 9.67568 11.7965 10.4309 11.7965C11.1864 11.7965 11.8257 11.1574 11.8254 10.4018C11.8254 9.64602 11.1864 9.00691 10.4309 9.00691C9.67568 9.00691 9.03633 9.64637 9.03633 10.4018Z" fill="currentColor" />
                                                                                        </svg>
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    agent.image &&
                                                                                    <div className="flex-shrink-0 h-10 w-10">
                                                                                        <img className="h-10 w-10 rounded-full" src={agent.image} alt="" />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    !this.state.enterprise_light &&
                                                                                    <div className="ml-4 whitespace-no-wrap">
                                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.name}</div>
                                                                                        <a href={this.functions.getEnterpriseType(agent)} target="_blank" className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap">
                                                                                            #{agent.reference}
                                                                                        </a>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    this.state.enterprise_light &&
                                                                                    <div className="ml-4 whitespace-no-wrap">
                                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{this.state.enterprise_project ? agent.projectName : agent.name}</div>
                                                                                        <a className="text-sm text-gray-500 whitespace-no-wrap">
                                                                                            #{agent.reference}
                                                                                        </a>
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                            {
                                                                                false &&
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.permit_id ? agent.permit_id : "-"}</div>
                                                                                    <div className="text-sm text-gray-500">Permit</div>
                                                                                </td>
                                                                            }
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.report && agent.report[this.state.channel.value] ? (agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]] ? agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]].impressions : "-") : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Impressions</div>
                                                                            </td>
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.report && agent.report[this.state.channel.value] ? (agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]] ? agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]].click : "-") : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Clicks</div>
                                                                            </td>
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.report && agent.report[this.state.channel.value] ? (agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]] ? agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]].ctr_parsed : "-") : "-"}%</div>
                                                                                <div className="text-sm text-gray-500">CTR</div>
                                                                            </td>
                                                                            {
                                                                                this.state.enterprise_light &&
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.report && agent.report[this.state.channel.value] ? (agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]] && agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]].leads ? agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]].leads : "-") : "-"}</div>
                                                                                    <div className="text-sm text-gray-500">Leads</div>
                                                                                </td>
                                                                            }
                                                                            {
                                                                                this.state.enterprise_light &&
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.report && agent.report[this.state.channel.value] ? (agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]] ? agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]].cvr_parsed : "-") : "-"}%</div>
                                                                                    <div className="text-sm text-gray-500">CVR</div>
                                                                                </td>
                                                                            }
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.report && agent.report[this.state.channel.value] ? (agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]] ? agent.report[this.state.channel.value][this.state.dates[this.state.tab.value]].reactions : "-") : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Reactions</div>
                                                                            </td>
                                                                            <td className="text-center px-6 py-4"></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {
                                                        this.state.loading_listings &&
                                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                this.state.listings.length > 0 &&
                                                <div className="rounded-b-lg">
                                                    <nav
                                                        className="py-4 flex items-center justify-between px-6"
                                                        aria-label="Pagination"
                                                    >
                                                        {
                                                            this.state.listings.length > 0 &&
                                                            <div className="hidden sm:block">
                                                                <p className="text-sm text-gray-700">
                                                                    Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.listings.length ? this.state.listings.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                                                    <span className="font-medium">{this.state.total}</span> results
                                                                </p>
                                                            </div>
                                                        }
                                                        <div className="flex-1 flex flex-row">
                                                            <div className="flex-1 sm:justify-end flex">
                                                                <Menu as="div" className="relative inline-block text-left mr-2">
                                                                    <div>
                                                                        <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                            Page limit: {this.state.page_count}
                                                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                        </Menu.Button>
                                                                    </div>
                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                            <div className="py-1">
                                                                                {
                                                                                    [1, 10, 20, 30, 50, 100].map((item) => {
                                                                                        return (
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <div
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                page_count: item,
                                                                                                                page: 1
                                                                                                            }, () => {
                                                                                                                this.functions.getListings();
                                                                                                            })
                                                                                                        }}
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                            this.state.page_count == item ? "text-purple-500" : ""
                                                                                                        )}
                                                                                                    >
                                                                                                        {item}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </Menu>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    this.setState({
                                                                        select_all: false,
                                                                        page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                                    }, () => {
                                                                        this.functions.getListings();
                                                                    });
                                                                }}
                                                                className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                            >
                                                                Previous
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                        this.setState({
                                                                            select_all: false,
                                                                            page: this.state.page + 1
                                                                        }, () => {
                                                                            this.functions.getListings();
                                                                        });
                                                                    }
                                                                }}
                                                                className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                            >
                                                                Next
                                                            </div>
                                                        </div>
                                                    </nav>
                                                </div>
                                            }
                                        </section>
                                    }
                                </div>
                            </div>
                        }

                    </div>
                }

                <div className="col-span-12 h-6"></div>

            </div>
        )
    }
}

export default Report;
