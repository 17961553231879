import React, { Component, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Pusher from 'pusher-js';
import { CashIcon, CollectionIcon, CurrencyDollarIcon, CursorClickIcon, EmojiSadIcon, ExclamationCircleIcon, FolderAddIcon, HomeIcon, MailOpenIcon, PlayIcon, SortAscendingIcon, SortDescendingIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import SingleSelection from '../components/singleSelection';
import WarningModal from '../components/warningModal';
import TestData from '../assets/test.json';
import { RefreshIcon, PlusCircleIcon, TrashIcon, PencilIcon, ArchiveIcon, ChevronDownIcon, CheckIcon, XIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { ArrowRightIcon, StopIcon } from '@heroicons/react/solid';
import { versionRegister } from '../services/versionRegister';
import { isFor } from '@babel/types';
var moment = require('moment');

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

var pusherService = null;

class ListListings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            listings: [],
            enterprises: [],
            enterprise: { id: 0, name: "Select enterprise" },
            page: 1,
            page_count: 10,
            loading: true,
            initial: true,
            running: false,
            columns: [

                //STATUS AND IDS
                { name: "Message", value: "message" },
                { name: "Enterprise", value: "enterprise" },
                { name: "External ref.", value: "externalId" },
                { name: "Upload progress", value: "listingProgress" },

                //FEEDS INFO
                { name: "Status", value: "listingStatus" },
                { name: "Badges", value: "badges" },
                { name: "Hosted images", value: "hostedImages" },
                { name: "Blur", value: "blurImage" },
                { name: "Feed images", value: "feedImages" },
                { name: "Updated", value: "internalUpdatedData" },
                { name: "Landing", value: "adboosterId" },

                //STATUS AND IDS
                { name: "Campaign ID", value: "adboosterCampaign" },
                { name: "Permit ID", value: "permitId" },
                { name: "Permit QR External", value: "permitQR" },
                { name: "Permit QR Internal", value: "permitQRinternal" },

                //TIME
                { name: "Created", value: "createdAt" },
                { name: "Updated", value: "lastUpdated" },

                //FOR SALE/RENT AND PRICE
                { name: "For sale", value: "forSale" },
                { name: "For rent", value: "forRent" },
                { name: "Price", value: "price", category: "unit" },
                //PROJECT START
                { name: "From price", value: "price", version: "light", category: "project" },
                { name: "To price", value: "toPrice", version: "light", category: "project" },
                //PROJECT END

                //BROKER
                { name: "Agent", value: "broker" },

                //INFORMATION
                { name: "Adgroup", value: "adboosterIndustry", category: "unit" },
                //PROJECT START
                { name: "Master type", value: "propertyType", version: "light", category: "project" },
                { name: "Project types", value: "propertyTypes", version: "light", category: "project" },
                //PROJECT END
                { name: "Bedrooms", value: "bedrooms", category: "unit" },
                //PROJECT START
                { name: "From bedrooms", value: "bedrooms", version: "light", category: "project" },
                { name: "To bedrooms", value: "toBedrooms", version: "light", category: "project" },
                { name: "From bathrooms", value: "bathrooms", version: "light", category: "project" },
                { name: "To bathrooms", value: "toBathrooms", version: "light", category: "project" },
                { name: "From size (sqft)", value: "size", version: "light", category: "project" },
                { name: "To size (sqft)", value: "toSize", version: "light", category: "project" },
                { name: "ROI", value: "roi", version: "light", category: "project" },
                { name: "Payment plan up front", value: "paymentPlanUpFront", version: "light", category: "project" },
                { name: "Payment plan installment", value: "paymentPlanInstallment", version: "light", category: "project" },
                { name: "Payment plan during", value: "paymentPlanDuring", version: "light", category: "project" },
                { name: "Payment plan handover", value: "paymentPlanHandover", version: "light", category: "project" },
                { name: "Payment plan post handover", value: "paymentPlanPostHandover", version: "light", category: "project" },
                { name: "Display priority", value: "displayPriority", version: "light", category: "project" },
                //PROJECT END
                { name: "Bathrooms", value: "bathrooms", category: "unit" },
                { name: "Size (sqft)", value: "size", category: "unit" },
                { name: "CRM images", value: "images" },
                { name: "Amenities", value: "amenities" },

                //LOCATION
                { name: "Country", value: "country" },
                { name: "Region", value: "region" },
                { name: "City", value: "city" },
                { name: "Area", value: "area" },
                { name: "Building name", value: "buildingName", category: "unit" },
                //PROJECT START
                { name: "Project name", value: "projectName", version: "light", category: "project" },
                { name: "Project logo External", value: "projectImage", version: "light", category: "project" },
                { name: "Project logo Internal", value: "projectImageInternal", version: "light", category: "project" },
                //PROJECT END
                { name: "Geopoints", value: "location" },

                //LIGHT START
                //NEW START
                { name: "Developer", value: "developer", version: "light" },
                { name: "Developer logo External", value: "developerImage", version: "light" },
                { name: "Developer logo Internal", value: "developerImageInternal", version: "light" },
                { name: "Custom creative Override", value: "creativeOverride", version: "light" },
                { name: "Custom creative External", value: "customCreativeURL", version: "light" },
                { name: "Custom creative Internal", value: "customCreativeURLinternal", version: "light" },
                { name: "Auto slogan", value: "autoSlogan", version: "light" },
                { name: "Slogan", value: "sloganInternal", version: "light" },
                { name: "Auto call to action", value: "autoCallToAction", version: "light" },
                { name: "Call to action", value: "callToActionInternal", version: "light" },
                { name: "External website", value: "externalWebsite", version: "light" }
                //LIGHT END
                //NEW END
            ],
            filter: { id: 1, name: "New", value: "new" },
            sort: 'externalId',
            order: 'ascending',
            limit: { id: 1, name: "5", value: 5 },
            edit: false,
            status: { id: 1, name: "New", value: "new" },
            type: { id: 1, name: "Both", value: "both" },
            minPrice: 0,
            maxPrice: 0,
            listing_options: [
                { id: 1, name: "New", value: "new" },
                { id: 9, name: "Ready", value: "ready" },
                //{ id: 2, name: "Live", value: "live" },
                { id: 3, name: "Updated", value: "updated" },
                { id: 4, name: "Removed", value: "removed" },
                { id: 5, name: "Pending", value: "standard" },
                { id: 6, name: "Not supported", value: "not supported" },
                { id: 8, name: "Archived", value: "archived" },
                //{ id: 7, name: "All listings", value: "all" },
            ],
            listing_stats: {},
            version: {},
            sequence_loading: false
        }
    };

    componentWillMount() {
        this.functions.getUser();
    }

    componentDidMount() {
        pusherService = new Pusher('a4ee0d05afe391530eb1', {
            cluster: 'ap1'
        });
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });

            let version = versionRegister.get();
            await this.promisedSetState({ version: version });

            if (this.state.user.level && this.state.user.level.id == 1) {
                this.functions.getEnterprise();
            }

            //GET STATUS
            let param_string = window.location.search ? window.location.search.replace("?", "") : "";
            let params_list = typeof param_string === "string" ? param_string.split("&") : [];
            if (Array.isArray(params_list) && params_list.length > 0) {
                for (let i = 0; i < params_list.length; i++) {
                    let key_value = params_list[i].split("=");
                    if (key_value.length > 1 && key_value[0] == "status") {
                        let status = null;
                        this.state.listing_options.map((item) => {
                            if (item.id == key_value[1]) {
                                status = item;
                            }
                        });
                        if (status) {
                            await this.promisedSetState({ status: status });
                        }
                    }
                }
            }

            this.promisedSetState({
                loading: false
            })

        },
        getListings: async () => {
            try {
                await this.promisedSetState({
                    loading_xml: true
                });
                let response = await this.calls.getListings();
                response.data = response.data.map((item) => {
                    item.listingProgress = this.renders.progress(item);
                    return item;
                });
                await this.promisedSetState({
                    listings: response.data,
                    listing_stats: response.meta ? response.meta : {},
                    loading_xml: false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.setState({
                    loading_xml: false
                })
            }
        },
        getListingStats: async () => {
            try {
                await this.promisedSetState({
                    loading_stats: true,
                });
                let response = await this.calls.getListingStats();
                await this.promisedSetState({
                    listing_stats: response.data ? response.data : {},
                    loading_stats: false,
                })
            } catch (error) {
                await this.promisedSetState({
                    loading_stats: false
                });
            }
        },
        getEnterprise: async () => {
            try {
                let response = await this.calls.getEnterprise();
                this.setState({
                    enterprises: response.data.filter((item) => { return item.version.id == this.state.version.id }),
                    //loading: false
                })
            } catch (error) {
                this.setState({
                    //loading: false
                })
            }
        },
        removeListing: async (item) => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                try {
                    item.loading = true;
                    await self.promisedSetState({ listings: self.state.listings });
                    let response = await self.calls.removeListing(item.id);
                    self.state.listings = self.state.listings.filter((listing) => {
                        return listing.id !== response.data.id;
                    });
                    await self.promisedSetState({ listings: self.state.listings });
                    resolve();
                } catch (error) {
                    item.loading = false;
                    await self.promisedSetState({
                        error: error.body ? error.body.message : "Something went wrong",
                        listings: self.state.listings,
                        running: error.body && error.body.message.indexOf("running") ? true : false
                    });
                    reject();
                }
            });
        },
        removeMedia: async (item) => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                try {
                    item.loading = true;
                    await self.promisedSetState({ listings: self.state.listings });
                    let response = await self.calls.removeMedia(item);
                    item.imagesInternal = response.data.imagesInternal;
                    item.blurImage = response.data.blurImage;
                    item.landingpageImage = response.data.landingpageImage;
                    item.feedImages = response.data.feedImages;
                    item.loading = false;
                    await self.promisedSetState({ listings: self.state.listings });
                } catch (error) {
                    item.loading = false;
                    await self.promisedSetState({
                        error: error.body ? error.body.message : "Something went wrong",
                        listings: self.state.listings
                    });
                }
                resolve();
            });
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table');
                let isDown = false;
                let startX;
                let scrollLeft;

                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1; //scroll-fast
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        },
        removeListings: async () => {
            await this.promisedSetState({ delete_loading: true, remove: false, remove_code: "" });
            let selected_listings = this.state.listings.filter((item) => { return item.selected });
            for (let i = 0; i < selected_listings.length; i++) {
                try {
                    await this.functions.removeListing(selected_listings[i]);
                } catch (error) {
                    await this.promisedSetState({ delete_loading: false });
                    break;
                }
            }
            await this.promisedSetState({
                delete_loading: false,
                select_all: false
            });
            this.functions.getListingStats();
        },
        removeMediaListings: async () => {
            await this.promisedSetState({ delete_loading: true, remove: false, remove_code: "" });
            let selected_listings = this.state.listings.filter((item) => { return item.selected });
            for (let i = 0; i < selected_listings.length; i++) {
                try {
                    await this.functions.removeMedia(selected_listings[i]);
                } catch (error) {
                    await this.promisedSetState({ delete_loading: false });
                    break;
                }
            }
            await this.promisedSetState({
                delete_loading: false,
                select_all: false
            });
        },
        addListings: async () => {
            let self = this;
            await self.promisedSetState({ sequence_loading: true });
            var channel = pusherService.subscribe(self.state.user.id);
            channel.bind('listings', function (response) {
                if (response.last || response.error) {
                    self.promisedSetState({ sequence_loading: false });
                    pusherService.unsubscribe(self.state.user.id);
                    if (response.error) {
                        self.promisedSetState({
                            error: response.message,
                            listings: self.state.listings.map((item) => { item.loading = false; return item })
                        });
                    }
                } else {
                    self.state.listings = self.state.listings.map((listing) => {
                        if (listing.id === response.id) {
                            if (response.step === 'done') {
                                self.functions.getListingStats();
                                listing.loading = false;
                            } else if (response.step === 'init') {
                                listing.loading = true;
                            } else if (response.images) {
                                listing.total_images = response.total;
                                listing.finished_images = response.finished;
                            } else {
                                listing = { ...listing, ...response.listing };
                                listing.listingProgress = self.renders.progress(listing);
                            }
                        }
                        return listing;
                    });
                    self.promisedSetState({
                        listings: self.state.listings,
                    });
                }
            });

            await self.calls.addListingsBulk({
                ids: self.state.listings.filter((item) => { return item.selected }).map((item) => { return item.id }),
                channel: self.state.user.id
            });

        },
        updateListings: async () => {
            let self = this;
            await self.promisedSetState({ sequence_loading: true });
            var channel = pusherService.subscribe(self.state.user.id);
            channel.bind('update-listings', function (response) {
                if (response.last || response.error) {
                    self.promisedSetState({ sequence_loading: false });
                    pusherService.unsubscribe(self.state.user.id);
                    if (response.error) {
                        self.promisedSetState({
                            error: response.message,
                            listings: self.state.listings.map((item) => { item.loading = false; return item })
                        });
                    }
                } else {
                    self.state.listings = self.state.listings.map((listing) => {
                        if (listing.id === response.id) {
                            if (response.step === 'done') {
                                self.functions.getListingStats();
                                listing.price = response.price;
                                listing.broker = response.broker;
                                listing.message = response.message;
                                listing.listingStatus = response.listingStatus;
                                listing.internalUpdatedData = response.internalUpdatedData;
                                listing.loading = false;
                            } else if (response.step === 'init') {
                                listing.loading = true;
                            }
                        }
                        return listing;
                    });
                    self.promisedSetState({
                        listings: self.state.listings,
                    });
                }
            });
            await self.calls.updateListingsBulk({
                ids: self.state.listings.filter((item) => { return item.selected }).map((item) => { return item.id })
            });
        },
        updateToReady: async (item, status) => {
            try {
                item.loading = true;
                await this.promisedSetState({ listings: this.state.listings });
                await this.calls.updateStatus({ id: item.id, listingStatus: status });
                this.state.listings = this.state.listings.map((listing) => {
                    if (listing.externalId == item.externalId) {
                        listing.loading = false;
                        listing.listingStatus = status;
                        item = listing;
                    }
                    return listing;
                });
                item.loading = false;
                await this.promisedSetState({ listings: this.state.listings });
                this.functions.getListingStats();
            } catch (error) { }
        }
    };

    calls = {
        getEnterprise: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/enterprise/list?version=" + this.state.version.id;
            return apiRegister.call(options, url);
        },
        getListingStats: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/listings/stats?live=" + (this.state.version.id === 1) + (this.state.status.value !== "all" ? ("&status=" + this.state.status.value) : "") + (this.state.enterprise.id !== 0 ? ("&enterprise=" + this.state.enterprise.id) : "") + "&page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&sortBy=" + this.state.sort + "&type=" + this.state.type.value + (this.state.minPrice > 0 ? ("&minPrice=" + this.state.minPrice) : "") + (this.state.maxPrice > 0 ? ("&maxPrice=" + this.state.maxPrice) : "");
            return apiRegister.call(options, url);
        },
        getListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/listings?live=" + (this.state.version.id === 1) + (this.state.status.value !== "all" ? ("&status=" + this.state.status.value) : "") + (this.state.enterprise.id !== 0 ? ("&enterprise=" + this.state.enterprise.id) : "") + "&page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&sortBy=" + this.state.sort + "&type=" + this.state.type.value + (this.state.minPrice > 0 ? ("&minPrice=" + this.state.minPrice) : "") + (this.state.maxPrice > 0 ? ("&maxPrice=" + this.state.maxPrice) : "");
            return apiRegister.call(options, url);
        },
        removeListing: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/listings/remove-listing/" + id;
            return apiRegister.call(options, url);
        },
        addListingsBulk: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/listings/add-listing-bulk";
            return apiRegister.call(options, url);
        },
        updateListingsBulk: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/listings/update-listing-bulk?enterprise=" + this.state.enterprise.id + "&channel=" + this.state.user.id + ((this.state.status.value === 'ready' || this.state.status.value === 'live') ? "&permit=true" : "");
            return apiRegister.call(options, url);
        },
        updateStatus: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', { listingStatus: data.listingStatus });
            let url = apiRegister.url.api + "/listings/update-status/" + data.id;
            return apiRegister.call(options, url);
        },
        removeMedia: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/media/removeMedia";
            return apiRegister.call(options, url);
        }
    };

    renders = {
        progress: (item) => {
            let progress = "0%";
            if (item.listingStatus == "new") {
                progress = "0%";
            } else if (item.listingStatus == "standard" && !item.blurImage) {
                progress = "37%";
            } else if (item.listingStatus == "standard" && item.blurImage && !item.adboosterId) {
                progress = "62%";
            } else if (item.readyForFeed) {
                progress = "100%";
            }
            return progress;
        },
        listings: () => {
            let listings = this.state.listings;
            if (typeof this.state.search == "string" && this.state.search !== "") {
                return listings.filter((str) => {
                    return typeof str.externalId == "string" && str.externalId.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0;
                })
            } else {
                return listings;
            }
        },
        sort: (listings) => {
            if (this.state.order == 'descending') {
                return listings.sort((a, b) => (a[this.state.sort] > b[this.state.sort]) ? 1 : ((b[this.state.sort] > a[this.state.sort]) ? -1 : 0))
            } else {
                return listings.sort((a, b) => (a[this.state.sort] > b[this.state.sort]) ? -1 : ((b[this.state.sort] > a[this.state.sort]) ? 1 : 0))
            }
        },
        getEnterpriseType: (listing) => {
            if (listing.enterprise.adboosterType && listing.enterprise.adboosterType.value === 'boostabostad') {
                if (listing.enterprise.version && listing.enterprise.version.id === 1) {
                    return "https://lp.boostabostad.se/" + listing.adboosterId;
                } else {
                    return "https://lp.devboostabostad.se/" + listing.adboosterId;
                }
            } else {
                if (listing.enterprise.version && listing.enterprise.version.id === 1) {
                    return "https://lp.propertybooster.io/" + listing.adboosterId;
                } else {
                    return "https://lp.devpropertybooster.com/" + listing.adboosterId;
                }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                {/*ERROR WARNING*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={this.state.running ? "Warning" : "Error"}
                    description={typeof this.state.message == "string" ? this.state.message : "Something went wrong"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete Listings"}
                    description={"Are you sure you want to delete these listings? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove && this.state.remove_code != "123"}
                    showInput={true}
                    copyInput={"123"}
                    inputTitle={'Type "123" to remove'}
                    inputError={(this.state.remove && this.state.remove_code && this.state.remove_code !== "" && this.state.remove_code != "123") ? "Code not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null, remove_code: "" })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_code == "123") {
                            this.setState({ remove_code: "" });
                            this.functions.removeListings();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_code: value })
                    }}
                />

                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border border-green-500 overflow-hidden justify-center flex items-center bg-green-100">
                                        <div>
                                            <CollectionIcon className="h-8 w-8 text-green-500" aria-hidden="true" />
                                            {
                                                false &&
                                                this.state.user.image &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.image}
                                                    alt=""
                                                />
                                            }
                                            {
                                                false &&
                                                !this.state.user.image &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Listings</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*AGENT HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 2 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {

                                                this.state.user.enterprise && this.state.user.enterprise.logo &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.enterprise.logo}
                                                    alt=""
                                                />
                                            }
                                            {

                                                (!this.state.user.enterprise || (this.state.user.enterprise && !this.state.user.enterprise.logo)) &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Listings</p>
                                        <p className="text-sm font-medium text-gray-600">{this.state.user.enterprise.companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">

                        {/*BASIC ENTERPRISE INFORMATION*/}
                        {
                            !this.state.loading &&
                            <div className="mb-8">
                                <div className="shadow rounded-md bg-white">
                                    <div className="flex items-center p-6 border-b">
                                        <div className="flex flex-1 flex-col">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Manage listings</h3>
                                            <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                        </div>
                                    </div>
                                    <div className="py-6 px-4 space-y-6 sm:p-6">

                                        {
                                            this.state.user && this.state.user.level && this.state.user.level.id == 1 &&
                                            <div className="grid grid-cols-6 gap-6">

                                                <div className={"col-span-6 sm:col-span-2"}>
                                                    <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                        Enterprise
                                                    </label>
                                                    <div className="mt-1">
                                                        <SingleSelection
                                                            select={async (option) => {
                                                                await this.promisedSetState({
                                                                    page: 1,
                                                                    enterprise: option
                                                                });
                                                                this.functions.getListings();
                                                            }}
                                                            disabled={this.state.loading_xml || this.state.loading}
                                                            selected={this.state.enterprise}
                                                            options={Array.isArray(this.state.enterprises) ? this.state.enterprises : []}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={"col-span-6 sm:col-span-1"}>
                                                    <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                        Status
                                                    </label>
                                                    <div className="mt-1">
                                                        <SingleSelection
                                                            select={async (option) => {
                                                                await this.promisedSetState({
                                                                    page: 1,
                                                                    status: option
                                                                });
                                                                if (this.state.enterprise.id !== 0) {
                                                                    this.functions.getListings();
                                                                }
                                                            }}
                                                            disabled={this.state.loading_xml || this.state.loading}
                                                            selected={this.state.status}
                                                            options={this.state.listing_options}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-span-6 sm:col-span-1">
                                                    <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                        Type
                                                    </label>
                                                    <div className="mt-1">
                                                        <SingleSelection
                                                            select={async (option) => {
                                                                await this.promisedSetState({
                                                                    page: 1,
                                                                    type: option
                                                                });
                                                                if (this.state.enterprise.id !== 0) {
                                                                    this.functions.getListings();
                                                                }
                                                            }}
                                                            disabled={this.state.loading_xml || this.state.loading}
                                                            selected={this.state.type}
                                                            options={[
                                                                { id: 1, name: "Both", value: "both" },
                                                                { id: 2, name: "Rent only", value: "rent" },
                                                                { id: 3, name: "Sale only", value: "sale" }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-span-6 sm:col-span-1">
                                                    <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                        Min price
                                                    </label>
                                                    <input
                                                        disabled={this.state.loading_xml || this.state.loading}
                                                        value={this.state.minPrice}
                                                        onChange={(event) => {
                                                            this.setState({
                                                                minPrice: event.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        name="contact-email"
                                                        id="contact-email"
                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    />
                                                </div>

                                                <div className="col-span-6 sm:col-span-1">
                                                    <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                        Max price
                                                    </label>
                                                    <input
                                                        disabled={this.state.loading_xml || this.state.loading}
                                                        value={this.state.maxPrice}
                                                        onChange={(event) => {
                                                            this.setState({
                                                                maxPrice: event.target.value
                                                            })
                                                        }}
                                                        type="text"
                                                        name="contact-email"
                                                        id="contact-email"
                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                                {
                                                    false &&
                                                    this.renders.listings().length > 0 &&
                                                    <div className="col-span-6 sm:col-span-6">
                                                        <div>
                                                            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                                Search reference number
                                                            </label>
                                                            <input
                                                                value={this.state.search}
                                                                onChange={(event) => {
                                                                    this.setState({
                                                                        search: event.target.value
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="contact-email"
                                                                id="contact-email"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.loading_xml &&
                                        <div className="bg-white h-48 flex border-t justify-center items-center rounded-b">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    {
                                        !this.state.loading_xml &&
                                        !this.state.loading &&
                                        this.state.enterprise.id !== 0 &&
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <div className="sm:p-6 sm:pt-0 bg-white">
                                                    <dl className="border shadow-sm relative rounded-lg grid grid-cols-1 bg-white overflow-hidden divide-y divide-gray-200 md:grid-cols-7 md:divide-y-0 md:divide-x">
                                                        {[
                                                            { id: "new", value: "new", status_id: 1, borderColor: "border-green-500", bgColor: "bg-green-100", name: "New", stat: 0 },
                                                            { id: "removed", value: "removed", status_id: 4, name: "Removed", stat: 0 },
                                                            { id: "updated", value: "updated", status_id: 3, name: "Updated", stat: 0 },
                                                            //{ id: "live", value: "live", status_id: 2, name: "Live", stat: 0 },
                                                            { id: "pending", value: "pending", status_id: 5, name: "Pending", stat: 0 },
                                                            { id: "not_supported", value: "not_supported", status_id: 6, name: "Not supported", stat: 0 },
                                                            { id: "archived", value: "archived", status_id: 8, name: "Archived", stat: 0 },
                                                            { id: "ready", value: "ready", status_id: 9, name: "Ready", stat: 0 },
                                                        ].map((item) => (
                                                            <div onClick={() => {
                                                                let status = {};
                                                                this.state.listing_options.map((option) => {
                                                                    if (option.id == item.status_id) {
                                                                        status = option;
                                                                    }
                                                                });
                                                                this.setState({
                                                                    page: 1,
                                                                    status: status,
                                                                    loading_xml: true,
                                                                    listings: []
                                                                }, () => {
                                                                    this.functions.getListings();
                                                                })
                                                            }} key={item.name} className="px-4 py-5 sm:p-6 cursor-pointer">
                                                                <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                                                                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                                                        {this.state.listing_stats[item.id] ? this.state.listing_stats[item.id] : 0}
                                                                    </div>
                                                                </dd>
                                                            </div>
                                                        ))}
                                                        {
                                                            this.state.loading_stats &&
                                                            <div className="bg-white justify-center flex items-center absolute left-0 right-0 bottom-0 top-0">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.listings.length < 1 &&
                                        !this.state.loading_xml &&
                                        this.state.enterprise.id !== 0 &&
                                        <div className="bg-white h-48 flex border-t justify-center items-center rounded-b">
                                            <div className="text-center flex justity-center items-center flex-col">
                                                <ExclamationCircleIcon className="h-10 w-10 text-gray-300" />
                                                <h3 className="mt-2 text-sm font-medium text-gray-900">No listings</h3>
                                                <p className="mt-1 text-sm text-gray-500">Choose another status or enterprise</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.listings.length > 0 &&
                                        !this.state.loading_xml &&
                                        this.state.enterprise.id !== 0 &&
                                        <div className="grid grid-cols-6 overflow-hidden">
                                            {
                                                this.state.listings.length > 0 &&
                                                <div className="flex col-span-12 flex-col">
                                                    <div id={"table"} className="overflow-x-scroll sm:-mx-6 lg:-mx-8">
                                                        {
                                                            <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                <div className="">
                                                                    <table className="min-w-full divide-y divide-gray-200">
                                                                        <thead className="bg-gray-100">
                                                                            <tr>
                                                                                <th scope="col" className="relative px-6 py-3 border-r">
                                                                                    <div className="flex flex-row">
                                                                                        <div onClick={() => {
                                                                                            if (!this.state.sequence_loading) {
                                                                                                this.state.listings.map((item) => {
                                                                                                    item.selected = !this.state.select_all;
                                                                                                });
                                                                                                this.setState({
                                                                                                    listings: this.state.listings,
                                                                                                    select_all: !this.state.select_all
                                                                                                })
                                                                                            }
                                                                                        }} className={(this.state.select_all ? "border-indigo-500 bg-indigo-100" : "") + " h-8 w-8 flex justify-center items-center cursor-pointer rounded border-2 hover:border-indigo-500"}>
                                                                                            {
                                                                                                this.state.select_all &&
                                                                                                <CheckIcon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </th>
                                                                                <th scope="col" className="relative px-6 py-3 border-r">
                                                                                    <div className="flex flex-row">
                                                                                        {
                                                                                            (this.state.status.value === 'updated' || this.state.status.value === 'ready' || this.state.status.value === 'live') &&
                                                                                            <div onClick={() => {
                                                                                                if (!this.state.delete_loading && !this.state.sequence_loading && this.state.listings.filter((item) => { return item.selected }).length > 0) {
                                                                                                    this.functions.updateListings();
                                                                                                }
                                                                                            }} className={(!this.state.delete_loading && this.state.listings.filter((item) => { return item.selected && item.listingStatus !== "not supported" }).length > 0 ? "cursor-pointer" : "cursor-not-allowed opacity-50") + " mr-2 inline-flex relative whitespace-no-wrap justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                                Update
                                                                                                {
                                                                                                    this.state.sequence_loading &&
                                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            this.state.status.value === 'new' &&
                                                                                            <div onClick={() => {
                                                                                                if (!this.state.delete_loading && !this.state.sequence_loading && this.state.listings.filter((item) => { return item.selected }).length > 0) {
                                                                                                    this.functions.addListings();
                                                                                                }
                                                                                            }} className={(!this.state.delete_loading && this.state.listings.filter((item) => { return item.selected && item.listingStatus !== "not supported" }).length > 0 ? "cursor-pointer" : "cursor-not-allowed opacity-50") + " mr-2 inline-flex relative whitespace-no-wrap justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                                Upload
                                                                                                {
                                                                                                    this.state.sequence_loading &&
                                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            <div onClick={() => {
                                                                                                if (!this.state.delete_loading && !this.state.sequence_loading && this.state.listings.filter((item) => { return item.selected }).length > 0) {
                                                                                                    this.setState({
                                                                                                        remove: true
                                                                                                    })
                                                                                                }
                                                                                            }} className={(!this.state.sequence_loading && this.state.listings.filter((item) => { return item.selected }).length > 0 ? "cursor-pointer" : "cursor-not-allowed opacity-50") + " inline-flex relative whitespace-no-wrap justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                                Remove
                                                                                                {
                                                                                                    this.state.delete_loading &&
                                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            this.state.status.value === 'archived' &&
                                                                                            <div onClick={() => {
                                                                                                if (!this.state.delete_loading && !this.state.sequence_loading && this.state.listings.filter((item) => { return item.selected }).length > 0) {
                                                                                                    this.functions.removeMediaListings();
                                                                                                }
                                                                                            }} className={(!this.state.sequence_loading && this.state.listings.filter((item) => { return item.selected }).length > 0 ? "cursor-pointer" : "cursor-not-allowed opacity-50") + " inline-flex ml-2 relative whitespace-no-wrap justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                                Remove media
                                                                                                {
                                                                                                    this.state.delete_loading &&
                                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>

                                                                                </th>
                                                                                {
                                                                                    this.state.columns.filter((item) => {
                                                                                        if (this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") {
                                                                                            if (this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) {
                                                                                                return item.category !== "unit";
                                                                                            } else {
                                                                                                return item.category !== "project";
                                                                                            }
                                                                                        } else {
                                                                                            return item.version !== "light";
                                                                                        }
                                                                                    }).map((column) => {
                                                                                        if (column.value == "images") {
                                                                                            return (
                                                                                                <th
                                                                                                    scope="col"
                                                                                                    style={{ minWidth: "180px" }}
                                                                                                    className="px-6 py-3 items-center border-r text-left text-xs font-medium whitespace-no-wrap text-gray-500 uppercase tracking-wider"
                                                                                                >
                                                                                                    <div className="flex flex-row">
                                                                                                        <div>{column.name}</div>
                                                                                                    </div>
                                                                                                </th>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <th
                                                                                                    onClick={() => {
                                                                                                        if (column.value === "externalId" || column.value === "price") {
                                                                                                            this.setState({
                                                                                                                sort: column.value,
                                                                                                                page: 1,
                                                                                                                order: this.state.order == 'ascending' ? 'descending' : 'ascending'
                                                                                                            }, () => {
                                                                                                                this.functions.getListings();
                                                                                                            })
                                                                                                        }
                                                                                                    }}
                                                                                                    scope="col"
                                                                                                    className="px-6 py-3 border-r cursor-pointer hover:text-gray-600 text-left text-xs font-medium whitespace-no-wrap text-gray-500 uppercase tracking-wider"
                                                                                                >
                                                                                                    <div className={(this.state.sort == column.value ? "text-gray-900" : "") + " flex flex-row"}>
                                                                                                        <div className="mr-4">{column.name}</div>
                                                                                                        {

                                                                                                            this.state.order == 'ascending' &&
                                                                                                            <SortAscendingIcon className={(this.state.sort == column.value ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                                                        }
                                                                                                        {

                                                                                                            this.state.order == 'descending' &&
                                                                                                            <SortDescendingIcon className={(this.state.sort == column.value ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </th>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="bg-white divide-y divide-gray-200 whitespace-no-wrap">
                                                                            {this.renders.sort(this.renders.listings()).map((listing) => (
                                                                                <tr key={listing.externalId}>
                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-right text-sm font-medium">
                                                                                        {
                                                                                            (!this.state.sequence_loading || (this.state.sequence_loading && listing.selected)) &&
                                                                                            <div onClick={() => {
                                                                                                if (!this.state.sequence_loading) {
                                                                                                    listing.selected = !listing.selected;
                                                                                                    this.setState({
                                                                                                        listings: this.state.listings
                                                                                                    })
                                                                                                }
                                                                                            }} className={(listing.selected ? "border-indigo-500 bg-indigo-100" : "") + " hover:border-indigo-500 cursor-pointer border-2 h-8 w-8 flex justify-center items-center rounded"}>
                                                                                                {
                                                                                                    listing.selected &&
                                                                                                    <CheckIcon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </td>
                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-right text-sm font-medium">
                                                                                        <div className="text-indigo-600 hover:text-indigo-900 cursor-pointer text-left">
                                                                                            {
                                                                                                //!(listing.broker && listing.broker.updated && (listing.broker.updated.id === 1 || listing.broker.updated.id === 2)) &&
                                                                                                this.state.user && this.state.user.level && this.state.user.level.id == 1 &&
                                                                                                <Menu as="div" className="relative inline-block text-left">
                                                                                                    <div>
                                                                                                        <Menu.Button disabled={listing.listingStatus == "not supported" || listing.selected || this.state.sequence_loading} className={((listing.selected || this.state.sequence_loading || listing.loading || listing.listingStatus == "not supported") ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                                            Options
                                                                                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                                            {
                                                                                                                listing.loading &&
                                                                                                                <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                                                        class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </Menu.Button>
                                                                                                    </div>
                                                                                                    <Transition
                                                                                                        as={Fragment}
                                                                                                        enter="transition ease-out duration-100"
                                                                                                        enterFrom="transform opacity-0 scale-95"
                                                                                                        enterTo="transform opacity-100 scale-100"
                                                                                                        leave="transition ease-in duration-75"
                                                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                                                        leaveTo="transform opacity-0 scale-95"
                                                                                                    >
                                                                                                        <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                                            <div className="py-1">
                                                                                                                {
                                                                                                                    <Menu.Item>
                                                                                                                        {({ active }) => (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    if (!this.state.sequence_loading && !listing.readyForFeed) {
                                                                                                                                        listing.selected = true;
                                                                                                                                        this.setState({
                                                                                                                                            listings: this.state.listings
                                                                                                                                        }, () => {
                                                                                                                                            this.functions.addListings();
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className={classNames(
                                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                    'px-4 py-2 text-sm relative flex flex-row',
                                                                                                                                    listing.readyForFeed ? "opacity-50 cursor-not-allowed" : ""
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                Upload
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Menu.Item>
                                                                                                                }
                                                                                                                {
                                                                                                                    listing.readyForFeed &&
                                                                                                                    (listing.listingStatus === 'updated' || listing.listingStatus === 'removed') &&
                                                                                                                    <Menu.Item>
                                                                                                                        {({ active }) => (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    this.functions.updateToReady(listing, "ready");
                                                                                                                                }}
                                                                                                                                className={classNames(
                                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                    'px-4 py-2 text-sm relative flex flex-row',
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                Update to "Ready"
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Menu.Item>
                                                                                                                }
                                                                                                                {
                                                                                                                    listing.readyForFeed &&
                                                                                                                    listing.listingStatus === 'ready' &&
                                                                                                                    <Menu.Item>
                                                                                                                        {({ active }) => (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    this.functions.updateToReady(listing, "updated");
                                                                                                                                }}
                                                                                                                                className={classNames(
                                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                    'px-4 py-2 text-sm relative flex flex-row',
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                Update to "Updated"
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Menu.Item>
                                                                                                                }
                                                                                                                {
                                                                                                                    this.state.status.value === 'archived' &&
                                                                                                                    //listing.listingStatus === 'archived' &&
                                                                                                                    <Menu.Item>
                                                                                                                        {({ active }) => (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    this.functions.removeMedia(listing);
                                                                                                                                }}
                                                                                                                                className={classNames(
                                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                    'px-4 py-2 text-sm relative flex flex-row',
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                Remove media
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Menu.Item>
                                                                                                                }
                                                                                                                {
                                                                                                                    <Menu.Item>
                                                                                                                        {({ active }) => (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    if (!this.state.sequence_loading) {
                                                                                                                                        //listing.loading = true;
                                                                                                                                        listing.selected = true;
                                                                                                                                        this.setState({
                                                                                                                                            listings: this.state.listings
                                                                                                                                        }, () => {
                                                                                                                                            this.functions.removeListings();
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className={classNames(
                                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                    'block px-4 py-2 text-sm',
                                                                                                                                    //!(listing.listingStatus != "new") ? "opacity-50 cursor-not-allowed" : ""
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                Remove
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Menu.Item>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </Menu.Items>
                                                                                                    </Transition>
                                                                                                </Menu>
                                                                                            }
                                                                                            {
                                                                                                (listing.broker && listing.broker.updated && (listing.broker.updated.id === 1 || listing.broker.updated.id === 2)) &&
                                                                                                <button onClick={() => {
                                                                                                    this.props.history.push("/dashboard/agents?enterprise=" + listing.enterprise.id);
                                                                                                }} className={"cursor-pointer ml-2 inline-flex items-center relative justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                                    Agent updated
                                                                                                    <ArrowRightIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
                                                                                                </button>
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    {
                                                                                        this.state.columns.filter((item) => {
                                                                                            if (this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled") {
                                                                                                if (this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) {
                                                                                                    return item.category !== "unit";
                                                                                                } else {
                                                                                                    return item.category !== "project";
                                                                                                }
                                                                                            } else {
                                                                                                return item.version !== "light";
                                                                                            }
                                                                                        }).map((column) => {
                                                                                            if (column.value == "images") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap relative">
                                                                                                        <div className="flex -space-x-2 overflow-hidden">
                                                                                                            {
                                                                                                                Array.isArray(listing[column.value]) && listing[column.value].filter((item, index) => {
                                                                                                                    return index < 4
                                                                                                                }).map((item) => {
                                                                                                                    if (column.value == "images") {
                                                                                                                        return (
                                                                                                                            <img
                                                                                                                                className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                                                                                                                                src={item}
                                                                                                                            />
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return (
                                                                                                                            <img
                                                                                                                                className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                                                                                                                                src={item.image}
                                                                                                                            />
                                                                                                                        )
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                            {
                                                                                                                Array.isArray(listing[column.value]) && listing[column.value].length > 4 &&
                                                                                                                <div className="inline-block h-8 w-12 rounded-full ring-white">
                                                                                                                    <div className="h-full w-12 flex text-gray-500 text-xs font-medium justify-center items-center">+{listing[column.value].length - 4}</div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "blurImage") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap relative">
                                                                                                        <div className="flex -space-x-2 overflow-hidden">
                                                                                                            {
                                                                                                                listing.blurImage &&
                                                                                                                <img
                                                                                                                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                                                                                                                    src={listing.blurImage}
                                                                                                                />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "internalUpdatedData") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap relative">
                                                                                                        <div className="flex -space-x-2 overflow-hidden">
                                                                                                            {
                                                                                                                listing.listingStatus == "updated" && listing.internalUpdatedData &&
                                                                                                                <div className="text-xxs flex flex-col">
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.price &&
                                                                                                                        <div className="mb-1">{this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1 ? "From price: " : "Price: "} {listing.internalUpdatedData.price ? listing.internalUpdatedData.price : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.toPrice &&
                                                                                                                        <div className="mb-1">To price: {listing.internalUpdatedData.toPrice ? listing.internalUpdatedData.toPrice : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        /*** LIGHT START ***/
                                                                                                                        /*** PROJECT START ***/
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.toPrice === "" &&
                                                                                                                        <div className="mb-1">To price: removed</div>
                                                                                                                        /*** PROJECT END ***/
                                                                                                                        /*** LIGHT END ***/
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.permitId &&
                                                                                                                        <div className="mb-1">Permit ID: {listing.internalUpdatedData.permitId ? listing.internalUpdatedData.permitId : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed LIGHT PROJECT only)
                                                                                                                        listing.internalUpdatedData.permitId === "" &&
                                                                                                                        <div className="mb-1">Permit ID: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.permitQR &&
                                                                                                                        <div className="mb-1">Permit QR: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.permitURL &&
                                                                                                                        <div className="mb-1">Permit URL: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.brokerCustomReference &&
                                                                                                                        <div className="mb-1">Agent ID: {listing.internalUpdatedData.brokerCustomReference ? listing.internalUpdatedData.brokerCustomReference : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.internalCustomKey &&
                                                                                                                        <div className="mb-1">Internal custom key: {listing.internalUpdatedData.internalCustomKey ? listing.internalUpdatedData.internalCustomKey : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.broker &&
                                                                                                                        <div className="mb-1">Agent: new ({listing.internalUpdatedData.broker ? listing.internalUpdatedData.broker.email_match : "-"})</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.updatedBroker &&
                                                                                                                        <div className="mb-1">Agent: updated ({Object.keys(listing.internalUpdatedData.updatedBroker).join(", ")})</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.removedBroker &&
                                                                                                                        <div className="mb-1">Agent: removed ({listing.internalUpdatedData.removedBroker.email})</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.updatedEnterprise &&
                                                                                                                        <div className="mb-1">Enterprise: updated ({Object.keys(listing.internalUpdatedData.updatedEnterprise).join(", ")})</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.badgeNewListing && !listing.internalUpdatedData.badgeNewListing.show &&
                                                                                                                        <div className="mb-1">Newly listed badge: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.badgePriceChange && !listing.internalUpdatedData.badgePriceChange.show &&
                                                                                                                        <div className="mb-1">Price change badge: removed</div>
                                                                                                                    }
                                                                                                                    {/*** LIGHT START ***/}
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.region &&
                                                                                                                        <div className="mb-1">Region: {listing.internalUpdatedData.region ? listing.internalUpdatedData.region : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.city &&
                                                                                                                        <div className="mb-1">City: {listing.internalUpdatedData.city ? listing.internalUpdatedData.city : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.area &&
                                                                                                                        <div className="mb-1">Area: {listing.internalUpdatedData.area ? listing.internalUpdatedData.area : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.buildingName &&
                                                                                                                        <div className="mb-1">Building name: {listing.internalUpdatedData.buildingName ? listing.internalUpdatedData.buildingName : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.description &&
                                                                                                                        <div className="mb-1">Description: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.description === "" &&
                                                                                                                        <div className="mb-1">Description: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.latitude &&
                                                                                                                        <div className="mb-1">Latitude: {listing.internalUpdatedData.latitude ? listing.internalUpdatedData.latitude : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.longitude &&
                                                                                                                        <div className="mb-1">Longitude: {listing.internalUpdatedData.longitude ? listing.internalUpdatedData.longitude : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.amenities &&
                                                                                                                        <div className="mb-1">Amenities: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.images &&
                                                                                                                        <div className="mb-1">CRM images: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.imagesInternal &&
                                                                                                                        <div className="mb-1">Hosted images: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.hasOwnProperty('paymentPlan') &&
                                                                                                                        <div className="mb-1">Payment plan: {listing.internalUpdatedData.paymentPlan ? 'true' : 'false'}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.hasOwnProperty('exclusive') &&
                                                                                                                        <div className="mb-1">Exclusive: {listing.internalUpdatedData.exclusive ? 'true' : 'false'}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.hasOwnProperty('featured') &&
                                                                                                                        <div className="mb-1">Featured: {listing.internalUpdatedData.featured ? 'true' : 'false'}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.furnishing &&
                                                                                                                        <div className="mb-1">Furnishing: {listing.internalUpdatedData.furnishing ? listing.internalUpdatedData.furnishing : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.webTour &&
                                                                                                                        <div className="mb-1">Web tour: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.webTour === "" &&
                                                                                                                        <div className="mb-1">Web tour: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.threesixtyTour &&
                                                                                                                        <div className="mb-1">Threesixty tour: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.threesixtyTour === "" &&
                                                                                                                        <div className="mb-1">Threesixty tour: removed</div>
                                                                                                                    }
                                                                                                                    {/*** NEW START ***/}
                                                                                                                    {/*** PROJECT START ***/}
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.projectName &&
                                                                                                                        <div className="mb-1">Project name: {listing.internalUpdatedData.projectName ? listing.internalUpdatedData.projectName : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.projectImage &&
                                                                                                                        <div className="mb-1">Project logo: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.completionYear &&
                                                                                                                        <div className="mb-1">Completion year: {listing.internalUpdatedData.completionYear ? listing.internalUpdatedData.completionYear : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.completionYear === "" &&
                                                                                                                        <div className="mb-1">Completion year: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.completionQuarter &&
                                                                                                                        <div className="mb-1">Completion quarter: {listing.internalUpdatedData.completionQuarter ? listing.internalUpdatedData.completionQuarter : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.availabilityYear &&
                                                                                                                        <div className="mb-1">Availability year: {listing.internalUpdatedData.availabilityYear ? listing.internalUpdatedData.availabilityYear : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.availabilityYear === "" &&
                                                                                                                        <div className="mb-1">Availability year: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.availabilityQuarter &&
                                                                                                                        <div className="mb-1">Availability quarter: {listing.internalUpdatedData.availabilityQuarter ? listing.internalUpdatedData.availabilityQuarter : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.floorPlans &&
                                                                                                                        <div className="mb-1">Floor plans: updated ({listing.internalUpdatedData.floorPlans ? listing.internalUpdatedData.floorPlans.length + " new / " : "0 new / "}{(listing.floorPlans ? listing.floorPlans.length + " old" : "0 old")})</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.propertyTypes &&
                                                                                                                        <div className="mb-1">Property types: updated ({listing.internalUpdatedData.propertyTypes ? listing.internalUpdatedData.propertyTypes.length + " new / " : "0 new / "}{(listing.propertyTypes ? listing.propertyTypes.length + " old" : "0 old")})</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.roi &&
                                                                                                                        <div className="mb-1">ROI: {listing.internalUpdatedData.roi ? listing.internalUpdatedData.roi : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.paymentPlanUpFront &&
                                                                                                                        <div className="mb-1">Payment plan up front: {listing.internalUpdatedData.paymentPlanUpFront ? listing.internalUpdatedData.paymentPlanUpFront : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.paymentPlanInstallment &&
                                                                                                                        <div className="mb-1">Payment plan installment: {listing.internalUpdatedData.paymentPlanInstallment ? listing.internalUpdatedData.paymentPlanInstallment : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.paymentPlanDuring &&
                                                                                                                        <div className="mb-1">Payment plan during: {listing.internalUpdatedData.paymentPlanDuring ? listing.internalUpdatedData.paymentPlanDuring : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.paymentPlanHandover &&
                                                                                                                        <div className="mb-1">Payment plan handover: {listing.internalUpdatedData.paymentPlanHandover ? listing.internalUpdatedData.paymentPlanHandover : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.paymentPlanPostHandover &&
                                                                                                                        <div className="mb-1">Payment plan post handover: {listing.internalUpdatedData.paymentPlanPostHandover ? listing.internalUpdatedData.paymentPlanPostHandover : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.displayPriority &&
                                                                                                                        <div className="mb-1">Display priority: {listing.internalUpdatedData.displayPriority ? listing.internalUpdatedData.displayPriority : "-"}</div>
                                                                                                                    }
                                                                                                                    {/*** PROJECT END ***/}
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.title &&
                                                                                                                        <div className="mb-1">Title: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.developer &&
                                                                                                                        <div className="mb-1">Developer name: {listing.internalUpdatedData.developer ? listing.internalUpdatedData.developer : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.developer === "" &&
                                                                                                                        <div className="mb-1">Developer name: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.developerImage &&
                                                                                                                        <div className="mb-1">Developer logo: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.buildYear &&
                                                                                                                        <div className="mb-1">Build year: {listing.internalUpdatedData.buildYear ? listing.internalUpdatedData.buildYear : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.buildYear === "" &&
                                                                                                                        <div className="mb-1">Build year: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.availabilityDate &&
                                                                                                                        <div className="mb-1">Availability date: {listing.internalUpdatedData.availabilityDate ? listing.internalUpdatedData.availabilityDate : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.availabilityDate === "" &&
                                                                                                                        <div className="mb-1">Availability date: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.floorPlanImage &&
                                                                                                                        <div className="mb-1">Floor plan: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.floorPlanImage === "" &&
                                                                                                                        <div className="mb-1">Floor plan: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.brochureURL &&
                                                                                                                        <div className="mb-1">Brochure URL: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.brochureURL === "" &&
                                                                                                                        <div className="mb-1">Brochure URL: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.document1URL &&
                                                                                                                        <div className="mb-1">Document 1 URL: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.document1URL === "" &&
                                                                                                                        <div className="mb-1">Document 1 URL: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.document2URL &&
                                                                                                                        <div className="mb-1">Document 2 URL: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.document2URL === "" &&
                                                                                                                        <div className="mb-1">Document 2 URL: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.document3URL &&
                                                                                                                        <div className="mb-1">Document 3 URL: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.document3URL === "" &&
                                                                                                                        <div className="mb-1">Document 3 URL: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.document4URL &&
                                                                                                                        <div className="mb-1">Document 4 URL: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.document4URL === "" &&
                                                                                                                        <div className="mb-1">Document 4 URL: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.document5URL &&
                                                                                                                        <div className="mb-1">Document 5 URL: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.document5URL === "" &&
                                                                                                                        <div className="mb-1">Document 5 URL: removed</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.customCreativeURL &&
                                                                                                                        <div className="mb-1">Custom creative URL: updated</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.hasOwnProperty('creativeOverride') &&
                                                                                                                        <div className="mb-1">Creative override: {listing.internalUpdatedData.creativeOverride ? 'enabled' : 'disabled'}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.sloganInternal &&
                                                                                                                        <div className="mb-1">Slogan internal: {listing.internalUpdatedData.sloganInternal ? listing.internalUpdatedData.sloganInternal : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.hasOwnProperty('autoSlogan') &&
                                                                                                                        <div className="mb-1">Auto slogan: {listing.internalUpdatedData.autoSlogan ? 'enabled' : 'disabled'}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.callToActionInternal &&
                                                                                                                        <div className="mb-1">Call to action internal: {listing.internalUpdatedData.callToActionInternal ? listing.internalUpdatedData.callToActionInternal : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.hasOwnProperty('autoCallToAction') &&
                                                                                                                        <div className="mb-1">Auto call to action: {listing.internalUpdatedData.autoCallToAction ? 'enabled' : 'disabled'}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        listing.internalUpdatedData.externalWebsite &&
                                                                                                                        <div className="mb-1">External website: {listing.internalUpdatedData.externalWebsite ? listing.internalUpdatedData.externalWebsite : "-"}</div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        //(removal allowed)
                                                                                                                        listing.internalUpdatedData.externalWebsite === "" &&
                                                                                                                        <div className="mb-1">External website: removed}</div>
                                                                                                                    }
                                                                                                                    {/*** NEW END ***/}
                                                                                                                    {/*** LIGHT END ***/}
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "badges") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap relative">
                                                                                                        <div className="flex -space-x-2 overflow-hidden">
                                                                                                            <div className="text-xxs flex flex-col">
                                                                                                                {
                                                                                                                    listing.badgePriceChange && listing.badgePriceChange.show &&
                                                                                                                    <div className="mb-1">Price change: {listing.badgePriceChange.show ? "true" : 'false'}
                                                                                                                        {
                                                                                                                            false &&
                                                                                                                            listing.badgePriceChange.show &&
                                                                                                                            <span>, added: {listing.badgePriceChange.date && moment(listing.badgePriceChange.date).format("YYYY-MM-DD hh:mm a")}</span>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    listing.badgeNewListing && listing.badgeNewListing.show &&
                                                                                                                    <div className="mb-1">New listing: {listing.badgeNewListing.show ? "true" : 'false'}
                                                                                                                        {
                                                                                                                            false &&
                                                                                                                            listing.badgeNewListing.show &&
                                                                                                                            <span>, added: {listing.badgeNewListing.date && moment(listing.badgeNewListing.date).format("YYYY-MM-DD hh:mm a")}</span>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "listingStatus") {
                                                                                                return (
                                                                                                    <td
                                                                                                        className="px-6 py-4 border-r whitespace-nowrap"
                                                                                                    >
                                                                                                        {
                                                                                                            listing[column.value] == "not supported" &&
                                                                                                            <div className="inline-flex px-4 text-center justify-center border-red-500 border items-center py-1 rounded-md text-xs font-medium bg-red-100 text-red-500">
                                                                                                                Not Supported
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            listing[column.value] == "new" &&
                                                                                                            <div className="inline-flex w-20 text-center justify-center border-green-500 border items-center  py-1 rounded-md text-xs font-medium bg-green-100 text-green-500">
                                                                                                                New
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            listing[column.value] == "updated" &&
                                                                                                            <div className="inline-flex w-20 text-center justify-center  border-yellow-500 border items-center  py-1 rounded-md text-xs font-medium bg-yellow-100 text-yellow-500">
                                                                                                                Updated
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            listing[column.value] == "removed" &&
                                                                                                            <div className="inline-flex w-20 text-center justify-center border-red-500 border items-center py-1 rounded-md text-xs font-medium bg-red-100 text-red-500">
                                                                                                                Removed
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            listing[column.value] == "standard" && listing.listingProgress != "100%" &&
                                                                                                            <div className="inline-flex w-20 text-center justify-center border-gray-500 border items-center py-1 rounded-md text-xs font-medium bg-gray-100 text-gray-500">
                                                                                                                Pending
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            false && listing[column.value] == "live" &&
                                                                                                            <div className="inline-flex w-20 text-center justify-center border-purple-500 border items-center py-1 rounded-md text-xs font-medium bg-purple-100 text-purple-500">
                                                                                                                Live
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            listing[column.value] == "ready" &&
                                                                                                            <div className="inline-flex w-20 text-center justify-center border-purple-500 border items-center py-1 rounded-md text-xs font-medium bg-purple-100 text-purple-500">
                                                                                                                Ready
                                                                                                            </div>
                                                                                                        }
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "forSale" || column.value == "forRent") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">
                                                                                                        {
                                                                                                            listing[column.value] &&
                                                                                                            <div className="h-8 w-8 bg-green-100 border-green-500 rounded-full mr-2 border flex justify-center items-center">
                                                                                                                <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "location") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div className="flex flex-row items-center">
                                                                                                            <div className="h-8 w-8 bg-purple-100 border-purple-500 rounded-full mr-2 border flex justify-center items-center">
                                                                                                                <LocationMarkerIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                                                                                                            </div>
                                                                                                            <div className="flex flex-col">
                                                                                                                <div>
                                                                                                                    {listing.latitude}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {listing.longitude}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "price") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div className="flex flex-row items-center">
                                                                                                            <div className="h-8 w-8 bg-purple-100 border-purple-500 rounded-full mr-2 border flex justify-center items-center">
                                                                                                                <CashIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                                                                                                            </div>
                                                                                                            <div className="flex flex-col">
                                                                                                                <div>
                                                                                                                    {listing.price}<span className="ml-1">{listing.currency}</span>
                                                                                                                </div>
                                                                                                                <div className="capitalize">
                                                                                                                    {listing.forSale ? "" : (listing.rentalPeriod)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "toPrice" && this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) {
                                                                                                //*** LIGHT START
                                                                                                //*** PROJECT START
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div className="flex flex-row items-center">
                                                                                                            {
                                                                                                                listing.toPrice !== undefined && listing.toPrice !== null && listing.toPrice !== "" && listing.toPrice !== 0 &&
                                                                                                                <div className="flex flex-col">
                                                                                                                    <div>
                                                                                                                        {listing.toPrice}<span className="ml-1">{listing.currency}</span>
                                                                                                                    </div>
                                                                                                                    <div className="capitalize">
                                                                                                                        {listing.forSale ? "" : (listing.rentalPeriod)}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                                //*** PROJECT END
                                                                                                //*** LIGHT END
                                                                                            } else if (column.value == "amenities") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{Array.isArray(listing[column.value]) ? listing[column.value].length : 0}</td>
                                                                                                )
                                                                                            } else if (column.value == "externalId") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">
                                                                                                        <a href={listing.externalWebsite} target="_blank" className="cursor-pointer">
                                                                                                            {listing.externalId ? listing.externalId.toUpperCase() : ""}
                                                                                                        </a>
                                                                                                    </td>
                                                                                                )
                                                                                            }
                                                                                            else if (column.value == "hostedImages") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">
                                                                                                        {Array.isArray(listing.imagesInternal) ? listing.imagesInternal.length : "-"}
                                                                                                    </td>
                                                                                                )
                                                                                            }
                                                                                            else if (column.value == "feedImages") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">
                                                                                                        {Array.isArray(listing.feedImages) ? listing.feedImages.length : "-"}
                                                                                                    </td>
                                                                                                )
                                                                                            }
                                                                                            else if (column.value == "adboosterId") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">
                                                                                                        <a href={this.renders.getEnterpriseType(listing)} target="_blank" className="cursor-pointer">
                                                                                                            {listing.adboosterId}
                                                                                                        </a>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "adboosterIndustry" && !(this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1)) {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div className="flex flex-row items-center">
                                                                                                            <div className="h-8 w-8 bg-purple-100 border-purple-500 rounded-full mr-2 border flex justify-center items-center">
                                                                                                                <HomeIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                                                                                                            </div>
                                                                                                            {
                                                                                                                listing.adboosterIndustry &&
                                                                                                                <div className="flex flex-col">
                                                                                                                    <div>
                                                                                                                        {listing.adboosterIndustry.name}
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        #{listing.adboosterIndustry.id}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "propertyType" && this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) {
                                                                                                //*** LIGHT START
                                                                                                //*** PROJECT START
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div className="flex flex-row items-center">
                                                                                                            <div className="h-8 w-8 bg-purple-100 border-purple-500 rounded-full mr-2 border flex justify-center items-center">
                                                                                                                <HomeIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                                                                                                            </div>
                                                                                                            {
                                                                                                                listing.propertyType &&
                                                                                                                <div className="flex flex-col capitalize">
                                                                                                                    <div>
                                                                                                                        {listing.propertyType}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "propertyTypes" && this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div className="flex flex-row items-center">
                                                                                                            {
                                                                                                                listing.propertyTypes &&
                                                                                                                <div className="flex flex-col">
                                                                                                                    <div>
                                                                                                                        {listing.propertyTypes.map(property => property.title).join(", ")}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                                //*** PROJECT END
                                                                                                //*** LIGHT END
                                                                                            } else if (column.value == "permitQR") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <div className="h-8 w-8 overflow-hidden mr-2 border flex justify-center items-center">
                                                                                                                    {
                                                                                                                        listing.permitQR &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.permitQR}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "permitQRinternal") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <div className="h-8 w-8 overflow-hidden mr-2 border flex justify-center items-center">
                                                                                                                    {
                                                                                                                        listing.permitQRinternal &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.permitQRinternal}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "broker") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <div className="h-8 w-8 overflow-hidden rounded-full mr-2 border flex justify-center items-center">
                                                                                                                    {
                                                                                                                        listing.broker && listing.broker.image &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8 rounded-full"
                                                                                                                            src={listing.broker.image}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {
                                                                                                                    listing.broker &&
                                                                                                                    <div className="flex flex-col">
                                                                                                                        <div>
                                                                                                                            {listing.broker.name}
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            {listing.broker.email}
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            {listing.broker.phone}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "enterprise") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <div className="h-8 w-8 overflow-hidden rounded-full mr-2 border flex justify-center items-center">
                                                                                                                    {
                                                                                                                        listing.enterprise && listing.enterprise.logo &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.enterprise.logo}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {
                                                                                                                    listing.enterprise &&
                                                                                                                    <div className="ml-4">
                                                                                                                        <div className="text-sm font-medium text-gray-900 capitalize">{listing.enterprise.name}</div>
                                                                                                                        <div className="text-sm text-gray-500">{listing.enterprise.companyName}</div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "createdAt" || column.value == "lastUpdated") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{moment(listing[column.value]).format('YYYY-MM-DD')}</td>
                                                                                                )
                                                                                            } else if (column.value == "feedsAdded") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">
                                                                                                        {Array.isArray(listing[column.value]) &&
                                                                                                            listing[column.value].map((item) => {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        {item}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "listingProgress") {
                                                                                                return (
                                                                                                    <td style={{ minWidth: "340px" }} className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">
                                                                                                        <div className="mt-2" style={{ minWidth: "340px" }}>
                                                                                                            <div>
                                                                                                                <div className="bg-gray-200 rounded-full overflow-hidden">
                                                                                                                    <div className="h-2 bg-indigo-600 rounded-full transition-all ease-in-out duration-200" style={{ width: listing.listingProgress }} />
                                                                                                                </div>
                                                                                                                <div className="hidden sm:grid grid-cols-4 text-xxs text-gray-600 mt-2">
                                                                                                                    <div className={((listing.listingProgress == "0%" || listing.listingProgress == "30%" || listing.listingProgress == "50%" || listing.listingProgress == "75%" || listing.listingProgress == "100%") ? "text-indigo-600" : "")}>Sync {listing.total_images && <span>({listing.finished_images}/{listing.total_images})</span>}</div>
                                                                                                                    {
                                                                                                                        /*
                                                                                                                        <div className={((listing.listingProgress == "30%" || listing.listingProgress == "50%" || listing.listingProgress == "75%" || listing.listingProgress == "100%") ? "text-indigo-600" : "") + " text-center"}>Canvas</div>
                                                                                                                        */
                                                                                                                    }
                                                                                                                    <div className={((listing.listingProgress == "50%" || listing.listingProgress == "75%" || listing.listingProgress == "100%") ? "text-indigo-600" : "") + " text-center"}>Blur</div>
                                                                                                                    <div className={((listing.listingProgress == "75%" || listing.listingProgress == "100%") ? "text-indigo-600" : "") + " text-center"}>{this.state.enterprise && this.state.enterprise.legacy && this.state.enterprise.legacy.value === "enabled" ? "Save" : "Landing"}</div>
                                                                                                                    <div className={(listing.listingProgress == "100%" ? "text-indigo-600" : "") + " text-right"}>Ready</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "developerImage") {
                                                                                                //*** LIGHT START
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <a href={listing.developerImage ? listing.developerImage : ""} target="_blank" className="h-8 w-8 overflow-hidden mr-2 border flex justify-center items-center cursor-pointer">
                                                                                                                    {
                                                                                                                        listing.developerImage &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.developerImage}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "developerImageInternal") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <a href={listing.developerImageInternal ? listing.developerImageInternal : ""} target="_blank" className="h-8 w-8 overflow-hidden mr-2 border flex justify-center items-center cursor-pointer">
                                                                                                                    {
                                                                                                                        listing.developerImageInternal &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.developerImageInternal}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "customCreativeURL") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <a href={listing.customCreativeURL ? listing.customCreativeURL : ""} target="_blank" className="h-8 w-8 overflow-hidden mr-2 border flex justify-center items-center cursor-pointer">
                                                                                                                    {
                                                                                                                        listing.customCreativeURL &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.customCreativeURL}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "customCreativeURLinternal") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <a href={listing.customCreativeURLinternal ? listing.customCreativeURLinternal : ""} target="_blank" className="h-8 w-8 overflow-hidden mr-2 border flex justify-center items-center cursor-pointer">
                                                                                                                    {
                                                                                                                        listing.customCreativeURLinternal &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.customCreativeURLinternal}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "creativeOverride") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing.creativeOverride ? 'enabled' : 'disabled'}</td>
                                                                                                )
                                                                                            } else if (column.value == "autoSlogan") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing.autoSlogan ? 'enabled' : 'disabled'}</td>
                                                                                                )
                                                                                            } else if (column.value == "sloganInternal") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing.sloganInternal ? listing.sloganInternal : ''}</td>
                                                                                                )
                                                                                            } else if (column.value == "autoCallToAction") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing.autoCallToAction ? 'enabled' : 'disabled'}</td>
                                                                                                )
                                                                                            } else if (column.value == "callToActionInternal") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing.callToActionInternal ? listing.callToActionInternal : ''}</td>
                                                                                                )
                                                                                            } else if (column.value == "externalWebsite") {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing.externalWebsite ? listing.externalWebsite : ''}</td>
                                                                                                )
                                                                                            } else if (column.value == "projectName" && this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) {
                                                                                                //*** PROJECT START
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing.projectName ? listing.projectName : ''}</td>
                                                                                                )
                                                                                            } else if (column.value == "projectImage" && this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <a href={listing.projectImage ? listing.projectImage : ""} target="_blank" className="h-8 w-8 overflow-hidden mr-2 border flex justify-center items-center cursor-pointer">
                                                                                                                    {
                                                                                                                        listing.projectImage &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.projectImage}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            } else if (column.value == "projectImageInternal" && this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1) {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-xs text-gray-500">
                                                                                                        <div>
                                                                                                            <div className="flex flex-row items-center">
                                                                                                                <a href={listing.projectImageInternal ? listing.projectImageInternal : ""} target="_blank" className="h-8 w-8 overflow-hidden mr-2 border flex justify-center items-center cursor-pointer">
                                                                                                                    {
                                                                                                                        listing.projectImageInternal &&
                                                                                                                        <img
                                                                                                                            className="inline-block min-h-8 min-w-8"
                                                                                                                            src={listing.projectImageInternal}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                                //*** PROJECT END
                                                                                                //*** LIGHT END
                                                                                            } else if (column.value == "buildingName" && !(this.state.enterprise.parserType && this.state.enterprise.parserType.value.indexOf("project") !== -1)) {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing.buildingName ? listing.buildingName : ''}</td>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <td className="px-6 py-4 border-r whitespace-nowrap text-sm text-gray-500">{listing[column.value]}</td>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        !this.state.loading_xml &&
                                        !this.state.loading &&
                                        this.state.enterprise.id !== 0 &&
                                        this.renders.listings().length > 0 &&
                                        <div className="rounded-b-md bg-gray-50">
                                            <nav
                                                className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className="hidden sm:block">
                                                    <p className="text-sm text-gray-700">
                                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.renders.listings().length ? this.renders.listings().length : (this.state.page * this.state.page_count)}</span> of{' '}
                                                        <span className="font-medium">{this.state.listing_stats[this.state.status.value.replace(" ", "_")]}</span> results
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex justify-between sm:justify-end">
                                                    <Menu as="div" className="relative inline-block text-left mr-2">
                                                        <div>
                                                            <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                Page limit: {this.state.page_count}
                                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {
                                                                        [10, 20, 30, 50, 100, 1000].map((item) => {
                                                                            return (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    page_count: item,
                                                                                                    page: 1
                                                                                                }, () => {
                                                                                                    this.functions.getListings();
                                                                                                })
                                                                                            }}
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                this.state.page_count == item ? "text-purple-500" : ""
                                                                                            )}
                                                                                        >
                                                                                            {item}
                                                                                        </div>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                    <div
                                                        onClick={() => {

                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            }, () => {
                                                                this.functions.getListings();
                                                            });

                                                            /*
                                                            this.state.listings = this.state.listings.map((item) => {
                                                                item.selected = false;
                                                                return item;
                                                            });
                                                            this.setState({
                                                                select_all: false,
                                                                listings: this.state.listings,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            });
                                                            */
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {

                                                            if (((this.state.page) * this.state.page_count) <= this.state.listing_stats[this.state.status.value.replace(" ", "_")]) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.getListings();
                                                                });
                                                            }

                                                            /*
                                                            if ((((this.state.page) * this.state.page_count) <= this.renders.listings().length)) {
                                                                this.state.listings = this.state.listings.map((item) => {
                                                                    item.selected = false;
                                                                    return item;
                                                                });
                                                                this.setState({
                                                                    select_all: false,
                                                                    listings: this.state.listings,
                                                                    page: this.state.page + 1
                                                                });
                                                            }
                                                            */
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.listing_stats[this.state.status.value.replace(" ", "_")] ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }


                    </div>
                </div>

            </>
        )
    }
}

export default ListListings;
