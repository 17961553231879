class userRegister {

    static user = {};

    static set(user){
        userRegister.user = user;
        try {
            sessionStorage.setItem("user", JSON.stringify(user));
        }catch (e) {}
    }

    static get(){
        if(userRegister.user.id){
            return userRegister.user
        }else{
            try {
                if(sessionStorage.getItem("user")){
                    return JSON.parse(sessionStorage.getItem("user"));
                }else{
                    return {};
                }
            }catch (e) {
                return {};
            }
        }
    }

    static remove(){
        userRegister.user = {};
        try {
            sessionStorage.removeItem("user");
        }catch (e) {}
    }

}

export {userRegister}