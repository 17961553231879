import React, { Component } from 'react';
import SingleSelection from '../components/singleSelection';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';

class LoginWithWhatsApp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            whatsAppCountryCode: { id: 1, name: '+971', value: "971" },
            loading: false
        }
    };

    functions = {
        loginWithWhatsApp: async () => {
            await this.promisedSetState({
                loading: true
            });
            try {
                let response = await this.calls.loginWithWhatsApp({
                    whatsAppCountryCode: this.state.whatsAppCountryCode,
                    phone: this.state.phone
                });
                this.props.history.push("/verify/agent");
            } catch (error) {
                this.promisedSetState({
                    message: error.body ? error.body.message : "",
                    error: true,
                    loading: false
                })
            }
        }
    };

    calls = {
        loginWithWhatsApp: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/user/loginWithWhatsApp";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>
                <div style={{ height: "100vh" }} className="min-h-full flex flex-col justify-center py-12 px-6">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md items-center">
                        <div className="w-full flex justify-center items-center">
                            <img
                                className="m-6 h-20 w-px-200"
                                src={require("../assets/images/platonic_diamond_1.png")}
                                alt="Workflow"
                            />
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <img
                                className="h-6 w-px-100"
                                src={require("../assets/images/adbooster_indigo.png")}
                                alt="Workflow"
                            />
                        </div>
                        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Login</h2>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white border py-8 px-4 shadow rounded-lg sm:px-10">
                            <div className="grid grid-cols-12 gap-2">
                                <div className="col-span-4">
                                    <label htmlFor="dial-code" className="block text-sm font-medium text-gray-900">
                                        Country code
                                    </label>
                                    <div className="mt-1">
                                        <SingleSelection
                                            select={async (option) => {
                                                await this.promisedSetState({
                                                    whatsAppCountryCode: option
                                                });
                                            }}
                                            selected={this.state.whatsAppCountryCode ? this.state.whatsAppCountryCode : { id: 0, name: 'Click to Select' }}
                                            options={[
                                                { id: 9, name: '+45', value: "45" },
                                                { id: 7, name: '+33', value: "33" },
                                                { id: 4, name: '+49', value: "49" },
                                                { id: 5, name: '+353', value: "353" },
                                                { id: 6, name: '+47', value: "47" },
                                                { id: 3, name: '+46', value: "46" },
                                                { id: 8, name: '+41', value: "41" },
                                                { id: 2, name: '+44', value: "44" },
                                                { id: 10, name: '+61', value: "61" },
                                                { id: 11, name: '+1', value: "1" },
                                                { id: 12, name: '+91', value: "91" },
                                                { id: 13, name: '+972', value: "972" },
                                                { id: 14, name: '+64', value: "64" },
                                                { id: 15, name: '+974', value: "974" },
                                                { id: 16, name: '+966', value: "966" },
                                                { id: 17, name: '+27', value: "27" },
                                                { id: 1, name: '+971', value: "971" },
                                                { id: 19, name: '+34', value: "34" },
                                                { id: 20, name: '+351', value: "351" },
                                                { id: 21, name: '+31', value: "31" },
                                                { id: 22, name: '+39', value: "39" },
                                                { id: 23, name: '+93', value: "93" },
                                                { id: 24, name: '+355', value: "355" },
                                                { id: 25, name: '+213', value: "213" },
                                                { id: 26, name: '+1684', value: "1684" },
                                                { id: 27, name: '+376', value: "376" },
                                                { id: 28, name: '+244', value: "244" },
                                                { id: 29, name: '+1268', value: "1268" },
                                                { id: 30, name: '+54', value: "54" },
                                                { id: 31, name: '+374', value: "374" },
                                                { id: 32, name: '+43', value: "43" },
                                                { id: 33, name: '+994', value: "994" },
                                                { id: 34, name: '+1242', value: "1242" },
                                                { id: 35, name: '+973', value: "973" },
                                                { id: 36, name: '+880', value: "880" },
                                                { id: 37, name: '+1246', value: "1246" },
                                                { id: 38, name: '+375', value: "375" },
                                                { id: 39, name: '+32', value: "32" },
                                                { id: 40, name: '+501', value: "501" },
                                                { id: 41, name: '+229', value: "229" },
                                                { id: 42, name: '+975', value: "975" },
                                                { id: 43, name: '+591', value: "591" },
                                                { id: 44, name: '+387', value: "387" },
                                                { id: 45, name: '+267', value: "267" },
                                                { id: 46, name: '+55', value: "55" },
                                                { id: 47, name: '+673', value: "673" },
                                                { id: 48, name: '+359', value: "359" },
                                                { id: 49, name: '+226', value: "226" },
                                                { id: 50, name: '+257', value: "257" },
                                                { id: 51, name: '+238', value: "238" },
                                                { id: 52, name: '+855', value: "855" },
                                                { id: 53, name: '+237', value: "237" },
                                                { id: 54, name: '+236', value: "236" },
                                                { id: 55, name: '+235', value: "235" },
                                                { id: 56, name: '+56', value: "56" },
                                                { id: 57, name: '+86', value: "86" },
                                                { id: 58, name: '+57', value: "57" },
                                                { id: 59, name: '+269', value: "269" },
                                                { id: 60, name: '+242', value: "242" },
                                                { id: 61, name: '+506', value: "506" },
                                                { id: 62, name: '+385', value: "385" },
                                                { id: 63, name: '+53', value: "53" },
                                                { id: 64, name: '+357', value: "357" },
                                                { id: 65, name: '+420', value: "420" },
                                                { id: 66, name: '+253', value: "253" },
                                                { id: 67, name: '+1767', value: "1767" },
                                                { id: 68, name: '+1809', value: "1809" },
                                                { id: 69, name: '+593', value: "593" },
                                                { id: 70, name: '+20', value: "20" },
                                                { id: 71, name: '+503', value: "503" },
                                                { id: 72, name: '+240', value: "240" },
                                                { id: 73, name: '+291', value: "291" },
                                                { id: 74, name: '+372', value: "372" },
                                                { id: 75, name: '+268', value: "268" },
                                                { id: 76, name: '+251', value: "251" },
                                                { id: 77, name: '+679', value: "679" },
                                                { id: 78, name: '+358', value: "358" },
                                                { id: 79, name: '+241', value: "241" },
                                                { id: 80, name: '+220', value: "220" },
                                                { id: 81, name: '+995', value: "995" },
                                                { id: 82, name: '+233', value: "233" },
                                                { id: 83, name: '+30', value: "30" },
                                                { id: 84, name: '+1473', value: "1473" },
                                                { id: 85, name: '+502', value: "502" },
                                                { id: 86, name: '+224', value: "224" },
                                                { id: 87, name: '+245', value: "245" },
                                                { id: 88, name: '+592', value: "592" },
                                                { id: 89, name: '+509', value: "509" },
                                                { id: 90, name: '+504', value: "504" },
                                                { id: 195, name: '+852', value: "852" },
                                                { id: 91, name: '+36', value: "36" },
                                                { id: 92, name: '+354', value: "354" },
                                                { id: 93, name: '+62', value: "62" },
                                                { id: 94, name: '+98', value: "98" },
                                                { id: 95, name: '+964', value: "964" },
                                                { id: 98, name: '+1876', value: "1876" },
                                                { id: 99, name: '+81', value: "81" },
                                                { id: 100, name: '+962', value: "962" },
                                                { id: 101, name: '+7', value: "7" },
                                                { id: 102, name: '+254', value: "254" },
                                                { id: 103, name: '+686', value: "686" },
                                                //{ id: 104, name: '+82', value: "82" },
                                                { id: 105, name: '+965', value: "965" },
                                                { id: 106, name: '+996', value: "996" },
                                                { id: 107, name: '+856', value: "856" },
                                                { id: 108, name: '+371', value: "371" },
                                                { id: 109, name: '+961', value: "961" },
                                                { id: 110, name: '+266', value: "266" },
                                                { id: 111, name: '+231', value: "231" },
                                                { id: 112, name: '+218', value: "218" },
                                                { id: 113, name: '+423', value: "423" },
                                                { id: 114, name: '+370', value: "370" },
                                                { id: 115, name: '+352', value: "352" },
                                                { id: 116, name: '+261', value: "261" },
                                                { id: 117, name: '+265', value: "265" },
                                                { id: 118, name: '+60', value: "60" },
                                                { id: 119, name: '+960', value: "960" },
                                                { id: 120, name: '+223', value: "223" },
                                                { id: 121, name: '+356', value: "356" },
                                                { id: 122, name: '+692', value: "692" },
                                                { id: 123, name: '+222', value: "222" },
                                                { id: 124, name: '+230', value: "230" },
                                                { id: 125, name: '+52', value: "52" },
                                                { id: 126, name: '+691', value: "691" },
                                                { id: 127, name: '+373', value: "373" },
                                                { id: 128, name: '+377', value: "377" },
                                                { id: 129, name: '+976', value: "976" },
                                                { id: 130, name: '+382', value: "382" },
                                                { id: 131, name: '+212', value: "212" },
                                                { id: 132, name: '+258', value: "258" },
                                                { id: 133, name: '+95', value: "95" },
                                                { id: 134, name: '+264', value: "264" },
                                                { id: 135, name: '+674', value: "674" },
                                                { id: 136, name: '+977', value: "977" },
                                                { id: 137, name: '+505', value: "505" },
                                                { id: 138, name: '+227', value: "227" },
                                                { id: 139, name: '+234', value: "234" },
                                                { id: 140, name: '+389', value: "389" },
                                                { id: 142, name: '+968', value: "968" },
                                                { id: 143, name: '+92', value: "92" },
                                                { id: 144, name: '+680', value: "680" },
                                                { id: 145, name: '+507', value: "507" },
                                                { id: 146, name: '+675', value: "675" },
                                                { id: 147, name: '+595', value: "595" },
                                                { id: 148, name: '+51', value: "51" },
                                                { id: 149, name: '+63', value: "63" },
                                                { id: 150, name: '+48', value: "48" },
                                                { id: 151, name: '+40', value: "40" },
                                                { id: 152, name: '+7', value: "7" },
                                                { id: 153, name: '+250', value: "250" },
                                                { id: 154, name: '+1869', value: "1869" },
                                                { id: 155, name: '+1758', value: "1758" },
                                                { id: 156, name: '+1784', value: "1784" },
                                                { id: 157, name: '+685', value: "685" },
                                                { id: 158, name: '+378', value: "378" },
                                                { id: 159, name: '+239', value: "239" },
                                                { id: 160, name: '+221', value: "221" },
                                                { id: 161, name: '+381', value: "381" },
                                                { id: 162, name: '+248', value: "248" },
                                                { id: 163, name: '+232', value: "232" },
                                                { id: 164, name: '+65', value: "65" },
                                                { id: 165, name: '+421', value: "421" },
                                                { id: 166, name: '+386', value: "386" },
                                                { id: 167, name: '+677', value: "677" },
                                                { id: 168, name: '+82', value: "82" },
                                                { id: 169, name: '+211', value: "211" },
                                                { id: 170, name: '+94', value: "94" },
                                                { id: 171, name: '+249', value: "249" },
                                                { id: 173, name: '+963', value: "963" },
                                                { id: 174, name: '+886', value: "886" },
                                                { id: 175, name: '+992', value: "992" },
                                                { id: 176, name: '+255', value: "255" },
                                                { id: 177, name: '+66', value: "66" },
                                                { id: 178, name: '+228', value: "228" },
                                                { id: 179, name: '+1868', value: "1868" },
                                                { id: 180, name: '+216', value: "216" },
                                                { id: 181, name: '+90', value: "90" },
                                                { id: 182, name: '+993', value: "993" },
                                                { id: 183, name: '+688', value: "688" },
                                                { id: 184, name: '+256', value: "256" },
                                                { id: 185, name: '+380', value: "380" },
                                                { id: 186, name: '+598', value: "598" },
                                                { id: 187, name: '+998', value: "998" },
                                                { id: 188, name: '+678', value: "678" },
                                                { id: 189, name: '+379', value: "379" },
                                                { id: 190, name: '+58', value: "58" },
                                                { id: 191, name: '+84', value: "84" },
                                                { id: 192, name: '+967', value: "967" },
                                                { id: 193, name: '+260', value: "260" },
                                                { id: 194, name: '+263', value: "263" }
                                            ]}
                                            name="dial-code"
                                            id="dial-code"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label htmlFor="email" className={(this.state.error ? "text-red-700" : "text-gray-700") + " block text-sm font-medium "}>
                                        WhatsApp
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="phone"
                                            name="phone"
                                            type="number"
                                            onChange={(event) => {
                                                this.setState({
                                                    phone: event.target.value
                                                })
                                            }}
                                            disabled={this.state.loading}
                                            value={this.state.phone}
                                            autoComplete="phone"
                                            required
                                            className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block w-full px-3 py-2 border  rounded-md shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm"}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-12 mt-4">
                                    <button
                                        onClick={() => {
                                            if (!this.state.loading) {
                                                this.functions.loginWithWhatsApp();
                                            }
                                        }}
                                        className="w-full flex overflow-hidden relative justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {
                                            this.state.loading &&
                                            <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        Login
                                    </button>
                                </div>
                                {
                                    this.state.error &&
                                    <div className="flex col-span-12 items-center justify-center mt-3 font-medium text-sm text-red-600 hover:text-red-500">
                                        {this.state.message}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="w-full flex flex-row font-medium text-sm text-gray-600 justify-center items-center mt-4">
                            <a target="_blank" className="hover:text-gray-800" href="https://adbooster.ai/privacy-policy">Privacy Policy</a>
                            <div className="ml-2 mr-2 text-xxs">|</div>
                            <a target="_blank" className="hover:text-gray-800" href="https://adbooster.ai/terms">Terms</a>
                            <div className="ml-2 mr-2 text-xxs">|</div>
                            <a target="_blank" className="hover:text-gray-800" href="https://adbooster.ai/use-policy">Use Policy</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    /*
    render() {
        return (
            <>
                <div style={{ height: "100vh" }} className="flex">
                    <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:w-1/3 lg:flex-none lg:px-20 xl:px-24">
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div>
                                {
                                    <img
                                        className="h-6 w-auto"
                                        src={"https://api.primehit.com/v1/ae/static/logo/kb0os5ytf7hrjcpw.png"}
                                        alt="Workflow"
                                    />
                                }
                                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Welcome back</h2>
                                <p className="mt-1 text-sm text-gray-600">
                                    Please login to your account.
                                </p>
                            </div>
                            <div className="mt-8">
                                <div className="mt-6">
                                    <div className="space-y-6">
                                        <div>
                                            <label htmlFor="email" className={(this.state.error ? "text-red-500" : "text-gray-700") + " block text-sm font-medium "}>
                                                Email address
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(event) => {
                                                        this.setState({
                                                            email: event.target.value
                                                        })
                                                    }}
                                                    value={this.state.email}
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    required
                                                    className={(this.state.error ? "border-red-500" : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                />
                                            </div>
                                        </div>

                                        <div className="space-y-1">
                                            <label htmlFor="password" className={(this.state.error ? "text-red-500" : "text-gray-700") + " block text-sm font-medium "}>
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(event) => {
                                                        this.setState({
                                                            password: event.target.value
                                                        })
                                                    }}
                                                    value={this.state.password}
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    required
                                                    className={(this.state.error ? "border-red-500" : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="text-sm">
                                                <a href="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                    Forgot your password?
                                                </a>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                onClick={() => {
                                                    this.functions.loginUser();
                                                }}
                                                className="w-full relative flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div>Sign in</div>
                                            </button>
                                        </div>
                                        {
                                            this.state.error && this.state.message &&
                                            <div style={{marginTop: "10px"}} className="font-medium text-sm text-red-500 text-center">
                                                {this.state.message}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:block relative w-0 flex-1 bg-center" style={{ backgroundImage: 'linear-gradient(180deg,#d6f1f5,#aee3ec)' }}>

                    </div>
                </div>
            </>
        )
    }
    */
}

export default LoginWithWhatsApp;
