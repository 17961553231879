import React, { Component, Fragment } from 'react';
import { PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon, UploadIcon, VideoCameraIcon, DocumentIcon, PhotographIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import { versionRegister } from '../../services/versionRegister';
import SingleSelection from '../../components/singleSelection';
import Dropdown from '../../components/dropdown';
import CreateAgent from '../../components/createAgent';
import Pusher from 'pusher-js';
var moment = require('moment');

var pusherService = null;

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListMedia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            tab: { id: 1, name: "Active", value: "active", count: 0 },
            users: [],
            user: {},
            sort: { id: 1, name: "Created", value: 'createdAt' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 20,
            total: 0,
            push_code: Math.floor(100000000 + Math.random() * 900000000),
        }
    };

    componentDidMount() {

        pusherService = new Pusher('a4ee0d05afe391530eb1', {
            cluster: 'ap1'
        });

        this.functions.getUser();
        this.functions.getLogs();
        this.functions.startGlobalListener();
    }

    componentWillUnmount() {
        pusherService.unsubscribe("media_" + this.state.push_code);
    }

    functions = {
        getUser: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get() ? userRegister.get() : {}
                })
            }
        },
        getLogs: async () => {
            try {
                await this.promisedSetState({ loading_users: true });
                let response = await this.calls.getLogs();
                await this.promisedSetState({
                    users: response.data,
                    total: response.meta.total,
                    loading: false,
                    loading_users: false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_users: false,
                    loading: false
                })
            }
        },
        uploadFiles: (e) => {
            if (e.target.files) {
                console.log(e.target.files);
                this.setState({
                    loading_files: true
                });
                const formData = new FormData();
                for (let key in e.target.files) {
                    formData.append('files', e.target.files[key]);
                }
                this.calls.uploadImages(formData, true).then((response) => {
                    response.data.map((item) => {
                        item.loading = true;
                        this.state.users.unshift(item);
                    });
                    this.setState({
                        users: this.state.users,
                        loading_files: false
                    });
                }, (error) => {
                    this.setState({
                        loading_files: false
                    });
                });
            }
        },
        startGlobalListener: async () => {
            let self = this;
            let channel = pusherService.subscribe("media_" + this.state.push_code);
            channel.bind('media', async function (response) {
                if (response && response.media) {
                    self.state.users = self.state.users.map((item) => {
                        if (item.id == response.media.id) {
                            item.loading = false;
                            item.status = response.media.status;
                            item.format = response.media.format;
                            item.type = response.media.type;
                            item.width = response.media.width;
                            item.height = response.media.height;
                            item.url = response.media.url;
                        }
                        return item;
                    });
                    self.setState({
                        users: self.state.users
                    });
                }
            });
        },
        removeMedia: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                users: this.state.users.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                await this.calls.removeMedia(selected_feed.id);
                await this.promisedSetState({
                    users: this.state.users.filter((item) => { return selected_feed.id != item.id }),
                    remove: null,
                    remove_name: "",
                })
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    users: this.state.users.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        }
    };

    calls = {
        getLogs: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/media/listMedia?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&status=" + this.state.tab.id;
            return apiRegister.call(options, url);
        },
        uploadImages: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/images?push_code=" + this.state.push_code;
            return apiRegister.call(options, url);
        },
        removeMedia: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/media/deleteMedia/" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 h-full min-h-full bg-gray-50">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading media ...</div>
                        </div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={this.state.remove && this.state.remove.disabled ? "Delete Form" : "Disable Form"}
                    description={"Are you sure you want to " + (this.state.remove && this.state.remove.disabled ? "delete" : "disable") + " this form? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={this.state.remove && this.state.remove.disabled ? "delete" : "disable"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name"'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeMedia();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*UPLOAD*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12 md:col-span-4 mb-4 md:mb-6 pr-0 md:pr-2">
                        <div className="bg-white border relative rounded-md flex cursor-pointer hover:text-purple-500 justify-center items-center h-12 w-full shadow-sm">
                            <div className="text-sm font-medium">Upload media</div>
                            <UploadIcon className="w-5 ml-2" />
                            {
                                !this.state.loading_files &&
                                <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                                    onChange={(e) => this.functions.uploadFiles(e)}
                                    type="file"
                                    accept={"image/png,image/jpg,video/mp4,.pdf"}
                                    multiple={"true"}
                                    className="opacity-0 absolute h-full left-0 right-0 top-0 bottom-0 cursor-pointer" />
                            }
                            {
                                this.state.loading_files &&
                                <div className="w-full h-full absolute bg-white bg-opacity-50 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    </div>
                }


                <div className="col-span-0 hidden md:block md:col-span-4"></div>

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Created", value: 'createdAt' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getLogs();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getLogs();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*AGENTS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white border shadow-lg rounded-lg">
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            Media
                                        </div>
                                    </div>
                                    {
                                        this.state.users.length < 1 &&
                                        <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_users &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">Media</span> available</div>
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div id={"table_container"} className="relative overflow-x-scroll min-h-110">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Agent</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Status</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Permit</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Listings</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Leads</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Action</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Button</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.users.map((agent, index) => {
                                                            return (
                                                                <tr key={agent.email} className="border-b border-gray-100">
                                                                    <td className="text-right px-6">
                                                                        <div className="flex flex-row justify-start">
                                                                            <div
                                                                                onClick={async () => {
                                                                                    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                                                                                        navigator.clipboard.writeText(agent.url);
                                                                                        agent.copied = true;
                                                                                        await this.promisedSetState({
                                                                                            users: this.state.users
                                                                                        });
                                                                                        await this.functions.sleep(1000);
                                                                                        agent.copied = false;
                                                                                        await this.promisedSetState({
                                                                                            users: this.state.users
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                className={"cursor-pointer whitespace-no-wrap mr-2 w-24 relative inline-flex justify-center items-center border px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                            >
                                                                                {
                                                                                    !agent.copied &&
                                                                                    <div>Copy link</div>
                                                                                }
                                                                                {
                                                                                    agent.copied &&
                                                                                    <div>Copied!</div>
                                                                                }
                                                                                {
                                                                                    agent.loading &&
                                                                                    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-gray-50">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                onClick={async () => {
                                                                                    if (!agent.loading_remove) {
                                                                                        this.setState({
                                                                                            remove: agent
                                                                                        })
                                                                                    }
                                                                                }}
                                                                                className={"cursor-pointer whitespace-no-wrap w-24 relative inline-flex items-center border justify-center py-2 border-gray-300 text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50"}
                                                                            >
                                                                                <div>Remove</div>
                                                                                {
                                                                                    agent.loading_remove &&
                                                                                    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="flex relative items-center px-6 py-4">
                                                                        {
                                                                            agent.hover &&
                                                                            <div
                                                                                style={{
                                                                                    width: "250px",
                                                                                    height: "250px",
                                                                                    left: "90px",
                                                                                    zIndex: "9999",
                                                                                    position: "absolute",
                                                                                    ...(index < 3
                                                                                        ? { top: "15px" }
                                                                                        : { bottom: "15px" })
                                                                                }}
                                                                                className="bg-gray-50 overflow-hidden rounded-md shadow"
                                                                            >
                                                                                {
                                                                                    agent.url && agent.type == "video" &&
                                                                                    <div className="w-full h-full flex items-center justify-center">
                                                                                        <video controls={false} muted={true} autoPlay={true} className="object-contain" src={agent.url}></video>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    agent.url && agent.type == "image" && agent.format !== "pdf" &&
                                                                                    <div className="w-full h-full flex items-center justify-center">
                                                                                        <img className="object-contain" src={agent.url} />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !agent.url &&
                                                                            <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">

                                                                            </span>
                                                                        }
                                                                        {
                                                                            agent.url && agent.type == "image" && agent.format !== "pdf" &&
                                                                            <div
                                                                                onMouseEnter={() => {
                                                                                    agent.hover = true;
                                                                                    this.setState({
                                                                                        users: this.state.users
                                                                                    })
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    agent.hover = false;
                                                                                    this.setState({
                                                                                        users: this.state.users
                                                                                    })
                                                                                }}
                                                                                className="flex-shrink-0 rounded-full flex cursor-wait items-center justify-center h-10 w-10 bg-purple-100 text-purple-500">
                                                                                <PhotographIcon className="w-5" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            agent.url && agent.type == "video" &&
                                                                            <div
                                                                                onMouseEnter={() => {
                                                                                    agent.hover = true;
                                                                                    this.setState({
                                                                                        users: this.state.users
                                                                                    })
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    agent.hover = false;
                                                                                    this.setState({
                                                                                        users: this.state.users
                                                                                    })
                                                                                }}
                                                                                className="flex-shrink-0 rounded-full flex items-center cursor-wait justify-center h-10 w-10 bg-orange-100 text-orange-500">
                                                                                <VideoCameraIcon className="w-5" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            agent.url && agent.format == "pdf" &&
                                                                            <div className="flex-shrink-0 rounded-full flex items-center justify-center h-10 w-10 bg-blue-100 text-blue-500">
                                                                                <DocumentIcon className="w-5" />
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{moment(agent.createdAt).format("YYYY-MM-DD")}</div>
                                                                        <div className="text-sm text-gray-500">Created</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.status}</div>
                                                                        <div className="text-sm text-gray-500">Status</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.format ? agent.format : "-"}</div>
                                                                        <div className="text-sm text-gray-500">Format</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.type ? agent.type : "-"}</div>
                                                                        <div className="text-sm text-gray-500">Type</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.width ? agent.width : "-"}</div>
                                                                        <div className="text-sm text-gray-500">Width</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.height ? agent.height : "-"}</div>
                                                                        <div className="text-sm text-gray-500">Height</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.name ? agent.name : "-"}</div>
                                                                        <div className="text-sm text-gray-500">Name</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.url ? agent.url : "-"}</div>
                                                                        <div className="text-sm text-gray-500">URL</div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className="hidden sm:block">
                                                    <p className="text-sm text-gray-700">
                                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.total ? this.state.total : (this.state.page * this.state.page_count)}</span> of{' '}
                                                        <span className="font-medium">{this.state.total}</span> results
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        this.functions.getLogs();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.page !== 1) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                                }, () => {
                                                                    this.functions.getLogs();
                                                                });
                                                            }
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) < this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.getLogs();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div>
        )
    }
}

export default ListMedia;
