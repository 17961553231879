import React, { Component } from 'react';

class ReportPdfHeaderSlide extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    };

    componentWillMount() { }

    componentDidMount() { }

    functions = {};

    render() {
        return (
            <div style={{ width: '842px', height: '595px' }} className="bg-white">
                <div className="flex flex-row w-full h-full overflow-hidden relative">
                    <div className="absolute z-10 left-0 right-0 top-0 bottom-0 flex flex-row p-8">
                        <div className="h-full">
                            <div style={{width: '136px'}}>
                                <img className="w-full" src={"https://res.cloudinary.com/propertybooster/image/upload/v1647557324/kxt2v2eolulwhnddreky.png"} />
                            </div>
                        </div>
                        <div className="flex flex-1"></div>
                        <div className="h-full flex flex-col">
                            <div>
                                <div className="text-3xl text-right font-medium text-white">
                                    Google Ads Report
                                </div>
                                <div className="text-sm pt-1 text-white text-right text-opacity-75">
                                    May 1st 2021 - May 31st 2021
                                </div>
                            </div>
                            <div className="flex flex-1"></div>
                            <div className="flex flex-row items-center justify-end mb-2">
                                <div className="w-12 h-12 rounded-full bg-white mr-3">

                                </div>
                                <div className="flex-col flex">
                                    <div className="font-semibold text-xxs text-white">
                                        Martin Ploug
                                    </div>
                                    <div className="font-medium text-xxs text-white">
                                        martin@propertybooster.io
                                    </div>
                                    <div className="font-medium text-xxs text-white">
                                        073 505 54 80
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '1061px', height: '1061px', left: '350px', top: '-374px', backgroundColor: '#B4B3A1' }} className="absolute rounded-full"></div>
                </div>
            </div>
        )
    }
}

export default ReportPdfHeaderSlide;
