import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline';

import { userRegister } from '../services/userRegister';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';

import ListLeads from '../pages/agent/listLeads';
import ListListings from '../pages/agent/listListings';
import ListClients from '../pages/agent/listClients';
import Dashboard from './dashboard';
import Billing from './billing';
import Settings from './settings';

class LoggedIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            sidebarOpen: false,
            version: {},
            navigation: []
        }
    };

    componentWillMount() {
        this.functions.getUser();
    }

    componentDidMount() {

    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({
                user: user
            });
            this.functions.setMenu();
        },
        setMenu: () => {
            let navs = [];
            if (window.location.origin.indexOf("localhost") !== -1) {
                if (this.state.user.type == "agent") {
                    navs = [
                        { name: 'Dashboard', link: '/v1-proptree' },
                        { name: 'Inquiries', link: '/v1-proptree/inquiries' },
                        { name: 'Contacts', link: '/v1-proptree/contacts' },
                        { name: 'Settings', link: '/v1-proptree/settings' },
                        { name: 'Plans', link: '/v1-proptree/billing' }
                    ];
                } else if (this.state.user.type == "custom" && this.state.user.agencyDefault) {
                    navs = [
                        { name: 'Dashboard', link: '/v1-proptree' },
                        { name: 'Inquiries', link: '/v1-proptree/inquiries' },
                        { name: 'Contacts', link: '/v1-proptree/contacts' },
                        { name: 'Settings', link: '/v1-proptree/settings' },
                    ];
                } else {
                    navs = [
                        { name: 'Dashboard', link: '/v1-proptree' },
                        { name: 'Inquiries', link: '/v1-proptree/inquiries' },
                        { name: 'Contacts', link: '/v1-proptree/contacts' }
                    ];
                }
            } else {
                if (this.state.user.type == "agent") {
                    navs = [
                        { name: 'Dashboard', link: '/v1' },
                        { name: 'Inquiries', link: '/v1/inquiries' },
                        { name: 'Contacts', link: '/v1/contacts' },
                        { name: 'Settings', link: '/v1/settings' },
                        { name: 'Plans', link: '/v1/billing' }
                    ];
                } else if (this.state.user.type == "custom" && this.state.user.agencyDefault) {
                    navs = [
                        { name: 'Dashboard', link: '/v1' },
                        { name: 'Inquiries', link: '/v1/inquiries' },
                        { name: 'Contacts', link: '/v1/contacts' },
                        { name: 'Settings', link: '/v1/settings' }
                    ];
                } else {
                    navs = [
                        { name: 'Dashboard', link: '/v1' },
                        { name: 'Inquiries', link: '/v1/inquiries' },
                        { name: 'Contacts', link: '/v1/contacts' }
                    ];
                }
            }
            this.promisedSetState({
                navigation: navs
            })
        },
        logout: async () => {
            await this.calls.logoutUser();
            userRegister.remove();
            tokenRegister.remove();
            if (window.location.origin.indexOf("localhost") !== -1) {
                this.props.history.push('/login-proptree');
            } else {
                this.props.history.push('/login');
            }
        }
    };

    renders = {};

    calls = {
        logoutUser: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.proptree + "/logoutProptree";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="min-h-full h-full">
                <Disclosure as="nav" className="border-b border-gray-200 bg-white z-50 fixed left-0 right-0">
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 justify-between">
                                    <div className="flex">
                                        <div className="flex flex-shrink-0 items-center">
                                            {
                                                this.state.user.enterprise &&
                                                <img
                                                    className="block h-12 w-auto"
                                                    src={this.state.user.enterprise.logo}
                                                    alt="Your Company"
                                                />
                                            }
                                        </div>
                                        <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                            {this.state.navigation.map((item) => (
                                                <div
                                                    onClick={() => {
                                                        this.props.history.push(item.link);
                                                    }}
                                                    key={item.name}
                                                    className={classNames(
                                                        window.location.pathname === item.link
                                                            ? 'border-purple-500 text-gray-900'
                                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                        'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer'
                                                    )}
                                                    aria-current={window.location.pathname === item.link ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                        {/* Profile dropdown */}
                                        <div className="flex flex-row items-center">
                                            <div className="flex items-center">
                                                <Menu as="div" className="ml-3 relative">
                                                    <div>
                                                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-2 rounded-md hover:bg-gray-100 hover:bg-opacity-50">
                                                            {
                                                                !this.state.user.image &&
                                                                <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                </span>
                                                            }
                                                            {
                                                                this.state.user.image &&
                                                                <img
                                                                    className="h-8 w-8 rounded-full"
                                                                    src={this.state.user.image}
                                                                    alt=""
                                                                />
                                                            }
                                                            <span className="ml-3 text-gray-700 text-left text-sm font-semibold block">
                                                                <span className="sr-only">Open user menu for </span>{this.state.user.name}
                                                            </span>
                                                            <ChevronDownIcon
                                                                className="flex-shrink-0 ml-2 h-5 w-5 text-gray-400 block"
                                                                aria-hidden="true"
                                                            />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="origin-top-right border absolute z-50 border-1.5 right-0 mt-5 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                                            <div className="px-4 py-3">
                                                                <p className="text-sm mb-1">Account type</p>
                                                                <p className="text-sm font-medium text-purple-500 truncate">{this.state.user.type == "agent" ? "Agent" : this.state.user.type == "custom" && this.state.user.agencyDefault ? "Agency" : "Affiliate"}</p>
                                                            </div>
                                                            <div className="py-1">
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <button
                                                                            onClick={() => {
                                                                                this.functions.logout();
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                'block w-full text-left px-4 py-2 text-sm cursor-pointer'
                                                                            )}
                                                                        >
                                                                            Sign out
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex items-center sm:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="sm:hidden">
                                <div className="space-y-1 pt-2 pb-3">
                                    {this.state.navigation.map((item) => (
                                        <Disclosure.Button
                                            onMouseDown={() => {
                                                this.props.history.push(item.link);
                                            }}
                                            key={item.name}
                                            as="div"
                                            className={classNames(
                                                window.location.pathname === item.link
                                                    ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                                                    : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                                'block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer'
                                            )}
                                            aria-current={window.location.pathname === item.link ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    <div className="flex items-center px-4">
                                        <div className="flex-shrink-0">
                                            {
                                                !this.state.user.image &&
                                                <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                    </svg>
                                                </span>
                                            }
                                            {
                                                this.state.user.image &&
                                                <img className="h-10 w-10 rounded-full" src={this.state.user.image} alt="" />
                                            }
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{this.state.user.name}</div>
                                            <div className="text-sm font-medium text-gray-500">{this.state.user.type == "agent" ? "Agent" : "Affiliate"}</div>
                                        </div>
                                    </div>
                                    <div className="mt-3 space-y-1">
                                        <Disclosure.Button
                                            onMouseDown={() => {
                                                this.functions.logout();
                                            }}
                                            key={'signout'}
                                            as="div"
                                            className="block px-4 cursor-pointer py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                        >
                                            Sign out
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <div className="pt-16 min-h-full h-full">
                    <main>
                        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 h-full min-h-full pt-8">
                            <div className="h-full min-h-full">
                                <Route exact path={window.location.origin.indexOf("localhost") !== -1 ? "/v1-proptree" : "/v1"}>
                                    <Dashboard user={this.state.user} proptree={true} history={this.props.history} />
                                </Route>
                                <Route exact path={window.location.origin.indexOf("localhost") !== -1 ? "/v1-proptree/listings" : "/v1/listings"}>
                                    <ListListings user={this.state.user} proptree={true} history={this.props.history} />
                                </Route>
                                <Route exact path={window.location.origin.indexOf("localhost") !== -1 ? "/v1-proptree/inquiries" : "/v1/inquiries"}>
                                    <ListLeads user={this.state.user} proptree={true} history={this.props.history} />
                                </Route>
                                <Route exact path={window.location.origin.indexOf("localhost") !== -1 ? "/v1-proptree/contacts" : "/v1/contacts"}>
                                    <ListClients user={this.state.user} proptree={true} history={this.props.history} />
                                </Route>
                                <Route exact path={window.location.origin.indexOf("localhost") !== -1 ? "/v1-proptree/billing" : "/v1/billing"}>
                                    <Billing user={this.state.user} proptree={true} history={this.props.history} />
                                </Route>
                                <Route exact path={window.location.origin.indexOf("localhost") !== -1 ? "/v1-proptree/settings" : "/v1/settings"}>
                                    <Settings user={this.state.user} proptree={true} history={this.props.history} />
                                </Route>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}

export default LoggedIn;
