import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';

class CreateNewReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            campaigns: [],
            report: {}
        }
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.state.report = (nextProps.report ? nextProps.report : {});
            this.functions.getCampaigns();
        }
        this.setState({
            report: this.state.report,
            open: nextProps.open
        })
    }

    functions = {
        createReport: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let report = JSON.parse(JSON.stringify(this.state.report));
                let response = await this.calls.createReport(report);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onCreate) {
                    this.props.onCreate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        getCampaigns: async () => {
            try {
                await this.promisedSetState({ loading_campaigns: true });
                let response = await this.calls.getCampaigns();
                this.setState({
                    campaigns: response.data.map((item) => {
                        if (item.name) {
                            item.name = item.name + ' (' + item.id + ')';
                        } else {
                            item.name = item.id;
                        }
                        return item;
                    }),
                    loading_campaigns: false
                })
            } catch (error) {
                this.setState({
                    loading_campaigns: false
                })
            }
        },
    };

    calls = {
        createReport: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/report-light";
            return apiRegister.call(options, url);
        },
        getCampaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/enterprise/campaigns";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-4xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.report.id ? "Update Report" : "New Report"}</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="flex flex-row">
                                                <div className="flex flex-1 flex-col pt-6">
                                                    <div className="px-4 grid-cols-12 grid gap-4 sm:px-6">
                                                        <div className="col-span-12 divide-y divide-gray-200">
                                                            <div className="space-y-6 pb-5">
                                                                <div>
                                                                    <label htmlFor="report-name" className="block text-sm font-medium text-gray-900">
                                                                        Name
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <input
                                                                            onChange={(event) => {
                                                                                this.state.report.name = event.target.value;
                                                                                this.setState({
                                                                                    report: this.state.report
                                                                                })
                                                                            }}
                                                                            disabled={this.state.report.id}
                                                                            value={this.state.report.name ? this.state.report.name : ""}
                                                                            type="text"
                                                                            name="report-name"
                                                                            id="report-name"
                                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-4 grid-cols-6 grid gap-4 sm:px-6">
                                                        <div className="col-span-6 divide-y divide-gray-200">
                                                            <div className="space-y-6 pb-5">
                                                                <div>
                                                                    <label htmlFor="report-campaign" className="block text-sm font-medium text-gray-900">
                                                                        Campaigns
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                if (!Array.isArray(this.state.report.campaigns)) {
                                                                                    this.state.report.campaigns = [];
                                                                                }
                                                                                this.state.report.campaigns.push(option);
                                                                                this.setState({
                                                                                    report: this.state.report
                                                                                })
                                                                            }}
                                                                            locked={this.state.loading_campaigns}
                                                                            disabled={this.state.report.id}
                                                                            selected={{ id: 0, name: 'Select campaigns' }}
                                                                            options={this.state.campaigns.filter((item) => {
                                                                                if (Array.isArray(this.state.report.campaigns)) {
                                                                                    return this.state.report.campaigns.filter((inner_item) => { return inner_item.id == item.id }).length < 1
                                                                                } else {
                                                                                    return true;
                                                                                }
                                                                            })}
                                                                            name="report-campaign"
                                                                            id="report-campaign"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                Array.isArray(this.state.report.campaigns) &&
                                                this.state.report.campaigns.length > 0 &&
                                                <div className="px-4 grid-cols-12 grid gap-4 sm:px-6">
                                                    <div className="col-span-12">
                                                        {
                                                            this.state.report.campaigns.map((item) => {
                                                                return (
                                                                    <div className="bg-white mb-2 relative flex-row flex w-full border cursor-pointer border-gray-300 rounded-md shadow-sm px-3 py-2 text-left">
                                                                        <div>
                                                                            {
                                                                                item.channel && (item.channel.value === 'facebook' || item.channel.value === 'meta') &&
                                                                                <div className="flex-shrink-0 bg-facebook-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                                    <div>
                                                                                        <img src={require('../assets/images/AB_Social_meta.png')} alt="" className="w-1" />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.channel && item.channel.value === 'google' &&
                                                                                <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                                    <div>
                                                                                        <img src={require('../assets/images/AB_Social_google.png')} alt="" className="w-2" />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className={'flex flex-1 truncate font-medium text-sm'}>
                                                                            {item.name}
                                                                        </div>
                                                                        {
                                                                            !this.state.report.id &&
                                                                            <div onClick={() => {
                                                                                this.state.report.campaigns = this.state.report.campaigns.filter((inner_item) => {
                                                                                    return inner_item.id !== item.id;
                                                                                });
                                                                                this.setState({
                                                                                    report: this.state.report
                                                                                })
                                                                            }} className={'text-red-500 font-medium text-sm hover:text-red-800 cursor-pointer'}>
                                                                                Remove
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.error &&
                                                <div className="px-6 pb-6">
                                                    <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <ul role="list" className="list-disc pl-5 space-y-1">
                                                                        <li>{this.state.error}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-shrink-0 px-4 sm:px-6 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    if (this.state.report.id) {
                                                        this.props.toggle();
                                                    } else {
                                                        this.functions.createReport();
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.report.id &&
                                                    <span>Create</span>
                                                }
                                                {
                                                    this.state.report.id &&
                                                    <span>Close</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateNewReport;
