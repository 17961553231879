import React, { Component } from 'react';
import { PlusIcon } from '@heroicons/react/solid'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import CreateUser from '../components/createUser';
import WarningModal from '../components/warningModal';
import CreateEnterprise from '../components/createEnterprise';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            user: {}
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: () => {
            let user = userRegister.get();
            this.setState({
                user: JSON.parse(JSON.stringify(user))
            })
        },
        uploadImage: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_image: true
                }, () => {
                    this.calls.uploadImage(formData).then((response) => {
                        this.state.user.image = response.data;
                        this.setState({
                            user: this.state.user,
                            loading_image: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_image: false
                        })
                    });
                });
            }
        }
    };

    calls = {
        updateSettings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/settings";
            return apiRegister.call(options, url);
        },
        uploadImage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/image";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <>
                <div className="rounded-lg bg-white overflow-hidden shadow">
                    <h2 className="sr-only" id="profile-overview-title">
                        Hi {this.state.user.name}
                    </h2>
                    <div className="bg-white p-6">
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                    <div>
                                        {
                                            this.state.user.image &&
                                            <img
                                                className="w-full"
                                                src={this.state.user.image}
                                                alt=""
                                            />
                                        }
                                        {
                                            !this.state.user.image &&
                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        }
                                    </div>
                                </div>
                                <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                    <p className="text-xl font-bold text-gray-900 sm:text-2xl">{this.state.user.name}</p>
                                    <p className="text-sm font-medium text-gray-600">{this.state.user.email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8 px-4">
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">

                        {/*BASIC USER INFORMATION*/}
                        <div>
                            <div className="shadow sm:rounded-md sm:overflow-hidden">
                                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                    <div>
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Profile information</h3>
                                        <p className="mt-1 text-sm text-gray-500">Update user information</p>
                                    </div>
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-2">
                                            <div className="flex flex-col items-center mt-5 sm:flex-row">
                                                {
                                                    !this.state.user.image &&
                                                    <span className="inline-block h-12 w-12 rounded-full relative overflow-hidden bg-gray-100">
                                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                        </svg>
                                                        {
                                                            this.state.loading_image &&
                                                            <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </span>
                                                }
                                                {
                                                    this.state.user.image &&
                                                    <div className="rounded-full relative mb-3 sm:mb-0 h-20 w-20 sm:h-12 sm:w-12 border overflow-hidden justify-center flex items-center">
                                                        <div>
                                                            <img
                                                                className="w-full"
                                                                src={this.state.user.image}
                                                                alt=""
                                                            />
                                                        </div>
                                                        {
                                                            this.state.loading_image &&
                                                            <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="flex flex-1">
                                                    <div className="ml-0 sm:ml-4 flex flex-1 w-full">
                                                        <div className="relative w-full text-center justify-center cursor-pointer bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                            <label
                                                                htmlFor="user-photo"
                                                                className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                                                            >
                                                                <span>Change image</span>
                                                                <span className="sr-only"> user photo</span>
                                                            </label>
                                                            {
                                                                <input
                                                                    ref="uploadImageFile"
                                                                    onClick={() => this.refs.uploadImageFile.value = null}
                                                                    onChange={(e) => {
                                                                        this.functions.uploadImage(e)
                                                                    }}
                                                                    type="file"
                                                                    accept={"image/png,image/jpg"}
                                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-6 sm:col-span-2">
                                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={this.state.user.name}
                                                onChange={(event) => {
                                                    this.state.user.name = event.target.value;
                                                    this.setState({
                                                        user: this.state.user
                                                    });
                                                }}
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="col-span-6 sm:col-span-2">
                                            <label htmlFor="user-email" className="block text-sm font-medium text-gray-700">
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                name="user-email"
                                                id="user-email"
                                                readOnly={true}
                                                value={this.state.user.email}
                                                onChange={(event) => {
                                                    this.state.user.email = event.target.value;
                                                    this.setState({
                                                        user: this.state.user
                                                    });
                                                }}
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-white text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="bg-indigo-600 relative border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {
                                            this.state.loading_information &&
                                            <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/*CHANGE PASSWORD*/}
                        {
                            false &&
                            <div>
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Password</h3>
                                            <p className="mt-1 text-sm text-gray-500">Update user password</p>
                                        </div>
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="old-password" className="block text-sm font-medium text-gray-700">
                                                    Old password
                                                </label>
                                                <input
                                                    type="text"
                                                    name="old-password"
                                                    id="old-password"
                                                    value={this.state.user.old_password}
                                                    onChange={(event) => {
                                                        this.state.user.old_password = event.target.value;
                                                        this.setState({
                                                            user: this.state.user
                                                        });
                                                    }}
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="user-password" className="block text-sm font-medium text-gray-700">
                                                    New password
                                                </label>
                                                <input
                                                    type="text"
                                                    name="user-password"
                                                    id="user-password"
                                                    readOnly={true}
                                                    value={this.state.user.password}
                                                    onChange={(event) => {
                                                        this.state.user.password = event.target.value;
                                                        this.setState({
                                                            user: this.state.user
                                                        });
                                                    }}
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-white text-right sm:px-6">
                                        <button
                                            onClick={() => {

                                            }}
                                            type="submit"
                                            className="bg-indigo-600 relative border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            {
                                                this.state.loading_password &&
                                                <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default Profile;
