import React, { Component, Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { ChevronDownIcon, PlusCircleIcon, ChatIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import Dropdown from '../../components/dropdown';
import CreateAdvertisingRequest from '../../components/createAdvertisingRequest';
import moment from "moment";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListListings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            tab: { id: 'sale', name: "For sale", count: 0, value: 'sale', status: 'ready' },
            users: [],
            user: {},
            sort: { id: 2, name: "Leads", value: 'totalLeads' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            if (userRegister.get()) {
                await this.promisedSetState({
                    user: userRegister.get() ? userRegister.get() : {}
                })
            }
            this.functions.applyUI();
            this.functions.getListings();
        },
        applyUI: async () => {
            try {
                if (this.renders.basicUI() && !this.renders.proptreeUI()) {
                    this.setState({
                        sort: { id: 3, name: "Price", value: 'price' },
                    })
                } else if (this.renders.basicUI() && this.renders.proptreeUI()) {
                    this.setState({
                        sort: { id: 2, name: "Inquiries", value: 'totalLeads' },
                    })
                }
            } catch (error) {

            }
        },
        getListings: async () => {
            try {
                await this.promisedSetState({ loading_users: true });
                let response = await this.calls.getListings();
                await this.promisedSetState({
                    users: response.data,
                    total: response.meta[this.state.tab.id] ? response.meta[this.state.tab.id] : 0,
                    sale: response.meta.sale ? response.meta.sale : 0,
                    rent: response.meta.rent ? response.meta.rent : 0,
                    archived: response.meta.archived ? response.meta.archived : 0,
                    loading_users: false,
                    loading: false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_users: false,
                    loading: false
                })
            }
        },
        downloadImage: async (imageSrc) => {
            const image = await fetch(imageSrc);
            const imageBlog = await image.blob();
            const imageURL = URL.createObjectURL(imageBlog);
            const link = document.createElement('a');
            link.href = imageURL;
            link.download = 'image file name here';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        }
    };

    calls = {
        getListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let user = userRegister.get();
            let url = "";
            if (user && user.enterprise && user.enterprise.legacy && user.enterprise.legacy.value == "enabled") {
                url = apiRegister.url.api + "/listings/getListingsLight?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&status=" + this.state.tab.status;
            } else {
                url = apiRegister.url.api + "/listings?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&status=" + this.state.tab.status;
            }
            return apiRegister.call(options, url);
        }
    };

    renders = {
        getListingUrl: (listing) => {
            if (listing.enterprise.adboosterType && listing.enterprise.adboosterType.value === 'boostabostad') {
                if (listing.enterprise.version && (listing.enterprise.version.id === 1 || listing.enterprise.version.value === "live")) {
                    return "https://lp.boostabostad.se/" + listing.adboosterId;
                } else {
                    return "https://lp.devboostabostad.se/" + listing.adboosterId;
                }
            } else {
                if (listing.enterprise.version && (listing.enterprise.version.id === 1 || listing.enterprise.version.value === "live")) {
                    return "https://lp.propertybooster.io/" + listing.adboosterId;
                } else {
                    return "https://lp.devpropertybooster.com/" + listing.adboosterId;
                }
            }
        },
        price: (num) => {
            try {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            } catch (error) {
                console.log(error);
                return num;
            }
        },
        legacyUI: () => {
            return !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value == 'enabled');
        },
        basicUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled';
        },
        creativeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.creativeSwitch && this.state.user.enterprise.creativeSwitch.value == 'enabled';
        },
        proptreeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.proptreeSwitch && this.state.user.enterprise.proptreeSwitch.value == 'enabled';
        },
        showLeads: () => {
            return !(this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value == 'disabled');
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 h-full min-h-full">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading listings ...</div>
                        </div>
                    </div>
                }

                {/*ERROR WARNING*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={"Error"}
                    description={typeof this.state.message == "string" ? this.state.message : "Something went wrong"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*SIDE MENU*/}
                {
                    !this.state.loading &&
                    <CreateAdvertisingRequest
                        partner={false}
                        listing={this.state.selected ? this.state.selected : {}}
                        open={this.state.open}
                        toggle={() => {
                            this.setState({
                                open: !this.state.open
                            })
                        }}
                        onUser={(user) => {

                        }}
                        onUpdate={(user) => {

                        }}
                    />
                }

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12 md:col-span-2 mb-4 md:mb-6">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.tab}
                                options={[
                                    { id: 'sale', name: "For sale", value: 'sale', status: 'ready', count: this.state.sale },
                                    { id: 'rent', name: "For rent", value: 'rent', status: 'ready', count: this.state.rent },
                                    { id: 'archived', name: "Archived", value: null, status: 'archived', count: this.state.archived }
                                ].filter((item) => {
                                    if (item.value === 'rent') {
                                        return !(this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1);
                                    } else {
                                        return true;
                                    }
                                })}



                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getListings();
                                }}
                            />
                        </div>
                    </div>
                }

                <div className="col-span-0 hidden md:block md:col-span-6"></div>

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Actions", value: 'totalActions' },
                                    this.renders.basicUI() && this.renders.proptreeUI() ? { id: 2, name: "Inquiries", value: 'totalLeads' } : { id: 2, name: "Leads", value: 'totalLeads' },
                                    { id: 3, name: "Price", value: 'price' },
                                    { id: 4, name: "Bedrooms", value: 'bedrooms' },
                                    { id: 5, name: "Bathrooms", value: 'bathrooms' }
                                ].filter((item) => {
                                    if (item.value === 'totalLeads') {
                                        return !this.renders.basicUI() || (this.renders.basicUI() && this.renders.proptreeUI());
                                    } else {
                                        return true;
                                    }
                                }).filter((item) => {
                                    if (!this.renders.legacyUI()) {
                                        return item.value !== "totalActions";
                                    } else {
                                        return true;
                                    }
                                }).filter((item) => {
                                    if (!this.renders.showLeads()) {
                                        if (item.value === 'totalLeads' || item.value === 'totalActions') {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    } else {
                                        return true;
                                    }
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getListings();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getListings();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*LISTINGS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white shadow-lg border rounded-lg">
                                    <div className="flex p-4 lg:px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold">
                                            {this.state.tab.name}
                                        </div>
                                        <div className="flex flex-1"></div>
                                    </div>
                                    {
                                        this.state.users.length < 1 &&
                                        <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_users &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">{this.state.tab.name}</span> available</div>
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div id={"table_container"} className="relative overflow-x-scroll min-h-110">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Listing</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Permit</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Bedrooms</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Bathrooms</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Price</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Leads</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Actions</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.users.map((agent, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={agent.email} className="border-b border-gray-100">
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            this.renders.showLeads() &&
                                                                            <td className="text-left pl-6 pr-6">
                                                                                <div className="flex flex-row">
                                                                                    {
                                                                                        !this.renders.basicUI() &&
                                                                                        this.renders.showLeads() &&
                                                                                        <Menu as="div" className="relative inline-block mr-2 text-left">
                                                                                            <div>
                                                                                                <Menu.Button className="inline-flex relative hover:bg-gray-50 items-center justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                                    {
                                                                                                        !agent.loading &&
                                                                                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                    }
                                                                                                    {
                                                                                                        agent.loading &&
                                                                                                        <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                                class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                        </div>
                                                                                                    }
                                                                                                </Menu.Button>
                                                                                            </div>
                                                                                            <Transition
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-100"
                                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                                enterTo="transform opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-75"
                                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                                leaveTo="transform opacity-0 scale-95"
                                                                                            >
                                                                                                <Menu.Items className="absolute border left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg">
                                                                                                    <div className="py-1">
                                                                                                        {
                                                                                                            [
                                                                                                                { name: "Show leads", value: "show_leads", icon: ExternalLinkIcon },
                                                                                                            ].map((item) => {
                                                                                                                return (
                                                                                                                    <Menu.Item>
                                                                                                                        {({ active }) => (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    if (item.value == "show_leads") {
                                                                                                                                        if (this.state.user.access && this.state.user.access.id === 1) {
                                                                                                                                            this.props.history.push("/manager/leads?listing=" + agent.reference);
                                                                                                                                        } else {
                                                                                                                                            this.props.history.push("/agent/leads?listing=" + agent.reference);
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className={classNames(
                                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                    'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                                    (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                <div className="flex flex-1">{item.name}</div>
                                                                                                                                <item.icon className="w-5" />
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Menu.Item>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </Menu.Items>
                                                                                            </Transition>
                                                                                        </Menu>
                                                                                    }
                                                                                    {
                                                                                        this.renders.basicUI() && this.renders.proptreeUI() &&
                                                                                        this.renders.showLeads() &&
                                                                                        <Menu as="div" className="relative inline-block mr-2 text-left">
                                                                                            <div>
                                                                                                <Menu.Button className="inline-flex relative hover:bg-gray-50 items-center justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                                    {
                                                                                                        !agent.loading &&
                                                                                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                    }
                                                                                                    {
                                                                                                        agent.loading &&
                                                                                                        <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                                 class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                        </div>
                                                                                                    }
                                                                                                </Menu.Button>
                                                                                            </div>
                                                                                            <Transition
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-100"
                                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                                enterTo="transform opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-75"
                                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                                leaveTo="transform opacity-0 scale-95"
                                                                                            >
                                                                                                <Menu.Items className="absolute border left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg">
                                                                                                    <div className="py-1">
                                                                                                        {
                                                                                                            [
                                                                                                                { name: "Show Inquiries", value: "show_leads", icon: ExternalLinkIcon },
                                                                                                            ].map((item) => {
                                                                                                                return (
                                                                                                                    <Menu.Item>
                                                                                                                        {({ active }) => (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    if (item.value == "show_leads") {
                                                                                                                                        if (this.state.user.access && this.state.user.access.id === 1) {
                                                                                                                                            this.props.history.push("/manager/leads?listing=" + agent.reference);
                                                                                                                                        } else {
                                                                                                                                            this.props.history.push("/agent/leads?listing=" + agent.reference);
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className={classNames(
                                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                    'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                                    (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                <div className="flex flex-1">{item.name}</div>
                                                                                                                                <item.icon className="w-5" />
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Menu.Item>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </Menu.Items>
                                                                                            </Transition>
                                                                                        </Menu>
                                                                                    }
                                                                                    {
                                                                                        !this.renders.basicUI() &&
                                                                                        this.renders.showLeads() &&
                                                                                        this.renders.legacyUI() &&
                                                                                        <div
                                                                                            onClick={async () => {
                                                                                                agent.open = !agent.open;
                                                                                                this.setState({
                                                                                                    users: this.state.users
                                                                                                });
                                                                                            }} className="inline-flex relative cursor-pointer hover:bg-gray-50 items-center justify-center rounded-md bg-white border px-4 h-10 text-sm font-medium text-gray-900">
                                                                                            {
                                                                                                !agent.open &&
                                                                                                <div>Creatives</div>
                                                                                            }
                                                                                            {
                                                                                                agent.open &&
                                                                                                <div>Close</div>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            this.renders.basicUI() && this.renders.proptreeUI() &&
                                                                            this.renders.showLeads() &&
                                                                            <td className="text-left pl-6 pr-6">
                                                                            <div className="flex flex-row">
                                                                                <Menu as="div"
                                                                                          className="relative inline-block mr-2 text-left">
                                                                                        <div>
                                                                                            <Menu.Button
                                                                                                className="inline-flex relative hover:bg-gray-50 items-center justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                                {
                                                                                                    !agent.loading &&
                                                                                                    <ChevronDownIcon
                                                                                                        className="h-5 w-5"
                                                                                                        aria-hidden="true"/>
                                                                                                }
                                                                                                {
                                                                                                    agent.loading &&
                                                                                                    <div
                                                                                                        className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                        <div
                                                                                                            style={{borderTopColor: "transparent"}}
                                                                                                            className="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </Menu.Button>
                                                                                        </div>
                                                                                        <Transition
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-100"
                                                                                            enterFrom="transform opacity-0 scale-95"
                                                                                            enterTo="transform opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-75"
                                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                                            leaveTo="transform opacity-0 scale-95"
                                                                                        >
                                                                                            <Menu.Items
                                                                                                className="absolute border left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg">
                                                                                                <div className="py-1">
                                                                                                    {
                                                                                                        [
                                                                                                            {
                                                                                                                name: "Show Inquiries",
                                                                                                                value: "show_leads",
                                                                                                                icon: ExternalLinkIcon
                                                                                                            },
                                                                                                        ].map((item) => {
                                                                                                            return (
                                                                                                                <Menu.Item>
                                                                                                                    {({active}) => (
                                                                                                                        <div
                                                                                                                            onClick={() => {
                                                                                                                                if (item.value == "show_leads") {
                                                                                                                                    if (this.state.user.access && this.state.user.access.id === 1) {
                                                                                                                                        this.props.history.push("/manager/leads?listing=" + agent.reference);
                                                                                                                                    } else {
                                                                                                                                        this.props.history.push("/agent/leads?listing=" + agent.reference);
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            className={classNames(
                                                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                                (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className="flex flex-1">{item.name}</div>
                                                                                                                            <item.icon
                                                                                                                                className="w-5"/>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </Menu.Item>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </Menu.Items>
                                                                                        </Transition>
                                                                                    </Menu>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            this.renders.basicUI() && !this.renders.proptreeUI() &&
                                                                            <td className="pl-6 w-0">
                                                                            </td>
                                                                        }
                                                                        <td className="flex items-center py-4">
                                                                            {
                                                                                !agent.image &&
                                                                                <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                    <svg className="h-full w-full text-gray-300" width="24" height="20" viewBox="0 0 24 20" fill="none">
                                                                                     <path d="M19.0316 0L19.9614 5.43362H24V20.2812H4.67806V18.9156L2.78923 19.2351L0 3.34155L19.0316 0ZM4.06803 17.4332L4.67817 17.3172V5.43339H18.3927L17.7533 1.80113L1.8017 4.61979L4.06803 17.4332ZM6.247 18.7119H22.4312V7.00255H6.247V18.7119ZM7.29291 16.7359V8.04847H21.3849V14.3535L18.1889 10.6632L17.5494 10.4018L12.6101 15.8352L10.5763 14.557L7.29291 16.7359ZM9.03633 10.4018C9.03633 11.1574 9.67568 11.7965 10.4309 11.7965C11.1864 11.7965 11.8257 11.1574 11.8254 10.4018C11.8254 9.64602 11.1864 9.00691 10.4309 9.00691C9.67568 9.00691 9.03633 9.64637 9.03633 10.4018Z" fill="currentColor"/>
                                                                                    </svg>
                                                                                </span>
                                                                            }
                                                                            {
                                                                                agent.image &&
                                                                                <div className="flex-shrink-0 h-10 w-10">
                                                                                    <img className="h-10 w-10 rounded-full" src={agent.image} alt="" />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.renders.legacyUI() &&
                                                                                <div className="ml-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.name}</div>
                                                                                    <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap hover:text-purple-500 hover:underline">
                                                                                        <a href={this.renders.getListingUrl(agent)} target="_blank" >#{agent.reference}</a>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !this.renders.legacyUI() &&
                                                                                <div className="ml-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1 ? agent.projectName : agent.name}</div>
                                                                                    <div className="text-sm text-gray-500 whitespace-no-wrap">
                                                                                        #{agent.reference}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.permit_id ? agent.permit_id : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Permit</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.bedrooms}</div>
                                                                            <div className="text-sm text-gray-500">{this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1 ? "From bedrooms" : "Bedrooms"}</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.bathrooms ? agent.bathrooms : '-'}</div>
                                                                            <div className="text-sm text-gray-500">{this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1 ? "From bathrooms" : "Bathrooms"}</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{this.renders.price(agent.price)} {agent.currency}</div>
                                                                            <div className="text-sm text-gray-500">{this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1 ? "From price" : "Price"}</div>
                                                                        </td>
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            this.renders.showLeads() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.totalLeads ? agent.totalLeads : '-'}</div>
                                                                                <div className="text-sm text-gray-500">Leads</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            this.renders.basicUI() && this.renders.proptreeUI() &&
                                                                            !this.renders.legacyUI() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.totalLeads ? agent.totalLeads : '-'}</div>
                                                                                <div className="text-sm text-gray-500">Inquiries</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            this.renders.legacyUI() &&
                                                                            this.renders.showLeads() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.totalActions ? agent.totalActions : '-'}</div>
                                                                                <div className="text-sm text-gray-500">Actions</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.legacyUI() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{moment(agent.internalCreated).format("YYYY-MM-DD") ? moment(agent.internalCreated).format("YYYY-MM-DD") : '-'}</div>
                                                                                <div className="text-sm text-gray-500">Created</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.legacyUI() && false &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{moment(agent.internalUpdated).format("YYYY-MM-DD") ? moment(agent.internalUpdated).format("YYYY-MM-DD") : '-'}</div>
                                                                                <div className="text-sm text-gray-500">Updated</div>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                    <tr key={agent.email} className={(agent.open ? "h-100 border-b" : "h-0 overflow-hidden") + " transition-height duration-500 ease-in-out border-gray-100 relative"}>
                                                                        {
                                                                            agent.open &&
                                                                            <div className="absolute w-full bg-gray-50 h-100 flex items-center">
                                                                                <div className="px-6 flex flex-row overflow-x-auto">
                                                                                    {
                                                                                        agent.feedImages.map((item) => {
                                                                                            return (
                                                                                                <div className="text-center">
                                                                                                    <div style={{ width: '250px', height: "250px" }} className=" mr-6 bg-white">
                                                                                                        <img className="w-full" src={item.image} alt="" />
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={"cursor-pointer mt-4 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md hover:text-purple-500 text-gray-700 bg-white hover:bg-purple-100"}
                                                                                                        onClick={() => {
                                                                                                            this.functions.downloadImage(item.image);
                                                                                                        }} >Download</div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </tr>
                                                                </Fragment>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                {
                                                    this.state.users.length > 0 &&
                                                    <div className="hidden sm:block ml-2">
                                                        <p className="text-gray-700 text-sm">
                                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state[this.state.tab.value] ? this.state[this.state.tab.value] : (this.state.page * this.state.page_count)}</span> of{' '}
                                                            <span className="font-medium">{this.state[this.state.tab.value]}</span> results
                                                        </p>
                                                    </div>
                                                }
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100, 200, 300, 400].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                                                        this.functions.getListings();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.page !== 1) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                                }, () => {
                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                    this.functions.getListings();
                                                                });
                                                            }
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) < this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                    this.functions.getListings();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div>
        )
    }
}

export default ListListings;
