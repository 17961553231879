import React, { Component, Fragment } from 'react';
import { MinusCircleIcon, PlusIcon, SaveAsIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon, ChatIcon, SaveIcon } from '@heroicons/react/outline';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import { versionRegister } from '../../services/versionRegister';
import SingleSelection from '../../components/singleSelection';
import moment from 'moment';
import CreateClient from '../../components/createClient';
import CreateLead from '../../components/createLead';
import Input from '../../components/input';
import Dropdown from '../../components/dropdown';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            client: {},
            tab: { id: 1, name: "Client", value: 'client' },
            leads: [],
            lead: { id: 1, name: "Qualified", value: 'qualified', count: 0 },
            sort: 'createdAt',
            order: 'descending',
            page: 1,
            page_count: 10,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getClient();
        this.functions.getLeads();
    }

    functions = {
        getClient: async () => {
            try {
                let id = window.location.href.split("/")[5];
                let response = await this.calls.getClient(id);
                await this.promisedSetState({
                    client: response.data,
                    loading: false
                })
            } catch (error) {
                console.log(error);
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false
                })
            }
        },
        getLeads: async () => {
            try {
                let id = window.location.href.split("/")[5];
                await this.promisedSetState({ loading_leads: true });
                let response = await this.calls.getLeads(id);
                this.setState({
                    leads: response.data,
                    qualified_: response.meta ? response.meta.qualified_ : 0,
                    unqualified: response.meta ? response.meta.unqualified : 0,
                    loading_leads: false,
                })
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_leads: false,
                })
            }
        }
    };

    calls = {
        getClient: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/client/getClient?id=" + id + "&activity=true";
            return apiRegister.call(options, url);
        },
        getLeads: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/lead/listLeads?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&sortBy=" + this.state.sort + "&type=" + this.state.lead.value + "&client=" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="grid grid-cols-12 min-h-full">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 h-64 relative flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading client ...</div>
                        </div>
                    </div>
                }

                {/*CREATE USER*/}
                <CreateLead
                    user={this.state.selected}
                    open={this.state.open}
                    toggle={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }}
                    onLead={(user) => {
                        this.functions.getLeads();
                    }}
                    onUpdate={(user) => {
                        this.functions.getLeads();
                    }}
                />

                {/*SIDE MENU*/}
                {
                    !this.state.loading &&
                    <div className="col-span-2 pr-6">
                        <nav className="space-y-1">
                            {[
                                { id: 1, name: "Client", value: 'client' },
                                { id: 2, name: "Activity", value: 'activity' },
                                { id: 3, name: "Leads", value: 'leads' },
                            ].map((item) => (
                                <div
                                    key={item.name}
                                    onClick={async () => {
                                        await this.promisedSetState({
                                            tab: item,
                                            page: 1
                                        });
                                        this.functions.getLeads();
                                    }}
                                    className={classNames(
                                        item.id == this.state.tab.id
                                            ? 'text-purple-500 bg-white'
                                            : ' hover:text-gray-900 ',
                                        'group rounded-md px-3 text-gray-700 py-2 flex items-center text-sm font-medium cursor-pointer'
                                    )}
                                    aria-current={item.id == this.state.tab.id ? 'page' : undefined}
                                >
                                    <div className="truncate flex flex-1">{item.name}</div>
                                    <div>{item.count}</div>
                                </div>
                            ))}
                        </nav>
                    </div>
                }

                {/*AGENTS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-10">
                        <div className="">
                            {
                                <section className="bg-white shadow-lg rounded-lg">
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.client.name}
                                        </div>
                                        <div onClick={() => {

                                        }} className="text-sm font-medium cursor-pointer items-center flex flex-row text-purple-500 hover:text-purple-700">
                                            <SaveIcon className="w-5 h-5 mr-2" />
                                            Save client
                                        </div>
                                    </div>
                                    <div className="p-4 px-6">
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-4">
                                                <Input
                                                    label={"Name"}
                                                    onChange={(value) => {
                                                        this.state.client.name = value;
                                                        this.setState({
                                                            client: this.state.client
                                                        })
                                                    }}
                                                    value={this.state.client.name}
                                                    type={"text"}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <Input
                                                    label={"Email"}
                                                    locked={true}
                                                    readOnly={true}
                                                    onChange={(value) => {

                                                    }}
                                                    value={this.state.client.email}
                                                    type={"text"}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <Input
                                                    label={"Phone"}
                                                    onChange={(value) => {
                                                        this.state.client.phone = value;
                                                        this.setState({
                                                            client: this.state.client
                                                        })
                                                    }}
                                                    value={this.state.client.phone}
                                                    type={"text"}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <Dropdown
                                                    label={"Country"}
                                                    selected={this.state.client.country ? this.state.client.country : { id: 0, name: "Unknown" }}
                                                    options={[
                                                        { id: 0, name: "Unknown" },
                                                        { id: 1, name: 'Sweden', value: "SE" },
                                                        { id: 2, name: 'Denmark', value: "DK" }
                                                    ]}
                                                    searchInput={true}
                                                    onChange={(option) => {
                                                        this.state.client.country = option;
                                                        this.setState({
                                                            client: this.state.client
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <Dropdown
                                                    label={"Gender"}
                                                    selected={this.state.client.gender ? this.state.client.gender : { id: 0, name: "Unknown" }}
                                                    options={[
                                                        { id: 0, name: "Unknown" },
                                                        { id: 1, name: 'Male' },
                                                        { id: 2, name: 'Female' }
                                                    ]}
                                                    searchInput={true}
                                                    onChange={(option) => {
                                                        this.state.client.country = option;
                                                        this.setState({
                                                            client: this.state.client
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <Dropdown
                                                    label={"Age"}
                                                    selected={this.state.client.age ? this.state.client.age : { id: 0, name: "Unknown" }}
                                                    options={[
                                                        { id: 0, name: "Unknown" },
                                                        { id: 1, name: '20-30' },
                                                        { id: 2, name: '31-40' },
                                                        { id: 3, name: '41-50' },
                                                        { id: 4, name: '51-60' },
                                                        { id: 5, name: '61-70' },
                                                        { id: 6, name: '71-80' },
                                                        { id: 7, name: '81-90' },
                                                        { id: 9, name: '91-100' },
                                                        { id: 10, name: '+100' }
                                                    ]}
                                                    searchInput={true}
                                                    onChange={(option) => {
                                                        this.state.client.age = option;
                                                        this.setState({
                                                            client: this.state.client
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            Activity last 30 days
                                        </div>
                                        {
                                            false &&
                                            <div onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    selected: null
                                                })
                                            }} className="text-sm  font-medium cursor-pointer items-center flex flex-row text-red-500 hover:text-red-700">
                                                <MinusCircleIcon className="w-5 h-5 mr-2" />
                                                Remove client
                                            </div>
                                        }
                                    </div>
                                    <div className="w-full px-6 mt-6 mb-6">
                                        <div className="h-64">
                                            <Line
                                                options={{
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                stacked: true,
                                                                ticks: {
                                                                    beginAtZero: true,
                                                                    precision: 0,
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    title: {
                                                        display: false
                                                    },
                                                    maintainAspectRatio: false,
                                                }}
                                                data={{
                                                    labels: this.state.client.activity_last_30 ? Object.keys(this.state.client.activity_last_30) : [],
                                                    datasets: [{
                                                        label: 'Actions',
                                                        borderColor: 'rgb(69, 63, 241)',
                                                        backgroundColor: 'rgba(69, 63, 241, 0.5)',
                                                        data: this.state.client.activity_last_30 ? Object.keys(this.state.client.activity_last_30).map((key) => { return this.state.client.activity_last_30[key] }) : []
                                                    }]
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            Leads
                                        </div>
                                        <div className="flex flex-row">
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    lead: { id: 1, name: "Qualified", value: 'qualified', count: 0 }
                                                });
                                                this.functions.getLeads();
                                            }} className={(this.state.lead.value === "qualified" ? "text-purple-500 " : "text-gray-500 hover:text-gray-900") + " text-sm mr-4 font-medium cursor-pointer items-center flex flex-row"}>
                                                Qualified
                                            </div>
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    lead: { id: 2, name: "Unqualified", value: 'unqualified', count: 0 }
                                                });
                                                this.functions.getLeads();
                                            }} className={(this.state.lead.value === "unqualified" ? "text-purple-500 " : "text-gray-500 hover:text-gray-900") + " text-sm font-medium cursor-pointer items-center flex flex-row"}>
                                                Unqualified
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.leads.length < 1 &&
                                        <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_leads &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">{this.state.lead.name}</span> available</div>
                                            }
                                            {
                                                this.state.loading_leads &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.leads.length > 0 &&
                                        <div className="relative">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Listing</span>
                                                        </th>
                                                        {
                                                            false &&
                                                            <th scope="col" className="">
                                                                <span className="sr-only">Client</span>
                                                            </th>
                                                        }
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Agent</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Status</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Actions</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Updated</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.leads.map((agent, index) => {
                                                            return (
                                                                <tr key={agent.email} className="border-b border-gray-100">
                                                                    <td className="flex items-center px-6 py-4">
                                                                        {
                                                                            agent.listing &&
                                                                            !agent.listing.image &&
                                                                            <div className="flex-shrink-0 h-10 w-10 rounded-full flex items-center justify-center bg-purple-100">
                                                                                <ChatIcon className="w-5 h-5 text-purple-500" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            agent.listing &&
                                                                            agent.listing.image &&
                                                                            <div className="flex-shrink-0 h-10 w-10">
                                                                                <img className="h-10 w-10 rounded-full" src={agent.listing.image} alt="" />
                                                                            </div>
                                                                        }
                                                                        <div className="ml-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.listing ? agent.listing.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">#{agent.listing ? agent.listing.reference : "-"}</div>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        false &&
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="text-sm text-gray-900">{agent.client ? agent.client.name : '...'}</div>
                                                                            <div className="text-sm text-gray-500">Client</div>
                                                                        </td>
                                                                    }
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900">{agent.broker ? agent.broker.name : '-'}</div>
                                                                        <div className="text-sm text-gray-500">Agent</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900">{agent.status ? agent.status.name : '-'}</div>
                                                                        <div className="text-sm text-gray-500">Status</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900">{agent.actions ? agent.actions : 0}</div>
                                                                        <div className="text-sm text-gray-500">Actions</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4">
                                                                        <div className="text-sm text-gray-900">{moment(agent.lastUpdated).format("YYYY-MM-DD")}</div>
                                                                        <div className="text-sm text-gray-500">Updated</div>
                                                                    </td>
                                                                    <td className="text-right px-6">

                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_leads &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className={(this.state.leads.length > 0 ? "" : "opacity-0") + " hidden sm:block"}>
                                                    <p className="text-sm text-gray-700">
                                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.leads.length ? this.state.leads.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                                        <span className="font-medium">{this.state.total}</span> results
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={((this.state.sequence_loading || this.state.leads.length < 1) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            {
                                                                this.state.leads.length > 0 &&
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="py-1">
                                                                            {
                                                                                [1, 10, 20, 30, 50, 100].map((item) => {
                                                                                    return (
                                                                                        <Menu.Item>
                                                                                            {({ active }) => (
                                                                                                <div
                                                                                                    onClick={async () => {
                                                                                                        await this.promisedSetState({
                                                                                                            page_count: item,
                                                                                                            page: 1
                                                                                                        });
                                                                                                        this.functions.getLeads();
                                                                                                    }}
                                                                                                    className={classNames(
                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                        'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                        this.state.page_count == item ? "text-purple-500" : ""
                                                                                                    )}
                                                                                                >
                                                                                                    {item}
                                                                                                </div>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            }
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={async () => {
                                                            await this.promisedSetState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            });
                                                            this.functions.getLeads();
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={async () => {
                                                            if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                await this.promisedSetState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                });
                                                                this.functions.getLeads();
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default Client;
