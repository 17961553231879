import React, { Component, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, LockClosedIcon, SelectorIcon } from '@heroicons/react/solid'

class TextArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            label: "",
            disabled: false,
            locked: false
        }
    };

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : "",
            label: this.props.label,
            type: this.props.type,
            disabled: this.props.disabled,
            locked: this.props.locked,
            dontResize: this.props.dontResize
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value ? nextProps.value : "",
            label: nextProps.label,
            type: nextProps.type,
            disabled: nextProps.disabled,
            locked: nextProps.locked,
            dontResize: nextProps.dontResize
        })
    }

    render() {
        return (
            <>
                <div>
                    <label htmlFor="area-label" className="block text-sm font-medium text-gray-900">
                        {this.state.label}
                    </label>
                    <div className="mt-1">
                        <textarea
                            style={this.state.dontResize ? { resize: "none" } : {}}
                            onChange={(event) => {
                                if (this.props.onChange) {
                                    this.props.onChange(event.target.value);
                                }
                            }}
                            value={this.state.value}
                            type={this.state.type ? this.state.type : "text"}
                            name="area-label"
                            id="area-label"
                            rows={this.props.rows ? this.props.rows : 5}
                            onBlur={() => {
                                if (this.props.onBlur) {
                                    this.props.onBlur();
                                }
                            }}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default TextArea;
