import React, { Component, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, LockClosedIcon, SelectorIcon } from '@heroicons/react/solid'

class Input extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            label: "",
            disabled: false,
            locked: false
        }
    };

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : "",
            label: this.props.label,
            type: this.props.type,
            disabled: this.props.disabled,
            locked: this.props.locked,
            rightLabel: this.props.rightLabel
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value ? nextProps.value : "",
            label: nextProps.label,
            type: nextProps.type,
            disabled: nextProps.disabled,
            locked: nextProps.locked,
            rightLabel: nextProps.rightLabel
        })
    }

    render() {
        return (
            <>
                <div>
                    <label htmlFor="project-name" className="flex flex-row text-sm font-medium text-gray-900">
                        <div>{this.state.label}</div>
                        <div className="flex flex-1"></div>
                        {
                            this.state.rightLabel &&
                            <div onClick={() => {
                                if (this.props.onRemove) {
                                    this.props.onRemove();
                                }
                            }} className="text-red-500 cursor-pointer hover:text-red-700">
                                remove
                            </div>
                        }
                    </label>
                    <div className="mt-1">
                        <input
                            onChange={(event) => {
                                if (this.props.onChange) {
                                    this.props.onChange(event.target.value);
                                }
                            }}
                            value={this.state.value}
                            type={this.state.type ? this.state.type : "text"}
                            name="project-name"
                            id="project-name"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default Input;
