import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import Input from './input';
import Dropdown from './dropdown';
import TextArea from './textArea';
import InputDatepickerTailwind from './inputDatepickerTailwind';
var moment = require('moment');

class CreateCustomCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            user: {},
            message: "",
            listings: [],
            agents: []
        }
    };

    componentDidMount() { }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.state.user = (nextProps.user ? nextProps.user : {});
            this.functions.getListings();
        }
        this.setState({
            user: this.state.user,
            open: nextProps.open
        })
    }

    functions = {
        getListings: async () => {
            try {
                await this.promisedSetState({ loading_listings: true });
                let response = await this.calls.getListings();
                await this.promisedSetState({
                    listings: response.data
                });
            } catch (error) { }
            await this.promisedSetState({ loading_listings: false });
        },
        createLead: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let response = await this.calls.createLead(this.state.user);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onLead) {
                    this.props.onLead();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        updateLead: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.updateLead(this.state.user);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onLead) {
                    this.props.onLead();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        }
    }

    calls = {
        getListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/listings";
            return apiRegister.call(options, url);
        },
        createLead: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/custom-campaign/createCustomCampaign";
            return apiRegister.call(options, url);
        },
        updateLead: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/lead/updateLead?id=" + this.state.user.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-4xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.user.id ? "Update campaign" : "New campaign"}</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="px-4 sm:px-6 pt-6">
                                            <div className="grid grid-cols-12 gap-4">
                                                <div className={"col-span-12"}>
                                                    <Dropdown
                                                        label={"Listing"}
                                                        selected={this.state.user.listing ? this.state.user.listing : { id: 0, name: 'Select listing' }}
                                                        options={this.state.listings.map((item) => {
                                                            item.name = item.reference;
                                                            return item;
                                                        })}
                                                        locked={this.state.user.id}
                                                        loader={this.state.loading_listings}
                                                        searchInput={true}
                                                        onChange={(option) => {
                                                            this.state.user.listing = option;
                                                            this.state.agents.map((item) => {
                                                                if (item.id === option.broker) {
                                                                    this.state.user.agent = item;
                                                                }
                                                            })
                                                            this.setState({
                                                                user: this.state.user
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-4">
                                                    <Input
                                                        label={"Receiver Name"}
                                                        onChange={(value) => {
                                                            this.state.user.name = value;
                                                            this.setState({
                                                                user: this.state.user
                                                            })
                                                        }}
                                                        value={this.state.user.name}
                                                        type={"text"}
                                                    />
                                                </div>
                                                <div className="col-span-4">
                                                    <Input
                                                        label={"Receiver Email"}
                                                        onChange={(value) => {
                                                            this.state.user.email = value;
                                                            this.setState({
                                                                user: this.state.user
                                                            })
                                                        }}
                                                        value={this.state.user.email}
                                                        type={"text"}
                                                    />
                                                </div>
                                                <div className={"col-span-4"}>
                                                    <Dropdown
                                                        label={"Package"}
                                                        selected={this.state.user.package ? this.state.user.package : { id: 1, name: '10 days, 2.000 AED', value: "one" }}
                                                        options={[
                                                            { id: 1, name: '10 days, 2.000 AED', value: "one" },
                                                            { id: 2, name: '10 days, 4.000 AED', value: "two" },
                                                            { id: 3, name: '15 days, 6.000 AED', value: "three" },
                                                            { id: 4, name: '15 days, 10.000 AED', value: "four" }
                                                        ]}
                                                        locked={this.state.user.id}
                                                        searchInput={true}
                                                        onChange={(option) => {
                                                            this.state.user.package = option;
                                                            this.setState({
                                                                user: this.state.user
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    this.state.user.listing && this.state.user.listing.id !== 0 &&
                                                    <div className="flex justify-center items-center col-span-4 mt-5">
                                                        <div className="text-center">
                                                            <div className="text-sm font-medium mb-2">1:1</div>
                                                            <div className="w-56">
                                                                <img className="w-full h-full" src={this.state.user.listing.promoted_image} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.user.listing && this.state.user.listing.id !== 0 &&
                                                    <div className="flex justify-center items-center col-span-4 mt-5">
                                                        <div className="text-center">
                                                            <div className="text-sm font-medium mb-2">9:16</div>
                                                            <div className="w-56">
                                                                <img className="w-full h-full" src={this.state.user.listing.promoted_image} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.user.listing && this.state.user.listing.id !== 0 &&
                                                    <div className="flex justify-center items-center col-span-4 mt-5">
                                                        <div className="text-center">
                                                            <div className="text-sm font-medium mb-2">1.91:1</div>
                                                            <div className="w-56">
                                                                <img className="w-full h-full" src={this.state.user.listing.promoted_image} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    <div className={"col-span-6"}>
                                                        <Dropdown
                                                            label={"Ad Language"}
                                                            selected={this.state.user.language ? this.state.user.language : { id: 0, name: 'Select language' }}
                                                            options={[
                                                                { id: 1, name: "English", value: 'EN' }
                                                            ]}
                                                            locked={this.state.user.id}
                                                            searchInput={true}
                                                            onChange={(option) => {
                                                                this.state.user.language = option;
                                                                this.setState({
                                                                    user: this.state.user
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    <div className={"col-span-6"}>
                                                        <Dropdown
                                                            label={"Ad Currency"}
                                                            selected={this.state.user.currency ? this.state.user.currency : { id: 0, name: 'Select currency' }}
                                                            options={[
                                                                { id: 1, name: "AED", value: 'AED' },
                                                                { id: 2, name: "USD", value: 'USD' }
                                                            ]}
                                                            locked={this.state.user.id}
                                                            searchInput={true}
                                                            onChange={(option) => {
                                                                this.state.user.currency = option;
                                                                this.setState({
                                                                    user: this.state.user
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    this.state.user.id &&
                                                    <div className="col-span-4">
                                                        <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                                                            Status
                                                        </label>
                                                        <div className="mt-1">
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    this.state.user.status = option;
                                                                    this.setState({
                                                                        user: this.state.user
                                                                    })
                                                                }}
                                                                selected={this.state.user.status ? this.state.user.status : { id: 1, name: "New", value: "new" }}
                                                                options={[
                                                                    { id: 1, name: "New", value: "new" },
                                                                    { id: 2, name: "Accepted", value: "accepted" },
                                                                    { id: 3, name: "Closed", value: "closed" }
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="flex-1 h-0 overflow-y-auto">
                                            {
                                                this.state.error &&
                                                <div className="px-6 pb-6 mt-6">
                                                    <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <ul role="list" className="list-disc pl-5 space-y-1">
                                                                        <li>{this.state.error}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-shrink-0 px-4 sm:px-6 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    if (this.state.user.id) {
                                                        this.functions.updateLead();
                                                    } else {
                                                        this.functions.createLead();
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.user.id &&
                                                    <span>Create</span>
                                                }
                                                {
                                                    this.state.user.id &&
                                                    <span>Update</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateCustomCampaign;
