import React, { Component } from 'react';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';

class WhatsappMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    };

    componentDidMount() {
        this.functions.getWhatsappRedirect();
    }

    functions = {
        getWhatsappRedirect: async () => {
            try {
                let response = await this.calls.getWhatsappRedirect();
                window.open(response.data.message, "_self");
            } catch (error) {
                this.setState({
                    message: error.body ? error.body.message : "",
                    error: true,
                    loading: false
                })
            }
        }
    };

    calls = {
        getWhatsappRedirect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/lead/getWhatsAppRedirect?lead=" + this.props.match.params.lead;
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <>
                <div className="w-full h-full flex justify-center items-start bg-white">
                    <div className="w-full h-full absolute bg-white top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                        <div style={{ borderTopColor: "transparent" }}
                            class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                    </div>
                </div>
            </>
        )
    }
}

export default WhatsappMessage;
