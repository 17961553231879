import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon, ShareIcon } from '@heroicons/react/outline'
import Form from '../pages/public/form';

class ListingModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            formId: this.props.formId,
            feedId: this.props.feedId,
            listingId: this.props.listingId,
            proptreeId: this.props.proptreeId,
            proptreeIdExist: this.props.proptreeIdExist,
            currency: this.props.currency
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            formId: nextProps.formId,
            feedId: nextProps.feedId,
            listingId: nextProps.listingId,
            proptreeId: nextProps.proptreeId,
            proptreeIdExist: nextProps.proptreeIdExist,
            currency: nextProps.currency
        });
    }

    renders = {
        fallbackCopyTextToClipboard: (text) => {
            let textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
                alert('Proptree copied to clipboard!');
            } catch (err) {
                console.error('Copy to clipboard failed');
            }
            document.body.removeChild(textArea);
        }
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed z-60 inset-0 overflow-y-auto" onClose={() => {
                    if (this.props.onClose) {
                        //this.props.onClose();
                    }
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block relative align-top sm:py-10 transform transition-all md:w-140 w-full">
                                <div className="flex flex-row items-center justify-center">
                                    {
                                        this.state.showCloseError &&
                                        <div className="flex items-center justify-center mr-2">
                                            <div style={{
                                                zIndex: "999",
                                                top: "13px",
                                                right: "-5px"
                                            }} onClick={() => {
                                                if (this.props.onClose) {
                                                    this.setState({
                                                        showCloseError: false
                                                    });
                                                    this.props.onClose();
                                                }
                                            }} className="relative cursor-pointer rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red">
                                                <XIcon className="w-6" />
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.showClose &&
                                        <div className="flex items-center justify-center mr-2">
                                            <div style={{
                                                zIndex: "999",
                                                top: "13px",
                                                right: "-5px"
                                            }} onClick={() => {
                                                if (this.props.onClose) {
                                                    this.setState({
                                                        showClose: false
                                                    });
                                                    this.props.onClose();
                                                }
                                            }} className="relative cursor-pointer rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red">
                                                <XIcon className="w-6" />
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.showClose &&
                                        <div className="flex items-center justify-center">
                                            <a href={""} style={{
                                                zIndex: "999",
                                                top: "13px",
                                                right: "-5px"
                                            }} onClick={(e) => {

                                                e.preventDefault();
                                                let shareUrl = window.location.origin + window.location.pathname + '?utm_medium=listing' + '&utm_listing=' + encodeURIComponent(this.state.listingId) + '&utm_form=' + encodeURIComponent(this.state.formId) + '&utm_feed=' + encodeURIComponent(this.state.feedId) + '&utm_proptree=' + encodeURIComponent(this.state.proptreeId) + '&utm_exist=' + encodeURIComponent(this.state.proptreeIdExist);

                                                // Check if navigator.share is supported
                                                if (navigator.share) {
                                                    navigator.share({
                                                        title: 'Check out this listing !',
                                                        //text: 'Share this link',
                                                        url: shareUrl
                                                    }).then(() => {
                                                        //console.log('Shared successfully');
                                                    }).catch((error) => {
                                                        //TODO: change state triggers refresh
                                                        //this.setState({ linkCopied: true });
                                                        //this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                    });
                                                } else if (navigator.clipboard && navigator.clipboard.writeText) {
                                                    // Fallback to clipboard API if navigator.share is not supported
                                                    navigator.clipboard.writeText(shareUrl).then(() => {
                                                        this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                    }).catch((error) => {
                                                        //TODO: change state triggers refresh
                                                        //this.setState({ linkCopied: true });
                                                        this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                    });
                                                } else {
                                                    //TODO: change state triggers refresh
                                                    //this.setState({ linkCopied: true });
                                                    this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                }

                                            }} className="relative cursor-pointer rounded-full h-12 w-12 flex items-center justify-center text-gray-600 bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray">
                                                <ShareIcon className="w-6" />
                                            </a>
                                        </div>
                                    }
                                </div>
                                <div className="bg-white rounded-lg relative shadow-xl overflow-hidden">
                                    {
                                        this.state.open &&
                                        <Form
                                            proptree={true}
                                            formId={this.state.formId}
                                            feedId={this.state.feedId}
                                            listingId={this.state.listingId}
                                            proptreeId={this.state.proptreeId}
                                            currency={this.state.currency}
                                            onLoaded={() => {
                                                this.setState({
                                                    showClose: true
                                                })
                                            }}
                                            onError={(error) => { // Handle the error state
                                                console.log("error");
                                                this.setState({
                                                    showCloseError: true
                                                });
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default ListingModal;
