import React, { Component } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';

class LoginToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    };

    componentDidMount() {
        this.functions.loginUser();
    }

    functions = {
        loginUser: async () => {
            try {
                let response = await this.calls.loginToken();
                userRegister.set(response.data);
                if (window.location.origin.indexOf('localhost') !== -1) {
                    this.props.history.push("/v1-proptree");
                } else {
                    this.props.history.push("/v1");
                }
            } catch (error) {
                this.setState({
                    message: error.body ? error.body.message : "",
                    error: true,
                    loading: false
                })
            }
        }
    };

    calls = {
        loginToken: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.proptree + "/loginTokenProptree/" + this.props.match.params.token;
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <>
                {
                    this.state.loading &&
                    <div className="w-full h-full flex justify-center items-start bg-white">
                        <div className="w-full h-full absolute bg-white top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.error &&
                    <div className="w-full h-full flex justify-center items-start bg-white">
                        <div className="w-full h-full absolute bg-white top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                            <div className="text-sm text-red-400 bg-red-100 p-4 rounded-md font-medium">
                                {this.state.message}
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default LoginToken;
