import { EyeIcon, CursorClickIcon, ArrowRightIcon, ExternalLinkIcon, OfficeBuildingIcon, SelectorIcon } from '@heroicons/react/outline';
import { HeartIcon } from '@heroicons/react/solid';
import React, { Component, Fragment } from 'react';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';

class LinkTreePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_listings: true,
            listings: [],
            agent: {},
            page: 1,
            limit: 4,
            orderBy: 'descending',
            sortBy: 'createdAt'
        }
    };

    componentDidMount() {
        this.functions.getProfile();
        this.functions.getListings(true);
    }

    functions = {
        getProfile: async () => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.getProfile();
                this.setState({
                    agent: response.data
                });
            } catch (error) { }
            await this.promisedSetState({ loading: false });
        },
        getListings: async (init) => {
            await this.promisedSetState({ loading_listings: true });
            try {
                let response = await this.calls.getListings();
                this.setState({
                    listings: init ? response.data : this.state.listings.concat(response.data),
                    total: response.meta ? response.meta.total : 0
                });
            } catch (error) { }
            await this.promisedSetState({ loading_listings: false });
        }
    };

    calls = {
        getProfile: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/proptree/profile?id=627ec9c5f4f5e16a4fafc9c5";
            return apiRegister.call(options, url);
        },
        getListings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/proptree/listings?profile=627ec9c5f4f5e16a4fafc9c5" + "&page=" + this.state.page + "&limit=" + this.state.limit + "&orderBy=" + this.state.orderBy + "&sortBy=" + this.state.sortBy;
            return apiRegister.call(options, url);
        }
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="h-full min-h-full">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/*HEADER*/}
                {
                    !this.state.loading &&
                    <div className="flex relative pb-12 flex-col justify-center items-center sm:pt-10 px-8">
                        <div className="w-48 h-48 rounded-full border bg-purple-400 mb-5 overflow-hidden">
                            <img src={this.state.agent.image} className="w-full h-full" />
                        </div>
                        <div className="font-bold text-3xl sm:text-4xl text-center">
                            {this.state.agent.name}
                        </div>
                        <div className="font-medium text-gray-500 text-md sm:text-lg text-center">
                            {this.state.agent.agency_name}
                        </div>
                    </div>
                }

                {/*BUTTONS*/}
                {
                    !this.state.loading &&
                    <div className="sm:px-8 max-w-sm mx-auto mb-10">
                        <div className="h-12 bg-white mb-4 rounded-lg shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                            <div>{this.state.agent.agency_name} Website</div>
                            <div className="flex flex-1"></div>
                            <ExternalLinkIcon className="h-5 w-5" />
                        </div>
                        <a href={""}>
                            <div className="h-12 bg-white mb-4 rounded-lg shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                                <div>WhatsApp Me</div>
                                <div className="flex flex-1"></div>
                                <ArrowRightIcon className="h-5 w-5" />
                            </div>
                        </a>
                        <div className="h-12 bg-white mb-4 rounded-lg shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                            <div>Email Me</div>
                            <div className="flex flex-1"></div>
                            <ArrowRightIcon className="h-5 w-5" />
                        </div>
                        <div className="h-12 bg-white mb-4 rounded-lg shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                            <div>My Top 10 Properties</div>
                            <div className="flex flex-1"></div>
                            <OfficeBuildingIcon className="h-5 w-5" />
                        </div>
                        <div className="h-12 bg-white rounded-lg shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                            <div>Best investments</div>
                            <div className="flex flex-1"></div>
                            <OfficeBuildingIcon className="h-5 w-5" />
                        </div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="sm:px-8 max-w-sm mx-auto text-left mb-6 sm:hidden">
                        <div className="font-medium">My listings</div>
                        <div className="font-medium text-gray-500 mb-2">15 For Sale and 10 Rentals</div>
                        <div className="h-12 bg-white rounded-lg shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                            <div>All listings</div>
                            <div className="flex flex-1"></div>
                            <SelectorIcon className="h-5 w-5" />
                        </div>
                    </div>
                }

                {
                    !this.state.loading &&
                    this.state.listings.length > 0 &&
                    <div className="max-w-sm sm:max-w-8xl mx-auto mb-10 hidden sm:block">
                        <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            <div className="col-span-1 max-w-sm">
                                <div className="w-full">
                                    <div className="font-medium mb-2">Sort</div>
                                    <div className="h-12 bg-white rounded-lg shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                                        <div>Newest</div>
                                        <div className="flex flex-1"></div>
                                        <SelectorIcon className="h-5 w-5" />
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-span-2 md:col-span-1">

                            </div>
                            <div className="col-span-1 max-w-sm">
                                <div className="w-full">
                                    <div className="font-medium mb-2">Filter</div>
                                    <div className="h-12 bg-white rounded-lg shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                                        <div>All listings</div>
                                        <div className="flex flex-1"></div>
                                        <SelectorIcon className="h-5 w-5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*LISTINGS*/}
                {
                    !this.state.loading &&
                    <div className="max-w-sm sm:max-w-8xl mx-auto">
                        <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {
                                (this.state.listings.length > 0 ? this.state.listings : [{ skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }]).map((item) => {
                                    return (
                                        <div className="col-span-1 max-w-sm">
                                            <div className="relative">
                                                <div className="absolute p-3">
                                                    <div className="inline-flex items-center flex-row px-2 py-1 leading-none bg-gray-800 bg-opacity-25 rounded-full font-semibold uppercase tracking-wide text-xs">
                                                        <HeartIcon className="text-red-500 h-4 w-4" />
                                                        <div className="text-white font-medium text-xs ml-1">70</div>
                                                        <EyeIcon className="text-gray-200 h-4 w-4 ml-2" />
                                                        <div className="text-white font-medium text-xs ml-1">250</div>
                                                    </div>
                                                </div>
                                                <div className={(item.skeleton ? "animate-pulse bg-gray-200" : "") + " h-64 w-full rounded-lg"}>
                                                    {
                                                        !item.skeleton &&
                                                        <img className="inset-0 h-full w-full rounded-lg shadow-md object-cover" src={item.imagesInternal[0]} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="relative px-4 -mt-16">
                                                <div className="bg-white rounded-lg px-4 py-4 shadow-lg">
                                                    <div className="flex items-baseline">
                                                        {
                                                            item.skeleton &&
                                                            <span class="animate-pulse inline-block px-2 py-1 bg-gray-200 leading-none  text-gray-200 text-opacity-0 rounded-full font-semibold uppercase tracking-wide text-xs">Loading</span>
                                                        }
                                                        {
                                                            !item.skeleton &&
                                                            item.forRent &&
                                                            <span class="inline-block px-2 py-1 leading-none bg-teal-200 text-teal-800 rounded-full font-semibold uppercase tracking-wide text-xs">For Rent</span>
                                                        }
                                                        {
                                                            !item.skeleton &&
                                                            item.forSale &&
                                                            <span class="inline-block px-2 py-1 leading-none bg-purple-200 text-purple-800 rounded-full font-semibold uppercase tracking-wide text-xs">For Sale</span>
                                                        }
                                                        {
                                                            !item.skeleton &&
                                                            <div class="ml-2 text-xs text-gray-600 font-semibold uppercase tracking-wide">
                                                                {item.bedrooms} beds • {item.bathrooms} baths
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        item.skeleton &&
                                                        <div class="animate-pulse inline-block px-2 py-1 bg-gray-200 leading-none  text-gray-200 text-opacity-0 rounded-full font-semibold uppercase tracking-wide text-xs">Loading property listing ...</div>
                                                    }
                                                    {
                                                        !item.skeleton &&
                                                        <h4 class="mt-1 text-gray-900 font-semibold text-lg">Modern home in city center</h4>
                                                    }
                                                    {
                                                        item.skeleton &&
                                                        <div class="animate-pulse inline-block px-2 py-1 bg-gray-200 leading-none  text-gray-200 text-opacity-0 rounded-full font-semibold uppercase tracking-wide text-xs">Loading headline ...</div>
                                                    }
                                                    {
                                                        !item.skeleton &&
                                                        <div class="mt-1">
                                                            <span class="text-gray-900">AED {item.price}</span>
                                                            {
                                                                item.forRent &&
                                                                <span class="ml-1 text-sm text-gray-600">/wk</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {
                    this.state.listings.length > 0 &&
                    !this.state.loading &&
                    this.state.listings.length < this.state.total &&
                    <div className="sm:px-8 max-w-sm mx-auto mt-12">
                        <div onClick={async () => {
                            if (!this.state.loading_listings) {
                                await this.promisedSetState({
                                    page: this.state.page + 1
                                });
                                this.functions.getListings();
                            }
                        }} className="h-12 rounded-lg bg-white shadow border relative overflow-hidden cursor-pointer justify-center items-center px-6 flex flex-row">
                            <div>Load more</div>
                            {
                                this.state.loading_listings &&
                                <div className=" absolute bg-white left-0 right-0 top-0 bottom-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className=" h-10 w-full">

                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="w-full text-center">
                        <div className="font-medium text-sm cursor-pointer">
                            adBooster
                        </div>
                        <div className="font-medium text-sm text-gray-500">
                            Powered By
                        </div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="h-10 w-full">

                    </div>
                }

            </div>
        )
    }
}

export default LinkTreePage;
