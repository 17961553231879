import React, { Component } from 'react';

class SavillsDubaiSquareTemplateOne extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    };

    componentWillMount() { }

    componentDidMount() { }

    functions = {};

    render() {
        return (
            <div style={{ width: '1080px', height: '1080px' }} className="bg-gray-100">
                <div className="flex flex-col w-full h-full">
                    <div className="flex flex-1">
                        <div style={{
                            backgroundImage: 'url(' + 'https://64.media.tumblr.com/e275b9999904f00bb83ee2a177010cdf/tumblr_rj8ejxpJDg1qdqlnso1_500.jpg' + ')', backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            position: "relative",
                            width: '100%',
                            height: '100%'
                        }} />
                    </div>
                    <div className="grid grid-cols-3 col-gap-3 row-gap-3 p-3">
                        <div style={{
                            height: '344px'
                        }}>
                            <div className="rounded-md" style={{
                                backgroundImage: 'url(' + 'https://64.media.tumblr.com/e275b9999904f00bb83ee2a177010cdf/tumblr_rj8ejxpJDg1qdqlnso1_500.jpg' + ')', backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                position: "relative",
                                width: '100%',
                                height: '100%'
                            }} />
                        </div>
                        <div style={{
                            height: '344px'
                        }}>
                            <div className="rounded-md" style={{
                                backgroundImage: 'url(' + 'https://64.media.tumblr.com/e275b9999904f00bb83ee2a177010cdf/tumblr_rj8ejxpJDg1qdqlnso1_500.jpg' + ')', backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                position: "relative",
                                width: '100%',
                                height: '100%'
                            }} />
                        </div>
                        <div style={{
                            height: '344px'
                        }}>
                            <div className="rounded-md" style={{
                                backgroundImage: 'url(' + 'https://64.media.tumblr.com/e275b9999904f00bb83ee2a177010cdf/tumblr_rj8ejxpJDg1qdqlnso1_500.jpg' + ')', backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                position: "relative",
                                width: '100%',
                                height: '100%'
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SavillsDubaiSquareTemplateOne;
