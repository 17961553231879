import React, { Component, Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { ChevronDownIcon, DuplicateIcon, MinusCircleIcon, PencilAltIcon, PlusCircleIcon, StarIcon, TrashIcon } from '@heroicons/react/outline';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import Dropdown from '../../components/dropdown';
import CreateNewFeed from '../../components/createNewFeed';
import CreateForm from '../../components/createForm';
import CreateLeadNew from '../../components/createLeadNew';
import "../../../node_modules/flag-icons/css/flag-icons.min.css";
var moment = require('moment');

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
class ListForms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            meta: {},
            open: false,
            tab: { id: 'all', name: "All themes", value: 'all', count: 0 },
            status: { id: 'enabled', name: "Enabled", value: 'enabled' },
            feeds: [],
            user: {},
            sort: { id: 1, name: "Created", value: 'createdAt' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0,
            loading_feeds: false,
            open_lead: false
        }
    };

    componentDidMount() {
        this.functions.getUser();
        this.functions.getForms(true);
    }

    functions = {
        getUser: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get() ? userRegister.get() : {}
                })
            }
        },
        getForms: async (init) => {
            try {
                await this.promisedSetState({
                    loading: init,
                    loading_feeds: !init
                });
                let response = await this.calls.getForms();
                await this.promisedSetState({
                    total: response.meta.total,
                    feeds: response.data,
                    meta: response.meta,
                    loading: false,
                    loading_feeds: false
                });
                this.functions.sideScroll()
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false,
                    loading_feeds: false
                })
            }
        },
        getFeed: async (feed) => {
            try {
                await this.promisedSetState({
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == feed.id) {
                            item.loading_creatives = true;
                        }
                        return item;
                    })
                });
                let response = await this.calls.getFeed(feed.id);
                await this.promisedSetState({
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == feed.id) {
                            item.loading_creatives = false;
                            item.creatives = response.data.listings;
                        }
                        return item;
                    })
                });
            } catch (error) {
                await this.promisedSetState({
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == feed.id) {
                            item.loading_creatives = false;
                        }
                        return item;
                    })
                });
            }
        },
        removeFeed: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeFeed(selected_feed.id);
                await this.promisedSetState({
                    feeds: this.state.feeds.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                });
            } catch (error) {
                //console.log(error);
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        clone: async (selected_feed) => {
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading_clone = true;
                    }
                    return item;
                })
            });
            try {
                await this.calls.cloneForm(selected_feed.id);
                this.functions.getForms();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading_clone = false;
                        }
                        return item;
                    })
                })
            }
        },
        disableFeed: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.disableFeed(selected_feed.id);
                await this.promisedSetState({
                    feeds: this.state.feeds.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                });
            } catch (error) {
                console.log(error);
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        }
    };

    calls = {
        getForms: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/form?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&status=" + this.state.status.value + "&type=" + this.state.tab.value;
            return apiRegister.call(options, url);
        },
        getFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/form/" + id;
            return apiRegister.call(options, url);
        },
        removeFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/form/" + id;
            return apiRegister.call(options, url);
        },
        cloneForm: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', null);
            let url = apiRegister.url.api + "/form/clone/" + id;
            return apiRegister.call(options, url);
        },
        disableFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = apiRegister.url.api + "/form/disable/" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        color: (color, percent) => {
            try {
                var R = parseInt(color.substring(1, 3), 16);
                var G = parseInt(color.substring(3, 5), 16);
                var B = parseInt(color.substring(5, 7), 16);
                R = parseInt(R * (100 + percent) / 100);
                G = parseInt(G * (100 + percent) / 100);
                B = parseInt(B * (100 + percent) / 100);
                R = (R < 255) ? R : 255;
                G = (G < 255) ? G : 255;
                B = (B < 255) ? B : 255;
                R = Math.round(R);
                G = Math.round(G);
                B = Math.round(B);
                var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
                var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
                var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));
                return "#" + RR + GG + BB;
            } catch (error) {
                return '#333333';
            }
        },
        basicUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled';
        },
        creativeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.creativeSwitch && this.state.user.enterprise.creativeSwitch.value == 'enabled';
        },
        proptreeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.proptreeSwitch && this.state.user.enterprise.proptreeSwitch.value == 'enabled';
        },
        fromAdmin: () => {
            return this.state.user && this.state.user.from_admin;
        },
        countryIcon: (agent) => {
            let code = "";
            if (agent.language && agent.language.value == "en") {
                code = <div className="mr-1"><span class="fi fi-gb"></span></div>;
            }
            if (agent.language && agent.language.value == "sv") {
                code = <div className="mr-1"><span class="fi fi-se"></span></div>;
            }
            if (agent.language && agent.language.value == "de") {
                code = <div className="mr-1"><span class="fi fi-de"></span></div>;
            }
            if (agent.language && agent.language.value == "es") {
                code = <div className="mr-1"><span class="fi fi-es"></span></div>;
            }
            if (agent.language && agent.language.value == "fr") {
                code = <div className="mr-1"><span class="fi fi-fr"></span></div>;
            }
            if (agent.language && agent.language.value == "ru") {
                code = <div className="mr-1"><span class="fi fi-ru"></span></div>;
            }
            if (agent.language && agent.language.value == "nl") {
                code = <div className="mr-1"><span class="fi fi-nl"></span></div>;
            }
            if (agent.language && agent.language.value == "pt") {
                code = <div className="mr-1"><span class="fi fi-pt"></span></div>;
            }
            if (agent.language && agent.language.value == "it") {
                code = <div className="mr-1"><span class="fi fi-it"></span></div>;
            }
            return code;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className={(this.props.admin ? "p-6 " : "sm:p-0 p-6 pt-0 ") + " grid grid-cols-12 h-full min-h-full bg-gray-50"}>

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading forms ...</div>
                        </div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={this.state.remove && this.state.remove.disabled ? "Delete Form" : "Disable Form"}
                    description={"Are you sure you want to " + (this.state.remove && this.state.remove.disabled ? "delete" : "disable") + " this form? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={this.state.remove && this.state.remove.disabled ? "delete" : "disable"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name"'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            if (this.state.remove.disabled) {
                                this.functions.removeFeed();
                            } else {
                                this.functions.disableFeed();
                            }
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*CREATE USER*/}
                <CreateLeadNew
                    view={'form'}
                    form={this.state.form_lead}
                    open={this.state.open_lead}
                    toggle={() => {
                        this.setState({
                            client_lead: {},
                            open_lead: !this.state.open_lead
                        })
                    }}
                />

                {/*CREATE USER*/}
                <CreateForm
                    version={this.state.version}
                    admin={this.props.admin}
                    enterprise={this.state.user.enterprise}
                    form={this.state.selected}
                    open={this.state.open}
                    toggle={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }}
                    onCreate={(feed) => {
                        this.functions.getForms();
                    }}
                    onUpdate={(feed) => {
                        this.functions.getForms();
                    }}
                />

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.tab}
                                options={[
                                    { id: 'all', name: "All themes", value: 'all', count: this.state.meta.all },
                                    { id: 'proptree', name: "Proptree", value: 'proptree', count: this.state.meta.proptree },
                                    { id: 'custom', name: "Other", value: 'custom', count: this.state.meta.custom }
                                ].filter((item) => {
                                    if (item.id == "proptree" || item.id == "custom") {
                                        return this.renders.proptreeUI();
                                    } else {
                                        return true;
                                    }
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getForms();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*STATUS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.status}
                                options={[
                                    { id: 'enabled', name: "Enabled", value: 'enabled', count: this.state.meta.enabled },
                                    { id: 'disabled', name: "Disabled", value: 'disabled', count: this.state.meta.disabled }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        status: option,
                                        page: 1
                                    });
                                    this.functions.getForms();
                                }}
                            />
                        </div>
                    </div>
                }

                <div className="col-span-0 hidden md:block md:col-span-4"></div>

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Created", value: 'createdAt' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getForms();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getForms();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*LISTINGS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white shadow-lg border rounded-lg">
                                    <div className="flex p-4 items-center lg:px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.tab.name}
                                        </div>
                                        {
                                            this.state.user.enterprise && this.state.user.enterprise.managerUIcreateForms && this.state.user.enterprise.managerUIcreateForms.value === 'enabled' &&
                                            this.state.user.access && this.state.user.access.id === 1 &&
                                            <div onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    selected: null
                                                })
                                            }} className={(this.renders.basicUI() ? "text-white bg-purple-500 shadow hover:bg-purple-600 " : "text-purple-500 bg-purple-100 hover:text-purple-700 ") + " text-sm px-4 h-10 rounded-md bg-purple-100 border-purple-500 font-medium cursor-pointer items-center flex flex-row "}>
                                                <PlusCircleIcon className="w-5 h-5 mr-2" />
                                                Create
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.feeds.length < 1 &&
                                        <div className="h-110 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_feeds &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">Forms</span> available</div>
                                            }
                                            {
                                                this.state.loading_feeds &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.feeds.length > 0 &&
                                        <div id={"table_container"} className="relative overflow-x-scroll min-h-110">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>

                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.feeds.map((agent, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={agent.id} className="border-b border-gray-100">
                                                                        <td className="text-left pl-6">

                                                                            <Menu as="div" className="relative inline-block text-left">
                                                                                <div>
                                                                                    <Menu.Button className="inline-flex relative hover:bg-gray-50 items-center justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                        {
                                                                                            !agent.loading &&
                                                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        }
                                                                                        {
                                                                                            agent.loading &&
                                                                                            <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                    class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </Menu.Button>
                                                                                </div>
                                                                                <Transition
                                                                                    as={Fragment}
                                                                                    enter="transition ease-out duration-100"
                                                                                    enterFrom="transform opacity-0 scale-95"
                                                                                    enterTo="transform opacity-100 scale-100"
                                                                                    leave="transition ease-in duration-75"
                                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                                    leaveTo="transform opacity-0 scale-95"
                                                                                >
                                                                                    <Menu.Items className="absolute border left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg">
                                                                                        <div className="py-1">
                                                                                            {
                                                                                                [
                                                                                                    { name: "Edit", value: "edit", icon: PencilAltIcon },
                                                                                                    { name: "Clone", value: "clone", icon: DuplicateIcon },
                                                                                                    { name: "Create lead", value: "create_lead", icon: StarIcon },
                                                                                                    { name: "Disable", value: "disable", icon: MinusCircleIcon },
                                                                                                    { name: "Remove", value: "remove", icon: TrashIcon }
                                                                                                ].filter((item) => {
                                                                                                    if (item.value == "create_lead") {
                                                                                                        return !this.renders.basicUI();
                                                                                                    } else if (item.value == "clone") {
                                                                                                        return !(agent.theme && agent.theme.value == "proptree");
                                                                                                    } else if (item.value == "disable") {
                                                                                                        if (agent.proptreeDefault) {
                                                                                                            return this.renders.fromAdmin() && !agent.disabled;
                                                                                                        } else {
                                                                                                            return !agent.disabled
                                                                                                        }
                                                                                                    } else if (item.value == "remove") {
                                                                                                        if (agent.proptreeDefault) {
                                                                                                            return this.renders.fromAdmin() && agent.disabled;
                                                                                                        } else {
                                                                                                            return agent.disabled
                                                                                                        }
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                }).map((item) => {
                                                                                                    return (
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        if (item.value == "edit") {
                                                                                                                            this.setState({
                                                                                                                                open: true,
                                                                                                                                selected: agent
                                                                                                                            })
                                                                                                                        } else if (item.value == "clone") {
                                                                                                                            this.functions.clone(agent);
                                                                                                                        } else if (item.value == "create_lead") {
                                                                                                                            this.setState({
                                                                                                                                open_lead: true,
                                                                                                                                form_lead: agent
                                                                                                                            });
                                                                                                                        } else if (item.value == "disable" || item.value == "remove") {
                                                                                                                            this.setState({
                                                                                                                                remove: agent
                                                                                                                            })
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                        (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <div className="flex flex-1">{item.name}</div>
                                                                                                                    <item.icon className="w-5" />
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </td>
                                                                        {
                                                                            <td className="flex items-center px-10 py-4">
                                                                                <span style={{
                                                                                    backgroundColor: "#" + agent.color,
                                                                                    backgroundImage: "linear-gradient(225deg, " + agent.color + "," + this.renders.color(agent.color, 15) + ", " + agent.color + ")"
                                                                                }} className="inline-block border h-10 w-10 relative rounded-full overflow-hidden bg-gray-100">
                                                                                </span>
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div><div className=" text-sm text-gray-900 whitespace-no-wrap">{agent.theme ? agent.theme.name : "-"}</div></div>
                                                                            <div className="text-sm text-gray-500 mb-1">Theme</div>
                                                                        </td>
                                                                        {
                                                                            //this.renders.basicUI() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div><div className="text-gray-900 p-1 px-2 inline-flex text-xs rounded-md bg-gray-100 whitespace-no-wrap">{agent.proptreeDefault ? "Fallback" : "Custom"}</div></div>
                                                                                <div className="text-sm text-gray-500 mb-1">Type</div>
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.name}</div>
                                                                            <div className="text-sm text-gray-500">Name</div>
                                                                        </td>
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.version ? agent.version.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Qualification</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.type ? agent.type.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Order</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.questionsAmount ? (agent.version && agent.version.value === "no_questions" ? "0" : agent.questionsAmount) : "-" }</div>
                                                                                <div className="text-sm text-gray-500">Questions</div>
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.language ? agent.language.name : "-"}
                                                                            </div>
                                                                            <div className="text-sm text-gray-500">Language</div>
                                                                        </td>
                                                                        {
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.currency ? agent.currency.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Currency</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.feeds}</div>
                                                                                <div className="text-sm text-gray-500">Linked feeds</div>
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{moment(agent.createdAt).format("YYYY-MM-DD")}</div>
                                                                            <div className="text-sm text-gray-500">Created</div>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_feeds &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.feeds.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                {
                                                    this.state.feeds.length > 0 &&
                                                    <div className="hidden sm:block">
                                                        <p className="text-sm text-gray-700">
                                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.total ? this.state.total : (this.state.page * this.state.page_count)}</span> of{' '}
                                                            <span className="font-medium">{this.state.total}</span> results
                                                        </p>
                                                    </div>
                                                }
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100, 200, 300, 400].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        this.functions.getForms();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            }, () => {
                                                                this.functions.getForms();
                                                            });
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.getForms();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div>
        )
    }
}

export default ListForms;
