import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { ArrowDownIcon, ArrowUpIcon, CashIcon, CheckIcon, CogIcon, ExternalLinkIcon, LockClosedIcon, PhotographIcon, PlusIcon, SaveAsIcon, SaveIcon, SparklesIcon, TrashIcon, ViewGridIcon } from '@heroicons/react/outline';
import SelectionModal from '../components/selectionModal';
import SuccessModal from '../components/successModal';
import Switch from '../components/switch';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
var moment = require('moment');

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_update_profile: false,
            loading_update_alias: false,
            settings: {},
            org_image: "",
            image: "",
            org_name: "",
            name: "",
            org_company: "",
            company: "",
            org_alias: "",
            alias: "",
            profileImage: null,
            alias_modal: false,
            success_modal: false,
            subscription: false,
            type: false,
            buttons: [],
            icons: [
                { color: "#ffffff", name: "Linkedin", value: 'linkedin', enabled: false, username: '', org_username: '' },
                { color: "#ffffff", name: "X (Twitter)", value: 'twitter', enabled: false, username: '', org_username: '' },
                { color: "#ffffff", name: "Facebook", value: 'facebook', enabled: false, username: '', org_username: '' },
                { color: "#ffffff", name: "Instagram", value: 'instagram', enabled: false, username: '', org_username: '' },
                { color: "#ffffff", name: "TikTok", value: 'tiktok', enabled: false, username: '', org_username: '' },
                { color: "#ffffff", name: "Snapchat", value: 'snapchat', enabled: false, username: '', org_username: '' },
                { color: "#ffffff", name: "Youtube", value: 'youtube', enabled: false, username: '', org_username: '' }
            ],
            dragOverIndex: null
        };
        this.promisedSetState = this.promisedSetState.bind(this);
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({
                user: user ? user : {}
            });
            this.functions.getSettings();
        },
        getSettings: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.getSettings();
                this.state.icons = this.state.icons.map((item) => {
                    if (response.data['socialIcon' + item.value] && 'username' in response.data['socialIcon' + item.value]) {
                        item.enabled = response.data['socialIcon' + item.value].enabled;
                        item.username = response.data['socialIcon' + item.value].username;
                        item.org_username = response.data['socialIcon' + item.value].username;
                    }
                    return item;
                });
                await this.promisedSetState({
                    icons: this.state.icons,
                    type: response.data.type,
                    subscription: response.data.subscription,
                    short_id: response.data.shortId,
                    org_alias: typeof response.data.alias == "string" ? response.data.alias : "",
                    alias: typeof response.data.alias == "string" ? response.data.alias : "",
                    org_name: typeof response.data.name == "string" ? response.data.name : "",
                    name: typeof response.data.name == "string" ? response.data.name : "",
                    org_image: response.data.image,
                    image: response.data.image,
                    org_company: response.data.company,
                    company: response.data.enterprise.companyName,
                    buttons: response.data.buttons,
                    loading: false
                });
            } catch (error) {
                console.log(error);
            }
        },
        uploadImage: async (e, button) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                if (button) {
                    this.state.buttons = this.state.buttons.map((item) => {
                        if (item.id == button.id) {
                            item.loading_image = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({
                        buttons: this.state.buttons
                    });
                } else {
                    await this.promisedSetState({
                        loading_image: true
                    });
                }
                try {
                    let response = await this.calls.uploadImage(formData);
                    if (button) {
                        this.state.buttons = this.state.buttons.map((item) => {
                            if (item.id == button.id) {
                                item.image = response.data;
                                item.loading_image = false;
                            }
                            return item;
                        });
                        await this.promisedSetState({
                            buttons: this.state.buttons
                        });
                        this.functions.updateUpdateButtons();
                    } else {
                        await this.promisedSetState({
                            image: response.data,
                            loading_image: false
                        })
                    }
                } catch (error) { }
                if (button) {
                    this.state.buttons = this.state.buttons.map((item) => {
                        if (item.id == button.id) {
                            item.loading_image = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({
                        buttons: this.state.buttons
                    });
                } else {
                    await this.promisedSetState({
                        loading_image: false
                    });
                }
            }
        },
        updateProptree: async () => {
            await this.promisedSetState({ loading_update_profile: true });
            try {
                let updated_date = {};
                if (this.renders.updatedImage()) {
                    updated_date.image = this.state.image;
                }
                if (this.renders.updatedName()) {
                    updated_date.name = this.state.name;
                }
                await this.calls.updateProfile(updated_date);
                await this.promisedSetState({
                    success_modal: true,
                    ...(this.renders.updatedImage() && { org_image: this.state.image }),
                    ...(this.renders.updatedName() && { org_name: this.state.name })
                });
            } catch (error) { }
            await this.promisedSetState({ loading_update_profile: false });
        },
        checkAlias: async () => {
            await this.promisedSetState({ loading_claim: true, claim_error: false });
            try {
                await this.calls.checkAlias({ alias: this.state.claim_alias });
                if (window.location.hostname === 'localhost') {
                    this.props.history.push("/v1-proptree/billing?alias=" + this.state.claim_alias);
                } else {
                    this.props.history.push("/v1/billing?alias=" + this.state.claim_alias);
                }
            } catch (error) {
                await this.promisedSetState({
                    claim_error: error && error.body ? error.body.message : "Not a valid alias"
                });
            }
            await this.promisedSetState({ loading_claim: false });
        },
        updateAlias: async () => {
            await this.promisedSetState({ loading_update_alias: true, alias_modal: false, alias_error: false });
            try {
                let updated_date = {};
                if (this.renders.updatedAlias()) {
                    updated_date.alias = this.state.alias;
                }
                await this.calls.updateProfile(updated_date);
                await this.promisedSetState({
                    org_alias: this.state.alias,
                    success_modal: true
                });
            } catch (error) {
                await this.promisedSetState({ alias_error: "This proptree link already exist, try another." });
            }
            await this.promisedSetState({ loading_update_alias: false });
        },
        updateSocialIcon: async (icon, skip_validation) => {
            try {
                if (this.renders.updatedSocialIcon(icon) || skip_validation) {
                    this.state.icons = this.state.icons.map((item) => {
                        if (item.value == icon.value) {
                            item.loading = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ icons: this.state.icons });
                    let updated_date = { social_icon: {} };
                    updated_date['social_icon'] = icon;
                    await this.calls.updateProfile(updated_date);
                    this.state.icons = this.state.icons.map((item) => {
                        if (item.value == icon.value) {
                            item.org_username = icon.username;
                        }
                        return item;
                    });
                    await this.promisedSetState({ icons: this.state.icons });
                }
            } catch (error) {
                this.state.icons = this.state.icons.map((item) => {
                    if (item.value == icon.value) {
                        item.error = "Something went wrong";
                    }
                    return item;
                });
                await this.promisedSetState({ icons: this.state.icons });
            }
            this.state.icons = this.state.icons.map((item) => {
                if (item.value == icon.value) {
                    item.loading = false;
                }
                return item;
            });
            await this.promisedSetState({ icons: this.state.icons });
        },
        updateUpdateButtons: async () => {
            await this.promisedSetState({ loading_buttons: true });
            try {
                await this.calls.updateProfile({ buttons: this.state.buttons });
            } catch (error) { }
            await this.promisedSetState({ loading_buttons: false });
        }
    };

    calls = {
        getSettings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.proptree + "/getProptreeSettings";
            return apiRegister.call(options, url);
        },
        updateProfile: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.proptree + "/updateProptreeSettings";
            return apiRegister.call(options, url);
        },
        checkAlias: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.proptree + "/checkAlias";
            return apiRegister.call(options, url);
        },
        uploadImage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data, true);
            let url = apiRegister.url.proptree + "/updateProptreeImage";
            return apiRegister.call(options, url);
        }
    };

    renders = {
        changes: () => {
            let changes = false;
            if (this.state.name.trim() != this.state.org_name.trim()) {
                changes = true;
            }
            if (this.state.image.trim() != this.state.org_image.trim()) {
                changes = true;
            }
            return changes;
        },
        updatedAlias: () => {
            let changes = false;
            try {
                if (this.state.alias.trim() != this.state.org_alias.trim()) {
                    changes = true;
                }
            } catch (error) { }
            return changes;
        },
        updatedName: () => {
            let changes = false;
            try {
                if (this.state.name.trim() != this.state.org_name.trim()) {
                    changes = true;
                }
            } catch (error) { }
            return changes;
        },
        updatedImage: () => {
            let changes = false;
            try {
                if (this.state.image.trim() != this.state.org_image.trim()) {
                    changes = true;
                }
            } catch (error) { }
            return changes;
        },
        updatedSocialIcon: (icon) => {
            let changes = false;
            try {
                if (icon.username.trim() != icon.org_username.trim()) {
                    changes = true;
                }
            } catch (error) { }
            return changes;
        },
        validUserName: (string, channel) => {
            let valid = false;
            try {
                if (/https?:\/\/|www\./i.test(string)) {
                    return false;
                }

                let usernameRegex;

                if (channel === 'facebook' || channel === 'twitter' || channel === 'linkedin' || channel === 'youtube') {
                    // For YouTube, we allow letters, digits, hyphens, underscores, periods (for paths), and '@'
                    if (channel === 'youtube') {
                        usernameRegex = /^[a-zA-Z0-9_\-\/\.@]+$/;
                    } else {
                        // For other channels like Facebook, Twitter, and LinkedIn, allow letters, digits, hyphens, underscores, and periods
                        usernameRegex = /^[a-zA-Z0-9_\-\/\.]+$/;
                    }
                } else if (channel === 'instagram' || channel === 'tiktok') {
                    // Instagram and TikTok usernames allow underscores and periods, but not slashes
                    usernameRegex = /^[a-zA-Z0-9_\-\.]+$/;
                } else if (channel === 'snapchat') {
                    // Snapchat allows only letters, digits, and underscores
                    usernameRegex = /^[a-zA-Z0-9_]+$/;
                } else {
                    return false;
                }

                let maxLength = 100;
                let minLength = 1;

                if (usernameRegex.test(string) && string.length >= minLength && string.length <= maxLength) {
                    valid = true;
                }
            } catch (error) {
            }
            return valid;
        },
        validAlias: (string) => {
            let valid = false;
            try {
                if ((/^[a-zA-Z0-9_-]+$/i).test(string)) {
                    valid = true;
                }
                if (string.length > 20) {
                    valid = false;
                }
            } catch (error) { }
            return valid;
        },
        validName: (string) => {
            let valid = false;
            if (this.state.type !== "custom") {
                try {
                    if ((/^[a-zA-Z- ]+$/i).test(string)) {
                        valid = true;
                    }
                    if (string.length > 30) {
                        valid = false;
                    }
                } catch (error) { }
                return valid;
            } else {
                return true;
            }
        },
        validDomain: (url) => {
            let regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?(\?[a-zA-Z0-9&=._-]*)?(#[^\s]*)?$/;
            return regex.test(url);
        },
        buttonItems: () => {
            let items = [];
            this.state.buttons.map((item, index) => {
                items.push({ index: index, number: index + 1 });
            });
            return items;
        },
        buildSocialUrl: (username, channel) => {
            let url = "";
            if (channel === 'facebook') {
                url = "https://facebook.com/" + username;
            } else if (channel === 'twitter') {
                // X (formerly Twitter)
                url = "https://x.com/" + username;
            } else if (channel === 'instagram') {
                url = "https://instagram.com/" + username;
            } else if (channel === 'linkedin') {
                url = "https://linkedin.com/" + username;
            } else if (channel === 'tiktok') {
                url = "https://www.tiktok.com/@" + username;
            } else if (channel === 'snapchat') {
                url = "https://www.snapchat.com/add/" + username;
            } else if (channel === 'youtube') {
                url = "https://youtube.com/" + username;
            } else {
                url = "";
            }
            return url;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            try {
                if (typeof newState === 'object' && newState !== null) {
                    this.setState(newState, () => {
                        resolve()
                    });
                }
            } catch (error) {
                console.log(error);
            }
        });
    };

    handleDragStart = (e, index) => {
        e.dataTransfer.setData('draggedItemIndex', index);
    };

    handleDragOver = (e, index) => {
        e.preventDefault();
        this.setState({ dragOverIndex: index });
    };

    handleDrop = async (e, targetIndex) => {
        const draggedIndex = e.dataTransfer.getData('draggedItemIndex');
        let newArr = [...this.state.buttons];
        [newArr[draggedIndex], newArr[targetIndex]] = [newArr[targetIndex], newArr[draggedIndex]];
        await this.promisedSetState({ buttons: newArr, dragOverIndex: null });
        if (targetIndex != draggedIndex) {
            this.functions.updateUpdateButtons();
        }
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="grid grid-cols-12 sm:row-gap-10 row-gap-6 sm:col-gap-10 h-full min-h-full sm:p-0 p-6 pt-0">

                {/*MODAL*/}
                <SelectionModal
                    open={this.state.alias_modal}
                    onAccept={() => {
                        this.functions.updateAlias();
                    }}
                    onClose={() => {
                        this.setState({
                            alias_modal: false
                        })
                    }}
                />

                {/*SUCCESS*/}
                <SuccessModal
                    open={this.state.success_modal}
                    title={"Success"}
                    text={"Updated"}
                    button={"close"}
                    onClose={() => {
                        this.setState({
                            success_modal: false
                        })
                    }}
                />

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/* CLAIM CUSTOM NAME */}
                {
                    !this.state.loading &&
                    !this.state.subscription &&
                    <div className="col-span-12 sm:-mb-4 flex flex-col items-center justify-center">
                        <div className="max-w-140 w-full">
                            <div style={{ backgroundImage: "linear-gradient(100deg, rgb(0, 50, 98) 8%, rgb(1, 28, 58) 92%)" }} className="border-t rounded-lg overflow-hidden">
                                <div className="px-4 py-5 sm:p-6">
                                    <h3 className="text-base font-semibold leading-6 text-white">Claim your custom shortname !</h3>
                                    <div className="mt-2 max-w-xl text-sm text-white">
                                        <p>
                                            Avail your 6-month free trial and get exclusive access to new features, customizations, and more insights.
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <div className="flex">
                                            <div className="bg-gray-50 border text-sm border-r-none rounded-l-md shadow-sm items-center inline-flex sm:h-10 px-4">
                                                https://proptr.ee/
                                            </div>
                                            <input
                                                onChange={(event) => {
                                                    this.setState({
                                                        claim_alias: event.target.value
                                                    })
                                                }}
                                                placeholder="input shortname"
                                                value={this.state.claim_alias}
                                                type="text"
                                                name="proptree-shortname"
                                                id="proptree-shortname"
                                                className={"appearance-none block rounded-l-none w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.claim_alias && this.state.claim_alias !== "" &&
                                        <div className="mt-5 w-full">
                                            <button
                                                onClick={async () => {
                                                    if (!this.state.loading_claim) {
                                                        this.functions.checkAlias();
                                                    }
                                                }}
                                                type="button"
                                                className="inline-flex relative items-center overflow-hidden rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                                Claim Shortname
                                                <SparklesIcon className="w-5 ml-2"></SparklesIcon>
                                                {
                                                    this.state.loading_claim &&
                                                    <div className="absolute left-0 right-0 z-60 bg-indigo-600 bottom-0 top-0">
                                                        <div className="w-full h-full flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    }
                                    {
                                        this.state.claim_error &&
                                        <div className="bg-red-100 text-red-500 rounded-md text-sm p-3 px-4 font-medium mt-5">
                                            {this.state.claim_error}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* PROFILE */}
                {
                    !this.state.loading &&
                    this.state.settings &&
                    <div className="col-span-12 flex flex-col items-center justify-center">
                        <div className="max-w-140 w-full">
                            <div className="bg-white overflow-hidden shadow rounded-lg border">
                                <div className="px-4 py-5 sm:p-6">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Profile</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            Your profile is your window to the world, make sure it's up to date.
                                        </p>
                                    </div>
                                    <div className="mt-5 grid grid-cols-12 gap-4">
                                        <div className="col-span-12 sm:col-span-4">
                                            <div className="mt-1 flex flex-col items-center">
                                                {
                                                    !this.state.image &&
                                                    <span className="inline-block w-40 h-40 rounded-full relative overflow-hidden bg-gray-100">
                                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                        </svg>
                                                        {
                                                            this.state.loading_image &&
                                                            <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </span>
                                                }
                                                {
                                                    this.state.image &&
                                                    <div className="rounded-full relative mb-3 sm:mb-0 w-40 h-40 border overflow-hidden justify-center flex items-center">
                                                        <div>
                                                            <img
                                                                className="w-full"
                                                                src={this.state.image}
                                                                alt=""
                                                            />
                                                        </div>
                                                        {
                                                            this.state.loading_image &&
                                                            <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="mt-5 flex">
                                                    <div>
                                                        <div style={{ marginTop: "2px" }} className="relative bg-white py-2 px-3 h-10 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                            <label
                                                                htmlFor="user-photo"
                                                                className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                                                            >
                                                                {
                                                                    this.state.image &&
                                                                    <span style={{ whiteSpace: "nowrap" }}>Change image</span>
                                                                }
                                                                {
                                                                    !this.state.image &&
                                                                    <span style={{ whiteSpace: "nowrap" }}>Upload image</span>
                                                                }
                                                                <span className="sr-only"> user photo</span>
                                                            </label>
                                                            {
                                                                <input
                                                                    ref="uploadImageFile"
                                                                    onClick={() => this.refs.uploadImageFile.value = null}
                                                                    onChange={(e) => this.functions.uploadImage(e)}
                                                                    type="file"
                                                                    accept={"image/png,image/jpg,image/jpeg"}
                                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12 sm:col-span-8">
                                            <div className="w-full">
                                                <div>
                                                    <label htmlFor="proptree-shortname" className="block text-sm font-medium text-gray-900">
                                                        Your proptree
                                                    </label>
                                                    <div className="mt-1 flex relative">
                                                        <div className="bg-gray-50 border text-sm border-r-none rounded-l-md shadow-sm items-center inline-flex sm:h-10 px-4">
                                                            https://proptr.ee/
                                                        </div>
                                                        <input
                                                            onChange={(event) => {
                                                                if (this.state.subscription) {
                                                                    this.setState({
                                                                        alias: event.target.value
                                                                    })
                                                                }
                                                            }}
                                                            value={!this.state.subscription ? this.state.short_id : (this.state.alias ? this.state.alias : "")}
                                                            type="text"
                                                            name="proptree-shortname"
                                                            id="proptree-shortname"
                                                            className={(this.state.subscription && this.state.alias !== "" && !this.renders.validAlias(this.state.alias) ? "border-red-500 text-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block rounded-l-none w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none text-sm"}
                                                        />
                                                        {
                                                            !this.state.subscription &&
                                                            <div className="left-0 absolute top-0 right-0 bottom-0 cursor-not-allowed z-10"></div>
                                                        }
                                                        {
                                                            !this.state.subscription &&
                                                            <div className="absolute cursor-not-allowed right-0 top-0 bottom-0 w-10 justify-center items-center flex">
                                                                <LockClosedIcon className="w-5 text-gray-500" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.subscription &&
                                                !(typeof this.state.org_alias == "string" && this.state.org_alias !== "") &&
                                                this.state.alias == "" &&
                                                <div className="mt-4 w-full rounded-md bg-purple-100 p-4 text-purple-500 font-medium text-sm">
                                                    Claim your custom shortlink, type above and update !
                                                </div>
                                            }
                                            {
                                                this.state.subscription &&
                                                this.state.alias_error &&
                                                <div className="mt-4 w-full rounded-md bg-red-100 p-4 text-red-500 font-medium text-sm">
                                                    {this.state.alias_error}
                                                </div>
                                            }
                                            {
                                                this.state.subscription &&
                                                !this.renders.validAlias(this.state.alias) &&
                                                this.state.alias !== "" &&
                                                <div className="mt-4 w-full rounded-md bg-red-100 p-4 text-red-500 font-medium text-sm">
                                                    There is one or more character that is not allowed. Allowed characters are aA-zZ, 0-9 and - or _. Maximum 20 characters !
                                                </div>
                                            }
                                            {
                                                this.state.subscription &&
                                                this.renders.validAlias(this.state.alias) &&
                                                this.renders.updatedAlias() &&
                                                <div className="mt-4 w-full rounded-md bg-purple-100 p-4 text-purple-500 font-medium text-sm">
                                                    {
                                                        this.state.org_alias !== "" &&
                                                        <div> Are you sure you want to update link ? Your old link <span className="font-bold">https://proptr.ee/{this.state.org_alias}</span> will no longer work after updating.</div>
                                                    }
                                                    {
                                                        this.state.org_alias == "" &&
                                                        <div>Update your proptree with a custom shortname !</div>
                                                    }
                                                    <div className="flex flex-row mt-4">
                                                        <button
                                                            onClick={() => {
                                                                this.setState({
                                                                    alias_modal: true
                                                                })
                                                            }}
                                                            type="button"
                                                            className="inline-flex relative overflow-hidden items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                        >
                                                            Update link
                                                            {
                                                                this.state.loading_update_alias &&
                                                                <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                        </button>
                                                        {
                                                            this.state.org_alias !== "" &&
                                                            <button
                                                                onClick={() => {
                                                                    this.setState({
                                                                        alias: this.state.org_alias
                                                                    })
                                                                }}
                                                                type="button"
                                                                className="inline-flex items-center text-gray-800 hover:text-gray-900 rounded-md ml-2 px-3 py-2 font-medium text-purple focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                            >
                                                                Dont update
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className="w-full mt-4">
                                                <div>
                                                    <label htmlFor="proptree-name" className="block text-sm font-medium text-gray-900">
                                                        Name
                                                    </label>
                                                    <div className="mt-1 flex">
                                                        <input
                                                            onChange={(event) => {
                                                                this.setState({
                                                                    name: event.target.value
                                                                })
                                                            }}
                                                            value={this.state.name}
                                                            type="text"
                                                            name="proptree-name"
                                                            id="proptree-name"
                                                            className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !this.renders.validName(this.state.name) &&
                                                <div className="mt-4 w-full rounded-md bg-red-100 p-4 text-red-500 font-medium text-sm">
                                                    There is one or more character that is not allowed. Allowed characters are aA-zZ, 0-9 and - or _. Maximum 30 characters !
                                                </div>
                                            }
                                            <div className="w-full mt-4">
                                                <div>
                                                    <label htmlFor="proptree-company" className="block text-sm font-medium text-gray-900">
                                                        Company
                                                    </label>
                                                    <div className="mt-1 flex relative">
                                                        <div className="left-0 absolute top-0 right-0 bottom-0 cursor-not-allowed z-10"></div>
                                                        <input
                                                            onChange={(event) => {

                                                            }}
                                                            readOnly={true}
                                                            value={this.state.company}
                                                            type="text"
                                                            name="proptree-company"
                                                            id="proptree-company"
                                                            className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                                        />
                                                        <div className="absolute cursor-not-allowed right-0 top-0 bottom-0 w-10 justify-center items-center flex">
                                                            <LockClosedIcon className="w-5 text-gray-500" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.renders.validName(this.state.name) &&
                                            this.renders.changes() &&
                                            <div className="col-span-12 flex mt-4">
                                                <div className="flex flex-1"></div>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_update_profile) {
                                                            this.functions.updateProptree();
                                                        }
                                                    }}
                                                    type="button"
                                                    className="inline-flex relative items-center overflow-hidden rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                >
                                                    Save changes
                                                    <SaveIcon className="w-5 ml-2"></SaveIcon>
                                                    {
                                                        this.state.loading_update_profile &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="px-4 py-5 sm:p-6 border-t">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Social links</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            Your social links provide your audience with easy access to all your profiles.
                                        </p>
                                    </div>
                                    <div className="w-full mt-5">
                                        <div className="mt-5 grid grid-cols-12 gap-4">
                                            {
                                                this.state.icons.map((item) => {
                                                    return (
                                                        <Fragment>
                                                            <div className="col-span-12 flex relative">
                                                                {
                                                                    item.loading &&
                                                                    <div className="absolute left-0 right-0 z-60 bg-white bg-opacity-50 bottom-0 top-0">
                                                                        <div className="w-full h-full flex justify-center items-center">
                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div
                                                                    style={{ backgroundColor: item.color }}
                                                                    className="border bg-gray-50 sm:h-10 rounded-r-none h-10 overflow-hidden rounded-md flex items-center"
                                                                >
                                                                    {this.renders.validUserName(item.username, item.value) ? (
                                                                        <a href={this.renders.buildSocialUrl(item.username, item.value)} target={"_blank"}>
                                                                            <div style={{paddingTop: "5px"}} className="text-sm text-white items-center inline-flex px-4">
                                                                                <img
                                                                                    className="w-5 text-white"
                                                                                    src={require("../assets/images/social_proptree/" + item.value + ".svg")}
                                                                                />
                                                                            </div>
                                                                        </a>
                                                                    ) : (
                                                                        <div className="text-sm opacity-25 text-white items-center inline-flex px-4">
                                                                            <img
                                                                                className="w-5 text-white"
                                                                                src={require("../assets/images/social_proptree/" + item.value + ".svg")}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="flex flex-1">
                                                                    <div className="text-md border-t border-b items-center inline-flex pl-2 justify-center">{item.value === "snapchat" ? "/add/" : item.value === "tiktok" ? "@" : "/" }</div>
                                                                    <div className="flex flex-1">
                                                                        <input
                                                                            onChange={async (event) => {
                                                                                event.persist();
                                                                                const newUsername = event.target.value;
                                                                                item.username = newUsername;
                                                                                const isValid = this.renders.validUserName(newUsername, item.value);
                                                                                if (!isValid) {
                                                                                    item.enabled = false;
                                                                                }
                                                                                await this.promisedSetState({
                                                                                    icons: this.state.icons
                                                                                });

                                                                                if (!isValid) {
                                                                                    event.target.classList.add("text-red-500");
                                                                                } else {
                                                                                    event.target.classList.remove("text-red-500");
                                                                                }
                                                                            }}
                                                                            onBlur={() => {
                                                                                this.functions.updateSocialIcon(item);
                                                                            }}
                                                                            placeholder={item.value === "linkedin" || item.value === "youtube" ? item.value === "youtube" ? "@username or path/username" : "path/username" : "username"}
                                                                            value={item.username}
                                                                            type="text"
                                                                            name="proptree-icon"
                                                                            id="proptree-icon"
                                                                            className={"appearance-none block h-10 w-full pl-0 px-3 border-r-0 rounded-r-none border-l-0 rounded-l-none py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="border bg-gray-50 sm:h-10 px-2 rounded-l-none h-10 overflow-hidden rounded-md flex items-center">
                                                                    <div className="relative overflow-hidden">
                                                                        <Switch
                                                                            value={item.enabled}
                                                                            disabled={!this.renders.validUserName(item.username, item.value)}
                                                                            className={!this.renders.validUserName(item.username, item.value) ? "cursor-not-allowed" : ""}
                                                                            onSwitch={async () => {
                                                                                if (this.renders.validUserName(item.username, item.value)) {
                                                                                    item.enabled = !item.enabled;
                                                                                    await this.promisedSetState({
                                                                                        icons: this.state.icons
                                                                                    });
                                                                                    this.functions.updateSocialIcon(item, true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                                {
                                    this.state.subscription &&
                                    <div className="px-4 py-5 sm:p-6 border-t">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900">Custom buttons</h3>
                                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                                            <p>
                                                Your custom buttons can be used to showcase more details about promotions and offers.
                                            </p>
                                        </div>
                                        <div className="mt-5 w-full">
                                            <button
                                                onClick={async () => {
                                                    if (!this.state.loading_buttons) {
                                                        this.state.buttons.push({
                                                            id: Math.floor(Math.random() * 10000000000000).toString(),
                                                            title: '',
                                                            url: '',
                                                            image: null,
                                                            enabled: false
                                                        });
                                                        await this.promisedSetState({
                                                            buttons: this.state.buttons
                                                        });
                                                        this.functions.updateUpdateButtons();
                                                    }
                                                }}
                                                type="button"
                                                className="inline-flex relative items-center overflow-hidden rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                                Add button
                                                <PlusIcon className="w-5 ml-2"></PlusIcon>
                                            </button>
                                        </div>
                                        <div className="sortable-list w-full">
                                            {
                                                Array.isArray(this.state.buttons) && this.state.buttons.map((value, index) => {
                                                    return (
                                                        <div
                                                            draggable
                                                            onDragStart={(e) => this.handleDragStart(e, index)}
                                                            onDragOver={(e) => this.handleDragOver(e, index)}
                                                            onDrop={(e) => this.handleDrop(e, index)}
                                                            className={(this.state.dragOverIndex === index ? "bg-purple-100" : "") + " mt-4 overflow-x-scroll border cursor-grabbing rounded-md w-full flex bg-gray-50 relative"}
                                                        >
                                                            {
                                                                this.state.loading_buttons &&
                                                                <div className="absolute left-0 right-0 z-60 bg-white bg-opacity-50 bottom-0 top-0">
                                                                    <div className="w-full h-full flex justify-center items-center">
                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                            class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="border-r p-4 cursor-pointer flex-col inline-flex items-center justify-center">
                                                                <ArrowUpIcon className="w-5 mb-1" />
                                                                <ArrowDownIcon className="w-5 mt-1" />
                                                            </div>
                                                            {
                                                                false &&
                                                                <div className="border-r p-4 cursor-pointer flex-col inline-flex items-center justify-start">
                                                                    {
                                                                        this.renders.buttonItems().map((number) => {
                                                                            return (
                                                                                <div onClick={() => {
                                                                                    let [value] = this.state.buttons.splice(index, 1);
                                                                                    this.state.buttons.splice(number.index, 0, value);
                                                                                    this.setState({
                                                                                        buttons: this.state.buttons
                                                                                    });
                                                                                }} className={(index == number.index ? "bg-purple-300 text-white" : "bg-gray-50 text-gray-800 hover:bg-purple-100") + " mb-2 flex items-center justify-center font-medium text-sm rounded-full h-8 w-8 border"}>
                                                                                    {number.number}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="flex items-center pt-5 flex-col justify-start p-4">
                                                                {
                                                                    !value.image &&
                                                                    <span className="w-20 h-20 border flex items-center justify-center rounded-full relative overflow-hidden bg-white">
                                                                        <PhotographIcon className="w-5" />
                                                                        {
                                                                            value.loading_image &&
                                                                            <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                    class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                }
                                                                {
                                                                    value.image &&
                                                                    <div style={{
                                                                        backgroundImage: 'url(' + value.image + ')',
                                                                        backgroundRepeat: "no-repeat",
                                                                        backgroundSize: "cover",
                                                                        backgroundPosition: "center"
                                                                    }} className="rounded-full border relative sm:mb-0 w-20 h-20 overflow-hidden justify-center flex items-center">
                                                                        {
                                                                            value.loading_image &&
                                                                            <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                    class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                <div className="relative mt-5 bg-white py-2 px-3 h-10 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                                    <label htmlFor="user-photo" className="relative text-sm font-medium text-blue-gray-900 pointer-events-none">
                                                                        {value.image ? <span style={{ whiteSpace: "nowrap" }}>Change image</span> : <span style={{ whiteSpace: "nowrap" }}>Upload image</span>}
                                                                        <span className="sr-only" style={{ whiteSpace: "nowrap" }}> user photo</span>
                                                                    </label>
                                                                    <input
                                                                        onChange={(e) => this.functions.uploadImage(e, value)}
                                                                        type="file"
                                                                        accept={"image/png,image/jpg,image/jpeg"}
                                                                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="p-4 flex flex-1 flex-col">
                                                                <div className="w-full">
                                                                    <label className="block text-sm font-medium text-gray-900">
                                                                        Title
                                                                    </label>
                                                                    <div className="mt-1 w-full">
                                                                        <input
                                                                            onBlur={async () => {
                                                                                await this.promisedSetState({
                                                                                    buttons: this.state.buttons
                                                                                });
                                                                                this.functions.updateUpdateButtons();
                                                                            }}
                                                                            onChange={async (event) => {
                                                                                value.title = event.target.value;
                                                                                await this.promisedSetState({
                                                                                    buttons: this.state.buttons
                                                                                });
                                                                            }}
                                                                            value={value.title}
                                                                            type="text"
                                                                            className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-row mt-4">
                                                                    <div className="w-full flex flex-1 flex-col mr-4">
                                                                        <label className="block text-sm font-medium text-gray-900">
                                                                            Link
                                                                        </label>
                                                                        <div className="mt-1 min-w-full w-56">
                                                                            <input
                                                                                onBlur={async () => {
                                                                                    let url = value.url.trim();
                                                                                    if (url !== '' && this.renders.validDomain(url)) {
                                                                                        url = url.replace(/^(https?:\/\/)+/, 'https://');

                                                                                        if (url.startsWith('http://')) {
                                                                                            url = 'https://' + url.slice(7);
                                                                                        } else if (!url.startsWith('https://')) {
                                                                                            url = 'https://' + url;
                                                                                        }
                                                                                    } else {
                                                                                        url = '';
                                                                                    }
                                                                                    value.url = url;
                                                                                    this.setState({
                                                                                        buttons: this.state.buttons
                                                                                    });

                                                                                    await this.promisedSetState({
                                                                                        buttons: this.state.buttons
                                                                                    });
                                                                                    this.functions.updateUpdateButtons();
                                                                                }}
                                                                                onChange={(event) => {
                                                                                    value.url = event.target.value;
                                                                                    this.setState({
                                                                                        buttons: this.state.buttons
                                                                                    })
                                                                                }}
                                                                                value={value.url}
                                                                                type="text"
                                                                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginTop: "26px" }}>
                                                                        <button
                                                                            onClick={async () => {
                                                                                await this.promisedSetState({
                                                                                    buttons: this.state.buttons.filter((button) => { return button.id != value.id })
                                                                                });
                                                                                this.functions.updateUpdateButtons();
                                                                            }}
                                                                            type="button"
                                                                            className="inline-flex relative items-center overflow-hidden rounded-md bg-red-500 px-3 py-2 text-sm font-medium text-white hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                                        >
                                                                            Remove
                                                                            <TrashIcon className="w-5 ml-2"></TrashIcon>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col p-4 border-l justify-center items-center">
                                                                <Switch
                                                                    value={value.enabled}
                                                                    onSwitch={async () => {
                                                                        value.enabled = !value.enabled;
                                                                        await this.promisedSetState({
                                                                            buttons: this.state.buttons
                                                                        });
                                                                        this.functions.updateUpdateButtons();
                                                                    }}
                                                                />
                                                                <div className="mt-1 text-xxs">{value.enabled ? "Enabled" : "Disabled"}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div >
                }

                {
                    !this.state.loading &&
                    <div className="h-12 w-full"></div>
                }

            </div >
        )
    }
}

/*
    onSortEnd = async ({ oldIndex, newIndex }) => {
        await this.promisedSetState({
            buttons: Array.isArray(this.state.buttons) ? this.state.buttons : [],
        });
        let array_of_buttons = arrayMove((Array.isArray(this.state.buttons) ? this.state.buttons : []), oldIndex, newIndex);
        await this.promisedSetState({
            buttons: Array.isArray(array_of_buttons) ? array_of_buttons : Array.isArray(this.state.buttons) ? this.state.buttons : [],
        });
        this.functions.updateUpdateButtons();
    };

    SortableItem = SortableElement(({ value }) => {
        return (
            <div className={(value.index > 0 ? "mt-4" : "") + " overflow-x-scroll border rounded-md w-full flex bg-gray-50 relative"}>
                {
                    this.state.loading_buttons &&
                    <div className="absolute left-0 right-0 z-60 bg-white bg-opacity-50 bottom-0 top-0">
                        <div className="w-full h-full flex justify-center items-center">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    </div>
                }
                <div className="border-r p-4 cursor-pointer flex-col inline-flex items-center justify-center">
                    <ArrowUpIcon className="w-5 mb-1" />
                    <ArrowDownIcon className="w-5 mt-1" />
                </div>
                <div className="flex items-center pt-5 flex-col justify-center p-4">
                    {
                        !value.image &&
                        <span className="w-20 h-20 border flex items-center justify-center rounded-full relative overflow-hidden bg-white">
                            <PhotographIcon className="w-5" />
                            {
                                value.loading_image &&
                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </span>
                    }
                    {
                        value.image &&
                        <div style={{
                            backgroundImage: 'url(' + value.image + ')',
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }} className="rounded-full border relative sm:mb-0 w-20 h-20 overflow-hidden justify-center flex items-center">
                            {
                                value.loading_image &&
                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                    <div className="flex flex-1"></div>
                    <div className="relative bg-white py-2 px-3 h-10 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                        <label htmlFor="user-photo" className="relative text-sm font-medium text-blue-gray-900 pointer-events-none">
                            {value.image ? <span style={{ whiteSpace: "nowrap" }}>Change image</span> : <span style={{ whiteSpace: "nowrap" }}>Upload image</span>}
                            <span className="sr-only" style={{ whiteSpace: "nowrap" }}> user photo</span>
                        </label>
                        <input
                            onChange={(e) => this.functions.uploadImage(e, value)}
                            type="file"
                            accept={"image/png,image/jpg,image/jpeg"}
                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                        />
                    </div>
                </div>
                <div className="p-4 flex flex-1 flex-col">
                    <div className="w-full">
                        <label className="block text-sm font-medium text-gray-900">
                            Title
                        </label>
                        <div className="mt-1 w-full">
                            <input
                                onBlur={async () => {
                                    await this.promisedSetState({
                                        buttons: this.state.buttons
                                    });
                                    this.functions.updateUpdateButtons();
                                }}
                                onChange={async (event) => {
                                    value.title = event.target.value;
                                    await this.promisedSetState({
                                        buttons: this.state.buttons
                                    });
                                }}
                                value={value.title}
                                type="text"
                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row mt-4">
                        <div className="w-full flex flex-1 flex-col mr-4">
                            <label className="block text-sm font-medium text-gray-900">
                                Website
                            </label>
                            <div className="mt-1 min-w-full w-56">
                                <input
                                    onBlur={async () => {
                                        await this.promisedSetState({
                                            buttons: this.state.buttons
                                        });
                                        this.functions.updateUpdateButtons();
                                    }}
                                    onChange={(event) => {
                                        value.url = event.target.value;
                                        this.setState({
                                            buttons: this.state.buttons
                                        })
                                    }}
                                    value={value.url}
                                    type="text"
                                    className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "26px" }}>
                            <button
                                onClick={async () => {
                                    await this.promisedSetState({
                                        buttons: this.state.buttons.filter((button) => { return button.id != value.id })
                                    });
                                    this.functions.updateUpdateButtons();
                                }}
                                type="button"
                                className="inline-flex relative items-center overflow-hidden rounded-md bg-red-500 px-3 py-2 text-sm font-medium text-white hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                Remove
                                <TrashIcon className="w-5 ml-2"></TrashIcon>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col p-4 border-l justify-center items-center">
                    <Switch
                        value={value.enabled}
                        onSwitch={async () => {
                            value.enabled = !value.enabled;
                            await this.promisedSetState({
                                buttons: this.state.buttons
                            });
                            this.functions.updateUpdateButtons();
                        }}
                    />
                    <div className="mt-1 text-xxs">{value.enabled ? "Enabled" : "Disabled"}</div>
                </div>
            </div>
        )
    });

    SortableList = SortableContainer(({ buttons }) => {
        return (
            <div className="w-full">
                {Array.isArray(buttons) && buttons.map((value, index) => {
                    value.index = index;
                    return (<this.SortableItem index={index} value={value} />)
                })}
            </div>
        );
    });

    renderSortableList() {
        return (
            <div className="w-full">
                <this.SortableList
                    buttons={Array.isArray(this.state.buttons) ? this.state.buttons : []}
                    onSortEnd={this.onSortEnd}
                    axis="y"
                    distance={1}
                />
            </div>
        )
    }
*/

export default Settings;
