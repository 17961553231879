import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CursorClickIcon, MenuIcon, ShareIcon, XIcon } from '@heroicons/react/outline'
import { userRegister } from '../services/userRegister';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import ProptreePage from './page';

const navigation = [
    //{ name: 'Listings', link: '/agent/listings' },
    //{ name: 'Leads', link: '/agent/leads' }
];

class ProptreeMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            listings: [],
            sidebarOpen: false,
            version: {},
            linkCopied: false,
            loading: true,
            no_page: false,
            preview: false
        }
    };

    componentWillMount() {

    }

    componentDidMount() {
        if (window.location.search.indexOf("preview=true") !== -1) {
            this.setState({
                preview: true
            });
            this.functions.getProfilePreview();
        } else {
            this.functions.getProfile();
        }
    }

    functions = {
        getProfile: async () => {
            try {
                if (this.props.match.params.id) {
                    let response = await this.calls.getProptree(this.props.match.params.id);
                    this.setState({
                        user: response.data,
                        loading: false
                    });
                } else {
                    let values = window.location.pathname.replace("/proptree/", "");
                    let values_parsed = values.split("/");
                    if (values_parsed.length > 1) {
                        let response = await this.calls.getProfile(values_parsed[0], values_parsed[1], values_parsed[2]);
                        this.setState({
                            user: response.data,
                            loading: false
                        });
                    }
                }
            } catch (error) {
                this.setState({
                    no_page: true
                });
            }
        },
        getProfilePreview: async () => {
            try {
                let response = await this.calls.getProfilePreview();
                this.setState({
                    user: response.data,
                    loading: false
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    renders = {
        fallbackCopyTextToClipboard: (text) => {
            let textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
                alert('Proptree copied to clipboard!');
            } catch (err) {
                console.error('Copy to clipboard failed');
            }
            document.body.removeChild(textArea);
        }
    };

    calls = {
        getProfile: (version, email, enterprise) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.api + "/proptree/profile?email=" + email + "&" + version + "=true" + "&enterprise=" + enterprise;
            return apiRegister.call(options, url);
        },
        getProptree: (id) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.proptree + "/getProptree/" + id;
            return apiRegister.call(options, url);
        },
        getProfilePreview: () => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.proptree + "/getProptreePreview" + window.location.search;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            this.state.no_page ?
                <div style={{ backgroundColor: "#F3F3F1" }} className="fixed left-0 right-0 top-0 bottom-0">
                    <div className="background-pattern h-full w-full flex items-center justify-center">
                        <div className="flex items-center justify-center flex-col">
                            <img className="w-24 sm:w-32 md:w-32 mb-5" src={require("../assets/images/Proptree_Logotype_RGB_Black.svg")} />
                            <div className="flex items-center justify-center flex-col">
                                <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl sm:max-w-130 text-center sm:text-4xl md:text-7xl">
                                    This page doesn't exist
                                </div>
                                <div className="flex flex-1 justify-center">
                                    <a target="_blank" href="https://proptr.ee" style={{ fontSize: "1.1rem", backgroundColor: "#1E2330" }} className="rounded-full cursor-pointer text-white shadow-sm mt-10 inline-flex items-center justify-center h-16 px-12">
                                        Main page
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div > :
                <div className="min-h-full h-full">

                    {/*LOADING*/}
                    {
                        this.state.loading &&
                        <div className="relative min-h-screen flex justify-center items-start">
                            <div className="w-full absolute h-full flex justify-center items-center flex-col pb-32">
                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            </div>
                        </div>
                    }

                    {
                        !this.state.loading &&
                        <Disclosure as="nav" className="border-b border-gray-200 bg-white z-50 fixed left-0 right-0">
                            {({ open }) => (
                                <>
                                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                        <div className="flex h-16 justify-between">
                                            <div className="flex">
                                                <div className="flex flex-shrink-0 items-center">
                                                    {
                                                        /*
                                                        this.state.user.enterprise &&
                                                        <img
                                                            className="h-12 w-auto hidden sm:block"
                                                            src={this.state.user.enterprise.logo}
                                                            alt="Your Company"
                                                        />
                                                    */
                                                    }
                                                    {
                                                        this.state.loading &&
                                                        <div
                                                            className="h-12 w-12 hidden sm:block bg-gray-200 animate-pulse rounded-full"
                                                        />
                                                    }
                                                    {
                                                        this.state.loading &&
                                                        <div
                                                            className="h-12 w-12 block sm:hidden bg-gray-200 animate-pulse rounded-full"
                                                        />
                                                    }
                                                    {
                                                        !this.state.loading && this.state.user.enterprise && this.state.user.enterprise.favicon &&
                                                        <img
                                                            className={this.state.user.type !== "custom" && this.state.user.proptree_agency_short_id && this.state.user.proptree_agency_short_id !== "" ? "h-12 w-12 hidden sm:block cursor-pointer" : "h-12 w-12 hidden sm:block"}
                                                            src={this.state.user.enterprise.favicon}
                                                            alt=""
                                                            onClick={() => {
                                                                if (this.state.user.type !== "custom" && this.state.user.proptree_agency_short_id && this.state.user.proptree_agency_short_id !== "") {
                                                                    let user_name = this.state.user.proptree_agency_short_id;
                                                                    if (this.state.user.proptree_agency_alias && this.state.user.proptree_agency_alias !== "") {
                                                                        user_name = this.state.user.proptree_agency_alias;
                                                                    }
                                                                    let proptree_url = "";
                                                                    if (window.location.hostname === 'localhost') {
                                                                        proptree_url = "http://localhost:5001/" + user_name + "/proptree";
                                                                    } else {
                                                                        proptree_url = "https://proptr.ee/" + user_name;
                                                                    }
                                                                    proptree_url = proptree_url + '?utm_medium=favicon&utm_campaign=' + this.state.user.shortId;
                                                                    window.open(proptree_url, '_blank');
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        !this.state.loading && this.state.user.enterprise && this.state.user.enterprise.favicon &&
                                                        <img
                                                            className={this.state.user.type !== "custom" && this.state.user.proptree_agency_short_id && this.state.user.proptree_agency_short_id !== "" ? "h-12 w-12 block sm:hidden cursor-pointer" : "h-12 w-12 block sm:hidden"}
                                                            src={this.state.user.enterprise.favicon}
                                                            alt=""
                                                            onClick={() => {
                                                                if (this.state.user.type !== "custom" && this.state.user.proptree_agency_short_id && this.state.user.proptree_agency_short_id !== "") {
                                                                    let user_name = this.state.user.proptree_agency_short_id;
                                                                    if (this.state.user.proptree_agency_alias && this.state.user.proptree_agency_alias !== "") {
                                                                        user_name = this.state.user.proptree_agency_alias;
                                                                    }
                                                                    let proptree_url = "";
                                                                    if (window.location.hostname === 'localhost') {
                                                                        proptree_url = "http://localhost:5001/" + user_name + "/proptree";
                                                                    } else {
                                                                        proptree_url = "https://proptr.ee/" + user_name;
                                                                    }
                                                                    proptree_url = proptree_url + '?utm_medium=favicon&utm_campaign=' + this.state.user.shortId;
                                                                    window.open(proptree_url, '_blank');
                                                                }
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                {/* Profile dropdown */}
                                                <div className="flex flex-row items-center">
                                                    <div className="flex items-center">
                                                        <Menu as="div" className="ml-3 relative">
                                                            <div>
                                                                <a
                                                                    href={this.state.preview ? "#" : ""}
                                                                    onClick={(e) => {

                                                                        if (!this.state.preview) {

                                                                            e.preventDefault();
                                                                            let shareUrl = window.location.origin + window.location.pathname + '?utm_medium=share';

                                                                            // Check if navigator.share is supported
                                                                            if (navigator.share) {
                                                                                navigator.share({
                                                                                    title: 'Check out this proptree !',
                                                                                    //text: 'Share this link',
                                                                                    url: shareUrl
                                                                                }).then(() => {
                                                                                    //console.log('Shared successfully');
                                                                                }).catch((error) => {
                                                                                    //TODO: change state triggers refresh
                                                                                    //this.setState({ linkCopied: true });
                                                                                    //this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                                                });
                                                                            } else if (navigator.clipboard && navigator.clipboard.writeText) {
                                                                                // Fallback to clipboard API if navigator.share is not supported
                                                                                navigator.clipboard.writeText(shareUrl).then(() => {
                                                                                    this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                                                }).catch((error) => {
                                                                                    //TODO: change state triggers refresh
                                                                                    //this.setState({ linkCopied: true });
                                                                                    this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                                                });
                                                                            } else {
                                                                                //TODO: change state triggers refresh
                                                                                //this.setState({ linkCopied: true });
                                                                                this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                                            }

                                                                        }
                                                                    }}
                                                                    className="h-10 hover:bg-gray-100 items-center justify-center text-sm font-semibold text-gray-900 px-4 rounded-md flex flex-row focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                                >
                                                                    <div>Click</div>
                                                                    <CursorClickIcon className="w-5 h-5 ml-2 mr-2"></CursorClickIcon>
                                                                    <div>Share</div>
                                                                </a>
                                                                {
                                                                    false && this.state.linkCopied &&
                                                                    <div className="absolute top-0 -ml-40 mt-2 bg-white border border-gray-200 text-xxs text-green-500 px-2 py-1 rounded-md shadow-lg">
                                                                        Proptree copied to clipboard!
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Menu>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Disclosure.Panel className="sm:hidden">
                                        <div className="space-y-1 pt-2 pb-3">
                                            {navigation.map((item) => (
                                                <Disclosure.Button
                                                    onClick={() => {
                                                        this.props.history.push(item.link);
                                                    }}
                                                    key={item.name}
                                                    as="div"
                                                    className={classNames(
                                                        window.location.pathname === item.link
                                                            ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                                                            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer'
                                                    )}
                                                    aria-current={window.location.pathname === item.link ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </Disclosure.Button>
                                            ))}
                                        </div>
                                        <div className="border-t border-gray-200 pt-4 pb-3">
                                            <div className="flex items-center px-4">
                                                <div className="flex-shrink-0">
                                                    {
                                                        !this.state.user.image &&
                                                        <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                            </svg>
                                                        </span>
                                                    }
                                                    {
                                                        this.state.user.image &&
                                                        <img className="h-10 w-10 rounded-full" src={this.state.user.image} alt="" />
                                                    }
                                                </div>
                                                <div className="ml-3">
                                                    <div className="text-base font-medium text-gray-800">{this.state.user.name}</div>
                                                    <div className="text-sm font-medium text-gray-500">{this.state.user.email}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    }
                    {
                        !this.state.loading &&
                        <div className="pt-16 min-h-full h-full">
                            <main>
                                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 h-full min-h-full pt-8">
                                    <div className="h-full min-h-full">
                                        {
                                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.adbooster') !== -1) &&
                                            <Route exact path={"/:id/proptree"}>
                                                <ProptreePage secondaryPreviewFeed={this.state.user.secondaryFeed} showAgentsBanner={this.state.user.agentsBanner} showAgencyBanner={this.state.user.agencyBanner} showFeedBanner={this.state.user.feedBanner} preview={window.location.search.indexOf("preview=true") !== -1} alias={this.props.match.params.id} user={this.state.user} history={this.props.history} />
                                            </Route>
                                        }
                                        {
                                            (window.location.origin.indexOf('proptr.') !== -1) &&
                                            <Route exact path={"/:id"}>
                                                <ProptreePage secondaryPreviewFeed={this.state.user.secondaryFeed} showAgentsBanner={this.state.user.agentsBanner} showAgencyBanner={this.state.user.agencyBanner} showFeedBanner={this.state.user.feedBanner} alias={this.props.match.params.id} user={this.state.user} history={this.props.history} />
                                            </Route>
                                        }
                                    </div>
                                </div>
                            </main>
                        </div>
                    }
                </div>
        )
    }
}

export default ProptreeMenu;
