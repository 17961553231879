import React, { Component } from 'react';

import { apiRegister } from "../services/apiRegister";

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            confirm_password: "",
            token: "",
            loading: false,
            success: false
        }
    };

    componentDidMount() {
        this.setState({
            token: this.props.match ? this.props.match.params.token : null
        })
    }

    functions = {
        resetPassword: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true
                }, () => {
                    let function_call = {
                        function: "resetPassword",
                        data: { 
                            password: this.state.password, 
                            confirm_password: this.state.confirm_password,
                            token: this.state.token
                         }
                    };
                    this.calls.resetPassword([function_call]).then((response) => {
                        if (Array.isArray(response.data) && response.data[0]) {
                            if (response.data[0].status == 200) {
                                this.setState({
                                    loading: false,
                                    success: true
                                });
                            } else {
                                this.setState({
                                    loading: false,
                                    error: true
                                });
                            }
                        }
                    }, (error) => {
                        this.setState({
                            loading: false,
                            error: true
                        });
                    });
                })
            }
        }
    };

    calls = {
        resetPassword: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/v2/controllers/batchFunctions";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <>
                
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        {
                            /*
                                <img
                            className="mx-auto h-12 w-auto"
                            src={require("../assets/images/adcredo_icon_purple.png")}
                            alt="Workflow"
                        />
                            */
                        }
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset your password</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Please type your new password below
                        </p>
                    </div>

                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div className="space-y-6">
                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onChange={(event) => {
                                                this.setState({
                                                    password: event.target.value
                                                })
                                            }}
                                            value={this.state.password}
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700">
                                        Type password again
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onChange={(event) => {
                                                this.setState({
                                                    confirm_password: event.target.value
                                                })
                                            }}
                                            value={this.state.confirm_password}
                                            id="confirm_password"
                                            name="confirm_password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                {/*
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <input
                                                id="remember-me"
                                                name="remember-me"
                                                type="checkbox"
                                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                                Remember me
                                            </label>
                                        </div>

                                        <div className="text-sm">
                                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                Forgot your password?
                                            </a>
                                        </div>
                                    </div>
                                */}

                                <div>
                                    <button
                                        onClick={() => {
                                            this.functions.resetPassword();
                                        }}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {
                                            this.state.loading &&
                                            <div>
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        {
                                            !this.state.loading &&
                                            <div>Reset</div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ResetPassword;
