import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateAdvertisingRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            admin: false,
            open: false,
            partner: {},
            listing: {}
        }
    };

    componentDidMount() {
        this.setState({
            partner: this.props.partner,
            admin: this.props.admin,
            listing: this.props.listing,
            open: this.props.open,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            listing: nextProps.listing,
            admin: nextProps.admin,
            partner: nextProps.partner,
            open: nextProps.open,
        })
    }

    functions = {
        createUser: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let response = await this.calls.createUser(this.state.listing);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onUser) {
                    this.props.onUser(response.data);
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        uploadImage: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_image: true
                }, () => {
                    this.calls.uploadImage(formData).then((response) => {
                        this.state.listing.image = response.data;
                        this.setState({
                            listing: this.state.listing,
                            loading_image: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_image: false
                        })
                    });
                });
            }
        }
    }

    calls = {
        createUser: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/user/create";
            return apiRegister.call(options, url);
        },
        uploadImage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/image";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open ? true : false} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-4xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">New request</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto mt-5">

                                            <div>
                                                <div className="w-full  flex items-center">
                                                    <div className="px-6 flex flex-row overflow-x-auto">
                                                        {
                                                            Array.isArray(this.state.listing.feedImages) &&
                                                            this.state.listing.feedImages.map((item) => {
                                                                return (
                                                                    <div className="text-center">
                                                                        <div style={{ width: '250px', height: "250px" }} className=" mr-6 bg-white">
                                                                            <img className="w-full" src={item.image} alt="" />
                                                                        </div>
                                                                        <div
                                                                            className={"cursor-pointer mt-4 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md hover:text-purple-500 text-gray-700 bg-white hover:bg-purple-100"}
                                                                            onClick={() => {

                                                                            }} >Select</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex-1 flex flex-col justify-between">
                                                    <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                        <div className="space-y-6 pb-5">
                                                            <div>
                                                                <label htmlFor="website" className="block text-sm font-medium text-gray-900">
                                                                    Website
                                                                </label>
                                                                <div className="mt-1">
                                                                    <input
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                website: event.target.value
                                                                            })
                                                                        }}
                                                                        value={this.state.website}
                                                                        type="text"
                                                                        name="website"
                                                                        id="website"
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex-1 flex flex-col justify-between">
                                                    <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                        <div className="space-y-6 pb-5">
                                                            <div>
                                                                <label htmlFor="headline" className="block text-sm font-medium text-gray-900">
                                                                    Headline
                                                                </label>
                                                                <div className="mt-1">
                                                                    <input
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                headline: event.target.value
                                                                            })
                                                                        }}
                                                                        value={this.state.headline}
                                                                        type="text"
                                                                        name="headline"
                                                                        id="headline"
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="flex-1 flex flex-col justify-between">
                                                    <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                        <div className="space-y-6 pb-5">
                                                            <div>
                                                                <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                                                    Description
                                                                </label>
                                                                <div className="mt-1">
                                                                    <textarea
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                body: event.target.value
                                                                            })
                                                                        }}
                                                                        rows="5"
                                                                        value={this.state.body}
                                                                        type="text"
                                                                        name="description"
                                                                        id="description"
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                this.state.error &&
                                                <div className="px-6 pb-6">
                                                    <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <ul role="list" className="list-disc pl-5 space-y-1">
                                                                        <li>{this.state.error}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-shrink-0 px-4 sm:px-6 py-4 flex justify-end">
                                            <button
                                                onClick={() => {

                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <span>Create</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateAdvertisingRequest;
