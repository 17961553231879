import React, { Component, Fragment } from 'react';
import { PlusIcon } from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import CreateUser from '../components/createUser';
import WarningModal from '../components/warningModal';
import CreateAd from '../components/createAd';
import { RefreshIcon, PlusCircleIcon, TrashIcon, PencilIcon, ArchiveIcon, ChevronDownIcon, CheckIcon, XIcon, LocationMarkerIcon, DeviceMobileIcon } from '@heroicons/react/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListAds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            ads: [],
            user: {},
            tab: 1,
            tet: true,
        }
    };

    componentDidMount() {
        this.functions.getAds();
        this.functions.getUser();
    }

    functions = {
        getUser: () => {
            let user = userRegister.get();
            this.setState({
                user: user
            })
        },
        getAds: async () => {
            try {
                let response = await this.calls.getAds();
                this.setState({
                    ads: response.data,
                    loading: false
                })
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        },
        removeEnterprise: async () => {
            let selected_enterprise = JSON.parse(JSON.stringify(this.state.remove));
            this.setState({
                remove: null,
                enterprise: this.state.enterprise.map((item) => {
                    if (item.id == selected_enterprise.id) {
                        item.loading = true;
                    }
                    return item;
                })
            }, async () => {
                try {
                    let response = await this.calls.removeEnterprise(selected_enterprise.id);
                    this.setState({
                        enterprise: this.state.enterprise.filter((item) => { return response.data.id != item.id }),
                        remove: null
                    })
                } catch (error) {
                    this.setState({
                        remove: null,
                        enterprise: this.state.enterprise.map((item) => {
                            if (item.id == selected_enterprise.id) {
                                item.loading = false;
                            }
                            return item;
                        })
                    })
                }
            })
        }
    }

    calls = {
        getAds: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/ads/list";
            return apiRegister.call(options, url);
        },
        removeEnterprise: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/enterprise/remove/" + id;
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <>
                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete Enterprise"}
                    description={"Are you sure you want to delete this enterprise? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    onClose={() => {
                        this.setState({
                            remove: null
                        })
                    }}
                    onSubmit={() => {
                        this.functions.removeEnterprise();
                    }}
                />

                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center bg-yellow-100 border-yellow-500">
                                        <div>
                                            <DeviceMobileIcon className="h-8 w-8 text-yellow-500" aria-hidden="true" />
                                            {
                                                false &&
                                                this.state.user.image &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.image}
                                                    alt=""
                                                />
                                            }
                                            {
                                                false &&
                                                !this.state.user.image &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Ads</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*AGENT HEADER*/}
                {
                    this.state.user && this.state.user.level && this.state.user.level.id == 2 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.enterprise && this.state.user.enterprise.logo &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.enterprise.logo}
                                                    alt=""
                                                />
                                            }
                                            {
                                                (!this.state.user.enterprise || (this.state.user.enterprise && !this.state.user.enterprise.logo)) &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Ads</p>
                                        <p className="text-sm font-medium text-gray-600">{this.state.user.enterprise.companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*LIST ADS*/}
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        {
                            this.state.ads.length > 0 && !this.state.loading &&
                            <section>
                                <div className="bg-white pt-6 shadow sm:rounded-md">
                                    <div className="px-4 sm:px-6 flex sm:flex-row flex-col-reverse">
                                        <div className="flex-1 mt-4 sm:mt-0">
                                            <div className="block">
                                                <nav className="flex sm:flex-row flex-col w-auto mr-0 sm:mr-6" aria-label="Tabs">
                                                    {[{ id: 1, name: "Feed Ads" }, { id: 2, name: "Lead Ads" }].map((tab) => (
                                                        <div
                                                            onClick={() => {
                                                                this.setState({
                                                                    tab: tab.id
                                                                })
                                                            }}
                                                            key={tab.name}
                                                            className={classNames(
                                                                tab.id === this.state.tab ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                                                                'px-3 py-2 font-medium text-sm rounded-md cursor-pointer'
                                                            )}
                                                            aria-current={tab.id === this.state.tab ? 'page' : undefined}
                                                        >
                                                            {tab.name}                                                        </div>
                                                    ))}
                                                </nav>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    selected: null
                                                })
                                            }}
                                            type="button"
                                            className="inline-flex items-center h-10 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Create new
                                        </button>
                                    </div>
                                    <div className="flex mt-6 flex-col">
                                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div className="border-t border-gray-200">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-100">
                                                            <tr>
                                                                <th scope="col" className="relative px-6 py-3">
                                                                    <span className="sr-only">Actions</span>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Name
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Channel
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Id
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {this.state.ads.filter(((item) => {
                                                                return !item.type || (item.type.id === this.state.tab)
                                                            })).map((item) => (
                                                                <tr key={item.email}>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                                                        <div className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                                                            {
                                                                                <Menu as="div" className="relative inline-block text-left">
                                                                                    <div>
                                                                                        <Menu.Button className="inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                                                            Options
                                                                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                            {
                                                                                                item.loading &&
                                                                                                <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                                        class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                </div>
                                                                                            }
                                                                                        </Menu.Button>
                                                                                    </div>
                                                                                    <Transition
                                                                                        as={Fragment}
                                                                                        enter="transition ease-out duration-100"
                                                                                        enterFrom="transform opacity-0 scale-95"
                                                                                        enterTo="transform opacity-100 scale-100"
                                                                                        leave="transition ease-in duration-75"
                                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                                        leaveTo="transform opacity-0 scale-95"
                                                                                    >
                                                                                        <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                            <div className="py-1">
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.props.history.push("/dashboard/report/" + item.externalId);
                                                                                                            }}
                                                                                                            className={classNames(
                                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                'block px-4 py-2 text-sm relative'
                                                                                                            )}
                                                                                                        >
                                                                                                            Report
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                                {
                                                                                                    this.state.user && this.state.user.level && this.state.user.level.id === 1 &&
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        remove: item
                                                                                                                    })
                                                                                                                }}
                                                                                                                className={classNames(
                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                    'block px-4 py-2 text-sm relative'
                                                                                                                )}
                                                                                                            >
                                                                                                                Delete
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                }
                                                                                            </div>
                                                                                        </Menu.Items>
                                                                                    </Transition>
                                                                                </Menu>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        {item.name}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        {item.channel.name}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                        #{item.externalId}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        }

                        {/*CREATE AD*/}
                        <CreateAd
                            open={this.state.open}
                            user={this.state.user}
                            toggle={() => {
                                this.setState({
                                    open: !this.state.open
                                })
                            }}
                            onEnterprise={(enterprise) => {
                                this.state.enterprise.push(enterprise);
                                this.setState({
                                    enterprise: this.state.enterprise
                                })
                            }}
                        />

                        {/*NO ADS*/}
                        {
                            this.state.ads.length < 1 && !this.state.loading &&
                            <div className="text-center pt-16">
                                <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        vectorEffect="non-scaling-stroke"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                    />
                                </svg>
                                <h3 className="mt-2 text-sm font-medium text-gray-900">No ads</h3>
                                <p className="mt-1 text-sm text-gray-500">Get started by creating an Ad.</p>
                                <div className="mt-6">
                                    <button
                                        onClick={() => {
                                            this.setState({
                                                open: true
                                            })
                                        }}
                                        type="button"
                                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                        New Ad
                                    </button>
                                </div>
                            </div>
                        }

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default ListAds;
