import React, { Component, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { CashIcon, ChartPieIcon, CurrencyDollarIcon, CursorClickIcon, HomeIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import SingleSelection from '../components/singleSelection';
import { Line, Bar } from "react-chartjs-2";
import TestData from '../assets/test.json';
import { RefreshIcon, PlusCircleIcon, TrashIcon, PencilIcon, ArchiveIcon, ChevronDownIcon, CheckIcon, XIcon, LocationMarkerIcon } from '@heroicons/react/outline'
var moment = require('moment');

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            ads: [],
            ad: { id: 0, name: "Select Ad", value: "0" },
            date_preset: { id: 0, name: "Select date preset", value: "0" },
            breakdown: { id: 0, name: "Select breakdown", value: "0" },
            report: null,
            loading: true
        }
    };

    componentWillMount() {
        this.functions.getUser();
        this.functions.getAds();
    }

    componentDidMount() {
    }

    functions = {
        getUser: () => {
            let user = userRegister.get();
            this.setState({
                user: user
            })
        },
        getAds: async () => {
            try {
                let response = await this.calls.getAds();
                this.setState({
                    ads: response.data,
                    loading: false
                })
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        },
        createReport: async () => {
            try {
                let response = await this.calls.createReport();
                this.setState({
                    report: response.data,
                    loading_report: false
                })
            } catch (error) {
                this.setState({
                    loading_report: false
                })
            }
        },
    };

    calls = {
        getEnterprise: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/enterprise/list";
            return apiRegister.call(options, url);
        },
        createReport: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/report/create?ad=" + this.state.ad.id + "&date_preset=" + this.state.date_preset.value + "&breakdown=" + this.state.breakdown.value;
            return apiRegister.call(options, url);
        },
        getAds: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/ads/list";
            return apiRegister.call(options, url);
        },
    };

    render() {
        return (
            <>
                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center bg-blue-100 border-blue-500">
                                        <div>
                                            <ChartPieIcon className="h-8 w-8 text-blue-500" aria-hidden="true" />
                                            {
                                                false &&
                                                this.state.user.image &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.image}
                                                    alt=""
                                                />
                                            }
                                            {
                                                   false &&
                                                !this.state.user.image &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Reports</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*AGENT HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 2 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.enterprise && this.state.user.enterprise.logo &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.enterprise.logo}
                                                    alt=""
                                                />
                                            }
                                            {
                                                (!this.state.user.enterprise || (this.state.user.enterprise && !this.state.user.enterprise.logo)) &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Reports</p>
                                        <p className="text-sm font-medium text-gray-600">{this.state.user.enterprise.companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">

                        {/* */}
                        {
                            !this.state.loading &&
                            <div className="mb-8">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                        <div className="flex items-center">
                                            <div className="flex flex-1 flex-col">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">Create report</h3>
                                                <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_report && this.state.ad.id !== 0 && this.state.date_preset.id !== 0 && this.state.breakdown.id !== 0) {
                                                            this.setState({
                                                                loading_report: true
                                                            }, () => {
                                                                this.functions.createReport();
                                                            })
                                                        }
                                                    }}
                                                    type="button"
                                                    className={((this.state.ad.id === 0 || this.state.date_preset.id === 0 || this.state.breakdown.id === 0) ? "opacity-50 cursor-not-allowed" : "") + " inline-flex relative items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                >
                                                    {
                                                        this.state.loading_report &&
                                                        <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    <ChartPieIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                    Create report
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                    {
                                                        false &&
                                                    <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                        Select Ad
                                                    </label>
                                                    }
                                                    <div className="mt-1">
                                                        <SingleSelection
                                                            select={(option) => {
                                                                this.setState({
                                                                    ad: option
                                                                })
                                                            }}
                                                            selected={this.state.ad}
                                                            options={this.state.ads}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                    {
                                                        false &&
                                                    <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                        Date preset
                                                    </label>
                                                    }
                                                    <div className="mt-1">
                                                        <SingleSelection
                                                            select={(option) => {
                                                                this.setState({
                                                                    date_preset: option
                                                                })
                                                            }}
                                                            selected={this.state.date_preset}
                                                            options={[
                                                                { id: 1, name: "Total", value: "total" },
                                                                { id: 2, name: "Last 30 days", value: "30" },
                                                                { id: 3, name: "Last 14 days", value: "14" },
                                                                { id: 4, name: "Last 7 days", value: "7" }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                    <div>
                                                        {
                                                            false &&
                                                        <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                            Breakdown
                                                        </label>
                                                        }
                                                        <SingleSelection
                                                            select={(option) => {
                                                                this.setState({
                                                                    breakdown: option
                                                                })
                                                            }}
                                                            selected={this.state.breakdown}
                                                            options={[
                                                                { id: 1, name: "Daily", value: "overview" },
                                                                { id: 2, name: "Gender", value: "gender" },
                                                                { id: 3, name: "Age", value: "age" },
                                                                { id: 4, name: "Region", value: "region" }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="grid grid-cols-6 bg-white border-t">
                                        {
                                            !this.state.report &&
                                            <div className="col-span-12 pb-24 flex items-start justify-center pt-24 py-6 px-4">
                                                <div className="text-center max-w-sm">
                                                    <h2 className="mt-2 text-lg font-medium text-gray-900">Select Ad, date preset and breakdown</h2>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        You haven’t added any listings to your project yet. As the admin of this enterprise, you can sync and manage listings.
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.state.loading_report && this.state.report &&
                                            <div className="flex col-span-12 flex-col">
                                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div className="">
                                                            <ChartComponent
                                                                breakdown={this.state.breakdown}
                                                                chart={this.state.report.chart}
                                                            />
                                                            <TableComponent
                                                                table={this.state.report.table}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }


                    </div>
                </div>

            </>
        )
    }
}

class TableComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_count: 10,
            page: 1,
            table: {}
        };
    };

    componentDidMount() {
        this.setState({
            table: this.props.table
        })
    }

    functions = {
        downloadCsv: () => {
            let filename = "" + ".csv";
            const replacer = (key, value) => value === null ? "" : value;
            let header = this.state.table.columns.map((metric) => {
                return metric;
            });
            let csv = this.state.table.rows.map(row => header.map(fieldName =>
                JSON.stringify(row[fieldName], replacer)).join(','))
            csv.unshift(header.join(','))
            csv = csv.join('\r\n')
            var link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        return (
            <>
                <div className="w-full">
                    {
                        false &&
                        <div style={{ backgroundColor: "#fcfcfc", height: "55px" }} className="px-4 rounded-t-lg flex border-b justify-center items-center relative">
                            <div className="flex flex-row w-full h-full justify-center items-center">
                                {
                                    <button
                                        onClick={() => {
                                            this.functions.downloadCsv();
                                        }}
                                        className="text-xs font-bold flex items-center px-4 h-8 justify-center bg-opacity-25 relative bg-purple-500 text-purple-500 rounded transition-all duration-200 hover:bg-opacity-100 hover:text-white">
                                        <span>Export (.csv)</span>
                                    </button>
                                }
                            </div>
                        </div>
                    }

                    <div className="overflow-scroll">
                        <table className="min-w-full divide-y divide-gray-200 bg-white table-fixed"
                            id="reportstable">
                            <thead className="bg-gray-100">
                                <tr>
                                    {
                                        Array.isArray(this.state.table.columns) &&
                                        this.state.table.columns.map((column, index) => {
                                            return (
                                                <th key={index} onClick={() => {
                                                    this.setState({
                                                        sort: column,
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    });
                                                }} className={"cursor-pointer transition-all duration-200  text-left text-xs leading-4 font-medium text-gray-500 uppercase " + (this.state.sort === column ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="px-6 py-3 flex flex-row items-center whitespace-no-wrap">
                                                        <div>
                                                            {column}
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 relative">
                                {
                                    this.state.table &&
                                    Array.isArray(this.state.table.rows) &&
                                    this.state.table.rows.sort((a, b) => {
                                        if (this.state.order === 'asc') {
                                            return (a[this.state.sort] > b[this.state.sort]) ? -1 : 1
                                        } else {
                                            return (a[this.state.sort] > b[this.state.sort]) ? 1 : -1
                                        }
                                    }).filter((item, index) => {
                                        return ((index >= ((this.state.page * this.state.page_count) - this.state.page_count)) && (index < (this.state.page * this.state.page_count)));
                                    }).map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                {
                                                    <tr>
                                                        {
                                                            Array.isArray(this.state.table.columns) &&
                                                            this.state.table.columns.map((column, i) => {
                                                                return (
                                                                    <td
                                                                        key={i}
                                                                        className={(this.state.sort === column ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                    >
                                                                        <div className="flex flex-row items-center">
                                                                            <div className={"text-sm leading-5 text-gray-900 truncate"}>
                                                                                {item[column]}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </tbody >
                        </table >
                    </div >
                    {
                        this.state.table &&
                        Array.isArray(this.state.table.rows) &&
                        this.state.table.rows.length > 0 &&
                        <div className="">
                            <nav
                                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                aria-label="Pagination"
                            >
                                <div className="hidden sm:block">
                                    <p className="text-sm text-gray-700">
                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.table.rows.length ? this.state.table.rows.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                        <span className="font-medium">{this.state.table.rows.length}</span> results
                                    </p>
                                </div>
                                <div className="flex-1 flex justify-between sm:justify-end">
                                    <div
                                        onClick={() => {
                                            this.setState({
                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                            })
                                        }}
                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                    >
                                        Previous
                                    </div>
                                    <div
                                        onClick={() => {
                                            if ((((this.state.page) * this.state.page_count) <= this.state.table.rows.length)) {
                                                this.setState({
                                                    page: this.state.page + 1
                                                })
                                            }
                                        }}
                                        className={((((this.state.page) * this.state.page_count) > this.state.table.rows.length) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                    >
                                        Next
                                    </div>
                                </div>
                            </nav>
                        </div>
                    }
                </div >
            </>
        )
    }

}

class ChartComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            column: "clicks",
            bar: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            //suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0,
                            //max: 5
                        }
                    }]
                }
            },
            line: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            //suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0,
                            //max: 5
                        }
                    }]
                }
            },
        };
    };

    componentDidMount() {
        this.setState({
            chart: this.props.chart,
            breakdown: this.props.breakdown
        });
    }

    render() {
        return (
            <div className="rounded p-4 relative mb-4">
                <div className="inline-flex mb-4 flex-row">
                    {
                        this.state.chart &&
                        Object.keys(this.state.chart).map((item, index) => {
                            return (
                                <div onClick={() => {
                                    this.setState({
                                        column: null
                                    }, () => {
                                        this.setState({
                                            column: item
                                        })
                                    })
                                }} className={(this.state.column === item ? "text-gray-900 " : "text-gray-500") + " text-xs cursor-pointer font-bold ml-1 inline-flex items-center px-4 h-8 justify-center bg-opacity-25 bg-gray-500 transition-all duration-200"}>
                                    {item}
                                </div>
                            )
                        })
                    }
                </div>
                {
                    this.state.chart && this.state.chart[this.state.column] &&
                    <div style={{ height: "350px" }}>
                        {
                            this.state.breakdown && this.state.breakdown.value == "overview" &&
                            <Line
                                options={this.state.line}
                                data={this.state.chart[this.state.column]}
                            />
                            ||
                            <Bar
                                options={this.state.bar}
                                data={this.state.chart[this.state.column]}
                            />
                        }
                    </div>
                }
            </div>
        )
    }

}

export default Report;
