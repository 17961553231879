import React from "react";
import { Redirect, Route } from "react-router-dom";
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";

function ProtectedRouteAdmin({ component: Component, ...restOfProps }) {
  const isAuthenticated = tokenRegister.get();
  const user = userRegister.get();
  console.log(user);
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        (isAuthenticated && user && user.level && user.level.id === 1) ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRouteAdmin;