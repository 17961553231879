class versionRegister {

    static version = {};

    static set(version) {
        versionRegister.version = version;
        try {
            sessionStorage.setItem("version", JSON.stringify(version));
        } catch (e) { }
    }

    static get() {
        if (versionRegister.version.name) {
            return versionRegister.version
        } else {
            try {
                if (sessionStorage.getItem("version")) {
                    return JSON.parse(sessionStorage.getItem("version"));
                } else {
                    return { name: "Live", value: 'live', id: 1 };
                }
            } catch (e) {
                return { name: "Live", value: 'live', id: 1 };
            }
        }
    }

    static remove() {
        versionRegister.version = { name: "Live", value: 'live', id: 1 };
        try {
            sessionStorage.removeItem("version");
        } catch (e) { }
    }

}

export { versionRegister }