class proptreeRegister {

    static listings = {};
    static proptrees = {};

    static set(option, items) {
        proptreeRegister[option] = items;
        try {
            localStorage.setItem(option, JSON.stringify(items));
        } catch (e) { }
    }

    static get(option) {
        if (typeof proptreeRegister[option] == "object" && Object.keys(proptreeRegister[option]).length > 0) {
            return proptreeRegister[option]
        } else {
            try {
                if (localStorage.getItem(option)) {
                    return JSON.parse(localStorage.getItem(option));
                } else {
                    return {};
                }
            } catch (e) {
                return {};
            }
        }
    }

    static remove(option) {
        proptreeRegister[option] = {};
        try {
            localStorage.removeItem(option);
        } catch (e) { }
    }

}

export { proptreeRegister }