import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, DuplicateIcon, ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/outline'

class WarningModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            copyInput: this.props.copyInput
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            inputError: nextProps.inputError,
            copyInput: nextProps.copyInput,
            disableSubmitButton: nextProps.disableSubmitButton
        })
    }

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {
                    this.props.onClose();
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    {
                                        !this.props.softWarning &&
                                        !this.props.decisionModal &&
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                    }
                                    {
                                        !this.props.softWarning &&
                                        this.props.decisionModal &&
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <BeakerIcon className="h-6 w-6 text-purple-600" aria-hidden="true" />
                                        </div>
                                    }
                                    {
                                        this.props.softWarning &&
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                        </div>
                                    }
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {this.props.title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {this.props.description}
                                            </p>
                                        </div>
                                        {
                                            this.state.copyInput !== "" &&
                                            this.props.showInput &&
                                            <div className="mt-3">
                                                <label htmlFor="warning-name" className="block text-sm font-medium text-gray-700">
                                                    {"Name"}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                        <input
                                                            type="text"
                                                            name="warning-name"
                                                            readOnly={true}
                                                            id="warning-name"
                                                            className="block focus:outline-none w-full focus:ring-1 focus:ring-gray-300 focus:border-gray-300 rounded-none border rounded-l-md px-3 sm:text-sm border-gray-300"
                                                            value={this.state.copyInput}
                                                        />
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            this.setState({
                                                                input: this.state.copyInput
                                                            }, () => {
                                                                if (this.props.onInput) {
                                                                    this.props.onInput(this.state.input)
                                                                }
                                                            })
                                                        }}
                                                        type="button"
                                                        className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                                                    >
                                                        <DuplicateIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Copy</span>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.props.showInput &&
                                            <div className="mt-3">
                                                <label htmlFor="input-title" className="block text-sm font-medium text-gray-700">
                                                    {this.props.inputTitle}
                                                </label>
                                                <input
                                                    value={this.state.input}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            input: event.target.value
                                                        }, () => {
                                                            if (this.props.onInput) {
                                                                this.props.onInput(this.state.input)
                                                            }
                                                        })
                                                    }}
                                                    type="text"
                                                    name="input-title"
                                                    id="input-title"
                                                    className={(this.state.inputError ? "text-red-900 border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"}
                                                />
                                                {
                                                    this.state.inputError &&
                                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                                        {this.state.inputError}
                                                    </p>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    {
                                        !this.props.noSubmitButton &&
                                        <button
                                            type="button"
                                            className={(this.props.decisionModal ? "bg-indigo-600 focus:ring-indigo-500 hover:bg-indigo-700 " : "bg-red-600 focus:ring-red-500 ") + (this.state.disableSubmitButton ? "cursor-not-allowed bg-opacity-50 " : "hover:bg-red-700 focus:ring-red-500 ") + " w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"}
                                            onClick={() => {
                                                this.props.onSubmit();
                                            }}
                                        >
                                            {this.props.submitButtonText}
                                        </button>
                                    }
                                    {
                                        !this.props.noCloseButton &&
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => {
                                                this.setState({
                                                    input: ""
                                                });
                                                this.props.onClose();
                                            }}
                                        >
                                            {this.props.cancelButtonText}
                                        </button>
                                    }
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default WarningModal;
