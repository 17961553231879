import React, { Component } from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon, PhoneIcon } from '@heroicons/react/solid'
import { CollectionIcon, CursorClickIcon, ClipboardListIcon, DeviceMobileIcon, LibraryIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { versionRegister } from '../services/versionRegister';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            dashboard: {},
            stats: [],
            listings: [],
            loading: true
        }
    };

    componentWillMount() {
        this.functions.getUser();
    }

    componentDidMount() { }

    functions = {
        getUser: async () => {

            let user = userRegister.get();
            let version = versionRegister.get();

            let stats = [];
            let listings = [];

            if (user && user.level.id == 1) {
                listings = [
                    { id: "new_listings", url: "/dashboard/listings?status=1", name: 'New listings', stat: 0, icon: CollectionIcon, color: "red" },
                    { id: "updated_listings", url: "/dashboard/listings?status=3", name: 'Updated listings', stat: 0, icon: CollectionIcon, color: "red" },
                    { id: "removed_listings", url: "/dashboard/listings?status=4", name: 'Removed listings', stat: 0, icon: CollectionIcon, color: "red" },
                    { id: "pending_listings", url: "/dashboard/listings?status=5", name: 'Pending listings', stat: 0, icon: CollectionIcon, color: "gray" },
                    { id: "live_listings", url: "/dashboard/listings?status=2", name: 'Ready listings', stat: 0, icon: CollectionIcon, color: "green" }
                ];
                stats = [
                    { id: "enterprise", name: 'Enterprises', stat: 0, icon: LibraryIcon, color: "purple" },
                    { id: "legacy_feeds", name: 'Legacy feeds', stat: 0, icon: ClipboardListIcon, color: "orange" },
                    { id: "light_feeds", name: 'Scale feeds', stat: 0, icon: ClipboardListIcon, color: "orange" },
                    //{ id: "ads", name: 'Ads', stat: 0, icon: DeviceMobileIcon, color: "yellow" },
                    { id: "admins", name: 'Admins', stat: 0, icon: UsersIcon, color: "gray" },
                    { id: "agents", name: 'Agents', stat: 0, icon: UsersIcon, color: "gray" },
                ];
            } else if (user && user.level.id == 2) {
                listings = [
                    { id: "live_listings", name: 'Ready listings', stat: 0, icon: UsersIcon, color: "green" }
                ];
                stats = [
                    //{ id: "ads", name: 'Ads', stat: 0, icon: DeviceMobileIcon, color: "yellow" },
                    { id: "legacy_feeds", name: 'Legacy feeds', stat: 0, icon: ClipboardListIcon, color: "orange" },
                    { id: "light_feeds", name: 'Light feeds', stat: 0, icon: ClipboardListIcon, color: "orange" },
                    { id: "agents", name: 'Agents', stat: 0, icon: UsersIcon, color: "gray" },
                ];
            }

            await this.promisedSetState({
                stats: stats,
                listings: listings,
                user: user,
                version: version
            });

            this.functions.getDashboard();

        },
        getDashboard: async () => {
            try {
                let response = await this.calls.dashboard();
                await this.promisedSetState({
                    dashboard: response.data,
                })
            } catch (error) { }
            await this.promisedSetState({ loading: false })
        }
    };

    calls = {
        dashboard: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/dashboard?version=" + this.state.version.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>
                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.image &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.image}
                                                    alt=""
                                                />
                                            }
                                            {
                                                !this.state.user.image &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">welcome {this.state.user.name}</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*AGENT HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 2 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.enterprise && this.state.user.enterprise.logo &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.enterprise.logo}
                                                    alt=""
                                                />
                                            }
                                            {
                                                (!this.state.user.enterprise || (this.state.user.enterprise && !this.state.user.enterprise.logo)) &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Welcome {this.state.user.name}</p>
                                        <p className="text-sm font-medium text-gray-600">{this.state.user.enterprise.companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="max-w-7xl mx-auto py-6 px-6 lg:px-8">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Listings</h3>
                        <dl className={("md:grid-cols-" + this.state.listings.length) + " mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x"}>
                            {this.state.listings.map((item) => (
                                <div onClick={() => {
                                    if (item.url) {
                                        this.props.history.push(item.url);
                                    }
                                }} key={item.name} className="px-4 py-5 sm:p-6 cursor-pointer">
                                    <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                            {this.state.dashboard[item.id] ? this.state.dashboard[item.id].toString() : 0}
                                        </div>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="max-w-7xl mx-auto py-6 pt-0 px-6 lg:px-8">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Overview</h3>
                        <dl className={("md:grid-cols-" + this.state.stats.length) + " mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x"}>
                            {this.state.stats.map((item) => (
                                <div key={item.name} className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                            {this.state.dashboard[item.id] ? this.state.dashboard[item.id].toString() : 0}
                                        </div>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                }

                {
                    false &&
                    !this.state.loading &&
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
                                {this.state.stats.map((item) => (
                                    <div onClick={() => {
                                        if (item.url) {
                                            this.props.history.push(item.url);
                                        }
                                    }} key={item.name} className={(item.url ? "cursor-pointer" : "") + " px-4 py-5 bg-white flex flex-row shadow rounded-lg overflow-hidden sm:p-6"}>
                                        <div>
                                            <div className={"h-16 w-16 mr-4 rounded-full border-" + item.color + "-400 bg-" + item.color + "-100 flex justify-center items-center"}>
                                                <item.icon className={"h-6 w-6 text-" + item.color + "-500"} aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="truncate">
                                            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                                            <dd className="mt-1 text-3xl font-semibold text-gray-900">{this.state.dashboard[item.id] ? this.state.dashboard[item.id].toString() : 0}</dd>
                                        </div>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <img
                                className="m-6 h-100 w-px-200"
                                src={require("../assets/images/social_spinn.png")}
                                alt="Workflow"
                            />
                        </div>
                    </div>
                }
                

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="w-full h-64 relative flex justify-center items-start">
                        <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                        </div>
                    </div>
                }

            </>
        )
    }
}

export default Dashboard;
