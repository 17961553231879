import React, { Component } from 'react';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';

class DisplayVertical extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: 3,
            duration: 100
        }
    };

    componentDidMount() {
        try {
            let url = window.location.search.replace("?", "");
            let queries = url.split("&");
            let object = {};
            queries.map((item) => {
                let query = item.split("=");
                if (query[0] == 'rows') {
                    query[1] = +query[1];
                }
                if (query[0] == 'duration') {
                    query[1] = +query[1];
                }
                object[query[0]] = query[1];
            });
            this.setState({ ...object, ...{ loading: false } });
        } catch (error) { }
    }

    functions = {
        getSite: async () => {
            try {
                let response = await this.calls.getSite();
                console.log(response);
            } catch (error) {
                this.setState({
                    message: error.body ? error.body.message : "",
                    error: true,
                    loading: false
                })
            }
        }
    };

    renders = {
        row: () => {
            return [
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390128/dev/Unique%20Properties/tp2vtfrogxvga2kzjdde.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390134/dev/Unique%20Properties/pi3ijgvjabntxq3hgefk.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390141/dev/Unique%20Properties/z7z9egusfxqt0vworil6.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390148/dev/Unique%20Properties/yx6hxokrzineyhytsbn7.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390155/dev/Unique%20Properties/gcfzncbpayhw8jolzzq7.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390161/dev/Unique%20Properties/trluutuxliajzqzppnls.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390168/dev/Unique%20Properties/hvxtwbhne9iuh3r0dlpa.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390175/dev/Unique%20Properties/miirpdflrbuyshtra0us.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390182/dev/Unique%20Properties/cxl4x066cmxzosemzjbs.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390189/dev/Unique%20Properties/a6ghrq7qrilglrseuc2b.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390197/dev/Unique%20Properties/ig0ulrluwfvloc3loerz.jpg',
                'https://res.cloudinary.com/propertybooster/image/upload/v1680390203/dev/Unique%20Properties/xkcrjs1dyoaw25hh2pld.jpg'
            ];
        }
    }

    calls = {
        getSite: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/lead/redirectSite?lead=" + this.props.match.params.lead;
            return apiRegister.call(options, url);
        }
    };

    render() {

        const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
            return (
                <div className='loop-slider' style={{
                    '--duration': `${duration}s`,
                    '--direction': reverse ? 'reverse' : 'normal',
                    height: (100 / this.state.rows) + "%"
                }}>
                    <div className='inner'>
                        {children}
                        {children}
                    </div>
                </div>
            );
        };

        return (
            <>
                <div style={{ backgroundColor: "#" + this.state.background_color }} className="w-full h-full fixed flex overflow-hidden justify-center items-start ">
                    {
                        this.state.loading &&
                        <div className="w-full h-full absolute bg-white top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                        </div>
                    }
                    {
                        !this.state.loading &&
                        <div className='flex flex-col relative overflow-hidden pt-2 pb-2 tag-list'>
                            {[...new Array(this.state.rows)].map((_, index) => (
                                <InfiniteLoopSlider key={index} duration={this.state.duration} reverse={index % 2}>
                                    {this.renders.row(index).map(url => (
                                        <div className='tag p-2'>
                                            <div>
                                                <img src={url} className="w-full w-auto" />
                                            </div>
                                        </div>
                                    ))}
                                </InfiniteLoopSlider>
                            ))}
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default DisplayVertical;
