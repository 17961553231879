import React, { Component, Fragment } from 'react';
import { PlusIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { ClipboardListIcon, CollectionIcon, CursorClickIcon, MailOpenIcon, SortAscendingIcon, SortDescendingIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import CreateUser from '../components/createUser';
import WarningModal from '../components/warningModal';
import { RefreshIcon, PlusCircleIcon, TrashIcon, PencilIcon, ArchiveIcon, ChevronDownIcon, CheckIcon, XIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import SingleSelection from '../components/singleSelection';
import { versionRegister } from '../services/versionRegister';
import Pusher from 'pusher-js';
var moment = require('moment');

var pusherService = null;

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListFeeds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_feeds: false,
            open: false,
            feeds: [],
            feed: {},
            user: {},
            feed_item: {},
            enterprise: { id: 0, name: "Select" },
            enterprises: [],
            version: {},
            tab: { id: 1, name: "Preview" },
            sort: 'lastUpdated',
            order: 'ascending',
            page: 1,
            page_count: 20,
            total: 0,
            overwrite_name: ''
        }
    };

    componentDidMount() {

        pusherService = new Pusher('a4ee0d05afe391530eb1', {
            cluster: 'ap1'
        });

        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
            this.functions.getEnterprise();
        },
        getEnterprise: async () => {
            try {
                let response = await this.calls.getEnterprise();
                this.setState({
                    enterprises: response.data,
                    loading: false
                })
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        },
        getFeeds: async () => {
            try {
                await this.promisedSetState({ loading_feeds: true });
                let response = await this.calls.getFeeds();
                await this.promisedSetState({
                    feeds: response.data,
                    total: response.meta ? response.meta.total : 0,
                    feed: {},
                    loading_feeds: false
                });
                this.functions.sideScroll('table');
            } catch (error) {
                console.log(error);
                await this.promisedSetState({ loading_feeds: false });
            }
        },
        sideScroll: (id) => {
            try {
                const slider = document.querySelector('#' + id);
                let isDown = false;
                let startX;
                let scrollLeft;

                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1; //scroll-fast
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        },
        /*
        combination: async (feed, overwrite) => {

            feed.loading = true;
            await this.promisedSetState({ feeds: this.state.feeds, overwrite: false });

            //CREATIVES
            let creatives = await this.functions.creatives(feed, overwrite);

            //UPDATE
            if (creatives) {
                await this.functions.update(feed);
            }

            feed.loading = false;
            await this.promisedSetState({ feeds: this.state.feeds });
        },
        update: async (feed) => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                try {
                    let response = await self.calls.updateFeed(feed);
                    feed.lastUpdated = response.data.lastUpdated;
                    feed.rows = response.data.rows;
                    feed.filePath = response.data.filePath;
                    if (response.data.status) {
                        feed.status = response.data.status;
                    }
                    feed.error = response.data.error;
                    self.state.feeds = self.state.feeds.map((item) => { if (item.id === feed.id) { item = feed }; return item });
                    resolve(true);
                } catch (error) {
                    if (error.body && error.body.message) {
                        await self.promisedSetState({
                            error: error.body.message
                        });
                    }
                    resolve(false);
                }
            })
        },
        creatives: async (feed, overwrite) => {
            let self = this;
            return new Promise(async function (resolve, reject) {

                //PUSHER
                var channel = pusherService.subscribe(self.state.user.id);
                channel.bind('feedCreatives', function (response) {
                    if (response.done) {
                        pusherService.unsubscribe(self.state.user.id);
                        resolve(true);
                    } else {
                        feed.creativesCreated = response.creativesCreated;
                        feed.creativesPending = response.creativesPending;
                        feed.creativesError = response.creativesError;
                        self.state.feeds = self.state.feeds.map((item) => { if (item.id === feed.id) { item = feed }; return item });
                        self.promisedSetState({ feeds: self.state.feeds });
                    }
                });

                try {
                    await self.calls.updateCreatives(feed, overwrite);
                } catch (error) {
                    if (error.body && error.body.message) {
                        await self.promisedSetState({
                            error: error.body.message
                        });
                    }
                    resolve(false);
                }
            });
        },
        */
        pusherListener: async (overwrite) => {
            let self = this;
            await self.promisedSetState({ sequence_loading: true });
            var channel = pusherService.subscribe(this.functions.convertUserIdToNumber());
            channel.bind('updateFeeds', async function (response) {
                if (response.last || response.error) {
                    self.promisedSetState({ sequence_loading: false });
                    pusherService.unsubscribe(self.functions.convertUserIdToNumber());
                    await self.promisedSetState({
                        select_all: false,
                        feeds: self.state.feeds.map((item) => {
                            item.loading = false;
                            item.selected = false;
                            return item
                        })
                    });
                } else {
                    self.state.feeds = self.state.feeds.map((feed) => {
                        if (feed.id === response.id) {
                            if (response.step === 'done') {
                                feed.loading = false;
                            } else if (response.step === 'init') {
                                feed.loading = true;
                            } else if (response.step === 'creatives') {
                                feed.creativesPending = response.creativesPending;
                                feed.creativesCreated = response.creativesCreated;
                                feed.creativesError = response.creativesError;
                            } else if (response.step === 'csv') {
                                feed = { ...feed, ...response.feed };
                            }
                        }
                        return feed;
                    });
                    self.promisedSetState({
                        feeds: self.state.feeds,
                    });
                }
            });
            await self.calls.updateFeeds({
                ids: self.state.feeds.filter((item) => { return item.selected }).map((item) => { return item.id })
            }, overwrite);
        },
        convertUserIdToNumber: () => {
            let number_string = "";
            for (var i = 0; i < this.state.user.id.length; i++) {
                number_string = number_string + stringToNumber(this.state.user.id.charAt(i));
            }
            return number_string;

            function stringToNumber(str) {
                if ((typeof str === "string" || str instanceof String) && /^[a-zA-Z]+$/.test(str)) {
                    str = str.toUpperCase();
                    let out = 0,
                        len = str.length;
                    for (let pos = 0; pos < len; pos++) {
                        out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
                    }
                    return out;
                } else {
                    return str;
                }
            }

        }
    };

    calls = {
        getEnterprise: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/enterprise/list?version=" + this.state.version.id;
            return apiRegister.call(options, url);
        },
        getFeeds: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed/list?enterprise=" + this.state.enterprise.id + "&page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order;
            return apiRegister.call(options, url);
        },
        /*
        updateFeed: (feed) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', null);
            let url = apiRegister.url.api + "/feed/update/" + feed.id;
            return apiRegister.call(options, url);
        },
        updateCreatives: (feed, overwrite) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', null);
            let url = apiRegister.url.api + "/feed/creative/" + feed.id + "?channel=" + this.state.user.id + (overwrite ? "&overwrite=true" : "");
            return apiRegister.call(options, url);
        },
        */
        updateFeeds: (data, overwrite) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/feed/update-bulk?channel=" + this.functions.convertUserIdToNumber() + (overwrite ? "&overwrite=true" : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.overwrite ? true : false}
                    title={"Overwrite feed"}
                    description={"Are you sure you want overwrite all creatives ?"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"overwrite"}
                    disableSubmitButton={this.state.overwrite && this.state.overwrite_name != this.state.overwrite.channel}
                    showInput={true}
                    copyInput={this.state.overwrite ? this.state.overwrite.channel : ""}
                    inputTitle={'Type "Channel" to overwrite'}
                    inputError={(this.state.overwrite && this.state.overwrite_name && this.state.overwrite_name !== "" && this.state.overwrite_name != this.state.overwrite.channel) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ overwrite: null })
                    }}
                    onSubmit={async (value) => {
                        if (this.state.overwrite && this.state.overwrite_name == this.state.overwrite.channel) {
                            this.state.feeds = this.state.feeds.map((item) => {
                                item.selected = item.id === this.state.overwrite.id;
                                return item;
                            });
                            await this.promisedSetState({ feeds: this.state.feeds, overwrite: false });
                            this.functions.pusherListener(true);
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ overwrite_name: value })
                    }}
                />

                {/*ERROR MODAL*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={"Something went wrong"}
                    description={this.state.error}
                    cancelButtonText={"cancel"}
                    submitButtonText={"ok"}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                    onSubmit={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center bg-orange-100 border-orange-500">
                                        <div>
                                            <ClipboardListIcon className="h-8 w-8 text-orange-500" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Legacy feeds</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">

                        {/*LIST FEEDS*/}
                        {
                            !this.state.loading &&
                            <section>
                                <div className="bg-white shadow sm:rounded-md">
                                    <div>
                                        <div className="flex items-center p-6 border-b">
                                            <div className="flex flex-1 flex-col">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">Manage feeds</h3>
                                                <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-6 gap-6 p-6">
                                            <div className={"col-span-6"}>
                                                <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                    Enterprise
                                                </label>
                                                <div className="mt-1">
                                                    <SingleSelection
                                                        select={async (option) => {
                                                            await this.promisedSetState({
                                                                enterprise: option,
                                                                feed: {},
                                                                page: 1
                                                            });
                                                            this.functions.getFeeds();
                                                        }}
                                                        disabled={this.state.loading_feeds}
                                                        selected={this.state.enterprise}
                                                        options={this.state.enterprises}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !this.state.loading_feeds &&
                                        this.state.enterprise.id !== 0 &&
                                        this.state.feeds.length > 0 &&
                                        <div id={"table"} className="flex flex-col overflow-x-scroll">
                                            <div className="sm:-mx-6 lg:-mx-8">
                                                <div className="align-middle inline-block min-w-full sm:pl-6 lg:pl-8">
                                                    <div className="border-t border-gray-200">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-100">
                                                                <tr>
                                                                    <th scope="col" className="relative px-6 py-3 border-r">
                                                                        <div className="flex flex-row">
                                                                            <div onClick={() => {
                                                                                if (!this.state.sequence_loading) {
                                                                                    this.state.feeds.map((item) => {
                                                                                        if (item.fileExist) {
                                                                                            item.selected = !this.state.select_all;
                                                                                        }
                                                                                    });
                                                                                    this.setState({
                                                                                        feeds: this.state.feeds,
                                                                                        select_all: !this.state.select_all
                                                                                    })
                                                                                }
                                                                            }} className={(this.state.select_all ? "border-indigo-500 bg-indigo-100" : "") + " h-8 w-8 flex justify-center items-center cursor-pointer rounded border-2 hover:border-indigo-500"}>
                                                                                {
                                                                                    this.state.select_all &&
                                                                                    <CheckIcon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col" className="relative px-6 py-3 border-r">
                                                                        <div className="flex flex-row">
                                                                            <div onClick={() => {
                                                                                if (!this.state.sequence_loading && this.state.feeds.filter((item) => { return item.selected && !item.loading }).length > 0) {
                                                                                    this.functions.pusherListener();
                                                                                }
                                                                            }} className={(!this.state.delete_loading && this.state.feeds.filter((item) => { return item.selected }).length > 0 ? "cursor-pointer" : "cursor-not-allowed opacity-50") + " inline-flex relative whitespace-no-wrap justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                Update
                                                                                {
                                                                                    this.state.sequence_loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Logs
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        File exist
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Error
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Status
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Matching listings
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Updated listings
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Unsynced listings
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Rows
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Channel
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-3 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Currency
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Language
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Type
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Plan
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Min Price
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Max Price
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                sort: "createdAt",
                                                                                page: 1,
                                                                                order: this.state.order == 'ascending' ? 'descending' : 'ascending'
                                                                            }, () => {
                                                                                this.functions.getFeeds();
                                                                            })
                                                                        }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r cursor-pointer hover:text-gray-600 text-left text-xs font-medium whitespace-no-wrap text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        <div className={(this.state.sort == "createdAt" ? "text-gray-900" : "") + " flex flex-row"}>
                                                                            <div className="mr-4">Created</div>
                                                                            {

                                                                                this.state.order == 'ascending' &&
                                                                                <SortAscendingIcon className={(this.state.sort == "createdAt" ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                            }
                                                                            {

                                                                                this.state.order == 'descending' &&
                                                                                <SortDescendingIcon className={(this.state.sort == "createdAt" ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                            }
                                                                        </div>
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                sort: "lastUpdated",
                                                                                page: 1,
                                                                                order: this.state.order == 'ascending' ? 'descending' : 'ascending'
                                                                            }, () => {
                                                                                this.functions.getFeeds();
                                                                            })
                                                                        }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r cursor-pointer hover:text-gray-600 text-left text-xs font-medium whitespace-no-wrap text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        <div className={(this.state.sort == "lastUpdated" ? "text-gray-900" : "") + " flex flex-row"}>
                                                                            <div className="mr-4"> Last updated</div>
                                                                            {

                                                                                this.state.order == 'ascending' &&
                                                                                <SortAscendingIcon className={(this.state.sort == "lastUpdated" ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                            }
                                                                            {

                                                                                this.state.order == 'descending' &&
                                                                                <SortDescendingIcon className={(this.state.sort == "lastUpdated" ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                            }
                                                                        </div>
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Filename
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Theme
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Custom
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Utm
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        scope="col"
                                                                        className="px-6 py-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Custom filter
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {
                                                                    this.state.feeds.map((feed) => (
                                                                        <tr key={feed.id}>
                                                                            <td className={(feed.fileExist ? "" : "opacity-0") + " px-6 py-4 border-r whitespace-nowrap text-right text-sm font-medium"}>
                                                                                {
                                                                                    (!this.state.sequence_loading || (this.state.sequence_loading && feed.selected)) &&
                                                                                    <div onClick={() => {
                                                                                        if (feed.fileExist) {
                                                                                            if (!this.state.sequence_loading) {
                                                                                                feed.selected = !feed.selected;
                                                                                                this.setState({
                                                                                                    listings: this.state.listings
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    }} className={(!feed.fileExist ? " cursor-not-allowed opacity-50" : "hover:border-indigo-500") + (feed.selected ? " border-indigo-500 bg-indigo-100" : "") + "  cursor-pointer border-2 h-8 w-8 flex justify-center items-center rounded"}>
                                                                                        {
                                                                                            feed.selected &&
                                                                                            <CheckIcon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                            <td className="px-6 py-4 border-r whitespace-nowrap text-left text-sm font-medium">
                                                                                <div className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                                                                    {
                                                                                        feed.fileExist &&
                                                                                        <Menu as="div" className="relative inline-block text-left">
                                                                                            <div>
                                                                                                <Menu.Button disabled={this.state.feeds.filter((item) => { return item.loading }).length > 0 || !feed.fileExist || this.state.sequence_loading} className="inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                                                                    Options
                                                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                                    {
                                                                                                        feed.loading &&
                                                                                                        <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                                class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                        </div>
                                                                                                    }
                                                                                                </Menu.Button>
                                                                                            </div>
                                                                                            <Transition
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-100"
                                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                                enterTo="transform opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-75"
                                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                                leaveTo="transform opacity-0 scale-95"
                                                                                            >
                                                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                                    <div className="py-1">
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={async () => {
                                                                                                                        if (feed.fileExist) {
                                                                                                                            window.location.assign("/dashboard/feeds/" + feed.id + "?enterprise=" + this.state.enterprise.id);
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        !feed.fileExist ? " cursor-not-allowed opacity-50" : "",
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Preview
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        window.open(feed.filePath, "_blank");
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Download
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                                                                                                                            return navigator.clipboard.writeText(feed.filePath);
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Copy url
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={async () => {
                                                                                                                        if (feed.fileExist) {
                                                                                                                            this.state.feeds = this.state.feeds.map((item) => {
                                                                                                                                item.selected = item.id === feed.id;
                                                                                                                                return item;
                                                                                                                            });
                                                                                                                            await this.promisedSetState({ feeds: this.state.feeds });
                                                                                                                            this.functions.pusherListener(false);
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active && feed.fileExist ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        !feed.fileExist ? " cursor-not-allowed opacity-50" : "",
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Update
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        if (feed.fileExist) {
                                                                                                                            this.setState({
                                                                                                                                overwrite: feed
                                                                                                                            })
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active && feed.fileExist ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        !feed.fileExist ? " cursor-not-allowed opacity-50" : "",
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Update (overwrite)
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    </div>
                                                                                                </Menu.Items>
                                                                                            </Transition>
                                                                                        </Menu>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                {
                                                                                    (feed.creativesCreated || feed.creativesError || feed.creativesPending) &&
                                                                                    <span className={"inline-flex text-xs font-medium flex-row"}>
                                                                                        <div className="mr-1">
                                                                                            Created: {feed.creativesCreated},
                                                                                        </div>
                                                                                        <div className="text-red-500 mr-1">
                                                                                            Error: {feed.creativesError},
                                                                                        </div>
                                                                                        <div>
                                                                                            Total: {feed.creativesPending}
                                                                                        </div>
                                                                                    </span>
                                                                                }
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.fileExist ? "true" : "false"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.error ? "true" : "false"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {
                                                                                        feed.fileExist && !feed.error &&
                                                                                        <span className={(feed.status === "sync" ? "border-green-500 bg-green-100 text-green-500" : "border-red-500 bg-red-100 text-red-500") + " inline-flex items-center border px-4 py-1 rounded-md text-sm font-medium "}>
                                                                                            {feed.status}
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        feed.fileExist && feed.error &&
                                                                                        <span className={"border-red-500 bg-red-100 text-red-500 inline-flex items-center border px-4 py-1 rounded-md text-sm font-medium "}>
                                                                                            {"error"}
                                                                                        </span>
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.listingsTotal}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.updatedListingsTotal}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.unsyncedListingsTotal}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.rows}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.channel}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.currency}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.language}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.type}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.property ? feed.property : "both"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.minPrice ? feed.minPrice : "-"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.maxPrice ? feed.maxPrice : "-"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.createdAt ? (moment(feed.createdAt).format("YYYY-MM-DD hh:mm a")) : "-"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.lastUpdated ? (moment(feed.lastUpdated).format("YYYY-MM-DD hh:mm a")) : "-"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex items-center text-sm font-medium "}>
                                                                                    {feed.publicId}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex whitespace-nowrap items-center text-sm font-medium "}>
                                                                                    {feed.theme ? feed.theme.name : "-"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex whitespace-nowrap items-center text-sm font-medium "}>
                                                                                    {feed.custom ? feed.custom.name : "disabled"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex whitespace-nowrap items-center text-sm font-medium "}>
                                                                                    {feed.utm ? feed.utm : "-"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ whiteSpace: "nowrap" }} className="px-6 py-4 whitespace-nowrap">
                                                                                <span className={"inline-flex whitespace-nowrap items-center text-sm font-medium "}>
                                                                                    {feed.customFilterValue ? feed.customFilterValue + (feed.customFilterInclude ? ' (incl)' : ' (excl)') : feed.customFilterKey && feed.customFilterEmpty ? (feed.customFilterInclude ? feed.customFilterKey + ' (incl empty)' : feed.customFilterKey + ' (excl empty)') : feed.customFilterKey && !feed.customFilterEmpty ? "not supported" : "-"}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        !this.state.loading_feeds &&
                                        this.state.enterprise.id !== 0 &&
                                        this.state.feeds.length > 0 &&
                                        <div className="rounded-b-md bg-gray-50">
                                            <nav
                                                className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className="hidden sm:block">
                                                    <p className="text-sm text-gray-700">
                                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.feeds.length ? this.state.feeds.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                                        <span className="font-medium">{this.state.total}</span> results
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex justify-between sm:justify-end">
                                                    <Menu as="div" className="relative inline-block text-left mr-2">
                                                        <div>
                                                            <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                Page limit: {this.state.page_count}
                                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {
                                                                        [1, 10, 20, 30, 50, 100].map((item) => {
                                                                            return (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    page_count: item,
                                                                                                    page: 1
                                                                                                }, () => {
                                                                                                    this.functions.getFeeds();
                                                                                                })
                                                                                            }}
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                this.state.page_count == item ? "text-purple-500" : ""
                                                                                            )}
                                                                                        >
                                                                                            {item}
                                                                                        </div>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                    <div
                                                        onClick={() => {
                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            }, () => {
                                                                this.functions.getFeeds();
                                                            });
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.getFeeds();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }

                                    {
                                        (this.state.loading_feeds || this.state.loading_feed) &&
                                        <div className="w-full h-64 relative flex justify-center items-start border-t">
                                            <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </section>
                        }

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default ListFeeds;
