import React, { Component } from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { CursorClickIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Finance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            stats: []
        }
    };

    componentWillMount() {
        this.functions.getUser();
    }

    componentDidMount() { }

    functions = {
        getUser: () => {
            let user = userRegister.get();
            this.setState({
                user: user
            })
        }
    };

    render() {
        return (
            <>
                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.image &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.image}
                                                    alt=""
                                                />
                                            }
                                            {
                                                !this.state.user.image &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Finance</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*AGENT HEADER*/}
                {
                    this.state.user && this.state.user.level.id == 2 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.enterprise && this.state.user.enterprise.logo &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.enterprise.logo}
                                                    alt=""
                                                />
                                            }
                                            {
                                                (!this.state.user.enterprise || (this.state.user.enterprise && !this.state.user.enterprise.logo)) &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Finance</p>
                                        <p className="text-sm font-medium text-gray-600">{this.state.user.enterprise.companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </>
        )
    }
}

export default Finance;
