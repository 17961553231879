import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateLeadNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            feeds: [],
            listings: [],
            form: {},
            lead: {},
            client: {},
            message: ""
        }
    };

    componentDidMount() {
        this.functions.listFeeds();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            form: nextProps.form ? nextProps.form : {},
            client: nextProps.client ? nextProps.client : {}
        });
    }

    functions = {
        listFeeds: async () => {
            try {
                await this.promisedSetState({ loading_feeds: true });
                let response = await this.calls.listFeeds();
                await this.promisedSetState({
                    feeds: response.data
                })
            } catch (error) { }
            await this.promisedSetState({ loading_feeds: false });
        },
        createLead: async () => {

            console.log(this.state.lead);

            try {
                if (this.props.view == 'form') {

                    this.props.toggle();

                    let form_url = "";
                    if (window.location.hostname === 'localhost') {
                        form_url = "http://localhost:5001/form/";
                    } else if (window.location.hostname === 'app.adbooster.se') {
                        form_url = "https://form.adbooster.se/";
                    } else if (window.location.hostname === 'app.adbooster.ai') {
                        form_url = "https://form.adbooster.ai/";
                    }

                    //TODO: append utms
                    window.open(form_url + this.state.form.id + "?utm_listing=" + this.state.lead.listing.externalId + "&utm_feed=" + this.state.lead.feed.id, '_blank');

                } else {

                    let form_id = this.state.lead.feed.form.id;

                    await this.promisedSetState({ loading: true, error: false });
                    this.state.lead.client = { id: this.state.client.id };
                    this.state.lead.feed = { id: this.state.lead.feed.id };
                    this.state.lead.listing = { externalId: this.state.lead.listing.externalId };
                    let lead_response = await this.calls.createLead(this.state.lead);

                    this.props.toggle();

                    let form_url = "";
                    if (window.location.hostname === 'localhost') {
                        form_url = "http://localhost:5001/form/";
                    } else if (window.location.hostname === 'app.adbooster.se') {
                        form_url = "https://form.adbooster.se/";
                    } else if (window.location.hostname === 'app.adbooster.ai') {
                        form_url = "https://form.adbooster.ai/";
                    }

                    //TODO: append utms
                    window.open(form_url + form_id + "/" + lead_response.data.id, '_blank');

                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        }
    };

    calls = {
        listFeeds: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed-light";
            return apiRegister.call(options, url);
        },
        listClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/client/listClients";
            return apiRegister.call(options, url);
        },
        createLead: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/lead/createLeadFromClient";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-4xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">{this.props.view === 'form' ? "New Lead (unlock)" : "New Lead"}</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto pt-6">

                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="lead-feed" className="block text-sm font-medium text-gray-900">
                                                                Feeds
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.lead.feed = option;
                                                                        this.setState({
                                                                            lead: this.state.lead
                                                                        })
                                                                    }}
                                                                    selected={this.state.lead.feed ? this.state.lead.feed : { id: 0, name: "Select feed" }}
                                                                    options={this.state.feeds}
                                                                    name="lead-feed"
                                                                    id="lead-feed"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 sm:px-6 relative">
                                                    {
                                                        !(this.state.lead.feed && this.state.lead.feed.id) &&
                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                    }
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="lead-listing" className="block text-sm font-medium text-gray-900">
                                                                Listing
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.lead.listing = option;
                                                                        this.setState({
                                                                            lead: this.state.lead
                                                                        })
                                                                    }}
                                                                    selected={this.state.lead.listing ? this.state.lead.listing : { id: 0, name: "Select listing" }}
                                                                    options={this.state.lead.feed && Array.isArray(this.state.lead.feed.listings) ? this.state.lead.feed.listings.map((item) => {
                                                                        item.name = item.externalId;
                                                                        item.image = Array.isArray(item.imagesInternal) && item.imagesInternal.length > 0 ? item.imagesInternal[0] : "";
                                                                        return item;
                                                                    }) : []}
                                                                    name="lead-listing"
                                                                    id="lead-listing"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.error &&
                                                <div className="px-6 pb-6">
                                                    <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <ul role="list" className="list-disc pl-5 space-y-1">
                                                                        <li>{this.state.error}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-shrink-0 px-4 sm:px-6 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    this.functions.createLead();
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <span>Create</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateLeadNew;
