import React, { Component } from 'react';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';

class LoginManagerProptree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false
        }
    };

    functions = {
        loginUser: () => {
            this.setState({
                loading: true
            }, async () => {
                try {
                    let response = await this.calls.loginUser({
                        email: this.state.email,
                        password: this.state.password
                    });
                    userRegister.set(response.data);
                    this.props.history.push("/manager");
                } catch (error) {
                    this.setState({
                        message: error.body ? error.body.message : "",
                        error: true,
                        loading: false
                    })
                }
            })
        }
    };

    calls = {
        loginUser: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/user/login";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                <div style={{ height: "100vh", backgroundColor: "#F3F3F1" }} className="min-h-full background-pattern flex flex-col justify-center py-12 px-6">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md items-center">
                        <div className="w-full flex justify-center items-center">
                            <img
                                className="w-24 mb-6 sm:w-32 md:w-32"
                                src={require("../../assets/images/Proptree_Logotype_RGB_Black.svg")}
                                alt="Workflow"
                            />
                        </div>
                        <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl sm:max-w-130 text-center sm:text-2xl md:text-7xl">
                            Manager
                        </div>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div style={{ borderRadius: "20px" }} className="bg-white py-8 px-4 shadow sm:px-10">
                            <div className="space-y-6">
                                <div>
                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-12">
                                            <label style={{ fontSize: "1.1rem" }} htmlFor="email" className={(this.state.error ? "text-red-700" : "text-gray-700") + " block font-medium "}>
                                                Email
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="email"
                                                    onChange={(event) => {
                                                        this.setState({
                                                            email: event.target.value
                                                        })
                                                    }}
                                                    disabled={this.state.loading}
                                                    value={this.state.email}
                                                    required
                                                    style={{ fontSize: "1.1rem" }}
                                                    className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block h-12 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <label style={{ fontSize: "1.1rem" }} htmlFor="email" className={(this.state.error ? "text-red-700" : "text-gray-700") + " block font-medium "}>
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="password"
                                                    onChange={(event) => {
                                                        this.setState({
                                                            password: event.target.value
                                                        })
                                                    }}
                                                    disabled={this.state.loading}
                                                    value={this.state.password}
                                                    required
                                                    style={{ fontSize: "1.1rem" }}
                                                    className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block h-12 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            if (!this.state.loading) {
                                                this.functions.loginUser();
                                            }
                                        }}
                                        style={{ fontSize: "1.1rem", backgroundColor: "#1E2330" }}
                                        className="w-full flex h-12 overflow-hidden rounded-full items-center text-white relative justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-offset-2"
                                    >
                                        {
                                            this.state.loading &&
                                            <div style={{ backgroundColor: "#1E2330" }} className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-6 h-6 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        Login
                                    </button>
                                </div>
                                {
                                    this.state.error &&
                                    <div className="flex items-center justify-center mt-3 font-medium text-sm text-red-600 hover:text-red-500">
                                        {this.state.message}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="w-full flex flex-row font-medium text-sm text-gray-600 justify-center items-center mt-4">
                            <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/policy/privacy">Privacy Policy</a>
                            <div className="ml-2 mr-2 text-xxs">|</div>
                            <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/policy/terms">Terms</a>
                            <div className="ml-2 mr-2 text-xxs">|</div>
                            <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/policy/use">Use Policy</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LoginManagerProptree;
